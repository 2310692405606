import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as Constants from "src/app/constants/constants";
import { CurrencyPipe } from '@angular/common';
import { Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { TabControlService } from 'src/app/services/tab-control.service';
import { ValidationService } from 'src/app/services/validators/validation.service';
import { NotificationService } from 'src/app/services/notification.service';
import { EstateControlService } from 'src/app/services/estate-control.service';
import { decodeSessionData, delay, generateRandomNumber, getValueByIdOrDescription, SessionData, truncateString } from 'src/app/util/Helper';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-step2-payment-will',
  templateUrl: './step2-payment-will.component.html',
  styleUrls: ['./step2-payment-will.component.scss']
})
export class Step2PaymentWillComponent {
  private subscription: Subscription = new Subscription();
  
  session: string = ""; // used to resume session
  sessionData: SessionData | string = "error";
  
  title: string = 'Make Payment';
  subtitle: string = 'To create your Trust, please pay the amount below through either a Bank Transfer (RTGS) or M-PESA';
  paymentTitle: string = 'Payment';
  paymentDetailsTitle: string = 'Payment Details';

  paymentModeLabel: string = 'Preferred Mode of Payment';
  phoneLabel: string = 'M-Pesa Phone Number';

  bankLabel: string = 'Bank Name';
  bankHint: string = 'Select Bank';

  bankBranchLabel: string = 'Bank Branch';
  bankBranchHint: string = 'Select Bank Branch';

  accNameLabel: string = 'Account Name';
  accNameHint: string = 'Enter Account Name';

  accLabel: string = 'Account Number';
  accHint: string = 'Enter Account Number';

  uploadSlipLabel: string = 'Upload Bank Slip/Remittance Advice';

  instructionsTitle: string = 'Payment Instructions';
  instructionsTitle2: string = 'Having Issues with M-Pesa Express?';

  instructionsTitleBank: string = 'ICEA LION Trust Co. Bank Deposit/Transfer Details';
  inst1: string = 'Enter your phone number and the amount you wish to pay';
  inst2: string = 'Click on the "Submit Button" below. You should receive a prompt on your phone to enter your M-Pesa PIN';
  inst3: string = 'You will receive a notification on your email once your transaction is complete';
  
  inst4: string = 'You can deposit directly via M-Pesa Paybill Number below:';
  inst5: string = 'Paybill Business number: ';
  inst6: string = 'Account number: ';
  
  trustName: string = 'TrustName';
  randomSuffix: number = generateRandomNumber();
  refValue: string = '';

  referreeTitle: string = 'Referral Details';
  referreLabel: string = 'Who Introduced you to Milele Trust';
  referreeOtherHint: string = 'Please tell us more';
  referreeOtherLabel: string = 'Other source';
  agentLabel: string = 'Name of Agent';
  agentHint: string = 'Enter Name';
  selectText: string = 'Select one';

  iceaFundLabel: string = 'Fund';
  iceaAcc: string = 'Account Number';
  iceaBank: string = 'Bank';
  iceaBranch: string = 'Branch';
  iceaBankCode: string = 'Bank Code';
  iceaBranchCode: string = 'Branch Code';
  iceaClearing: string = 'Clearing';

  iceaFundNameVal  : string = Constants.ICEA_ESTATE_NAME;
  iceaAccountVal : string = Constants.ESTATE_ACCOUNT_NUM;
  iceaBankVal : string = Constants.ICEA_BANK;
  iceaBranchVal : string = Constants.ICEA_BANK_BRANCH;
  iceaBankCodeVal : string = Constants.ICEA_BANK_CODE;
  iceaBranchCodeVal : string = Constants.ICEA_BRANCH_CODE;
  iceaClearingVal : string = Constants.ICEA_CLEARING;
  willAmount: number = Constants.WILL_CREATIION_FEE;
  iceaPaybill : number = Constants.PAYBILL_ESTATE;

  requiredFieldString: string = 'Required field';
  PaymentModeOptions: any = [
    'M-Pesa',
    'Cash Deposits/Bank Transfer'
  ];
  BranchPlaceHolders: any = [];

  loadingBanks: boolean = false;
  loadingBankBranches: boolean = false;
  banksEmpty: boolean = true;
  bankBranchesEmpty: boolean = true;

  ccEmails: any = [];


  formErrors: { [key: string]: string } = {
    PaymentMode: '',
    amount: '',
    phone: '',
    bank: '',
    bankBranch: '',
    accName: '',
    acc: '',
    slipDoc: '',
    email: '',
  };
  validationMessages: { [key: string]: {} } = {
    PaymentMode: { required: this.requiredFieldString },
    amount: { required: this.requiredFieldString, cMin:'Invalid amount' },
    phone: { required: this.requiredFieldString, invalid:'Invalid phone number' },
    bank: { required: this.requiredFieldString },
    bankBranch: { required: this.requiredFieldString },
    accName: { required: this.requiredFieldString },
    acc: { required: this.requiredFieldString },
    slipDoc: { required: this.requiredFieldString },
    email: { required: this.requiredFieldString, email: "Invalid email address" },
  };

  
  // Form
  paymentForm = this._formBuilder.group({
    PaymentMode: [this.PaymentModeOptions[0], Validators.required],
    // amount: ['', Validators.required],  // UAT remove 5k limit
    phone: ['', Validators.required],
    
    bank: [{value: '', disabled: true}],
    bankBranch: [{value: '', disabled: true}],
    accName: [''],
    acc: [''],
    slipDoc: [],

    referree: ['', Validators.required],
    referreeName: [''],
    other: ['']
  });

  constructor(
    private _formBuilder: FormBuilder,
    private validationService: ValidationService,
    private apiService: ApiService,
    private notificationService: NotificationService,
    private tabControlService: TabControlService,
    public estateControlService: EstateControlService,
    private currencyPipe: CurrencyPipe,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.BanksOptions.length == 0) this.getBanksList();

    if (this.testators && this.testators.length > 0) {
      for (let i=1; i<this.testators.length; i++) {
        const item = {
          FirstName: this.testators[i].FirstName,
          Email: this.testators[i].Email,
          Phone: this.testators[i].Phone
        };
        this.ccEmails.push(item);
      }
      this.trustName = `${this.testators[0].FirstName}-${this.testators[0].LastName}`;
    }

    this.route.queryParams.subscribe((params) => {
      // console.log('Params---> ', params); // log route params

      if (params["session"] != undefined) {
        this.session = params["session"];
        const sessionData = decodeSessionData(this.session);

        if (this.isSessionData(sessionData) && sessionData.Referree) {
          this.sessionData = sessionData;
          if (sessionData.Referree.Type && sessionData.Referree.Code) {
            if (this.paymentForm) {
              if (sessionData.Referree.Type == 'agent') {
                this.refValue = this.HearAboutusData[2];

              } else if (sessionData.Referree.Type == 'fubro') {
                  this.refValue = this.HearAboutusData[3];

              } else if (sessionData.Referree.Type == 'tla') {
                this.refValue = this.HearAboutusData[1];

              } else if (sessionData.Referree.Type == 'con') {
                this.refValue = this.HearAboutusData[0];
              }

              this.paymentForm.get('referree')?.setValue(this.refValue);
              this.checkIntermediary();
            }
            this.notificationService.viewToast('info', 'Referral details updated'); 
          }
        }
      }
    });

    // Resume page
    if (Object.keys(this.payment).length > 5 && this.paymentForm) {
      this.paymentForm = this._formBuilder.group({
        PaymentMode: [this.payment.PaymentMode || this.PaymentModeOptions[0], Validators.required],

        phone: [this.payment.phone || '', Validators.required],
        bank: [{value: '', disabled: true}],
        bankBranch: [{value: '', disabled: true}],
        accName: [this.payment.accName || ''],
        acc: [this.payment.acc || ''],
        slipDoc: [],

        referree: [this.payment.referree || '', Validators.required],
        referreeName: [this.payment.referreeName || ''],
        other: [this.payment.other || ''],
      });
    }else {
      this.estateControlService.paymentID = `WILL-${this.testators[0].IDNo || 'ID'}-${truncateString(this.trustName)}-${this.randomSuffix}`;
      this.estateControlService.updateStorageObjectWill();
    }
    
    this.subscription.add(
      this.estateControlService.updateTrustDetails$.subscribe(paymentMode => {
        this.updateTrustDetails(paymentMode);
      })
    );

    this.paymentForm.get('PaymentMode')?.valueChanges.subscribe((data) => {
      if (data === 'M-Pesa') {
        this.validationService.addOrRemoveValidator(true, this.paymentForm.get('phone')!);

        this.validationService.addOrRemoveValidator(false, this.paymentForm.get('bank')!);
        this.validationService.addOrRemoveValidator(false, this.paymentForm.get('bankBranch')!);
        this.validationService.addOrRemoveValidator(false, this.paymentForm.get('accName')!);
        this.validationService.addOrRemoveValidator(false, this.paymentForm.get('acc')!);
        this.validationService.addOrRemoveValidator(false, this.paymentForm.get('slipDoc')!);

        this.paymentForm.get('bank')?.setValue('');
        this.paymentForm.get('bankBranch')?.setValue('');
        this.paymentForm.get('accName')?.setValue('');
        this.paymentForm.get('acc')?.setValue('');
        this.paymentForm.get('slipDoc')?.setValue(null);
      
      } else {
        this.validationService.addOrRemoveValidator(true, this.paymentForm.get('bank')!);
        this.validationService.addOrRemoveValidator(true, this.paymentForm.get('bankBranch')!);
        this.validationService.addOrRemoveValidator(true, this.paymentForm.get('accName')!);
        this.validationService.addOrRemoveValidator(true, this.paymentForm.get('acc')!);
        this.validationService.addOrRemoveValidator(true, this.paymentForm.get('slipDoc')!);

        this.validationService.addOrRemoveValidator(false, this.paymentForm.get('phone')!);
        this.paymentForm.get('phone')?.setValue('');

        if (this.BanksOptions.length === 0) this.getBanksList();
        else this.paymentForm.get('bank')?.enable()
      }
    });
    this.paymentForm.get('referree')?.valueChanges.subscribe((value) => {
      if (value) {
        
        if (this.session !== "") {
          try {
            const sessionData = decodeSessionData(this.session);
    
            if (this.isSessionData(sessionData) && sessionData.Referree && sessionData.Referree.Type && sessionData.Referree.Code
                && value !== this.refValue) {
    
              this.paymentForm.get('referree')?.setValue(this.refValue);
              this.notificationService.viewToast('info', 'This session contains a referral from the agent')
            }
          } catch (ex) {console.log('error ? ', ex)};
        }
        
        this.checkIntermediary();
      }
    });

    this.paymentForm.valueChanges.subscribe(()=> {
      if (this.paymentForm && this.paymentForm.errors)
        this.logErrors();
    })

    this.validationService.addOrRemoveValidationOnValues(
      this.paymentForm.get("referree")!,
      "referreeName",
      this.paymentForm,
      ["Independent Agent", "Broker", "ICEA LION Financial Advisor", "ICEA LION Trust Consultant"]
    );
    this.validationService.addOrRemoveValidationOnValue(
      this.paymentForm.get("referree")!,
      "other",
      this.paymentForm,
      "Other"
    );
  }

  logErrors() {
    this.formErrors = this.validationService.logValidationErrors(
      this.paymentForm,
      this.formErrors,
      this.validationMessages
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get loading(): boolean {
    return this.notificationService.loading;
  }
  get paymentID(): string {
    return this.estateControlService.paymentID;
  }
  get stkRequestID(): string {
    return this.estateControlService.stkRequestID;
  }
  get BanksOptions(): any {
    return this.tabControlService.BanksOptions;
  }

  get testators(): any {
    return this.estateControlService.testators;
  }
  get beneficiaries(): any {
    return this.estateControlService.beneficiaries;
  }
  get assets(): any {
    return this.estateControlService.assets;
  }
  get executors(): any {
    return this.estateControlService.executors;
  }
  get witnesses(): any {
    return this.estateControlService.witnesses;
  }
  get payment(): any {
    return this.estateControlService.payment;
  }
  get summary(): string {
    return this.estateControlService.summary;
  }
  get HearAboutusData(): any {
    return this.tabControlService.HearAboutusData;
  }
  get loadingIntermediaries(): boolean {
    return this.tabControlService.loadingIntermediaries;
  }
  get IntermediaryOptions(): any {
    return this.tabControlService.IntermediaryOptions;
  }

  slipObject: any = null;

  updateSlipObject(object: any): void {
    this.slipObject = object;
  }
  isSessionData(obj: any): obj is SessionData {
    return (
      typeof obj === 'object'
    );
  }

  async checkIntermediary(): Promise<void> {
    this.paymentForm.get('referreeName')?.setValue('');
    
    const data = this.paymentForm.get('referree')?.value;

    if (data) {
      console.log('acsac ', data)
      if (data.toLowerCase() == 'icea lion trust consultant') {
        await this.tabControlService.getConsultants();

      } else {
        if (data.toLowerCase() == 'independent agent') {
          await this.tabControlService.getIntermediaries('agent');

        } else if (data.toLowerCase() == 'broker') {
          await this.tabControlService.getIntermediaries('Fubro');
          
        } else if (data.toLowerCase() == 'icea lion financial advisor') {
          await this.tabControlService.getIntermediaries('tla');
        }
      }

      if (this.isSessionData(this.sessionData)) {
  
        if (this.sessionData.Referree) {
          if (this.sessionData.Referree.Type && this.sessionData.Referree.Code) {
  
            if (this.IntermediaryOptions.length > 0) {
              // this.trustForm.get('referree')?.disable();
              this.paymentForm.get('referreeName')?.setValue(
                getValueByIdOrDescription(this.sessionData.Referree.Code, this.IntermediaryOptions, 0)
              );
              this.paymentForm.get('referreeName')?.disable();
            }
          }
        }
      }
    }
  }
  async getBanksList() {
    this.loadingBanks = true;

    const response = await this.tabControlService.getBanksList();
    if (response === 1) {
      this.paymentForm.get('bank')?.enable();
      this.banksEmpty = false;
    } else {
      this.banksEmpty = true;
    }
    this.loadingBanks = false;
  }
  async populateBankBranchesList() {
    this.loadingBankBranches = true;
    this.BranchPlaceHolders = [];
    
    try {
      const bankValue = this.paymentForm.get('bank')?.value;
  
      if (!(bankValue === null || bankValue ==='' || bankValue === undefined)) {
        
        const branchRequest = {
          BankID: `${bankValue}`,
        };
        const response = await this.apiService.postRequest(
          environment.baseUrl + Constants.bankBranchesURL,
          branchRequest
        );

        if (response.Status === 1) {
          const sortedData = response.Data.sort((a: any, b: any) => {
            return a.Description.localeCompare(b.Description);
          });
          this.BranchPlaceHolders = sortedData;
          this.paymentForm.get('bankBranch')?.enable();
          this.bankBranchesEmpty = false;
        } else {
          console.error(response.Message);
          this.bankBranchesEmpty = true;
        }
        this.loadingBankBranches = false;
      }
    } catch(error) {
      console.log('>>> Error !! ', error);
    }
    this.loadingBankBranches = false;
  }

  async mpesaPayment(isManual: boolean = false): Promise<void> {
    if (this.paymentForm.invalid) {
      this.notificationService.viewToast('warning', 'Please fill in the payment details first');
      return;
    }
    
    this.notificationService.isLoading(true);
    await this.estateControlService.updateRecordWill(2, this.paymentForm.value);

    try {
      let response;

      if (isManual) {
        response = {
          Status: 1
        }

      } else {
        const data = {
          Amount: environment.production ? this.willAmount : '1',
          Phone: this.paymentForm.value.phone,
          BillRefNo: this.paymentID,
          TrustName: this.trustName,
          Paybill: 'estate'
        };
  
        response = await this.apiService.postRequest(
          environment.baseUrl + Constants.mpesaPaymentURL, data);
      }

      if (response.Status === 1) {
        if (isManual) this.notificationService.viewToast('info', 'Checking M-Pesa Payment status');
        else this.notificationService.viewToast('info', 'M-Pesa Payment requested');

        if (environment.production) {
          this.estateControlService.updateSTK(this.willAmount.toString(), this.paymentForm.value.phone as string);
        } else {
          this.estateControlService.updateSTK('1', this.paymentForm.value.phone as string);
        }
        this.estateControlService.stkPinText = this.estateControlService.stkDefPinText;
        this.notificationService.isLoading(false);
        this.estateControlService.stkLoading = true;

        if (isManual) await delay(10000);
        else await delay(30000);

        await this.retryMpesaPaymentConfirmation()
        .then(async (result) => {
          // console.log('Final Result:', result);

          if (result.Status === 1) {
            this.estateControlService.stkRequestID = result.Reference;

            const object = {done: true, mode: 'MPESA'};
            await this.estateControlService.updateRecordWill(2, object);

            this.notificationService.viewToast('info', 'Payment received');
            this.estateControlService.stkLoading = false;
            await this.updateTrustDetails('MPESA');
            
          } else if (result.Status === 2) {
            this.notificationService.viewToast('error', 'Could not confirm your payment');
            this.estateControlService.stkTimeout = true;
            
          } else {
            // Error occurred!!
            this.notificationService.viewToast('error', result.Message);
            this.estateControlService.stkTimeout = true;
          }
          
        })
        .catch((error) => {
          console.error('Error:', error);
          this.notificationService.viewToast('error', 'An unexpected error occurred');
        }); 
      } else {
        console.error('MpesaPayment : ', response.Message);
        this.notificationService.isLoading(false);
        this.notificationService.viewToast('error', response.Message);
      }
    } catch (error) {
      console.error('MpesaPayment ! ', error);
      this.notificationService.isLoading(false);
      this.notificationService.viewToast('error', 'An unexpected error occurred');
    }
  }
  async retryMpesaPaymentConfirmation(maxAttempts: number = 5): Promise<any> {
    let attempts = 0;

    const confirmMpesaPayment = async (): Promise<any> => {
      try {
        const data = {
          BillRefNo: this.paymentID,
          Phone: this.paymentForm.value.phone
        };
  
        const response = await this.apiService.postRequest(
          environment.baseUrl + Constants.confirmMPESAPaymentURL, data);
  
        if (!environment.production) console.log(':::: RESPONSE !!!!\n', response);
  
        if (response && response.Status) {
          return response;
        } else {
          return {
            Status: 0,
            Message: 'Error contacting server'
          };
  
        }
        
      } catch (error) {
        let resp = {
          Status: 0,
          Message: 'An unexpected error occurred'
        }
        console.log(':: Error !! ', error);
  
        return resp;
      }
    }
  
    async function tryConfirmation() {
      attempts++;
      if (!environment.production) console.log(`Attempt ${attempts}`);
  
      const response = await confirmMpesaPayment();
  
      if (response.Status === 1) {
        // console.log('Payment confirmed successfully');
        return response;
        
      } else if (response.Status === 2) {
        if (attempts < maxAttempts) {
          await delay(15000); // Wait for 15 seconds
          return tryConfirmation();
        } else {
          // console.log('Max retry attempts reached');
          return {
            Status: 2,
            Message: 'Max retry attempts reached'
          };
        }
        
      } else {
        // console.log('Max retry attempts reached');
        return {
          Status: 0,
          Message: response.Message
        };
      }
    }
  
    return tryConfirmation();
  }

  async rtgsPayment(): Promise<void> {
    const object = {...this.paymentForm.value, ...{mode: 'RTGS'}};
    await this.estateControlService.updateRecordWill(2, object);
    this.updateTrustDetails('RTGS');
  }

  async updateTrustDetails(paymentMode: string): Promise<void> {
    this.notificationService.isLoading(true);
    try {
      let agentCode = '';
      let agentPhone = '';

      if (this.paymentForm.controls['referreeName'].value && this.paymentForm.controls['referreeName'].value !== '') {
        agentCode = getValueByIdOrDescription(this.paymentForm.controls['referreeName'].value, this.IntermediaryOptions, 2);
        agentPhone = getValueByIdOrDescription(this.paymentForm.controls['referreeName'].value, this.IntermediaryOptions, 1);
      }

      const data = {
        Progress: 'Payment',
        PaymentStatus: 'True',
        TrustName: this.trustName,
        MpesaRefNo: this.stkRequestID,
        Amount: this.willAmount,
        BankSlipUrl: this.slipObject,

        PaymentMode: paymentMode, // MPESA / RTGS
        
        Referree: this.paymentForm.value.referree == 'Other' ? this.paymentForm.value.other : this.paymentForm.value.referree,
        AgentName: this.paymentForm.value.referreeName,
        AgentCode: agentCode,
        AgentPhoneNo: agentPhone,
        
        Phone: this.paymentForm.value.phone,
        BankName: this.paymentForm.value.bank,
        BankBranch: this.paymentForm.value.bankBranch,
        AccountName: this.paymentForm.value.accName,
        AccountNo: this.paymentForm.value.acc,
        
        CCEmails: this.ccEmails,
        Name: this.estateControlService.testators[0].FirstName,
        Email: this.estateControlService.testators[0].Email,
        PrimaryPhone: this.estateControlService.testators[0].Phone,
        TrustID: this.estateControlService.TrustID,
      };

      const response = await this.apiService.postRequest(
        environment.baseUrl + Constants.willDetailsURL, data, false);
      
      // console.log('::::: RESPONSE ::::::\n', response);
      if (response.Status === 1) {
        if (paymentMode == 'RTGS') {
          this.notificationService.viewToast('info', 'Payment Slip received');
          this.estateControlService.updateDocSummary(response.SlipURL);
        } else {
          this.notificationService.viewToast('info', 'M-Pesa Payment received');
        }

        let object = this.payment;
        object.uploaded = true;
        object.done = true; // For RTGS
        object = {
          ...object,
          ...{
            Amount: data.Amount,
            TrustName: data.TrustName,
            PaymentMode: paymentMode,
            MpesaRefNo: data.MpesaRefNo
          }}
        await this.estateControlService.updateRecordWill(2, object);

        const extraData = {
          Persons: this.testators,
          PaymentMode: paymentMode,
          Amount: this.willAmount,
          Referree: (data.AgentName && data.AgentName.length > 2 ? data.AgentName : data.Referree),
          AccountType: 'WillCreation',
          TrustID: this.estateControlService.TrustID
        }

        await this.estateControlService.updateConsultationData(extraData);
        this.navigateFunction();

      } else {
        this.notificationService.viewToast('error', response.Message);
      }

    } catch (error) {
      console.error(':: Error !! ', error);
      this.notificationService.viewToast('error', 'An unexpected error occurred');
    }
    this.notificationService.isLoading(false);
  }

  submitForm(): void {
    if (this.payment.done == true && this.payment.mode) {
      if (this.payment.uploaded) {
        this.navigateFunction();
      } else {
        this.updateTrustDetails(this.payment.mode);
      }
    } else {
      if (this.paymentForm.value.PaymentMode === 'M-Pesa') {
        this.mpesaPayment();
      } else {
        this.rtgsPayment();
      }
    }
  }

  async navigateFunction(): Promise<void> {
    await this.estateControlService.changeTabWill(3);
    this.estateControlService.page2Done = true;
  }
}
