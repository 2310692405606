import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { EstateControlService } from 'src/app/services/estate-control.service';
import { TabControlService } from 'src/app/services/tab-control.service';
import { NotificationService } from 'src/app/services/notification.service';
import { generatePDF, formatDate, convertNumber } from 'src/app/util/Helper';
import { Router } from '@angular/router';

import * as Constants from "src/app/constants/constants";
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-step7-previews',
  templateUrl: './step7-previews.component.html',
  styleUrls: ['./step7-previews.component.scss']
})
export class Step7PreviewsComponent implements OnInit {
  @ViewChild('pdfContent', { static: false }) pdfContent?: ElementRef;
  
  title: string = 'Preview Information.';
  subtitle: string = 'Please review the information you have entered and make any changes by clicking the edit information button';

  settlorsTitle: string = 'Settlor Information';
  trusteesTitle: string = 'Trustees';
  enforcerTitle: string = 'Enforcer (Optional)';

  testatorsTitle: string = 'Testator Information';
  executorsTitle: string = 'Executor of Will';
  witnessTitle: string = 'Witnesses to the Will';

  bensTitle: string = 'Beneficiaries';
  assetsTitle: string = 'Estate Assets';

  editText: string = 'Edit Information';
  isPopupVisible = false;
  record: any;
  recordType: 'Settlor' | 'Beneficiary' | 'Asset' | 'Trustee' | 'Enforcer' | 'Guardian' | 'Testator' | 'Executor' | 'Witness' | null = 'Settlor';
  recordIndex: number = 0;
  parentIndex: number = 0;

  ccEmails: any = [];
  
  formatDate = formatDate;
  convertNumber = convertNumber;

  // FORM !
  previewForm = this._formBuilder.group({
  });

  constructor(
    public estateControlService: EstateControlService,
    private notificationService: NotificationService,
    private tabControlService: TabControlService,
    private apiService: ApiService,
    private router: Router,
    private _formBuilder: FormBuilder,
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.planType == 0) {
      if (this.settlors && this.settlors.length > 0) {
        for (let i=1; i<this.settlors.length; i++) {
          const item = {
            FirstName: this.settlors[i].FirstName,
            Email: this.settlors[i].Email
          };
          this.ccEmails.push(item);
        } 
      }
    } else {
      if (this.testators && this.testators.length > 0) {
        for (let i=1; i<this.testators.length; i++) {
          const item = {
            FirstName: this.testators[i].FirstName,
            Email: this.testators[i].Email
          };
          this.ccEmails.push(item);
        }
      }
    }
    
  }

  // Private Trust
  get settlors(): any {
    return this.estateControlService.settlors;
  }
  get trustees(): any {
    return this.estateControlService.trustees;
  }
  get enforcer(): any {
    return this.estateControlService.enforcer;
  }
  // Will Creation
  get testators(): any {
    return this.estateControlService.testators;
  }
  get executors(): any {
    return this.estateControlService.executors;
  }
  get witnesses(): any {
    return this.estateControlService.witnesses;
  }
  

  // Shared
  get beneficiaries(): any {
    return this.estateControlService.beneficiaries;
  }
  get assets(): any {
    return this.estateControlService.assets;
  }
  get payment(): any {
    return this.estateControlService.payment;
  }
  get summary(): string {
    return this.estateControlService.summary;
  }

  get planType(): 0 | 1 {
    return this.estateControlService.planType;
  }
  get loading(): any {
    return this.notificationService.loading;
  }

  onPopupClose(): void {
    this.isPopupVisible = false;
  }
  showPopup(): void {
    this.isPopupVisible = true;
  }

  generateExcelFileJSON(): string {
    let formattedData;
    if (this.planType == 0) formattedData = this.estateControlService.stripSavedObjects(this.estateControlService.storageObjectPrivate);
    else formattedData = this.estateControlService.stripSavedObjects(this.estateControlService.storageObjectWill);
    
    if (!environment.production) console.log('FormattedData:\n', formattedData)
    formattedData = this.processObject(formattedData);
    
    let formattedGuardians: any = [];
    let formattedContactPersons: any = [];
    let formattedTrusteePersons: any = [];

    if (formattedData.payment.hasOwnProperty('done')) {
      delete formattedData.payment['done'];
    }
    if (formattedData.payment.hasOwnProperty('uploaded')) {
      delete formattedData.payment['uploaded'];
    }

    let excelData: any = {'Payment Details': formattedData.payment};

    if (this.planType == 0) {
      // Settlors
      for (let i=0;i<formattedData.settlors.length; i++) {
        if (formattedData.settlors[i].hasOwnProperty('verified')) {
          delete formattedData.settlors[i]['verified'];
        }
        if (formattedData.settlors[i].hasOwnProperty('detailsDone')) {
          delete formattedData.settlors[i]['detailsDone'];
        }
        if (formattedData.settlors[i].hasOwnProperty('done')) {
          delete formattedData.settlors[i]['done'];
        }
        if (formattedData.settlors[i].hasOwnProperty('IDNoImage')) {
          delete formattedData.settlors[i]['IDNoImage'];
        }
        if (formattedData.settlors[i].hasOwnProperty('KraPinImage')) {
          delete formattedData.settlors[i]['KraPinImage'];
        }
        if (formattedData.settlors[i].hasOwnProperty('PassportImage')) {
          delete formattedData.settlors[i]['PassportImage'];
        }
      }
      // Trustees
      for (let i=0; i<formattedData.trustees.length; i++) {
        if (formattedData.trustees[i].ContactPersons && formattedData.trustees[i].ContactPersons.length > 0) {
          for (let j=0; j<formattedData.trustees[i].ContactPersons.length; j++) {
            let contact = formattedData.trustees[i].ContactPersons[j];
            contact = {...contact, ...{'Linked Trustee': formattedData.trustees[i].TrusteeID} };

            formattedTrusteePersons.push(contact);
          }
        }
        
        if (formattedData.trustees[i].hasOwnProperty('ContactPersons')) {
          delete formattedData.trustees[i]['ContactPersons'];
        }
        if (formattedData.trustees[i].hasOwnProperty('personalDone')) {
          delete formattedData.trustees[i]['personalDone'];
        }
        if (formattedData.trustees[i].hasOwnProperty('contactsDone')) {
          delete formattedData.trustees[i]['contactsDone'];
        }
        if (formattedData.trustees[i].hasOwnProperty('done')) {
          delete formattedData.trustees[i]['done'];
        }
        if (formattedData.trustees[i].hasOwnProperty('IDNoImage')) {
          delete formattedData.trustees[i]['IDNoImage'];
        }
        if (formattedData.trustees[i].hasOwnProperty('CvImage')) {
          delete formattedData.trustees[i]['CvImage'];
        }
        if (formattedData.trustees[i].hasOwnProperty('RegCertImage')) {
          delete formattedData.trustees[i]['RegCertImage'];
        }
        if (formattedData.trustees[i].hasOwnProperty('PassportImage')) {
          delete formattedData.trustees[i]['PassportImage'];
        }
        if (formattedData.trustees[i].hasOwnProperty('KraPinImage')) {
          delete formattedData.trustees[i]['KraPinImage'];
        }
      }

      excelData = {
        ...excelData,
        ...{
          'Settlors': formattedData.settlors, //array
          'Trustees': formattedData.trustees, //array
        }
      };

      // Enforcer (optional)
      if (formattedData.enforcer.done) {
        if (formattedData.enforcer.hasOwnProperty('verified')) {
          delete formattedData.enforcer['verified'];
        }
        if (formattedData.enforcer.hasOwnProperty('done')) {
          delete formattedData.enforcer['done'];
        }
        if (formattedData.enforcer.hasOwnProperty('IDNoImage')) {
          delete formattedData.enforcer['IDNoImage'];
        }
        if (formattedData.enforcer.hasOwnProperty('KraPinImage')) {
          delete formattedData.enforcer['KraPinImage'];
        }
        excelData = {...excelData, ...{'Enforcer': formattedData.enforcer} };
      }

    } else {
      // Testators
      for (let i=0;i<formattedData.testators.length; i++) {
        if (formattedData.testators[i].hasOwnProperty('verified')) {
          delete formattedData.testators[i]['verified'];
        }
        if (formattedData.testators[i].hasOwnProperty('detailsDone')) {
          delete formattedData.testators[i]['detailsDone'];
        }
        if (formattedData.testators[i].hasOwnProperty('done')) {
          delete formattedData.testators[i]['done'];
        }
        if (formattedData.testators[i].hasOwnProperty('IDNoImage')) {
          delete formattedData.testators[i]['IDNoImage'];
        }
        if (formattedData.testators[i].hasOwnProperty('KraPinImage')) {
          delete formattedData.testators[i]['KraPinImage'];
        }
      }
      // Executors
      for (let i=0; i<formattedData.executors.length; i++) {
        if (formattedData.executors[i].hasOwnProperty('done')) {
          delete formattedData.executors[i]['done'];
        }
      }
      // Witnesses
      for (let i=0; i<formattedData.witnesses.length; i++) {
        if (formattedData.witnesses[i].hasOwnProperty('verified')) {
          delete formattedData.witnesses[i]['verified'];
        }
        if (formattedData.witnesses[i].hasOwnProperty('done')) {
          delete formattedData.witnesses[i]['done'];
        }
        if (formattedData.witnesses[i].hasOwnProperty('IDNoImage')) {
          delete formattedData.witnesses[i]['IDNoImage'];
        }
        if (formattedData.witnesses[i].hasOwnProperty('PassportImage')) {
          delete formattedData.witnesses[i]['PassportImage'];
        }
        if (formattedData.witnesses[i].hasOwnProperty('KraPinImage')) {
          delete formattedData.witnesses[i]['KraPinImage'];
        }
      }

      excelData = {
        ...excelData,
        ...{
          'Testators': formattedData.testators, //array
          'Executors': formattedData.executors,
        }
      };

      if (formattedData.witnesses && formattedData.witnesses.length > 0) {
        excelData = {
          ...excelData,
          ...{
            'Witnesses': formattedData.witnesses, //array
          }
        };
      }
    }

    // Beneficiaries
    for (let i=0; i<formattedData.beneficiaries.length; i++) {
      if (formattedData.beneficiaries[i].ContactPersons && formattedData.beneficiaries[i].ContactPersons.length > 0) {
        for (let j=0; j<formattedData.beneficiaries[i].ContactPersons.length; j++) {
          let contact = formattedData.beneficiaries[i].ContactPersons[j];
          contact = {
            ...contact,
            ...{
              'Beneficiary ID': formattedData.beneficiaries[i].BeneficiaryID,
              'Beneficiary Name': `${formattedData.beneficiaries[i].FirstName} ${formattedData.beneficiaries[i].LastName}`
            }
          };

          formattedContactPersons.push(contact);
        }
      }

      if (formattedData.beneficiaries[i].Guardians && formattedData.beneficiaries[i].Guardians.length > 0) {
        for (let j=0; j<formattedData.beneficiaries[i].Guardians.length; j++) {
          let guardian = formattedData.beneficiaries[i].Guardians[j];

          if (guardian.hasOwnProperty('done')) {
            delete guardian['done'];
          }
          if (guardian.hasOwnProperty('IDNoImage')) {
            delete guardian['IDNoImage'];
          }
          if (guardian.hasOwnProperty('KraPinImage')) {
            delete guardian['KraPinImage'];
          }

          guardian = {
            ...guardian,
            ...{
              'Beneficiary ID': formattedData.beneficiaries[i].BeneficiaryID,
              'Beneficiary Name': `${formattedData.beneficiaries[i].FirstName} ${formattedData.beneficiaries[i].LastName}`
            }
          };
          formattedGuardians.push(guardian);
        }

      }
      
      if (formattedData.beneficiaries[i].hasOwnProperty('ContactPersons')) {
        delete formattedData.beneficiaries[i]['ContactPersons'];
      }
      if (formattedData.beneficiaries[i].hasOwnProperty('Guardians')) {
        delete formattedData.beneficiaries[i]['Guardians'];
      }
      if (formattedData.beneficiaries[i].hasOwnProperty('personalDone')) {
        delete formattedData.beneficiaries[i]['personalDone'];
      }
      if (formattedData.beneficiaries[i].hasOwnProperty('docsDone')) {
        delete formattedData.beneficiaries[i]['docsDone'];
      }
      if (formattedData.beneficiaries[i].hasOwnProperty('contactsDone')) {
        delete formattedData.beneficiaries[i]['contactsDone'];
      }
      if (formattedData.beneficiaries[i].hasOwnProperty('done')) {
        delete formattedData.beneficiaries[i]['done'];
      }
      if (formattedData.beneficiaries[i].hasOwnProperty('IDNoImage')) {
        delete formattedData.beneficiaries[i]['IDNoImage'];
      }
      if (formattedData.beneficiaries[i].hasOwnProperty('RegCertImage')) {
        delete formattedData.beneficiaries[i]['RegCertImage'];
      }
      if (formattedData.beneficiaries[i].hasOwnProperty('KraPinImage')) {
        delete formattedData.beneficiaries[i]['KraPinImage'];
      }
      if (formattedData.beneficiaries[i].hasOwnProperty('BirthCertImage')) {
        delete formattedData.beneficiaries[i]['BirthCertImage'];
      }
      if (formattedData.beneficiaries[i].hasOwnProperty('DOB')) {
        const date = formatDate(formattedData.beneficiaries[i].DOB);
        formattedData.beneficiaries[i].DOB = date;
      }
    }

    excelData = {...excelData, ...{'Beneficiaries': formattedData.beneficiaries} };

    if (formattedContactPersons.length > 0) {
      excelData = {...excelData, ...{'Beneficiary-ContactPersons': formattedContactPersons} };
    }
    if (formattedTrusteePersons.length > 0) {
      excelData = {...excelData, ...{'Trustee-ContactPersons': formattedTrusteePersons} };
    }

    // Guardians
    if (formattedGuardians.length > 0) {
      excelData = {...excelData, ...{'Guardians': formattedGuardians} };
    }
    
    const assetsObject = formattedData.assets;
    
    if (assetsObject.properties && assetsObject.properties.length > 0) {
      excelData = {...excelData, ...{'Assets-Properties': assetsObject.properties} };
    }
    if (assetsObject.investments && assetsObject.investments.length > 0) {
      excelData = {...excelData, ...{'Assets-Investments': assetsObject.investments} };
    }
    if (assetsObject.policies && assetsObject.policies.length > 0) {
      for (let i=0;i<assetsObject.policies.length; i++) {
        let newDate = formatDate(assetsObject.policies[i].MaturityDate.toString());
        assetsObject.policies[i].MaturityDate = newDate;
      }
      excelData = {...excelData, ...{'Assets-Policies': assetsObject.policies} };
    }
    if (assetsObject.benefits && assetsObject.benefits.length > 0) {
      for (let i=0;i<assetsObject.benefits.length; i++) {
        let newDate = formatDate(assetsObject.benefits[i].RetirementDate.toString());
        assetsObject.benefits[i].RetirementDate = newDate;
      }
      excelData = {...excelData, ...{'Assets-Pension': assetsObject.benefits} };
    }
    if (assetsObject.otherAssets && assetsObject.otherAssets.length > 0) {
      excelData = {...excelData, ...{'Assets (Other)': assetsObject.otherAssets} };
    }

    try {
      return JSON.stringify(excelData);
    } catch (error) {
      console.error(error);
      return 'error';
    }
  }
  processObject(obj: any): any {
    // Check if the input is an array
    if (Array.isArray(obj)) {
        // If the array contains strings, join them with commas
        if (typeof obj[0] === 'string') {
            return obj.join(', ');
        }
        // If the array contains objects, process each object recursively
        else {
            return obj.map((item: any) => this.processObject(item));
        }
    }
    
    // If the input is an object, process its keys and values
    if (typeof obj === 'object' && obj !== null) {
        const result: any = {};
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                // Rename 'PercShare' to 'SharePercentage'
                const newKey = key === 'PercShare' ? 'SharePercentage' : key;
                // Recursively process nested objects or arrays
                result[newKey] = this.processObject(obj[key]);
            }
        }
        return result;
    }
    
    // If the input is neither an object nor an array, return it as is
    return obj;
  }

  editRecord(
    recordType: 'Settlor' | 'Beneficiary' | 'Asset' | 'Trustee' | 'Enforcer' | 'Guardian' | 'Testator' | 'Executor' | 'Witness' | null,
    recordIndex: number,
    parentIndex: number = 0
  ): void {
    switch (recordType) {
      case 'Beneficiary':
        this.record = this.estateControlService.beneficiaries[recordIndex];
        break;
      case 'Trustee':
        this.record = this.estateControlService.trustees[recordIndex];
        break;
      case 'Enforcer':
        this.record = this.estateControlService.enforcer;
        break;
      case 'Testator':
        this.record = this.estateControlService.testators[recordIndex];
        break;
      case 'Executor':
        this.record = this.estateControlService.executors[recordIndex];
        break;
      case 'Witness':
        this.record = this.estateControlService.witnesses[recordIndex];
        break;
      case 'Guardian':
        this.record = this.estateControlService.beneficiaries[parentIndex].Guardians[recordIndex];
        break;
      case 'Asset':
        break;
      default:
        this.record = this.estateControlService.settlors[recordIndex];
        break;
    }
    this.recordType = recordType;
    this.recordIndex = recordIndex;
    this.parentIndex = parentIndex

    this.showPopup();
  }

  async captureSummary(): Promise<void> {
    try {
      const element = this.pdfContent?.nativeElement;
      const summary = await generatePDF(element, 'add name here later');

      if (summary === 'error') {
        if (!environment.production) console.error('Summary not captured');
      } else {
        if (this.planType == 0) {
          this.estateControlService.updateRecord(7, summary);
        } else {
          this.estateControlService.updateRecordWill(7, summary);
        }
      }
    } catch (error) {
      console.error('CaptureSummary! ', error);
    }
  }

  async updateTrustDetails(): Promise<void> {
    this.notificationService.isLoading(true);
    await this.captureSummary();

    try {
      const excelFile = this.generateExcelFileJSON();
      if (excelFile == 'error') {
        this.notificationService.viewToast('error', 'A critical error occurred');
        return;
      }

      let referree = this.payment.referree;

      if (this.payment.referree == 'Other' && this.payment.other.length > 2)
        referree = this.payment.other;
      if (this.payment.referreeName && this.payment.referreeName.length > 2)
        referree = this.payment.referreeName;

      const data = {
        Progress: 'Preview',
        Name: this.estateControlService.settlors[0].FirstName,
        Email: this.estateControlService.settlors[0].Email,
        Docs: this.estateControlService.docSummary,
        TrustID: this.estateControlService.TrustID,

        TrustName: this.payment.TrustName,
        InvestmentConsultationFee: this.payment.InvestmentConsultationFee,
        LegalReviewFee: this.payment.LegalReviewFee,
        TaxConsultationFee: this.payment.TaxConsultationFee,
        TrustCreationFee: this.payment.TrustCreationFee,
        Amount: this.payment.Amount,
        Intermediary: referree,
        
        CCEmails: this.ccEmails,
        Summary: this.summary,
        ExcelData: excelFile,
      };

      const response = await this.apiService.postRequest(
        environment.baseUrl + Constants.privateTrustDetailsURL, data, false);
      
      // console.log('::::: RESPONSE ::::::\n', response);
      if (response.Status === 1) {
        this.notificationService.viewToast('success', 'Trust created');
        this.navigateFunction();

      } else {
        this.notificationService.viewToast('error', response.Message);
      }

    } catch (error) {
      console.error('UpdateTrustDetails! ', error);
      this.notificationService.viewToast('error', 'An unexpected error occurred');
    }
    this.notificationService.isLoading(false);
  }
  async updateWillDetails(): Promise<void> {
    this.notificationService.isLoading(true);
    await this.captureSummary();
    try {
      const excelFile = this.generateExcelFileJSON();
      if (excelFile == 'error') {
        this.notificationService.viewToast('error', 'A critical error occurred');
        return;
      }

      let referree = this.payment.referree;

      if (this.payment.referree == 'Other' && this.payment.other.length > 2)
        referree = this.payment.other;
      if (this.payment.referreeName && this.payment.referreeName.length > 2)
        referree = this.payment.referreeName;

      const data = {
        Progress: 'Preview',

        TrustName: this.payment.TrustName,
        Amount: this.payment.Amount,
        Intermediary: referree,

        CCEmails: this.ccEmails,
        Summary: this.summary,
        ExcelData: excelFile,
        
        Name: this.estateControlService.testators[0].FirstName,
        Email: this.estateControlService.testators[0].Email,
        Docs: this.estateControlService.docSummary,
        TrustID: this.estateControlService.TrustID,
      };

      const response = await this.apiService.postRequest(
        environment.baseUrl + Constants.willDetailsURL, data, false);
      
      // console.log('::::: RESPONSE ::::::\n', response);
      if (response.Status === 1) {
        this.notificationService.viewToast('success', 'Will created');
        this.navigateFunction();

      } else {
        this.notificationService.viewToast('error', response.Message);
      }

    } catch (error) {
      console.error('UpdateWillDetails! ', error);
      this.notificationService.viewToast('error', 'An unexpected error occurred');
    }
    this.notificationService.isLoading(false);
  }

  submitForm(): void {
    if (this.planType == 0) this.updateTrustDetails();
    else this.updateWillDetails();
  }

  async navigateFunction(): Promise<void> {
    const nextPage = `estate-planning/success`;
    this.router.navigate([nextPage]);
  }
}
