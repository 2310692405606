import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { OtpPopupComponent } from 'src/app/components/otp-popup/otp-popup.component';
import { ApiService } from 'src/app/services/api/api.service';
import { EstateControlService } from 'src/app/services/estate-control.service';
import { TabControlService } from 'src/app/services/tab-control.service';
import { createIDsValidatorEstate } from 'src/app/services/validators/custom.validators';
import { ValidationService } from 'src/app/services/validators/validation.service';
import { checkTime } from 'src/app/util/Helper';
import { environment } from 'src/environments/environment';
import * as Constants from "src/app/constants/constants";
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-step1-testator',
  templateUrl: './step1-testator.component.html',
  styleUrls: ['./step1-testator.component.scss']
})
export class Step1TestatorComponent {
  checkerPath: string = '../../../../assets/fi_check.png';

  formTitle: string = 'Testator Information';
  formSubtitle: string = 'A Testator is the person who makes a will, outlining how their assets should be distributed after their death.';

  contactInfoTitle: string = 'Testator Contact Information';
  detailsTitle: string = 'Testator Details';
  uploadDocsTitle: string = 'Upload Documents / Photo';

  phoneLabel : string = 'Mobile Number';
  emailLabel : string = 'Email Address';
  emailHint : string = 'Email address';

  titleLabel: string = 'Title';
  titleHint: string = 'Title';
  fNameLabel: string = 'First Name';
  fNameHint : string = 'First name';
  mNameLabel : string = 'Middle Name';
  mNameHint : string = 'Middle name';
  surnameLabel : string = 'Surname';
  surnameHint : string = 'Last name';

  idLabel: string = 'ID/Passport Number';
  idHint: string = 'Enter ID or Passport Number';
  kraPinLabel: string = 'KRA PIN';
  kraPinHint: string = 'Enter KRA PIN';
  addressLabel: string = 'Physical Address';
  addressHint: string = 'Enter Physical address';
  postalLabel: string = 'Postal Address';
  postalHint: string = 'Enter Postal Address';

  idFileLabel: string = 'Upload copy of ID/Passport';
  kraFileLabel: string = 'Upload copy of KRA PIN';

  addTestatorText: string = '+  Add Testator';
  removeTestatorText: string = 'Remove Testator';

  requiredFieldString: string = 'Required field';

  loadingText: string = 'Requesting OTP';
  loadingRemove: boolean = false;
  currentIndex: number = 0;
  removeButtonText: string = 'Remove Testator';

  otpVerified: boolean = false;

  formErrors: { [key: string]: string } = {
    Phone: '',
    Email: '',
    
    Title: '',
    FirstName: '',
    MiddleName: '',
    LastName: '',
    IDNo: '',
    KraPin: '',
    Address: '',
    PostalAddress: '',

    IDNoImage: '',
    KraPinImage: '',
  };

  validationMessages: { [key: string]: {} } = {
    Phone: { required: this.requiredFieldString, pattern:'Invalid phone number', exist: 'This number is tied to an existing testator' },
    Email: { required: this.requiredFieldString, email: "Invalid email address", exist: 'This email is tied to an existing testator' },

    Title: { required: this.requiredFieldString },
    FirstName: { required: this.requiredFieldString },
    MiddleName: { required: this.requiredFieldString },
    LastName: { required: this.requiredFieldString },
    IDNo:  { required: this.requiredFieldString, min:'Invalid ID or Passport number', exist: 'This ID is tied to an existing testator' },
    KraPin: { required: this.requiredFieldString, pattern:'Invalid KRA PIN', exist: 'This PIN is tied to an existing testator' },

    Address: { required: this.requiredFieldString },
    PostalAddress: { required: this.requiredFieldString },

    IDNoImage: { required: this.requiredFieldString },
    KraPinImage: { required: this.requiredFieldString },
  };

  testatorForm = this._formBuilder.group({
    testators: this._formBuilder.array([
      this.createTestatorsFormGroup()
    ]),
  });

  constructor(
    private _formBuilder: FormBuilder,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private tabControlService: TabControlService,
    public estateControlService: EstateControlService,
    private validationService: ValidationService,
    private apiService: ApiService
  ) {
    this.populateData();
  }

  async ngOnInit(): Promise<void> {
    await this.populateForm();
    this.testatorForm.valueChanges.subscribe(()=> {
      this.logErrors();
    })
  }

  logErrors() {
    this.formErrors = this.validationService.logValidationErrors(
      this.testatorForm,
      this.formErrors,
      this.validationMessages
    );
  }

  get loading(): boolean {
    return this.notificationService.loading;
  }
  get testators(): any {
    return this.estateControlService.testators;
  }
  get loadingTitles(): boolean {
    return this.tabControlService.loadingTitles;
  }
  get TitleOptions(): any {
    return this.tabControlService.TitleOptions;
  }

  idObject: any = null;
  kraObject: any = null;

  updateIDObject(object: any): void {
    this.idObject = object;
    // console.log('ID doc updated: ', this.idObject.name);
  }
  updateKraObject(object: any): void {
    this.kraObject = object;
  }

  async populateData(): Promise<void> {
    if (this.TitleOptions.length === 0) {
      this.tabControlService.loadingTitles = true;
      await this.tabControlService.getTitlesList();
      this.tabControlService.loadingTitles = false;
    }
  }

  async populateForm(): Promise<void> {
    if (this.testators && this.testators.length > 0) {
      let testate = this.testators;

      testate.forEach(async (testator: any, index: number) => {
        if (index > 0 && !testator.verified) {
          testate.splice(index, 1);
          await this.estateControlService.updateRecordWill(1, testate);
        }
      });

      if (this.testators[0] && this.testators[0].verified && this.testators[0].verified == true) {
        const testatorsArray = this.testatorForm.get('testators') as FormArray;

        // Clear existing testators
        while (testatorsArray.length !== 0) {
          testatorsArray.removeAt(0);
        }
        
        // Add testators from storage
        this.testators.forEach((testator: any) => {
          if (testator.verified) testatorsArray.push(this.createTestatorsFormGroup(testator));
        });

      }
    }
  }

  createTestatorsFormGroup(testator: any = null): FormGroup {
    let form: FormGroup;

    // console.log('scajhbusdycbudsb ', testator);

    if (testator) {
      form = this._formBuilder.group({
        Title: [`${testator.Title || ''}`],
        FirstName: [`${testator.FirstName || ''}`, Validators.required],
        MiddleName: [`${testator.MiddleName || ''}`,],
        LastName: [`${testator.LastName || ''}`, Validators.required],
        Phone: [`${testator.Phone || ''}`, Validators.required],
        Email: [`${testator.Email || ''}`, [Validators.required, Validators.email]],
        
        IDNo: [`${testator.IDNo || ''}`],
        KraPin: [`${testator.KraPin || ''}`],
        Address: [`${testator.Address || ''}`],
        PostalAddress: [`${testator.PostalAddress || ''}`],

        IDNoImage: [],
        KraPinImage: []
      });

      if (testator.IDNoImage) {
        form.get('IDNoImage')?.setValue(testator.IDNoImage.stringValue);
        this.updateIDObject(testator.IDNoImage);
        // console.log('New ID :\n', this.idObject);
      }

      if (testator.KraPinImage) {
        form.get('KraPinImage')?.setValue(testator.KraPinImage.stringValue);
        this.updateKraObject(testator.KraPinImage);
        // console.log('New KRA PIN :\n', this.kraObject);
      }

      if (testator.verified == true) {
        this.otpVerified = true;
        form.get('Address')?.addValidators([Validators.required]);
        form.get('PostalAddress')?.addValidators([Validators.required]);

        if (this.testatorForm && this.getTestators().length > 0 && testator.done == false) {
          this.validationService.addOrRemove3Validators(
            true,
            form.get('Email')!,
            [Validators.required,
              Validators.email,
              createIDsValidatorEstate('EMAIL', this.getTestators(), this.testators)
            ]
          );
          this.validationService.addOrRemoveSpecificValidators(
            true,
            form.get('Phone')!,
            [Validators.required,
              createIDsValidatorEstate('TEL', this.getTestators(), this.testators)
            ]
          );
          this.validationService.addOrRemove3Validators(
            true,
            form.get('IDNo')!,
            [Validators.required,
              Validators.min(4),
              createIDsValidatorEstate('ID', this.getTestators(), this.testators)
            ]
          );
          this.validationService.addOrRemove3Validators(
            true,
            form.get('KraPin')!,
            [Validators.required,
              Validators.pattern(/^[AP]\d{9}[A-Z]$/),
              createIDsValidatorEstate('PIN', this.getTestators(), this.testators)
            ]
          );
        }

        if (testator.detailsDone == true && testator.done == false) {
          this.validationService.addOrRemoveValidator(true, form.get('IDNoImage')!);
          this.validationService.addOrRemoveValidator(true, form.get('KraPinImage')!);
        }
      }
      // console.log('Current record verified: ', this.currentRecordVerified, '\n', testator);
    } else {
      if (
        this.testators && this.testators.length > 0 && this.testators[0].verified == false
      ) {

          this.estateControlService.refreshRecordWill(1);
      }

      this.otpVerified = false;
      let currentTestators = this.testators;
      let currentRecord: any = {verified: false, detailsDone: false, done: false};

      form = this._formBuilder.group({
        Title: [''],
        FirstName: ['', Validators.required],
        MiddleName: [''],
        LastName: ['', Validators.required],
        
        Phone: ['', Validators.required],
        Email: ['', [Validators.required, Validators.email]],
        
        IDNo: [''],
        KraPin: [''],
        Address: [''],
        PostalAddress: [''],

        IDNoImage: [],
        KraPinImage: [],
        Photo: []
      });

      if (this.testatorForm && this.getTestators().length > 0) {
        this.validationService.addOrRemove3Validators(
          true,
          form.get('Email')!,
          [Validators.required,
            Validators.email,
            createIDsValidatorEstate('EMAIL', this.getTestators(), this.testators)
          ]
        );
        this.validationService.addOrRemoveSpecificValidators(
          true,
          form.get('Phone')!,
          [Validators.required,
            createIDsValidatorEstate('TEL', this.getTestators(), this.testators)
          ]
        );
      }

      if (
        this.testators.length == 0 ||
        this.testators.length > 0 && this.testators[this.testators.length-1].done == true
      ) {
        currentTestators.push(currentRecord);
        this.estateControlService.updateRecordWill(1, currentTestators);
      }
      
    }

    form.updateValueAndValidity();

    return form;
  };

  async addNewTestator() {
    const fields = this.testatorForm.get('testators') as FormArray;
    fields.push(this.createTestatorsFormGroup());
    this.testatorForm.setControl('testators', fields);
  }

  async removeTestator(i:number) {
    this.currentIndex = i;
    const fields = this.testatorForm.get('testators') as FormArray;
    let currentTestators = this.testators;
    
    if (currentTestators[i] && currentTestators[i].TestatorID) {
      this.removeButtonText = 'Removing Testator';
      this.loadingRemove = true;
      
      try {
        if (this.estateControlService.TrustID !== null) {
          const result = await this.estateControlService.removeRecord(
            'testator',
            currentTestators[i].TestatorID,
            parseInt(this.estateControlService.TrustID),
            environment.baseUrl + Constants.willTestatorURL
          );

          if (result === 1) {
            this.notificationService.viewToast('success', 'Testator removed successfully');
            fields.removeAt(i);
            // Remove testator at index
            currentTestators.splice(i, 1);;
            await this.estateControlService.updateRecordWill(1, currentTestators);
          } else {
            this.notificationService.viewToast('error', 'Error removing record');
          }
        } else {
          console.log('Relevant IDs not found');
        }
      } catch (error) {
        console.log(':: Error !! ', error);
      }
      this.removeButtonText = 'Remove Testator';
      this.loadingRemove = false;
    } else {
      if (currentTestators[i] && currentTestators[i].done === false) {
        fields.removeAt(i);
        // Remove testator at index
        currentTestators.splice(i, 1);;
        await this.estateControlService.updateRecordWill(1, currentTestators);
      } else {
        fields.removeAt(i);
      }
    }
  }
  getTestators() : FormArray {  
    return this.testatorForm.get("testators") as FormArray  
  }
  getTestatorFormGroup(index: number): FormGroup {
    const testators = this.testatorForm.get('testators') as FormArray;
    return testators.at(index) as FormGroup;
  }

  async openOtpModal() {
    this.loadingText = 'Requesting OTP';
    this.notificationService.isLoading(true);

    try {
      const records = this.testatorForm.controls['testators'].value;
      let data = {
        FirstName: records[records.length-1].FirstName,
        LastName: records[records.length-1].LastName,
        Phone: records[records.length-1].Phone,
        Email: records[records.length-1].Email,
        MileleType: 'estate'
      };

      this.estateControlService.OTPMessage = `In order to proceed, please enter the One Time Password (OTP) sent to the Testator\'s email and phone`;
      // console.log('Dat ', data);

      let savedTime = this.estateControlService.OTPRequestStats;
      let response = {Status: 0, Message: 'Initialized'};

      this.estateControlService.OTPValid = checkTime(savedTime.time);

      if (this.estateControlService.OTPValid && savedTime.phone === data.Phone) {
        response.Status = 1;
        response.Message = 'Current OTP is still Valid';

      } else {
        response = await this.apiService.postRequest(
          environment.baseUrl + Constants.otpRequestURL, data);
      }

      if (response.Status === 1) {
        let currentTestators = this.testators;
        let currentRecord = currentTestators[currentTestators.length-1];

        currentRecord = {...currentRecord, ...records[records.length-1]};
        
        Object.assign(currentTestators[currentTestators.length - 1], currentRecord);
        await this.estateControlService.updateRecordWill(1, currentTestators);
        
        if (!this.estateControlService.OTPValid) {
          const timeStats = {time: new Date().getTime() / 1000, phone: data.Phone};
          this.estateControlService.OTPRequestStats = timeStats;
          this.estateControlService.OTPValid = true;

          this.notificationService.viewToast(
            'success',
            `OTP sent to ${data.Phone}.`,
            'We have also sent it to the provided Email');

        } else {
          this.notificationService.viewToast(
            'info',
            `Please enter the OTP that was sent to your Phone / Email` );
        }

        this.estateControlService.OTPRequestPage = 'Testator';
        this.estateControlService.OTPIndex = this.testators.length-1;
        
        this.loadingText = 'Verify Details';

        const dialogRef = this.dialog.open(OtpPopupComponent, {
          disableClose: true,
        });
    
        dialogRef.componentInstance.otpVerified.subscribe((value: boolean) => {
          this.otpVerified = value;
          // console.log('OTP verification status received from the modal:', this.otpVerified);
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if (this.otpVerified == true) {
            let currentTestators = this.testators;
            let currentRecord = currentTestators[currentTestators.length-1];
            currentRecord.verified = true;

            Object.assign(currentTestators[currentTestators.length - 1], currentRecord);
            this.estateControlService.updateRecordWill(1, currentTestators);

            this.validationService.addOrRemoveValidator(true, this.getTestatorFormGroup(this.testators.length - 1).get('Address')!);
            this.validationService.addOrRemoveValidator(true, this.getTestatorFormGroup(this.testators.length - 1).get('PostalAddress')!);

            this.validationService.addOrRemoveSpecificValidators(
              true,
              this.getTestatorFormGroup(this.testators.length - 1).get('IDNo')!,
              [Validators.required, Validators.min(4)]
            );
            this.validationService.addOrRemoveSpecificValidators(
              true,
              this.getTestatorFormGroup(this.testators.length - 1).get('KraPin')!,
              [Validators.required, Validators.pattern(/^[AP]\d{9}[A-Z]$/)]
            );

            if (this.testatorForm && this.getTestators().length > 0) {
              this.validationService.addOrRemove3Validators(
                true,
                this.getTestatorFormGroup(this.testators.length - 1).get('IDNo')!,
                [Validators.required,
                  Validators.min(4),
                  createIDsValidatorEstate('ID', this.getTestators(), this.testators)
                ]
              );
              this.validationService.addOrRemove3Validators(
                true,
                this.getTestatorFormGroup(this.testators.length - 1).get('KraPin')!,
                [Validators.required,
                  Validators.pattern(/^[AP]\d{9}[A-Z]$/),
                  createIDsValidatorEstate('PIN', this.getTestators(), this.testators)
                ]
              );
            }

          } else {
            this.notificationService.viewToast('info', 'Please verify your details to proceed');
          }
          console.groupEnd();
        });
      } else {
        this.notificationService.viewToast('error', response.Message);
      }
    } catch (error) {
      console.log(':: Error !! ', error);
      this.notificationService.viewToast('error', 'OTP request failed');

    }
    this.notificationService.isLoading(false);
  }

  async saveTestator(): Promise<void> {
    if (this.testators[this.testators.length-1].verified == true) this.loadingText = 'Updating Testator';
    else this.loadingText = 'Saving Testator';

    let tsMessage = 'Testator details saved';

    this.notificationService.isLoading(true);

    try {
      const testators = this.testatorForm.get('testators') as FormArray;
      const record: FormGroup = this.getTestatorFormGroup(testators.length - 1) as FormGroup;

      let category;
      if (this.estateControlService.TrustID === null || testators.length - 1 === 0) {
        category = 'Primary Testator';
      } else {
        category = 'Secondary Testator';
      }
      
      let data = {
        ...record.value,
        ...{
          TrustID: this.estateControlService.TrustID,
          Category: category
        }
      };

      let currentTestators = this.testators;
      
      if (currentTestators[currentTestators.length-1].detailsDone == true) {
        data.IDNoImage = this.idObject;
        data.KraPinImage = this.kraObject;
      } else {
        data.IDNoImage = {name: '', stringValue: ''};
        data.KraPinImage = {name: '', stringValue: ''};
      }

      if (currentTestators[currentTestators.length - 1].TestatorID) {
        data = {...data, ...{TestatorID: currentTestators[currentTestators.length - 1].TestatorID}};
      }
      
      const response = await this.apiService.postRequest(
        environment.baseUrl + Constants.willTestatorURL, data, false);
      
      // console.log('::::: RESPONSE ::::::\n', response);
      if (response.Status === 1) {
        if (this.estateControlService.TrustID === null) {this.estateControlService.TrustID = response.TrustID;}

        const latestRecord = currentTestators[currentTestators.length - 1];
        let updatedRecord = {...latestRecord, ...record.value, ...{TestatorID: response.TestatorID, Category: category}};

        Object.assign(currentTestators[currentTestators.length - 1], updatedRecord);

        await this.estateControlService.updateRecordWill(1, currentTestators);

        currentTestators = this.testators;
        let currentRecord = currentTestators[currentTestators.length-1];
        
        if (currentRecord.detailsDone == true) { // uploaded docs
          tsMessage = 'Testator details updated';
          
          currentRecord.IDNoImage = this.idObject;
          currentRecord.KraPinImage = this.kraObject;

          if (response.IdURL) {
            this.estateControlService.updateDocSummary(response.IdURL);
            currentRecord = {...currentRecord, ...{IDURL: response.IdURL}};
          }
          if (response.KraURL) {
            this.estateControlService.updateDocSummary(response.KraURL);
            currentRecord = {...currentRecord, ...{KraURL: response.KraURL}};
          }
          
          currentRecord.done = true;

        } else {
          currentRecord.detailsDone = true;
          this.validationService.addOrRemoveValidator(true, this.getTestatorFormGroup(testators.length - 1).get('IDNoImage')!);
          this.validationService.addOrRemoveValidator(true, this.getTestatorFormGroup(testators.length - 1).get('KraPinImage')!);
        }

        Object.assign(currentTestators[currentTestators.length - 1], currentRecord);
        await this.estateControlService.updateRecordWill(1, currentTestators);
        console.log('>> New Testators !! \n', this.estateControlService.testators);
        this.notificationService.viewToast('success', tsMessage);

      } else {
        this.notificationService.viewToast('error', response.Message);
      }
    } catch (error) {
      console.log(':: Error !! ', error);
      this.notificationService.viewToast('error', 'An unexpected error occurred');
    }
    this.notificationService.isLoading(false);
  }

  async submitForm(): Promise<void> {
    if (this.testators[this.testators.length-1].done) {
      this.navigateFunction();
    } else {
      if (this.testators[this.testators.length-1].verified) {
        this.saveTestator();
      } else {
        this.openOtpModal();
      }
    }
  }
  async navigateFunction(): Promise<void> {
    await this.estateControlService.changeTabWill(2);
    this.estateControlService.page1Done = true;
  }

}
