import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { EstateControlService } from 'src/app/services/estate-control.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TabControlService } from 'src/app/services/tab-control.service';

@Component({
  selector: 'app-form-bottom',
  templateUrl: './form-bottom.component.html',
  styleUrls: ['./form-bottom.component.scss']
})
export class FormBottomComponent {
  @Input() service: TabControlService | EstateControlService = this.tabControlService;
  @Input() text: string = 'Next';
  @Input() buttonClickHandler!: (() => void) | (() => Promise<void>) ;
  @Input() saveFunction: () => Promise<void> = async ()=> {};

  @Input() overridePrevious: boolean = false;
  @Input() previousFunction: () => Promise<void> = async ()=> {};
  @Input()
  formValid!: boolean;

  @Input() showPrevious: string = 'true';
  @Input() showContinue: string = 'true';

  constructor(
    private tabControlService: TabControlService,
    private notificationService: NotificationService,
    private router: Router ) {

    }

  get activeTab(): number {
    return this.service.activeTab;
  }
  get loadingUpdate(): boolean {
    return this.tabControlService.loadingUpdate;
  }
  get validatingFile(): boolean {
    return this.notificationService.validatingFile;
  }

  toPreviousTab(): void {
    if (this.overridePrevious) {
      this.previousFunction();
    } else {
      const previousTab = this.activeTab-1;
        if (previousTab === 0) {
          this.router.navigate(['/get-started']);
        } else {
          this.service.changeTab(previousTab);
        }
    }
  }
}
