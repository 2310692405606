<div class="flex container">
    <div class="flex one">
        <div class="flex half-1" style="position: relative;">
          <img src={{MileleLogoPath}} class="milele-logo" alt="logo" (click)="goHome()" />
          <div class="flex power-small"> {{ powerdByText }} </div>
        </div>
  
        <div class="flex half-2">
        </div>
    </div>
  
    <div class="flex two">
        <img src={{logoPath}} class="logo" alt="logo" />
    </div>

    <div class="flex content-holder">
        <div class="flex content-wrapper">
            <div class="content" >

                <div class="tab-form">
                    <div class="form-80">
                        <h1 class="blue margin" style="margin-top: 50px;">{{ title }}</h1>
                        <p class="lead rocal" style="margin-top: 30px; margin-bottom: 20px;">
                            {{ subtitle }}
                        </p>
                
                        <div class="flex blue-box">
                            <p class="blue lead">
                                {{ appointmentDetails }}
                            </p>
                            <div class="line-2"></div>
                            <div class="flex wide-bar" style="align-items: center; margin-left: 2px; margin-top: 20px; font-size: medium; width: 100%;">
                                <mat-radio-group aria-labelledby="appointment-radio-group-label"
                                    (change)="handleForm()"
                                    class="flex column"
                                    style="width: 100%;"
                                    [(ngModel)]="appointmentOption">
                                    <ng-container *ngFor="let option of appointmentOptions; let first = first; let last = last">
                                        <mat-radio-button class="appointment-button" [value]="option">
                                            <strong>{{ option }}</strong>
                                        </mat-radio-button>
                                        <div class="flex width-100" style="height: fit-content;" *ngIf="appointmentOption !== ''">
                                            <form [formGroup]="virtualForm" class="flex option" *ngIf="appointmentOption == option1 && first">
                                                <div class="row">
                                                    <app-input [formGroup]="virtualForm" controlName="Date"
                                                        label={{dateLabel}} hint={{dateHint}}
                                                        [minDate]="tomorrow"
                                                        [maxDate]="maxDate"
                                                        [weekdaysOnly]="true"
                                                        [includeSaturdays]="true"
                                                        inputType="date"
                                                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                                    <app-input [formGroup]="virtualForm" controlName="Time"
                                                        inputType="time"
                                                        label={{timeLabel}} hint={{timeHint}}
                                                        [subtext]="virtualOfficeText"
                                                        [selectedVirtualOfficeDay]="selectedVirtualMeetinDay"
                                                        [enforceVirtualOfficeHours]="true"
                                                        [timeFunction]="timeFunction.bind(this)"
                                                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                                </div>
                
                                                <div class="label" [@bounceIn] *ngIf="!getEmails() || getEmails() && getEmails().length == 0">
                                                    <p>{{emailsLabel}}</p>&nbsp;
                                                </div>
                                                
                                                <div *ngIf="getEmails()" formArrayName="Emails" class="flex column">
                                                    <div *ngFor="let email of getEmails().controls; let i=index" [formGroupName]="i" class="flex align" style="position: relative; flex: 1;">
                                                        <div class="row half" *ngIf="getEmails().length > 0">
                                                            <app-input [formGroup]="getEmailFormGroup(i)" controlName="Email"
                                                                required="false"
                                                                label={{emailLabel}} hint={{emailHint}}
                                                                subtext="For any additional meeting participant"
                                                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>

                                                            <div class="flex center" style="width: fit-content; margin-left: 10px;" *ngIf="getEmails().length > 0">
                                                                <div class="flex center circle red" (click)="removeEmail(i)" style="margin-right: 10px;" >
                                                                    <mat-icon fontIcon="delete_outline" aria-hidden="false" aria-label="delete email"></mat-icon>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <!-- BUTTTON Add Email-->
                                                     <div class="flex column" style=" flex: 1;" *ngIf="getEmailFormGroup(getEmails().length-1) && getEmailFormGroup(getEmails().length-1).get('Email')?.value.length > 4 && getEmailFormGroup(getEmails().length-1).get('Email')?.valid ">
                                                        <div class="blueStroke row" style="margin-top: 15px;">
                                                            <button mat-stroked-button
                                                                class="general-stroked-button" type="button" (click)="addToEmailArray()">
                                                                <span class="button-text">
                                                                    {{ '+ Add Another Email' }}
                                                                </span>
                                                            </button>
                                                        </div>
                                                     </div>
                                                </div>

                                                <!-- BUTTTON Add Email-->
                                                <div class="flex column" style=" flex: 1;" *ngIf="!getEmails() || getEmails().length == 0">
                                                    <div class="blueStroke row">
                                                        <button mat-stroked-button
                                                            class="general-stroked-button" type="button" (click)="addToEmailArray()">
                                                            <span class="button-text">
                                                                {{ '+ Add Email' }}
                                                            </span>
                                                        </button>
                                                    </div>
                                                 </div>
                                            </form>
                
                                            <form [formGroup]="officeForm" class="flex option" *ngIf="appointmentOption == option2 && !first && !last">
                                                <div class="row">
                                                    <app-input [formGroup]="officeForm" controlName="Date"
                                                        label={{dateLabel}} hint={{dateHint}}
                                                        [minDate]="tomorrow"
                                                        [maxDate]="maxDate"
                                                        [weekdaysOnly]="true"
                                                        inputType="date"
                                                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                                    <app-input [formGroup]="officeForm" controlName="Time"
                                                        inputType="time"
                                                        label={{timeLabel}} hint={{timeHint}} subtext="Office hours are between 8.00 AM - 4.30 PM"
                                                        [enforcePhysicalOfficeHours]="true"
                                                        [timeFunction]="timeFunction.bind(this)"
                                                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                                </div>
                
                                                <div class="row half">
                                                    <app-input [formGroup]="officeForm" controlName="PersonsNumber"
                                                        label={{personsLabel}} hint={{personsHint}}
                                                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                                </div>
                                            </form>
                
                                            <form [formGroup]="locationForm" class="flex option" *ngIf="appointmentOption == option3 && last">
                                                <div class="row">
                                                    <app-input [formGroup]="locationForm" controlName="Date"
                                                        label={{dateLabel}} hint={{dateHint}}
                                                        [minDate]="tomorrow"
                                                        [maxDate]="maxDate"
                                                        [weekdaysOnly]="true"
                                                        inputType="date"
                                                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                                    <app-input [formGroup]="locationForm" controlName="Time"
                                                        inputType="time"
                                                        label={{timeLabel}} hint={{timeHint}} subtext="Office hours are between 8.00 AM - 4.30 PM"
                                                        [enforcePhysicalOfficeHours]="true"
                                                        [timeFunction]="timeFunction.bind(this)"
                                                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                                </div>
                
                                                <div class="row half">
                                                    <app-input [formGroup]="locationForm" controlName="Location"
                                                        label={{locationLabel}} hint={{locationHint}}
                                                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                                </div>
                                            </form>
                                            
                                        </div>
                                    </ng-container>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                
                    <!-- BOTTOM OF FORM -->
                    <app-form-bottom style="width: 100%;"
                        [overridePrevious]="true"
                        [previousFunction]="backFunction.bind(this)"
                        [text]="loading ? 'Please Wait' : 'Submit'"
                        [formValid]="isFormValid()"
                        [showContinue]="'false'"
                        [buttonClickHandler]="submitForm.bind(this)">
                    </app-form-bottom>
                </div>
                
            </div>
        </div>
    </div>
</div>

