<!-- CONTENT -->
<div class="loading-loading" *ngIf="loading">
  <div class="flex loading-spinner">
    <mat-progress-spinner
        mode="indeterminate"
        color="warn"
        diameter="100"
        strokeWidth="12">
    </mat-progress-spinner>
  </div>
</div>

<div class="content-loading" *ngIf="stkLoading">
  <div class="flex popup-content">
    <div class="flex x-div">
      <div *ngIf="stkTimeout" (click)="closeSTKPopup()" style="cursor: pointer;">
          <mat-icon fontIcon="close" aria-hidden="false" aria-label="Close button"></mat-icon>
      </div>
    </div>

    <div class="spinn">
      <mat-progress-spinner
        *ngIf="!stkTimeout"
        mode="indeterminate"
        diameter="80"
        strokeWidth="7">
      </mat-progress-spinner>

      <img *ngIf="stkTimeout"src={{mPesaPath}} class="m-pesa" alt="M-Pesa" />
    </div>
    
    <span class="titl" *ngIf="!stkTimeout" >
      {{ 'M-PESA PAYMENT' }}
    </span>

    <span  *ngIf="!stkTimeout" class="stk-text">
      {{ enterPinText }}
    </span>

    <span *ngIf="!requestingSTK && stkTimeout" class="stk-text">
      {{ retryPaymentText }}<br>{{ retryQuestionText }}
    </span>

    <span *ngIf="requestingSTK || (stkMessage !== '' && stkTimeout)" class="stk-text">
      {{ stkMessage }}
    </span>

    <div *ngIf="stkTimeout" class="flex column stk-div">
      <div class="flex putton green" (click)="mpesaPayment(1)">
        {{ 'Yes, Re-confirm the payment' }}
      </div>

      <div class="flex putton red" (click)="mpesaPayment(0)">
        {{ 'No, Request new payment' }}
      </div>
    </div>
    
  </div>
</div>

<div class="flex whatsapp-frame" (click)="openWhatsappChat()">
  <img alt="Chat on WhatsApp" src="../assets/WhatsAppButtonGreenLarge.png" />
</div>

<router-outlet></router-outlet>