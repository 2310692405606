import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api/api.service';
import { EstateControlService } from 'src/app/services/estate-control.service';
import { TabControlService } from 'src/app/services/tab-control.service';
import { ValidationService } from 'src/app/services/validators/validation.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import * as Constants from "src/app/constants/constants";
import { MatDialog } from '@angular/material/dialog';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-step6-enforcer',
  templateUrl: './step6-enforcer.component.html',
  styleUrls: ['./step6-enforcer.component.scss'],
  animations: [
    trigger('bounceIn', [
      state('in', style({ opacity: 1, transform: 'scale(1)' })),
      transition('void => *', [
        style({ opacity: 0, transform: 'scale(0.8)' }),
        animate('0.15s ease-in-out')
      ]),
      transition('* => void', [
        animate('0.15s ease-in-out', style({ opacity: 0, transform: 'scale(0.7)' }))
      ])
    ]),
    trigger('slideInRight', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('0.15s ease-in-out', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('0.15s ease-in-out', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class Step6EnforcerComponent {
  @Output() scrollUp = new EventEmitter<number>();
  @ViewChild('suggestBox', { static: false }) suggestBox?: ElementRef;
  
  checkerPath: string = '../../../../assets/fi_check.png';

  title: string = 'Enforcer (Optional)';
  subtitle: string = 'Enforcer Information';
  uploadTitle: string = 'Upload Documents';

  relationLabel: string = 'Relationship To Settlor';
  relationHint: string = 'Specify Relationship';

  titleLabel: string = 'Title';
  titleHint: string = 'Title';
  fNameLabel: string = 'First Name';
  fNameHint : string = 'First name';
  mNameLabel : string = 'Middle Name';
  mNameHint : string = 'Middle name';
  surnameLabel : string = 'Surname';
  surnameHint : string = 'Last name';
  phoneLabel : string = 'Primary Phone Number';
  emailLabel : string = 'Email Address';
  emailHint : string = 'Email address';
  idLabel: string = 'ID/Passport Number';
  idHint: string = 'Enter ID or Passport Number';
  kraPinLabel: string = 'KRA Number';
  kraHint: string = 'Enter KRA PIN Number';
  addressLabel: string = 'Postal Address';
  addressHint: string = 'Enter Postal Address';

  idFileLabel: string = 'Upload Copy of ID/Passport';
  kraFileLabel: string = 'Upload KRA PIN';

  addText: string = 'Enforcer details saved';
  updateText: string = 'Enforcer details updated'
  
  requiredFieldString: string = 'Required field';
  invalidDocString: string = 'Please attach a valid document';

  loadingText: string = 'Saving Enforcer Details';

  selfRecords: any = []; // For suggesting self
  recordUpdate: any;


  formErrors: { [key: string]: string } = {
    Title: '',
    FirstName: '',
    MiddleName: '',
    LastName: '',
    Phone: '',
    Email: '',
    Relationship: '',
    Other: '',

    IDNo: '',
    KraPin: '',
    Address: '',

    IDNoImage: '',
    KraPinImage: '',
  };

  validationMessages: { [key: string]: {} } = {
    Title: { required: this.requiredFieldString },
    FirstName: { required: this.requiredFieldString },
    MiddleName: { required: this.requiredFieldString },
    LastName: { required: this.requiredFieldString },
    Phone: { required: this.requiredFieldString, pattern:'Invalid phone number', exist: 'This number is tied to an existing enforcer' },
    Email: { required: this.requiredFieldString, email: "Invalid email address" },

    Relationship: { required: this.requiredFieldString },
    Other: {required: this.requiredFieldString},
    IDNo:  { required: this.requiredFieldString, pattern:'Invalid ID or Passport number', exist: 'This ID is tied to an existing enforcer' },
    KraPin: { required: this.requiredFieldString, pattern:'Invalid KRA PIN', exist: 'This PIN is tied to an existing enforcer' },
    Address: { required: this.requiredFieldString },

    IDNoImage: { required: this.requiredFieldString, invalid: this.invalidDocString },
    KraPinImage: { required: this.requiredFieldString, invalid: this.invalidDocString },
  };


  enforcerForm = this._formBuilder.group({
    Title: [''],
    FirstName: ['', Validators.required],
    MiddleName: [''],
    LastName: ['', Validators.required],
    Phone: ['', Validators.required],
    Email: ['', [Validators.required, Validators.email]],
    Relationship: ['', Validators.required],
    Other: [''],

    IDNo: ['', Validators.required],
    KraPin: ['', [Validators.required, Validators.pattern(/^[AP]\d{9}[A-Z]$/)]],
    Address: ['', Validators.required],

    IDNoImage: [''],
    KraPinImage: ['']
  });


  constructor(
    private _formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private tabControlService: TabControlService,
    public estateControlService: EstateControlService,
    private validationService: ValidationService,
    private dialog: MatDialog,
    private apiService: ApiService,
  ) { }

  ngOnInit(): void {
    const records: any = this.settlors;
    this.selfRecords.push(records[0]);
    if (records.length > 1) this.selfRecords.push(records[1]);

    this.populateData();

    if (this.enforcer) {
      this.patchFormValues(this.enforcer);
    }
    this.showSuggestBrief();

    this.validationService.addOrRemoveValidationOnValue(
      this.enforcerForm.get("Relationship")!,
      "Other",
      this.enforcerForm,
      "Other"
    );

    this.enforcerForm.valueChanges.subscribe(()=> {
      this.logErrors();
    })
  }

  logErrors() {
    this.formErrors = this.validationService.logValidationErrors(
      this.enforcerForm,
      this.formErrors,
      this.validationMessages
    );
  }

  idObject: any = null;
  kraObject: any = null;

  get loading(): boolean {
    return this.notificationService.loading;
  }
  get enforcer(): any {
    return this.estateControlService.enforcer;
  }
  get loadingTitles(): boolean {
    return this.tabControlService.loadingTitles;
  }
  get TitleOptions(): any {
    return this.tabControlService.TitleOptions;
  }
  get RelationOptions():any {
    return this.tabControlService.RelationOptions;
  }
  Relationships: any = this.RelationOptions;

  get saveProgress(): ()=> void {
    return this.estateControlService.saveProgress;
  }

  // Autofill settlors
  showSuggestHint: boolean = false;
  showAssignPopup: boolean = false;
  autofilled: boolean = false;
  suggestions: any = null;
  briefTimeout: any;

  get settlors(): any {
    return this.estateControlService.settlors;
  }

  showSuggestBrief(): void {
    setTimeout(() => {
      this.showSuggestHint = true;
    }, 1000);

    this.briefTimeout = setTimeout(() => {
      this.showSuggestHint = false;
    }, 30000);
  }
  patchFormValues(enforcer: any, isResuming: boolean = true, value: any = null): void {
    if (enforcer) {
      this.enforcerForm = this._formBuilder.group({
        Title: [`${enforcer.Title || ''}`],
        FirstName: [`${enforcer.FirstName || ''}`, Validators.required],
        MiddleName: [`${enforcer.MiddleName || ''}`],
        LastName: [`${enforcer.LastName || ''}`, Validators.required],
        Phone: [`${enforcer.Phone || ''}`, Validators.required],
        Email: [`${enforcer.Email || ''}`, [Validators.required, Validators.email]],
    
        Relationship: [`${value ? value : enforcer.Relationship || ''}`, Validators.required],
        Other: [`${enforcer.Other || ''}`],
        IDNo: [`${enforcer.IDNo || ''}`, Validators.required],
        KraPin: [`${enforcer.KraPin || ''}`, [Validators.required, Validators.pattern(/^[AP]\d{9}[A-Z]$/)]],
        Address: [`${enforcer.Address || ''}`, Validators.required],
    
        IDNoImage: [''],
        KraPinImage: ['']
      });

      if (isResuming && enforcer.IDNoImage) {
        this.enforcerForm.get('IDNoImage')?.setValue(enforcer.IDNoImage.stringValue);
        this.updateIDObject(enforcer.IDNoImage);
        // console.log('New ID :\n', this.idObject);
      }

      if (isResuming && enforcer.KraPinImage) {
        this.enforcerForm.get('KraPinImage')?.setValue(enforcer.KraPinImage.stringValue);
        this.updateKraObject(enforcer.KraPinImage);
        // console.log('New KRA PIN :\n', this.kraObject);
      }

      if (isResuming && enforcer.verified) {
        this.validationService.addOrRemoveValidator(true, this.enforcerForm.get('IDNoImage')!);
        this.validationService.addOrRemoveValidator(true, this.enforcerForm.get('KraPinImage')!);

        if (!enforcer.done) {
          // Autofill for self relationship
          this.selfRecords.some((record: any) => {
            if (
              enforcer.Relationship == 'Self' &&
              enforcer.FirstName == record.FirstName &&
              enforcer.LastName == record.LastName &&
              enforcer.Phone == record.Phone &&
              enforcer.Email == record.Email
            ) {
              this.recordUpdate = record;
              if (record.IDNoImage.stringValue && record.IDNoImage.stringValue.length > 0) {
                this.enforcerForm.get('IDNoImage')?.setValue(record.IDNoImage.stringValue);
                this.updateIDObject(record.IDNoImage);
                this.enforcerForm.get('IDNoImage')?.disable();
              }
              if (record.KraPinImage.stringValue && record.KraPinImage.stringValue.length > 0) {
                this.enforcerForm.get('KraPinImage')?.setValue(record.KraPinImage.stringValue);
                this.updateKraObject(record.KraPinImage);
                this.enforcerForm.get('KraPinImage')?.disable();
              }
              this.notificationService.viewToast('info', 'Documents added from uploads');
            }
          })
        }
      }

      this.enforcerForm.markAsDirty();
    }
  }
  async suggestSettlors(val: string): Promise<any> {
    if (this.autofilled) {
      this.resetForm();
    }
    
    this.suggestions = null;
    let validEntries: any = [];

    if (val.length > 2) {
        try {
            for (let i = 0; i < this.settlors.length; i++) {
                let exist = false;
                let trueStuff = this.settlors[i].IDNo.includes(val);

                if (trueStuff) {
                  if (
                      this.enforcerForm.get('IDNo')?.value === this.settlors[i].IDNo ||
                      this.enforcerForm.get('Phone')?.value === this.settlors[i].Phone ||
                      this.enforcerForm.get('KraPin')?.value === this.settlors[i].KraPin) {
                      exist = true;
                  } else {
                    this.showSuggestHint = false;
                    clearTimeout(this.briefTimeout);
                  }

                  if (!exist && validEntries.length < 3 && !validEntries.some((entry: { IDNo: string; }) => entry.IDNo === this.settlors[i].IDNo)) {
                      validEntries.push(this.settlors[i]);
                  }
                }
            }

            if (validEntries.length > 0) {
              this.suggestions = validEntries;
            }
            if (this.suggestions !== null) {
              const childDivHeight = this.suggestBox?.nativeElement.clientHeight;
              this.scrollUp.emit(childDivHeight);
            }
        } catch (error) {
            console.error(error);
        }
    }

    return this.suggestions;
  }
  patchSuggestedValues(value: any): void {
    try {
      if (value) {
        this.patchFormValues(value, false);
        this.suggestions = null;
        this.autofilled = true;
      }

    } catch (error) {
      console.error('ERR ??? ', error);
    }
  }

  // ========================================================

  updateIDObject(object: any): void {
    this.idObject = object;
    // console.log('ID doc updated: ', this.idObject.name);
  }
  updateKraObject(object: any): void {
    this.kraObject = object;
  }

  resetForm() {
    if (this.autofilled) this.autofilled = false;
    this.enforcerForm.reset();
    this.enforcerForm.markAsUntouched();
    
    this.updateIDObject(null);
    this.updateKraObject(null);

    this.estateControlService.enforcer = {verified: false, done: false};
  }

  async populateData(): Promise<void> {
    if (this.TitleOptions.length === 0) {
      this.tabControlService.loadingTitles = true;
      await this.tabControlService.getTitlesList();
      this.tabControlService.loadingTitles = false;
    }
  }

  onRelationshipChange(): void {
    const value = this.enforcerForm.get('Relationship')?.value;
    if (value) {
      switch (value.toLowerCase()) {
        case 'self':
          this.recordUpdate = this.selfRecords[0];
          this.patchFormValues(this.recordUpdate, false, value);
          this.toggleFormControls(false);

          break;
        default:
          this.toggleFormControls(true);
          break;
      }
    }
  }

  toggleFormControls(enabled: boolean): void {
    if (enabled) { // Reset form when relationship != self
      const isSelfRecord: boolean = this.selfRecords.some((record: any) => {
        return this.enforcerForm.get('IDNo')?.value == record.IDNo;
      });

      if (isSelfRecord) {
        this.enforcerForm.get('Title')?.enable();
        this.enforcerForm.get('FirstName')?.enable();
        this.enforcerForm.get('MiddleName')?.enable();
        this.enforcerForm.get('LastName')?.enable();
        this.enforcerForm.get('Phone')?.enable();
        this.enforcerForm.get('Email')?.enable();
        this.enforcerForm.get('IDNo')?.enable();
        this.enforcerForm.get('KraPin')?.enable();
        this.enforcerForm.get('Address')?.enable();

        this.enforcerForm.get('Title')?.setValue('');
        this.enforcerForm.get('FirstName')?.setValue('');
        this.enforcerForm.get('MiddleName')?.setValue('');
        this.enforcerForm.get('LastName')?.setValue('');

        this.enforcerForm.get('Phone')?.setValue(' ');    
        this.enforcerForm.get('Email')?.setValue('');
        this.enforcerForm.get('IDNo')?.setValue('');
        this.enforcerForm.get('KraPin')?.setValue('');
        this.enforcerForm.get('Address')?.setValue('');
      }
      

    } else {
      // For 'self' option under relationship
      this.enforcerForm.get('Title')?.disable();
      this.enforcerForm.get('FirstName')?.disable();
      this.enforcerForm.get('MiddleName')?.disable();
      this.enforcerForm.get('LastName')?.disable();

      this.enforcerForm.get('Phone')?.disable();
      this.enforcerForm.get('Email')?.disable();  
      this.enforcerForm.get('IDNo')?.disable();
      this.enforcerForm.get('KraPin')?.disable();
      this.enforcerForm.get('PostalAddress')?.disable();
    }
    this.enforcerForm.updateValueAndValidity();
  }

  async saveEnforcer(): Promise<void> {
    if (this.enforcer.verified == true) this.loadingText = 'Updating Enforcer';
    else this.loadingText = 'Saving Enforcer';

    this.notificationService.isLoading(true);
    try {
      let data: any = {...this.enforcerForm.getRawValue(), ...{TrustID: this.estateControlService.TrustID}};

      if (data.Other && data.Other != '') {
        const other = data.Other;
        data.Relationship = other;
      }
      if (data.hasOwnProperty('Other')) {
        delete data['Other'];
      }
      
      if (this.enforcer.verified || this.autofilled) {
        data.IDNoImage = this.idObject;
        data.KraPinImage = this.kraObject;
      } else {
        data.IDNoImage = {name: '', stringValue: ''};
        data.KraPinImage = {name: '', stringValue: ''};
      }

      if (this.enforcer.EnforcerID) {
        data = {...data, ...{EnforcerID: this.enforcer.EnforcerID}};
      }

      const response = await this.apiService.postRequest(
        environment.baseUrl + Constants.privateEnforcerURL, data);
      
      // console.log('::::: RESPONSE ::::::\n', response);
      if (response.Status === 1) {
        let updatedRecord = {...data, ...{EnforcerID: response.EnforcerID}};
        
        if (this.enforcer.verified || this.autofilled) {
          if (response.IdURL) {
            this.estateControlService.updateDocSummary(response.IdURL);
            updatedRecord = {...updatedRecord, ...{IDURL: response.IdURL}};
          }
          if (response.KraURL) {
            this.estateControlService.updateDocSummary(response.KraURL);
            updatedRecord = {...updatedRecord, ...{KraURL: response.KraURL}};
          }

          if (this.autofilled) {
            this.autofilled = false;
            this.estateControlService.updateRecord(6, {...updatedRecord, ...{verified: true, done: true}});
          } else {
            this.estateControlService.updateRecord(6, {...updatedRecord, ...{done: true}});
          }
          
          this.notificationService.viewToast('success', 'Enforcer details saved');
          
        } else {
          this.validationService.addOrRemoveValidator(true, this.enforcerForm.get('IDNoImage')!);
          this.validationService.addOrRemoveValidator(true, this.enforcerForm.get('KraPinImage')!);

          // For updating auto-fill on 'self' relationship
          if (this.enforcerForm.value.Relationship?.toLowerCase() == 'self') {
            if (this.recordUpdate.IDNoImage) {
              if (this.recordUpdate.IDNoImage.stringValue &&
                this.recordUpdate.IDNoImage.stringValue.length > 0) {

                this.enforcerForm.get('IDNoImage')?.setValue(
                  this.recordUpdate.IDNoImage.stringValue);
              }
              this.updateIDObject(this.recordUpdate.IDNoImage);
              this.enforcerForm.get('IDNoImage')?.disable();
            }
            if (this.recordUpdate.KraPinImage) {
              if (this.recordUpdate.KraPinImage.stringValue &&
                this.recordUpdate.KraPinImage.stringValue.length > 0) {

                this.enforcerForm.get('KraPinImage')?.setValue(
                  this.recordUpdate.KraPinImage.stringValue);
              }
              this.updateKraObject(this.recordUpdate.KraPinImage);
              this.enforcerForm.get('KraPinImage')?.disable();
            }

            if (this.recordUpdate.IDNoImage || this.recordUpdate.KraPinImage) {
              this.notificationService.viewToast('info', 'Documents added from uploads');
            }
          }
          
          this.estateControlService.updateRecord(6, {...updatedRecord, ...{verified: true}});
          this.notificationService.viewToast('success', 'Enforcer details updated');
        }
        
      } else {
        this.notificationService.viewToast('error', response.Message);
      }

    } catch (error) {
      console.error(':: Error !! ', error);
      this.notificationService.viewToast('error', 'An unexpected error occurred');
    }
    this.notificationService.isLoading(false);
  }

  submitForm(): void {
    if (!this.enforcerForm.touched || this.enforcer.done === true) {
      this.navigateFunction();
    } else {
      this.saveEnforcer();
    }
  }

  async updateProgress(): Promise<void> {
    if (this.enforcerForm.touched && !this.enforcer.done && this.enforcerForm.valid) await this.saveEnforcer();
    this.saveProgress();
  }

  async navigateFunction(): Promise<void> {
    if (!this.estateControlService.page6Done) await this.estateControlService.updatePrivateTrustProgress('Enforcer');
    await this.estateControlService.changeTab(7);
    this.estateControlService.page6Done = true;
  }
}
