<div class="radio-button-group">
    <div class="radio-button" (click)="onRadioClick(true)" [ngClass]="{'selected': selectedA == true}">
        <div class="dot">
            <div class="innerDot"></div>
        </div>
        {{ labelOptionA}}
    </div>
    <div class="radio-button" (click)="onRadioClick(false)"  [ngClass]="{'selected': selectedA == false}">
        <div class="dot">
            <div class="innerDot"></div>
        </div>
        {{ labelOptionB}}
    </div>
</div>