import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StartComponent } from './pages/onboarding/start/start.component';
import { TrustComponent } from './pages/onboarding/trust/trust.component';
import { EstateComponent } from './pages/onboarding/estate/estate.component';
import { TrustFundComponent } from './pages/info/trust-fund/trust-fund.component';
import { EstatePlanningComponent } from './pages/info/estate-planning/estate-planning.component';
import { AppointmentsComponent } from './pages/onboarding/estate/appointments/appointments.component';

import { Step8CompleteComponent as Step8Milele } from './pages/onboarding/trust/step8-complete/step8-complete.component';
import { Step8CompleteComponent as Step8Estate } from './pages/onboarding/estate/private-trust/step8-complete/step8-complete.component';
import { LegalConsultationComponent } from './pages/onboarding/estate/legal-consultation/legal-consultation.component';



const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/get-started'},
  { path: 'get-started', component: StartComponent},

  // Milele trust
  { path: 'trust-fund/set-up', component: TrustComponent},
  { path: 'milele-trust/success', component: Step8Milele},
  
  // Estate Planning
  { path: 'estate-planning/appointment', component: AppointmentsComponent},
  { path: 'estate-planning/set-up', component: EstateComponent},
  { path: 'estate-planning/success', component: Step8Estate},
  { path: 'estate-planning/legal-consultaion', component: LegalConsultationComponent},

  { path: 'info/trust-fund', component: TrustFundComponent},
  { path: 'info/estate-planning', component: EstatePlanningComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
