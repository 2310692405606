import { Injectable } from '@angular/core';
import { ApiService } from './api/api.service';
import { environment } from 'src/environments/environment';
import * as Constants from "../constants/constants";
import { Subject } from 'rxjs';
import { delay, generateRandomNumber, getCurrentTime, getCurrentTimePlus1WeekInMilliseconds, removeEmptyObjects, truncateString } from '../util/Helper';
import { Router } from '@angular/router';
import * as localforage from 'localforage';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class EstateControlService {

  planType: 0 | 1 = 0;     // 0 - private trust | 1- will
  activeTab: number = 1;
  TrustID: string | null = null;

  session: any = null;

  // ============  Book Appointment  ==============
  appointmentType: 0 | 1 = 0;   // 0 - Individual | 1 - Related Individual/ Corporate
  AppointmentID: string | null = null;

  // Estate Planning
  estateReferee: any = {
    updated: false
  };

  // =================  Data  =====================
  // Private Trust
  settlors: any = [];
  payment: any = {done: false, uploaded: false};
  beneficiaries: any = [];
  assets: any = {};
  trustees: any = [];
  enforcer: any = {verified: false, done: false};

  docSummary: any = [];
  summary: any = '';

  // Will Creation
  testators: any = [];
  executors: any = [];
  witnesses: any = [];
  
  // =============  Page Markers  =================
  page1Done: boolean = false;
  page2Done: boolean = false;
  page3Done: boolean = false;
  page4Done: boolean = false;
  page5Done: boolean = false;
  page6Done: boolean = false;

  // Beneficiary Shares control
  finalSharesRemaining: number = 100;
  sharesRemaining: number = 100;

  // OTP Management
  OTPRequestPage: 'Basic' | 'Settlor' | 'Guardian' | 'SettlorEstate' | 'Testator' = 'Basic';
  OTPIndex: number = 0;
  OTPValid: boolean = false;
  defaultOTPMessage: string = 'In order to proceed, please enter the One Time Password (OTP) sent to your email and phone';
  OTPMessage: string = this.defaultOTPMessage;
  DefaultOTPRequestStats: any = {time: null, phone: null};
  OTPRequestStats: any = this.DefaultOTPRequestStats;
  
  // STK Popup
  paymentID: string = '';
  stkLoading: boolean = false;
  stkTimeout: boolean = false;
  stkMessage: string = '';
  requestingSTK: boolean = false;
  stkRequestID: string = '';
  stkAmount: string = '';
  stkPhone: string = '';
  stkMode: 0 | 1 = 0;
  stkDefPinText: string = 'Please check for the M-Pesa popup on your phone and input your M-Pesa PIN to complete payment';
  stkAltPinText: string = 'Please wait as we confirm the payment status';
  stkPinText: string = this.stkDefPinText;

  // ===============  Storage  ====================
  estateTypeKey: string = 'estateType';
  storageKey: string = 'MileleEstatePlanningData';
  storageKeyWill: string = 'MileleEstatePlanningWill';
  storageKeyAppointment: string = 'MileleAppointment';
  consultationKey: string = 'legalConsultation';

  showResumePopup: boolean = false;
  resumeSessionExpired: boolean = false;

  onlineStorageObject: any = null;
  storageObjectPrivate: any = {
    timeStamp: 0,
    planType: this.planType,
    activeTab: this.activeTab,
    TrustID: this.TrustID,

    AppointmentID: this.AppointmentID,
    appointmentType: this.appointmentType,
    estateReferee: this.estateReferee,

    settlors: this.settlors,
    beneficiaries: this.beneficiaries,
    assets: this.assets,
    trustees: this.trustees,
    enforcer: this.enforcer,
    payment: this.payment,

    page1Done: this.page1Done,
    page2Done: this.page2Done,
    page3Done: this.page3Done,
    page4Done: this.page4Done,
    page5Done: this.page5Done,
    page6Done: this.page6Done,
    
    docSummary: this.docSummary,
    summary: this.summary,

    OTPRequestStats: this.OTPRequestStats,
    paymentID: this.paymentID,
    stkLoading: this.stkLoading,
    stkTimeout: this.stkTimeout,
    stkRequestID: this.stkRequestID,
    stkAmount: this.stkAltPinText,
    stkPhone: this.stkPhone,
    stkMode: this.stkMode
  };

  storageObjectWill: any = {
    timeStamp: 0,
    planType: this.planType,
    activeTab: this.activeTab,
    TrustID: this.TrustID,

    AppointmentID: this.AppointmentID,
    appointmentType: this.appointmentType,

    testators: this.testators,
    executors: this.executors,
    beneficiaries: this.beneficiaries,
    assets: this.assets,
    witnesses: this.witnesses,
    payment: this.payment,

    page1Done: this.page1Done,
    page2Done: this.page2Done,
    page3Done: this.page3Done,
    page4Done: this.page4Done,
    page5Done: this.page5Done,
    page6Done: this.page6Done,
    
    docSummary: this.docSummary,
    summary: this.summary,

    finalSharesRemaining: this.finalSharesRemaining,
    sharesRemaining: this.sharesRemaining,
    OTPRequestStats: this.OTPRequestStats,
    paymentID: this.paymentID,
    stkLoading: this.stkLoading,
    stkTimeout: this.stkTimeout,
    stkRequestID: this.stkRequestID,
    stkAmount: this.stkAltPinText,
    stkPhone: this.stkPhone,
    stkMode: this.stkMode
  };

  updateStorageObject(): void {
    const timestamp = new Date().getTime();

    this.storageObjectPrivate = {
      planType: this.planType,
      activeTab: this.activeTab,
      TrustID: this.TrustID,

      AppointmentID: this.AppointmentID,
      appointmentType: this.appointmentType,
      estateReferee: this.estateReferee,

      settlors: this.settlors,
      beneficiaries: this.beneficiaries,
      assets: this.assets,
      trustees: this.trustees,
      enforcer: this.enforcer,
      payment: this.payment,

      page1Done: this.page1Done,
      page2Done: this.page2Done,
      page3Done: this.page3Done,
      page4Done: this.page4Done,
      page5Done: this.page5Done,
      page6Done: this.page6Done,
      
      docSummary: this.docSummary,
      summary: this.summary,

      OTPRequestStats: this.OTPRequestStats,
      paymentID: this.paymentID,
      stkLoading: this.stkLoading,
      stkTimeout: this.stkTimeout,
      stkRequestID: this.stkRequestID,
      stkAmount: this.stkAltPinText,
      stkPhone: this.stkPhone,
      stkMode: this.stkMode
    };

    if (this.TrustID || this.AppointmentID) {
      this.storageObjectPrivate = {...this.storageObjectPrivate, ...{timeStamp: timestamp}};
    }
  }
  updateStorageObjectWill(): void {
    const timestamp = new Date().getTime();

    this.storageObjectWill = {
      planType: this.planType,
      activeTab: this.activeTab,
      TrustID: this.TrustID,

      AppointmentID: this.AppointmentID,
      appointmentType: this.appointmentType,

      testators: this.testators,
      executors: this.executors,
      beneficiaries: this.beneficiaries,
      assets: this.assets,
      witnesses: this.witnesses,
      payment: this.payment,

      page1Done: this.page1Done,
      page2Done: this.page2Done,
      page3Done: this.page3Done,
      page4Done: this.page4Done,
      page5Done: this.page5Done,
      page6Done: this.page6Done,
      
      docSummary: this.docSummary,
      summary: this.summary,

      finalSharesRemaining: this.finalSharesRemaining,
      sharesRemaining: this.sharesRemaining,
      OTPRequestStats: this.OTPRequestStats,
      paymentID: this.paymentID,
      stkLoading: this.stkLoading,
      stkTimeout: this.stkTimeout,
      stkRequestID: this.stkRequestID,
      stkAmount: this.stkAltPinText,
      stkPhone: this.stkPhone,
      stkMode: this.stkMode
    };

    if (this.TrustID) {
      this.storageObjectWill = {...this.storageObjectWill, ...{timeStamp: timestamp}};
    }
  }
  // ===============    ====================
  async changeTab(tabNumber: number) {
    this.notificationService.isLoadingTab(true);
    this.activeTab = tabNumber;
    this.updateStorageObject();
    let key = this.storageKey;
    if (this.AppointmentID) key = this.storageKeyAppointment;
    await this.saveData(key, this.storageObjectPrivate);
    this.notificationService.isLoadingTab(false);
  }
  async changeTabWill(tabNumber: number) {
    this.notificationService.isLoadingTab(true);
    this.activeTab = tabNumber;
    this.updateStorageObjectWill();
    await this.saveData(this.storageKeyWill, this.storageObjectWill);
    this.notificationService.isLoadingTab(false);
  }

  updateDocSummary(newUrl: string) {
    try {
      const item = {
        Url: newUrl
      };
      this.docSummary.push(item);

    } catch (error) {
      console.error('DocSummary !!! ', error);
    }
  }

  async updateRecord(tabNumber: number, data: any) {
    if (tabNumber === 1) {
      this.settlors = data;
    } else if (tabNumber === 2) {
      if (this.AppointmentID) this.assets = data;
      else this.payment = {...this.payment, ...data};
    } else if (tabNumber === 3) {
      if (this.AppointmentID) {
        this.estateReferee = {...this.estateReferee, ...data};
        this.estateReferee.updated = true;
      } else this.beneficiaries = data;
    } else if (tabNumber === 4) {
      this.assets = data;
    } else if (tabNumber === 5) {
      this.trustees = data;
    } else if (tabNumber === 6) {
      this.enforcer = {...this.enforcer, ...data};
    } else if (tabNumber === 7) {
      this.summary = data;
    }

    this.updateStorageObject();

    const dat = this.storageObjectPrivate;
    let key = this.storageKey;
    if (this.AppointmentID) key = this.storageKeyAppointment;

    await this.saveData(key, dat);
  }
  async updateRecordWill(tabNumber: number, data: any) {
    if (tabNumber === 1) {
      this.testators = data;
    } else if (tabNumber === 2) {
      this.payment = {...this.payment, ...data};
    } else if (tabNumber === 3) {
      this.executors = data;
    } else if (tabNumber === 4) {
      this.assets = data;
    } else if (tabNumber === 5) {
      this.beneficiaries = data;
    } else if (tabNumber === 6) {
      this.witnesses = data;
    } else if (tabNumber === 7) {
      this.summary = data;
    }

    this.updateStorageObjectWill();

    const dat = this.storageObjectWill;
    // Save Data locally
    await this.saveData(this.storageKeyWill, dat);
  }

  async refreshRecord(tabNumber: number) {
    if (tabNumber === 1) {
      this.settlors = [];
    } else if (tabNumber === 2) {
      if (this.AppointmentID) this.assets = {};
      else this.payment = {done: false, uploaded: false};
    } else if (tabNumber === 3) {
      if (this.AppointmentID) this.estateReferee = {updated: false};
      else this.beneficiaries = [];
    } else if (tabNumber === 4) {
      this.assets = {};
    } else if (tabNumber === 5) {
      this.trustees = [];
    } else if (tabNumber === 6) {
      this.enforcer = {verified: false, done: false};
    }

    this.updateStorageObject();

    const dat = this.storageObjectPrivate;
    let key = this.storageKey;
    if (this.AppointmentID) key = this.storageKeyAppointment;
    await this.saveData(key, dat);
  }
  async refreshRecordWill(tabNumber: number) {
    if (tabNumber === 1) {
      this.testators = [];
    } else if (tabNumber === 2) {
      this.payment = {done: false, uploaded: false};
    } else if (tabNumber === 3) {
      this.executors = [];
    } else if (tabNumber === 4) {
      this.assets = {};
    } else if (tabNumber === 5) {
      this.beneficiaries = [];
    } else if (tabNumber === 6) {
      this.witnesses = [];
    }

    this.updateStorageObjectWill();

    const dat = this.storageObjectWill;
    // Save Data locally
    await this.saveData(this.storageKeyWill, dat);
  }

  updateRemainingShares(value: number) {
    try {
      this.sharesRemaining = this.finalSharesRemaining - value;
    } catch (error) {
      // console.log('-- Remaining shares error : ', error, ' :: Input : ', value);
      this.sharesRemaining = this.finalSharesRemaining - 0;
    }
    // console.log('-- Remaining shares : ', this.sharesRemaining);
  };

  revertRemainingShares(value: number) {
    try {
      this.sharesRemaining = this.finalSharesRemaining + value;
    } catch (error) {
      // console.log('++ Remaining shares error : ', error, ' :: Input : ', value);
      this.sharesRemaining = this.finalSharesRemaining + 0;
    }
    // console.log('++ Remaining shares : ', this.sharesRemaining, ', == Removed value : ', value);
  };

  updateFinalRemainingShares(value: number) {
    this.finalSharesRemaining = value;
    // console.log('Final shares up to date : ', this.finalSharesRemaining);
  };

  async cleanupDocSummary(): Promise<void> {
    const temp = removeEmptyObjects(this.docSummary);
    this.docSummary = temp;
    if (this.planType == 0) {
      this.updateStorageObject();
      let key = this.storageKey;

      if (this.AppointmentID) key = this.storageKeyAppointment;
      await this.saveData(key, this.storageObjectPrivate);
    } else {
      this.updateStorageObjectWill();
      await this.saveData(this.storageKeyWill, this.storageObjectWill);
    }
  }

  updateSTK(amount: string, phone: string): void {
    this.stkAmount = amount;
    this.stkPhone = phone;
  }
  resetSTK = () => {
    this.stkTimeout = false;
    this.stkMessage = '';
  }
  closeSTK = () => {
    this.stkLoading = false;
    this.resetSTK();;
  }

  async removeRecord(
    category: 'settlor' | 'beneficiary' | 'asset' | 'trustee' | 'enforcer' | 'testator' | 'executor' | 'witness',
    recordID: number,
    trustID: number,
    url: string
  ): Promise<number> {

    this.notificationService.isLoading(true);
    try {
      let request = {
        Deleted: '1',
        TrustID: trustID
      };

      switch(category) {
        case 'settlor':
          request = {...request, ...{SettlorID: recordID}};
          break;
        case 'beneficiary':
          request = {...request, ...{BeneficiaryID: recordID}};
          break;
        case 'asset':
          request = {...request, ...{AssetsID: recordID}};
          break;
        case 'trustee':
          request = {...request, ...{TrusteeID: recordID}};
          break;
        case 'enforcer':
          request = {...request, ...{EnforcerID: recordID}};
          break;
        case 'testator':
          request = {...request, ...{TestatorID: recordID}};
          break;
        case 'executor':
          request = {...request, ...{ExecutorID: recordID}};
          break;
          case 'witness':
            request = {...request, ...{WitnessID: recordID}};
            break;
        default:
          break;
      }

      const response = await this.apiService.postRequest(url, request);
        this.notificationService.isLoading(false);
        if (response.Status === 1) {
          return 1;
        } else {
          console.log('>>> Error : ', response);
          return 0;
        }
    } catch (error) {
      console.log(':: Error !! ', error);
      this.notificationService.isLoading(false);
      return 0;
    }
  }

  resetLocalData(): void {
    this.activeTab = 1;
    this.TrustID = null;
    this.AppointmentID = null;
    this.appointmentType = 0;
    this.estateReferee = {
      updated: false
    };

    this.settlors = [];
    this.beneficiaries = [];
    this.assets = {};
    this.trustees = [];
    this.enforcer = {verified: false, done: false};

    // Will Creation
    this.testators = [];
    this.executors = [];
    this.witnesses = [];

    this.payment = {done: false, uploaded: false};;
    this.docSummary = [];
    this.summary = '';

    this.page1Done = false;
    this.page2Done = false;
    this.page3Done = false;
    this.page4Done = false;
    this.page5Done = false;
    this.page6Done = false;

    this.finalSharesRemaining = 100;
    this.sharesRemaining = 100;
    this.OTPRequestStats = this.DefaultOTPRequestStats;
    this.paymentID = '';
    this.stkLoading = false;
    this.stkTimeout = false;
    this.stkRequestID = '';
    this.stkAmount = '';
    this.stkPhone = '';
    this.stkMode = 0;
  }
  async clearServiceRecords(): Promise<void> {
    await this.changeTab(1);
    this.updateFinalRemainingShares(100);
    this.resetLocalData();

    this.updateStorageObject();
    this.clearData(this.storageKey);
    this.clearData(this.storageKeyAppointment);
    this.updateStorageObjectWill();
    this.clearData(this.storageKeyWill);
  }

  async updateDataFromStorage(onlineObject: any = null): Promise<void> {
    let data;
    let currentKey = await this.getData(this.estateTypeKey);
    if (currentKey) data = await this.getData(currentKey);

    if (onlineObject) {
      data = onlineObject;
      if (onlineObject.planType == 0) currentKey = this.storageKey;
      else currentKey = this.storageKeyWill;
    }
    
    if (data) {
      if (data.planType) this.planType = data.planType;
      if (data.activeTab) this.activeTab = data.activeTab;
      if (data.TrustID) this.TrustID = data.TrustID.toString();

      if (data.AppointmentID) this.AppointmentID = data.AppointmentID;
      if (data.appointmentType) this.appointmentType = data.appointmentType;
      if (data.estateReferee) this.estateReferee = data.estateReferee;

      if (data.settlors) this.settlors = data.settlors;
      if (data.beneficiaries) this.beneficiaries = data.beneficiaries;
      if (data.assets) this.assets = data.assets;
      if (data.trustees) this.trustees = data.trustees;
      if (data.enforcer) this.enforcer = data.enforcer;
      if (data.payment) this.payment = data.payment;

      if (data.testators) this.testators = data.testators;
      if (data.executors) this.executors = data.executors;
      if (data.witnesses) this.witnesses = data.witnesses;

      
      if (data.docSummary) this.docSummary = data.docSummary;
      if (data.summary) this.summary = data.summary;

      switch (true) {
        case this.activeTab == 2:
          this.page1Done = true;
          break;
        case this.activeTab == 3:
          this.page1Done = true;
          this.page2Done = true;
          break;
        case this.activeTab == 4:
          this.page1Done = true;
          this.page2Done = true;
          this.page3Done = true;
          break;
        case this.activeTab == 5:
          this.page1Done = true;
          this.page2Done = true;
          this.page3Done = true;
          this.page4Done = true;
          break;
        case this.activeTab == 6:
          this.page1Done = true;
          this.page2Done = true;
          this.page3Done = true;
          this.page4Done = true;
          this.page5Done = true;
          break;
        case this.activeTab == 7:
          this.page1Done = true;
          this.page2Done = true;
          this.page3Done = true;
          this.page4Done = true;
          this.page5Done = true;
          this.page6Done = true;
          break;
        default:
          this.page1Done = false;
          this.page2Done = false;
          this.page3Done = false;
          this.page4Done = false;
          this.page5Done = false;
          this.page6Done = false;
          break;
      }

      if (data.finalSharesRemaining) this.finalSharesRemaining = data.finalSharesRemaining;
      if (data.sharesRemaining) {
        this.sharesRemaining = data.sharesRemaining;
      }
      if (data.OTPRequestStats) this.OTPRequestStats = data.OTPRequestStats;

      if (data.paymentID) this.paymentID = data.paymentID;
      // if (data.stkLoading) this.stkLoading = data.stkLoading;
      // if (data.stkTimeout) this.stkTimeout = data.stkTimeout;
      if (data.stkRequestID) this.stkRequestID = data.stkRequestID;
      // if (data.stkAmount) this.stkAmount = data.stkAmount;
      // if (data.stkPhone) this.stkPhone = data.stkPhone;
      // if (data.stkMode) this.stkMode = data.stkMode;
      
    }

    if (data.planType == 0) {
      this.updateStorageObject();
    }
    else {
      this.updateStorageObjectWill();
    }
    if (!environment.production) console.log( `${currentKey} Data retrieved\n`, data);
  }

  loadingUpdate: boolean = false;
  // Resume later functionality
  saveProgress = async(): Promise<void> => {
    this.loadingUpdate = true;
    if (this.planType == 0) {
      this.updateStorageObject();
      await this.saveData(this.storageKey, this.storageObjectPrivate);
    } else {
      this.updateStorageObjectWill();
      await this.saveData(this.storageKeyWill, this.storageObjectWill);
    }

    try {
      let formattedData;
      if (this.planType == 0) formattedData = this.stripSavedObjects(this.storageObjectPrivate);
      else formattedData = this.stripSavedObjects(this.storageObjectWill);

      let otp = generateRandomNumber();
      let expiry = getCurrentTimePlus1WeekInMilliseconds();
      const currentTime = getCurrentTime();
      
      if (this.onlineStorageObject) {
        this.notificationService.viewToast('info', 'Updating your progress at ' +currentTime);

        otp = this.onlineStorageObject.OTP;
      } else {
        this.notificationService.viewToast('info', 'Saving your progress at ' +currentTime);
      }
      
      formattedData = {...formattedData, ...{OTP: otp, Expiry: expiry}}
      if (formattedData == null) {
        return;
      }
      const csvData = this.convertObjectToCSV(formattedData);

      let ccEmails: any = [];
      let primaryRecord;

      if (this.planType == 0) {
        if (this.settlors && this.settlors.length > 0) {
          primaryRecord = this.settlors[0];

          for (let i=1; i<this.settlors.length; i++) {
            const item = {
              FirstName: this.settlors[i].FirstName,
              Email: this.settlors[i].Email
            };

            ccEmails.push(item);
          }  
        }
      } else {
        if (this.testators && this.testators.length > 0) {
          primaryRecord = this.testators[0];

          for (let i=1; i<this.testators.length; i++) {
            const item = {
              FirstName: this.testators[i].FirstName,
              Email: this.testators[i].Email
            };

            ccEmails.push(item);
          }  
        }
      }

      let data = {
        FirstName: primaryRecord.FirstName,
        Email: primaryRecord.Email,
        Phone: primaryRecord.Phone,
        TrustID: this.TrustID?.toString(),
        OTP: otp,
        Expiry: expiry,
        Data: csvData,  
        CCEmails: ccEmails,
        PlanType: this.planType,
      };

      if (this.session) {
        data = {...data, ...{Session: this.session}};
      }
      //console.log('dgsabcsdkj', csvData);

      const response = await this.apiService.postRequest(
        environment.baseUrl + Constants.cacheEstateURL, data);
      // Update local objects
      if (response.Status == 1 || response.Status == 2) {
        this.notificationService.viewToast('success', `Progress Saved at ${currentTime}`, `Check your email ${primaryRecord.Email} for how to get back in`);

      } else {
        console.error('SaveProgress: ', response.Message);
        this.notificationService.viewToast('error', 'Failed to save your progress', 'Your details are is still saved in this computer')
      }

    } catch (error) {
      console.error('SaveProgress! ', error);
      this.notificationService.viewToast('error', 'An error occurred', 'Your details are is still saved in this computer')
    }
    this.loadingUpdate = false;
  }
  // Post to server
  async checkProgress(session: string): Promise<void> {
    this.notificationService.isLoading(true);
    try {
      const data = {
        Session: session,
      };

      const response = await this.apiService.postRequest(
        environment.baseUrl + Constants.resumeEstateURL, data);

        if (response.Status === 1) {
          const result = await this.retrieveProgress(response.Message);

          if (result && result.Expiry) {
            const now = new Date().getTime();
            if (now <= result.Expiry) {
              this.onlineStorageObject = result;
              this.showResumePopup = true;
            } else {
              this.notificationService.viewToast('error', 'Session invalid or expired');
            }
          }
        } else {
          console.error('Check Progress : ', response.Message);
          this.notificationService.viewToast('error', 'Session invalid or expired');
        }
    } catch (error) {
      console.error('Check Progress ! ', error);
    }
    this.notificationService.isLoading(false);
  }
  async retrieveProgress(csvURL: string): Promise<any> {
    try {
      const csvData = await this.apiService.getFile(csvURL);
      // console.log('CSV DATA: ', csvData);

      // Convert CSV data back to the object
      const object = this.convertCSVToObject(csvData);
      return object;

    } catch (error) {
      console.error('RetrieveProgress! ', error);
      return null;
    }
  }
  stripSavedObjects(originalObj: any): any {
    try {
      let obj = originalObj; // copy obj to avoid editing original

      if (obj && obj.settlors && obj.settlors.length > 0) {
        for (let i = 0; i < obj.settlors.length; i++) {
          obj.settlors[i] = this.stripImages(obj.settlors[i]);
        }
      }
      if (obj && obj.beneficiaries && obj.beneficiaries.length > 0) {
        for (let i = 0; i < obj.beneficiaries.length; i++) {
          obj.beneficiaries[i] = this.stripImages(obj.beneficiaries[i]);
        }
      }
      if (obj && obj.trustees && obj.trustees.length > 0) {
        for (let i = 0; i < obj.trustees.length; i++) {
          obj.trustees[i] = this.stripImages(obj.trustees[i]);
        }
      }
      if (obj && obj.testators && obj.testators.length > 0) {
        for (let i = 0; i < obj.testators.length; i++) {
          obj.testators[i] = this.stripImages(obj.testators[i]);
        }
      }
      if (obj && obj.witnesses && obj.witnesses.length > 0) {
        for (let i = 0; i < obj.witnesses.length; i++) {
          obj.witnesses[i] = this.stripImages(obj.witnesses[i]);
        }
      }
      if (obj && obj.executors && obj.executors.length > 0) {
        for (let i = 0; i < obj.executors.length; i++) {
          obj.executors[i] = this.stripImages(obj.executors[i]);
        }
      }
      if (obj && obj.guardians && obj.guardians.length > 0) {
        for (let i = 0; i < obj.guardians.length; i++) {
          obj.guardians[i] = this.stripImages(obj.guardians[i]);
        }
      }
      if (obj && obj.trustees && obj.trustees.length > 0) {
        for (let i = 0; i < obj.trustees.length; i++) {
          obj.trustees[i] = this.stripImages(obj.trustees[i]);
        }
      }
      if (obj && obj.enforcer) {
        obj.enforcer = this.stripImages(obj.enforcer);
      }
      // console.log('Cleaned free of images:\n', obj);
      return obj;
    } catch (error) {
      console.error('ERROR Strip objects ! ', error);
      return null;
    }
  }
  stripImages(obj: any): any {
    if (obj.IDURL) {
      obj.IDNoImage = "";
    }
    if (obj.KraURL) {
      obj.KraPinImage = "";
    }
    if (obj.BirthCertURL) {
      obj.BirthCertImage = "";
    }
    if (obj.RegCertURL) {
      obj.RegCertImage = "";
    }
    if (obj.PassportURL) {
      obj.PassportImage = "";
    }
    if (obj.CvURL) {
      obj.CvURL = "";
    }

    return obj;
  } 

  // CSV Handlers
  convertObjectToCSV(obj: any): string {
    const csvArray: string[] = [];

    function flattenObject(object: any, prefix = '') {  
      for (const key in object) {  
        if (object.hasOwnProperty(key)) {  

          const prefixedKey = prefix + key;
                
          if (Array.isArray(object[key])) {        
            if (object[key].length === 0) {        
              csvArray.push(`${prefixedKey},[]`);    
            } else {          
              object[key].forEach((item: any, index: number) => {            
                flattenObject(item, `${prefixedKey}[${index}]_`);          
              });        
            }      
          } else if (typeof object[key] === 'object' && object[key] !== null) {     

            if (Object.keys(object[key]).length === 0) {
              csvArray.push(`${prefixedKey},{}`);
            } else {
              flattenObject(object[key], prefixedKey + '_');
            }
          } else {

            if (object[key] === null) {
              csvArray.push(`${prefixedKey},null`);
            } else if (object[key] === '') {
              csvArray.push(`${prefixedKey},""`);
            } else {
              csvArray.push(`${prefixedKey},${object[key]}`);
            }
          } 
        } 
      }
    }

    flattenObject(obj);

    return csvArray.join('\n');
  }
  convertCSVToObject(csv: string): any {
    const obj: any = {};

    const lines = csv.split('\n');
    lines.forEach(line => {
        const [key, value] = line.split(',');
        const keys = key.split('_');
        let currentObj = obj;

        for (let i = 0; i < keys.length; i++) {
            const k = keys[i];
            const isArrayElement = /\[\d+\]$/.test(k); // Check if the key represents an array element
            const isArray = /\[\]$/.test(k); // Check if the key represents an array

            if (isArrayElement) {
                const arrayKey = k.substring(0, k.indexOf('[')); // Extract the array key
                const index = parseInt(k.match(/\d+/)![0], 10); // Extract the array index

                if (!currentObj[arrayKey]) {
                    currentObj[arrayKey] = [];
                }

                if (i === keys.length - 1) {
                    currentObj[arrayKey][index] = this.parseValue(value);
                } else {
                    if (!currentObj[arrayKey][index]) {
                        currentObj[arrayKey][index] = {};
                    }
                }

                currentObj = currentObj[arrayKey][index];
            } else if (isArray) {
                const arrayKey = k.substring(0, k.indexOf('[')); // Extract the array key

                if (!currentObj[arrayKey]) {
                    currentObj[arrayKey] = [];
                }

                if (i === keys.length - 1) {
                    currentObj[arrayKey].push(this.parseValue(value));
                } else {
                    if (!currentObj[arrayKey][currentObj[arrayKey].length - 1]) {
                        currentObj[arrayKey].push({});
                    }
                }

                currentObj = currentObj[arrayKey][currentObj[arrayKey].length - 1];

            } else {
                if (!currentObj[k]) {
                    if (i === keys.length - 1) {
                        currentObj[k] = this.parseValue(value);
                        // Check if the key is 'Phone' and the value needs to be a string
                        if (k === 'Phone' && typeof currentObj[k] !== 'string') {
                            currentObj[k] = String(currentObj[k]);
                        }
                    } else {
                        currentObj[k] = {};
                    }
                }
                currentObj = currentObj[k];
            }
        }
    });

    // console.log('CSV Object: ', obj);

    return obj;
  }
  parseValue(value: string): any {
    switch (value) {
      case 'null':
        return null;
      case '""':
        return '';
      case '{}':
        return {};
      case '[]':
        return [];
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return isNaN(Number(value)) ? value : Number(value);
  
    }
  }


  /// Storage
  async getData(storageKey: string): Promise<any> {
    const data = await localforage.getItem(storageKey);
    return data ? data : null;
  }
  async saveData(storageKey: string, data: any): Promise<void> {
    await localforage.setItem(storageKey, data);
    await localforage.setItem(this.estateTypeKey, storageKey);
    // console.log('saved\n', await this.getData(storageKey));
  }
  async clearData(storageKey: string): Promise<void> {
    await localforage.removeItem(storageKey);
    await localforage.removeItem(this.estateTypeKey);
  }

  updateConsultationData = async(extraData: any) => {
    let consultationData = {
      TrustID: this.TrustID,
      AccountType: (this.planType === 0 ? 'PrivateTrust' : 'WillCreation')
    };

    if (extraData) {
      consultationData = {...consultationData, ...extraData};
    }

    await this.saveData(this.consultationKey, consultationData);
    if (!environment.production) console.log('Consutation Data :\n', await this.getData(this.consultationKey));
  };
  clearConsultationData = async() => {
    await localforage.removeItem(this.consultationKey);
  };

  ConfirmOptions: any = [
    'Yes',
    'No'
  ];

  TrusteeOptions: any = [
    'Individual Trustee',
    'Corporate Trustee'
  ];

  FinancierOptions: any = [
    'Bank',
    'Sacco',
    'Loan',
    'Other (Specify)'
  ];

  InvestmentAccountOptions: any = [
    'Shares',
    'Fixed Deposit',
    'Unit Trusts',
    'Govt Securities (T-Bills,T-Bonds,Infrastructure Bonds etc)',
    'Corporate Bonds',
    'Other'
  ];
  RetirementBenefitsOptions: any = [
    'Provident/Pension Fund',
    'Personal Pension Plan'
  ];
  OwnershipOptions: any = [
    'Sole Owner',
    'Joint Ownership',
    'Joint in Shares'
  ];

  randomSuffix: number = 0;
  // M-PESA Payment
  async mpesaPayment(): Promise<void> {
    this.requestingSTK = true;
    let trustName = this.payment.trustName;

    try {
      let response: any;

      if (this.stkMode === 1) {
        this.stkPinText = this.stkAltPinText;
        response = {
          Status: 1,
          BillRefNo: this.paymentID
        };

      } else {
        let paymentId;
        
        if (this.planType == 1) {
          trustName = `${truncateString(this.testators[0].LastName)}-${truncateString(this.testators[0].FirstName)}`;
          paymentId = `WILL-${this.testators[0].IDNo || 'ID'}-${trustName}-${this.randomSuffix}`;
        } else {
          paymentId = `PRVT-${this.settlors[0].IDNo || 'ID'}-${truncateString(trustName)}-${this.randomSuffix}`;
        }

        this.paymentID = paymentId;
        const data = {
          Amount: this.stkAmount,
          Phone: this.stkPhone,
          BillRefNo: this.paymentID,
          TrustName: trustName,
          Paybill: 'estate'
        };

        this.stkPinText = this.stkDefPinText;
        this.notificationService.viewToast('info', 'Requesting M-Pesa Payment');
        response = await this.apiService.postRequest(
          environment.baseUrl + Constants.mpesaPaymentURL, data);
      }

      if (response.Status === 1) {
        this.resetSTK();
        this.requestingSTK = false;

        if (this.stkMode === 0) {
          await delay(15000);
        }

        await this.retryMpesaPaymentConfirmation(this.paymentID)
        .then(async (result) => {
          if (!environment.production) console.log('Final Result:', result);

          if (result.Status === 1) {
            this.stkRequestID = response.Reference;
            this.notificationService.viewToast('success', 'Payment received');

            this.closeSTK();
            if (this.activeTab === 2) {
              this.triggerUpdateTrustDetails('MPESA');
            }
            
          } else if (result.Status === 2) {
            this.notificationService.viewToast('error', 'Could not confirm your payment');
            this.stkTimeout = true;
            
          } else {
            // Error occurred!!
            this.notificationService.viewToast('error', result.Message);
            this.stkMessage = 'Oops! Looks like an error occurred. Did you make the payment?';
            this.stkTimeout = true;
          }
          
        })
        .catch((error) => {
          console.error('MpesaPayment: ', error);
          this.notificationService.viewToast('error', 'An unexpected error occurred');
          this.stkMessage = 'Sorry, an unexpected error occurreed';
          this.stkTimeout = true;
          
        }); 
      } else {
        this.stkMessage = response.Message;
        this.notificationService.viewToast('error', response.Message);
      }
    } catch (error) {
      console.error('MpesaPayment! ', error);
      this.notificationService.viewToast('error', 'A fatal error occurred');
    }
    this.requestingSTK = false;
  }
  async retryMpesaPaymentConfirmation(paymentID: string): Promise<any> {
    this.resetSTK();

    const maxAttempts = 5;
    let attempts = 0;

    const confirmMpesaPayment = async (): Promise<any> => {
      try {
        const data = {
          BillRefNo: paymentID,
          Phone: this.stkPhone
        };
  
        const response = await this.apiService.postRequest(
          environment.baseUrl + Constants.confirmMPESAPaymentURL, data);
  
          if (!environment.production) console.log(':::: RESPONSE !!!!\n', response);
  
        if (response && response.Status) {
          return response;
        } else {
          return {
            Status: 0,
            Message: 'Error contacting server'
          };
        }
        
      } catch (error) {
        let resp = {
          Status: 0,
          Message: 'An unexpected error occurred'
        }
        console.error('ConfirmMpesaPayment! ', error);
  
        return resp;
      }
    }
  
    async function tryConfirmation() {
      attempts++;
      if (!environment.production) console.log(`Attempt ${attempts}`);
  
      const response = await confirmMpesaPayment();
  
      if (response.Status === 1) {
        if (!environment.production) console.log('Payment confirmed successfully');
        return response;
        
      } else if (response.Status === 2) {
        if (attempts < maxAttempts) {
          await delay(15000); // Wait for 12 seconds (4000 milliseconds)
          return tryConfirmation();
        } else {
          // console.log('Max retry attempts reached');
          return {
            Status: 2,
            Message: 'Max retry attempts reached'
          };
        }
        
      } else {
        // console.log('Max retry attempts reached');
        return {
          Status: 0,
          Message: response.Message
        };
      }
    }
  
    return tryConfirmation();
  }

  private updateTrustDetailsSubject = new Subject<string>();
  private initMpesaSubject = new Subject<void>();
  private retryMpesaSubject = new Subject<void>();

  updateTrustDetails$ = this.updateTrustDetailsSubject.asObservable();
  initMpesa$ = this.initMpesaSubject.asObservable();
  retryMpesa$ = this.retryMpesaSubject.asObservable();

  triggerUpdateTrustDetails(paymentMode: string) {
    this.updateTrustDetailsSubject.next(paymentMode);
  }
  payMpesa() {
    this.initMpesaSubject.next();
  }
  retryMpesa() {
    this.resetSTK();
    this.retryMpesaSubject.next();
  }
  async updatePrivateTrustProgress(step: string): Promise<void> {
    this.notificationService.isLoadingTab(true);
    try {
      const data = {
        Progress: step,
        InvestmentConsultationFee: this.payment.InvestmentConsultationFee,
        LegalReviewFee: this.payment.LegalReviewFee,
        TaxConsultationFee: this.payment.TaxConsultationFee,
        TrustCreationFee: this.payment.TrustCreationFee,
        Amount: this.payment.Amount,

        TrustID: this.TrustID
      };

      const response = await this.apiService.postRequest(
        environment.baseUrl + Constants.privateTrustDetailsURL, data, false);
      
      // console.log('::::: RESPONSE ::::::\n', response);
      if (response.Status != 1) {
        this.notificationService.viewToast('error', response.Message);
      }

    } catch (error) {
      console.error('UpdateTrustDetails! ', error);
      this.notificationService.viewToast('error', 'Could not update progress');
    }
    this.notificationService.isLoadingTab(false);
  }
  async updateWillProgress(step: string): Promise<void> {
    this.notificationService.isLoadingTab(true);
    try {
      const data = {
        Progress: step,
        Amount: this.payment.Amount,
        TrustID: this.TrustID,
      };

      const response = await this.apiService.postRequest(
        environment.baseUrl + Constants.willDetailsURL, data, false);
      
      // console.log('::::: RESPONSE ::::::\n', response);
      if (response.Status != 1) {
        this.notificationService.viewToast('error', response.Message);
      }
    } catch (error) {
      console.error('UpdateWillDetails! ', error);
      this.notificationService.viewToast('error', 'Could not update progress');
    }
    this.notificationService.isLoadingTab(false);
  }

  constructor(
    private notificationService: NotificationService,
    private apiService: ApiService,
    private router: Router
  ) { 
    this.randomSuffix = generateRandomNumber();
  }
}
