import { AfterViewInit, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableDataSourcePaginator } from '@angular/material/table';
import { AssetData } from './table-data';
import { convertNumber, formatDate } from 'src/app/util/Helper';

@Component({
  selector: 'app-asset-tables',
  templateUrl: './asset-tables.component.html',
  styleUrls: ['./asset-tables.component.scss']
})
export class AssetTablesComponent implements AfterViewInit, OnChanges {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  @Input() tableType: number = 1;

  @Input()
  tableTitle!: string;
  @Input()
  data!: any;
  
  @Input()
  editAsset!: (element: any, tableType: number) => void;
  @Input()
  deleteAsset!: (element: any, tableType: number) => void;


  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.loadTable();
    }
  }

  ngAfterViewInit(): void {
    if (this.data && this.data.length > 0) this.loadTable();
  }

  columns: string[] = [];
  dataSource!: MatTableDataSource<any>;
  formatDate = formatDate;
  convertNumber = convertNumber;

  loadTable(): void {
    const tableData = new AssetData(this.data);
    this.dataSource = new MatTableDataSource<any>(tableData.data);

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.columns = Object.keys(this.dataSource.data[0]).filter(
      key =>
        key != 'AssetsID'
    );
    this.columns.push('Editor');
    this.columns = this.formatHeaders(this.columns);

  }

  formatHeaders(columns: string[]): string[] {
    return columns.map(column => {
      return column.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    });
  }

  getColumnName(spacedName: string): string {
    if (spacedName === 'Title LR No') {
      return 'TitleLRNo';
    } else {
      return spacedName.split(' ').map((word, index) => {
        return index === 0 
          ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() 
          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }).join('');
    }
  }

}
