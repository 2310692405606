import { Component } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { OtpPopupComponent } from 'src/app/components/otp-popup/otp-popup.component';
import { ApiService } from 'src/app/services/api/api.service';
import { EstateControlService } from 'src/app/services/estate-control.service';
import { TabControlService } from 'src/app/services/tab-control.service';
import { createIDsValidatorEstate } from 'src/app/services/validators/custom.validators';
import { ValidationService } from 'src/app/services/validators/validation.service';
import { checkTime } from 'src/app/util/Helper';
import { environment } from 'src/environments/environment';
import * as Constants from "src/app/constants/constants";
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-step1-settlors',
  templateUrl: './step1-settlors.component.html',
  styleUrls: ['./step1-settlors.component.scss']
})
export class Step1SettlorsComponent {
  checkerPath: string = '../../../../assets/fi_check.png';

  formTitle: string = 'Settlor Information';
  formSubtitle: string = 'A settlor is the person or entity that intends to set up the trust and who prescribes the terms and conditions of the Trust.';

  contactInfoTitle: string = 'Settlor Contact Information';
  detailsTitle: string = 'Settlor Details';
  uploadDocsTitle: string = 'Upload Documents / Photo';

  phoneLabel : string = 'Mobile Number';
  emailLabel : string = 'Email Address';
  emailHint : string = 'Email address';

  titleLabel: string = 'Title';
  titleHint: string = 'Title';
  fNameLabel: string = 'First Name';
  fNameHint : string = 'First name';
  mNameLabel : string = 'Middle Name';
  mNameHint : string = 'Middle name';
  surnameLabel : string = 'Surname';
  surnameHint : string = 'Last name';

  idLabel: string = 'ID/Passport Number';
  idHint: string = 'Enter ID or Passport Number';
  kraPinLabel: string = 'KRA PIN';
  kraPinHint: string = 'Enter KRA PIN';
  addressLabel: string = 'Physical Address';
  addressHint: string = 'Enter Physical address';
  postalLabel: string = 'Postal Address';
  postalHint: string = 'Enter Postal Address';

  idFileLabel: string = 'Upload copy of ID/Passport';
  kraFileLabel: string = 'Upload copy of KRA PIN';
  photoLabel: string = 'Upload Passport Photo';

  addSettlorText: string = '+  Add Another Settlor';
  removeSettlorText: string = 'Remove Settlor';

  requiredFieldString: string = 'Required field';

  loadingText: string = 'Requesting OTP';
  loadingRemove: boolean = false;
  currentIndex: number = 0;
  removeButtonText: string = 'Remove Settlor';

  otpVerified: boolean = false;

  formErrors: { [key: string]: string } = {
    Phone: '',
    Email: '',
    
    Title: '',
    FirstName: '',
    MiddleName: '',
    LastName: '',
    IDNo: '',
    KraPin: '',
    Address: '',
    PostalAddress: '',

    IDNoImage: '',
    KraPinImage: '',
    PassportImage: ''
  };

  validationMessages: { [key: string]: {} } = {
    Phone: { required: this.requiredFieldString, pattern:'Invalid phone number', exist: 'This number is tied to an existing settlor' },
    Email: { required: this.requiredFieldString, email: "Invalid email address", exist: 'This email is tied to an existing settlor' },

    Title: { required: this.requiredFieldString },
    FirstName: { required: this.requiredFieldString },
    MiddleName: { required: this.requiredFieldString },
    LastName: { required: this.requiredFieldString },
    IDNo:  { required: this.requiredFieldString, min:'Invalid ID or Passport number', exist: 'This ID is tied to an existing settlor' },
    KraPin: { required: this.requiredFieldString, pattern:'Invalid KRA PIN', exist: 'This PIN is tied to an existing settlor' },

    Address: { required: this.requiredFieldString },
    PostalAddress: { required: this.requiredFieldString },

    IDNoImage: { required: this.requiredFieldString },
    KraPinImage: { required: this.requiredFieldString },
    PassportImage: { required: this.requiredFieldString }
  };

  settlorForm = this._formBuilder.group({
    settlors: this._formBuilder.array([
      this.createSettlorsFormGroup()
    ]),
  });

  constructor(
    private _formBuilder: FormBuilder,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private tabControlService: TabControlService,
    public estateControlService: EstateControlService,
    private validationService: ValidationService,
    private apiService: ApiService
  ) {
    this.populateData();
  }

  async ngOnInit(): Promise<void> {
    await this.populateForm();

    this.settlorForm.valueChanges.subscribe(()=> {
      this.logErrors();
    })
  }

  logErrors() {
    this.formErrors = this.validationService.logValidationErrors(
      this.settlorForm,
      this.formErrors,
      this.validationMessages
    );
  }

  get loading(): any {
    return this.notificationService.loading;
  }

  get settlors(): any {
    return this.estateControlService.settlors;
  }
  get loadingTitles(): boolean {
    return this.tabControlService.loadingTitles;
  }
  get TitleOptions(): any {
    return this.tabControlService.TitleOptions;
  }

  idObject: any = null;
  kraObject: any = null;
  photoObject: any = null;

  updateIDObject(object: any): void {
    this.idObject = object;
    // console.log('ID doc updated: ', this.idObject.name);
  }
  updateKraObject(object: any): void {
    this.kraObject = object;
  }
  updatePhotoObject(object: any): void {
    this.photoObject = object;
  }

  async populateData(): Promise<void> {
    if (this.TitleOptions.length === 0) {
      this.tabControlService.loadingTitles = true;
      await this.tabControlService.getTitlesList();
      this.tabControlService.loadingTitles = false;
    }
  }

  async populateForm(): Promise<void> {
    if (this.settlors && this.settlors.length > 0) {
      let settle = this.settlors;

      settle.forEach(async (settlor: any, index: number) => {
        if (index > 0 && !settlor.verified) {
          settle.splice(index, 1);
          await this.estateControlService.updateRecord(1, settle);
        }
      });

      if (this.settlors[0] && this.settlors[0].verified && this.settlors[0].verified == true) {
        const settlorsArray = this.settlorForm.get('settlors') as FormArray;

        // Clear existing settlors
        while (settlorsArray.length !== 0) {
          settlorsArray.removeAt(0);
        }
        
        // Add settlors from storage
        this.settlors.forEach((settlor: any) => {
          if (settlor.verified) settlorsArray.push(this.createSettlorsFormGroup(settlor));
        });
      }
    }
  }

  createSettlorsFormGroup(settlor: any = null): FormGroup {
    let form: FormGroup;

    // console.log('scajhbusdycbudsb ', settlor);

    if (settlor) {
      form = this._formBuilder.group({
        Title: [`${settlor.Title || ''}`],
        FirstName: [`${settlor.FirstName || ''}`, Validators.required],
        MiddleName: [`${settlor.MiddleName || ''}`,],
        LastName: [`${settlor.LastName || ''}`, Validators.required],
        Phone: [`${settlor.Phone || ''}`, Validators.required],
        Email: [`${settlor.Email || ''}`, [Validators.required, Validators.email]],
        
        IDNo: [`${settlor.IDNo || ''}`],
        KraPin: [`${settlor.KraPin || ''}`],
        Address: [`${settlor.Address || ''}`],
        PostalAddress: [`${settlor.PostalAddress || ''}`],

        IDNoImage: [],
        KraPinImage: [],
        PassportImage: []
      });

      if (this.settlorForm && this.getSettlors().length > 0) {
        this.validationService.addOrRemove3Validators(
            true,
            form.get('Email')!,
            [Validators.required,
              Validators.email,
              createIDsValidatorEstate('EMAIL', this.getSettlors(), this.settlors)
            ]
          );
          this.validationService.addOrRemoveSpecificValidators(
            true,
            form.get('Phone')!,
            [Validators.required,
              createIDsValidatorEstate('TEL', this.getSettlors(), this.settlors)
            ]
          );
      }

      if (settlor.IDNoImage) {
        form.get('IDNoImage')?.setValue(settlor.IDNoImage.stringValue);
        this.updateIDObject(settlor.IDNoImage);
        // console.log('New ID :\n', this.idObject);
      }

      if (settlor.KraPinImage) {
        form.get('KraPinImage')?.setValue(settlor.KraPinImage.stringValue);
        this.updateKraObject(settlor.KraPinImage);
        // console.log('New KRA PIN :\n', this.kraObject);
      }
      if (settlor.PassportImage) {
        form.get('PassportImage')?.setValue(settlor.PassportImage.stringValue);
        this.updatePhotoObject(settlor.PassportImage);
        // console.log('New PHOTO :\n', this.photoObject);
      }

      if (settlor.verified == true) {
        this.otpVerified = true;
        form.get('IDNo')?.addValidators([Validators.required, Validators.min(4)]);
        form.get('KraPin')?.addValidators([Validators.required, Validators.pattern(/^[AP]\d{9}[A-Z]$/)]);
        form.get('Address')?.addValidators([Validators.required]);
        form.get('PostalAddress')?.addValidators([Validators.required]);

        if (this.settlorForm && this.getSettlors().length > 0 && settlor.done == false) {
          this.validationService.addOrRemove3Validators(
            true,
            form.get('IDNo')!,
            [Validators.required,
              Validators.min(4),
              createIDsValidatorEstate('ID', this.getSettlors(), this.settlors)
            ]
          );
          this.validationService.addOrRemove3Validators(
            true,
            form.get('KraPin')!,
            [Validators.required,
              Validators.pattern(/^[AP]\d{9}[A-Z]$/),
              createIDsValidatorEstate('PIN', this.getSettlors(), this.settlors)
            ]
          );
        }

        if (settlor.detailsDone == true && settlor.done == false) {
          this.validationService.addOrRemoveValidator(true, form.get('IDNoImage')!);
          this.validationService.addOrRemoveValidator(true, form.get('KraPinImage')!);
          this.validationService.addOrRemoveValidator(true, form.get('PassportImage')!);
        }
      }

      // console.log('Current record verified: ', this.currentRecordVerified, '\n', settlor);
    } else {
      if (
        this.settlors && this.settlors.length > 0 && this.settlors[0].verified == false
      ) {
          this.estateControlService.refreshRecord(1);
      }

      this.otpVerified = false;

      let currentSettlors = this.settlors;
      let currentRecord: any = {verified: false, detailsDone: false, done: false};

      form = this._formBuilder.group({
        Title: [''],
        FirstName: ['', Validators.required],
        MiddleName: [''],
        LastName: ['', Validators.required],
        Phone: ['', Validators.required],
        Email: ['', [Validators.required, Validators.email]],
        
        IDNo: [''],
        KraPin: [''],
        Address: [''],
        PostalAddress: [''],

        IDNoImage: [],
        KraPinImage: [],
        PassportImage: []
      });

      if (this.settlorForm && this.getSettlors().length > 0) {
        this.validationService.addOrRemove3Validators(
          true,
          form.get('Email')!,
          [Validators.required,
            Validators.email,
            createIDsValidatorEstate('EMAIL', this.getSettlors(), this.settlors)
          ]
        );
        this.validationService.addOrRemoveSpecificValidators(
          true,
          form.get('Phone')!,
          [Validators.required,
            createIDsValidatorEstate('TEL', this.getSettlors(), this.settlors)
          ]
        );
      }

      if (
        this.settlors.length == 0 ||
        this.settlors.length > 0 && this.settlors[this.settlors.length-1].done == true
      ) {
        currentSettlors.push(currentRecord);
        this.estateControlService.updateRecord(1, currentSettlors);
      }
      
    }
    form.updateValueAndValidity();

    return form;
  };

  async addNewSettlor() {
    const fields = this.settlorForm.get('settlors') as FormArray;
    fields.push(this.createSettlorsFormGroup());
    this.settlorForm.setControl('settlors', fields);
  }
  // TODO: Update Remove function for Estate Settlors
  async removeSettlor(i:number) {
    this.currentIndex = i;
    const fields = this.settlorForm.get('settlors') as FormArray;
    let currentSettlors = this.settlors;
    
    if (currentSettlors[i] && currentSettlors[i].SettlorID) {
      this.removeButtonText = 'Removing Settlor';
      this.loadingRemove = true;
      
      try {
        if (this.estateControlService.TrustID !== null) {
          const result = await this.estateControlService.removeRecord(
            'settlor',
            currentSettlors[i].SettlorID,
            parseInt(this.estateControlService.TrustID),
            environment.baseUrl + Constants.privateSettlorURL
          );

          if (result === 1) {
            this.notificationService.viewToast('success', 'Settlor removed successfully');
            fields.removeAt(i);
            // Remove settlor at index
            currentSettlors.splice(i, 1);;
            await this.estateControlService.updateRecord(1, currentSettlors);
          } else {
            this.notificationService.viewToast('error', 'Error removing record');
          }
        } else {
          console.log('Relevant IDs not found');
        }
      } catch (error) {
        console.log(':: Error !! ', error);
      }
      this.removeButtonText = 'Remove Settlor';
      this.loadingRemove = false;
    } else {
      if (currentSettlors[i] && currentSettlors[i].done === false) {
        fields.removeAt(i);
        // Remove settlor at index
        currentSettlors.splice(i, 1);;
        await this.estateControlService.updateRecord(1, currentSettlors);
      } else {
        fields.removeAt(i);
      }
    }
  }
  getSettlors() : FormArray {  
    return this.settlorForm.get("settlors") as FormArray  
  }
  getSettlorFormGroup(index: number): FormGroup {
    const settlors = this.settlorForm.get('settlors') as FormArray;
    return settlors.at(index) as FormGroup;
  }

  async openOtpModal() {
    this.loadingText = 'Requesting OTP';
    this.notificationService.isLoading(true);

    try {
      const records = this.settlorForm.controls['settlors'].value;
      let data = {
        FirstName: records[records.length-1].FirstName,
        LastName: records[records.length-1].LastName,
        Phone: records[records.length-1].Phone,
        Email: records[records.length-1].Email,
        MileleType: 'estate'
      };

      this.estateControlService.OTPMessage = `In order to proceed, please enter the One Time Password (OTP) sent to ${data.FirstName}\'s email and phone`;
      // console.log('Dat ', data);

      let savedTime = this.estateControlService.OTPRequestStats;
      let response = {Status: 0, Message: 'Initialized'};

      this.estateControlService.OTPValid = checkTime(savedTime.time);

      if (this.estateControlService.OTPValid && savedTime.phone === data.Phone) {
        response.Status = 1;
        response.Message = 'Current OTP is still Valid';

      } else {
        response = await this.apiService.postRequest(
          environment.baseUrl + Constants.otpRequestURL, data);
      }

      if (response.Status === 1) {
        let currentSettlors = this.settlors;
        let currentRecord = currentSettlors[currentSettlors.length-1];

        currentRecord = {...currentRecord, ...records[records.length-1]};
        
        Object.assign(currentSettlors[currentSettlors.length - 1], currentRecord);
        await this.estateControlService.updateRecord(1, currentSettlors);
        
        if (!this.estateControlService.OTPValid) {
          const timeStats = {time: new Date().getTime() / 1000, phone: data.Phone};
          this.estateControlService.OTPRequestStats = timeStats;
          this.estateControlService.OTPValid = true;

          this.notificationService.viewToast(
            'success',
            `OTP sent to ${data.Phone}.`,
            'We have also sent it to the provided Email');

        } else {
          this.notificationService.viewToast(
            'info',
            `Please enter the OTP that was sent to your Phone / Email` );
        }

        this.estateControlService.OTPRequestPage = 'SettlorEstate';
        this.estateControlService.OTPIndex = this.settlors.length-1;
        
        this.loadingText = 'Verify Details';

        const dialogRef = this.dialog.open(OtpPopupComponent, {
          disableClose: true,
        });
    
        dialogRef.componentInstance.otpVerified.subscribe((value: boolean) => {
          this.otpVerified = value;
          // console.log('OTP verification status received from the modal:', this.otpVerified);
        });
    
        dialogRef.afterClosed().subscribe(result => {
          console.group('OTP Dialog ', this.otpVerified);
          if (this.otpVerified == true) {
            let currentSettlors = this.settlors;
            let currentRecord = currentSettlors[currentSettlors.length-1];
            currentRecord.verified = true;

            Object.assign(currentSettlors[currentSettlors.length - 1], currentRecord);
            this.estateControlService.updateRecord(1, currentSettlors);

            this.validationService.addOrRemoveValidator(true, this.getSettlorFormGroup(this.settlors.length - 1).get('Address')!);
            this.validationService.addOrRemoveValidator(true, this.getSettlorFormGroup(this.settlors.length - 1).get('PostalAddress')!);

            if (this.settlorForm && this.getSettlors().length > 0) {
              this.validationService.addOrRemove3Validators(
                true,
                this.getSettlorFormGroup(this.settlors.length - 1).get('IDNo')!,
                [Validators.required,
                  Validators.min(4),
                  createIDsValidatorEstate('ID', this.getSettlors(), this.settlors)
                ]
              );
              this.validationService.addOrRemove3Validators(
                true,
                this.getSettlorFormGroup(this.settlors.length - 1).get('KraPin')!,
                [Validators.required,
                  Validators.pattern(/^[AP]\d{9}[A-Z]$/),
                  createIDsValidatorEstate('PIN', this.getSettlors(), this.settlors)
                ]
              );
            } else {
              this.validationService.addOrRemoveSpecificValidators(
                true,
                this.getSettlorFormGroup(this.settlors.length - 1).get('IDNo')!,
                [Validators.required, Validators.min(4)]
              );
              this.validationService.addOrRemoveSpecificValidators(
                true,
                this.getSettlorFormGroup(this.settlors.length - 1).get('KraPin')!,
                [Validators.required, Validators.pattern(/^[AP]\d{9}[A-Z]$/)]
              );
            }

          } else {
            this.notificationService.viewToast('warning', 'Please verify your details to proceed');
          }
          console.groupEnd();
        });
      } else {
        this.notificationService.viewToast('error', response.Message);
      }
    } catch (error) {
      console.error(':: Error !! ', error);
      this.notificationService.viewToast('error', 'OTP request failed');

    }
    this.notificationService.isLoading(false);
  }

  async saveSettlor(): Promise<void> {
    if (this.settlors[this.settlors.length-1].verified == true) this.loadingText = 'Updating Settlor';
    else this.loadingText = 'Saving Settlor';

    let tsMessage = 'Settlor details saved';

    this.notificationService.isLoading(true);

    try {
      const settlors = this.settlorForm.get('settlors') as FormArray;
      const record: FormGroup = this.getSettlorFormGroup(settlors.length - 1) as FormGroup;

      let category;
      if (this.estateControlService.TrustID === null || settlors.length - 1 === 0) {
        category = 'Primary Settler';
      } else {
        category = 'Secondary Settler';
      }
      
      let data = {
        ...record.value,
        ...{
          TrustID: this.estateControlService.TrustID,
          Category: category
        }
      };

      let currentSettlors = this.settlors;
      
      if (currentSettlors[currentSettlors.length-1].detailsDone == true) {
        data.IDNoImage = this.idObject;
        data.KraPinImage = this.kraObject;
        data.PassportImage = this.photoObject;
      } else {
        data.IDNoImage = {name: '', stringValue: ''};
        data.KraPinImage = {name: '', stringValue: ''};
        data.PassportImage = {name: '', stringValue: ''};
      }

      if (currentSettlors[currentSettlors.length - 1].SettlorID) {
        data = {...data, ...{SettlorID: currentSettlors[currentSettlors.length - 1].SettlorID}};
      }
      
      const response = await this.apiService.postRequest(
        environment.baseUrl + Constants.privateSettlorURL, data, false);
      
      // console.log('::::: RESPONSE ::::::\n', response);
      if (response.Status === 1) {
        if (this.estateControlService.TrustID === null) {this.estateControlService.TrustID = response.TrustID;}

        const latestRecord = currentSettlors[currentSettlors.length - 1];
        let updatedRecord = {...latestRecord, ...record.value, ...{SettlorID: response.SettlorID, Category: category}};

        Object.assign(currentSettlors[currentSettlors.length - 1], updatedRecord);

        await this.estateControlService.updateRecord(1, currentSettlors);
        

        currentSettlors = this.settlors;
        let currentRecord = currentSettlors[currentSettlors.length-1];
        
        if (currentRecord.detailsDone == true) { // uploaded docs
          tsMessage = 'Settlor details updated';
          
          currentRecord.IDNoImage = this.idObject;
          currentRecord.KraPinImage = this.kraObject;
          currentRecord.PassportImage = this.photoObject;

          if (response.IdURL) {
            this.estateControlService.updateDocSummary(response.IdURL);
            currentRecord = {...currentRecord, ...{IDURL: response.IdURL}};
          }
          if (response.KraURL) {
            this.estateControlService.updateDocSummary(response.KraURL);
            currentRecord = {...currentRecord, ...{KraURL: response.KraURL}};
          }
          if (response.PassportURL) {
            this.estateControlService.updateDocSummary(response.PassportURL);
            currentRecord = {...currentRecord, ...{PassportURL: response.PassportURL}};
          }
          
          currentRecord.done = true;

        } else {
          currentRecord.detailsDone = true;
          this.validationService.addOrRemoveValidator(true, this.getSettlorFormGroup(settlors.length - 1).get('IDNoImage')!);
          this.validationService.addOrRemoveValidator(true, this.getSettlorFormGroup(settlors.length - 1).get('KraPinImage')!);
          this.validationService.addOrRemoveValidator(true, this.getSettlorFormGroup(settlors.length - 1).get('PassportImage')!); 
          this.settlorForm.updateValueAndValidity();
        }

        Object.assign(currentSettlors[currentSettlors.length - 1], currentRecord);
        await this.estateControlService.updateRecord(1, currentSettlors);

        console.log('>> New Settlors !! \n', this.estateControlService.settlors);  
        this.notificationService.viewToast('success', tsMessage);
        
        
      } else {
        this.notificationService.viewToast('error', response.Message);
      }
    } catch (error) {
      console.log(':: Error !! ', error);
      this.notificationService.viewToast('error', 'An unexpected error occurred');
    }
    this.notificationService.isLoading(false);
  }

  async submitForm(): Promise<void> {
    if (this.settlors[this.settlors.length-1].done) {
      this.navigateFunction();
    } else {
      if (this.settlors[this.settlors.length-1].verified) {
        this.saveSettlor();
      } else {
        this.openOtpModal();
      }
    }
  }
  async navigateFunction(): Promise<void> {
    await this.estateControlService.changeTab(2);
    this.estateControlService.page1Done = true;
  }

}
