import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ApiService } from 'src/app/services/api/api.service';
import { TabControlService } from 'src/app/services/tab-control.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import * as Constants from "../../../../../constants/constants";
import { EstateControlService } from 'src/app/services/estate-control.service';

@Component({
  selector: 'app-step2-assets',
  templateUrl: './step2-assets.component.html',
  styleUrls: ['./step2-assets.component.scss']
})
export class Step2AssetsComponent {

  formTitle: string = 'Consultation Details';

  assetForm = this._formBuilder.group({
    option1: [false],
    option2: [false],
    option3: [false],
    option4: [false]
  }, { validator: this.atLeastTwoSelectedValidator() });

  constructor(
    private _formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private tabControlService: TabControlService,
    private apiService: ApiService,
    public estateControlService: EstateControlService,
  ) {
  }

  ngOnInit(): void {
    if (this.assetDistribution) {
      this.assetForm = this._formBuilder.group({
        option1: [this.assetDistribution.option1 || false],
        option2: [this.assetDistribution.option2 || false],
        option3: [this.assetDistribution.option3 || false],
        option4: [this.assetDistribution.option4 || false]
      }, { validator: this.atLeastTwoSelectedValidator() });
    }
  }

  
  // Custom validator function
  atLeastTwoSelectedValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // Get values of options from the form group
      const option1 = control.get('option1')?.value;
      const option2 = control.get('option2')?.value;
      const option3 = control.get('option3')?.value;
      const option4 = control.get('option4')?.value;
  
      // Count the number of selected options
      const selectedCount = [option1, option2, option3, option4].filter(option => option).length;
  
      // Check if at least two options are selected
      if (selectedCount >0 && selectedCount <= 2) {
        return null; // Return null if validation passes
      } else {
        return { atLeastTwoSelected: true }; // Return validation error if less than two options are selected
      }
    };
  }

  sortSelection(): string {
    const selection = this.assetForm.value;
    let result = '';
    if (selection.option1 == true) {
      result += `Creating a Trust`;

      if (selection.option2 == true || selection.option3 == true || selection.option4 == true) {
        result += ', ';
      }
    }
    if (selection.option2 == true) {
      result += 'Estate Planning Consultation';

      if (selection.option3 == true || selection.option4 == true) {
        result += ', ';
      }
    }
    if (selection.option3 == true) {
      result += 'Creation of a Will';
      
      if (selection.option4 == true) {
        result += ', ';
      }
    }
    if (selection.option4 == true) {
      result += 'I\'m Not Sure';
    }

    return result;
  }

  get assetDistribution(): any {
    return this.estateControlService.assets;
  }
  get settlors(): any {
    return this.estateControlService.settlors;
  }
  get loading(): boolean {
    return this.notificationService.loading;
  }

  async submitForm(): Promise<void> {
    this.notificationService.isLoading(true);
    await this.estateControlService.updateRecord(2, this.assetForm.value);
    try {
      let assetsSelection = this.sortSelection();
      
      for (let i=0; i<this.settlors.length; i++) {
        const data = {
          AssetDistribution: assetsSelection,
          SettlorID: this.settlors[i].SettlorID,
          BookAppoitmentID: this.estateControlService.AppointmentID
        };

        const response = await this.apiService.postRequest(
        environment.baseUrl + Constants.saveAppointmentSettlorsEstateURL, data, false);

        if (response.Status !== 1) {
          console.error(response);
          this.notificationService.viewToast('error', 'Failed to update selection');
          return;
        }
      }
      this.notificationService.viewToast('success', 'Selection saved');
      this.navigateFunction();

    } catch (error) {
      console.error(error);
      this.notificationService.viewToast('error', 'An error occurred');
    }
    this.notificationService.isLoading(false);
  }
  async navigateFunction(): Promise<void> {
    await this.estateControlService.changeTab(3);
    this.estateControlService.page2Done = true;
  }
}
