import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api/api.service';
import { TabControlService } from 'src/app/services/tab-control.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import * as Constants from "../../../../../constants/constants";
import { EstateControlService } from 'src/app/services/estate-control.service';
import { ValidationService } from 'src/app/services/validators/validation.service';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent {

  formSubtitle: string = 'Please enter the details below so we can contact you';
  contactInfoTitle: string = 'Contact Information';

  relationLabel: string = 'Relationship To The Individual';
  titleLabel: string = 'Title';
  titleHint: string = 'Title';
  fNameLabel: string = 'First Name';
  fNameHint : string = 'First name';
  mNameLabel : string = 'Middle Name';
  mNameHint : string = 'Middle name';
  surnameLabel : string = 'Surname';
  surnameHint : string = 'Last name';
  phoneLabel : string = 'Mobile Number';
  emailLabel : string = 'Email Address';
  emailHint : string = 'Enter Email address';

  requiredFieldString: string = 'Required field';

  formErrors: { [key: string]: string } = {
    Relationship: '',
    Title: '',
    FirstName: '',
    MiddleName: '',
    LastName: '',
    Phone: '',
    Email: '',
    Other: '',
  };

  validationMessages: { [key: string]: {} } = {
    Title: { required: this.requiredFieldString },
    FirstName: { required: this.requiredFieldString },
    MiddleName: { required: this.requiredFieldString },
    LastName: { required: this.requiredFieldString },
    Phone: { required: this.requiredFieldString, pattern:'Invalid phone number' },
    Email: { required: this.requiredFieldString, email: "Invalid email address" },
    Relationship: { required: this.requiredFieldString },
    Other: {required: this.requiredFieldString}
  };

  contactForm = this._formBuilder.group({
    Relationship: ['', Validators.required],
    Title: [''],
    FirstName: ['', Validators.required],
    MiddleName: [''],
    LastName: ['', Validators.required],
    Phone: ['', Validators.required],
    Email: ['', [Validators.required, Validators.email]],
    Other: ['']
  });

  constructor(
    private _formBuilder: FormBuilder,
    public estateControlService: EstateControlService,
    private notificationService: NotificationService,
    private tabControlService: TabControlService,
    private validationService: ValidationService,
    private apiService: ApiService
  ) {
    this.populateData();

    if (this.contactForm) {
      this.validationService.addOrRemoveValidationOnValue(
        this.contactForm.get("Relationship")!,
        "Other",
        this.contactForm,
        "Other"
      );
    }
  }

  loading: boolean = false;

  get RelationOptions() {
    return this.tabControlService.RelationOptions;
  }
  get loadingTitles(): boolean {
    return this.tabControlService.loadingTitles;
  }
  get TitleOptions(): any {
    return this.tabControlService.TitleOptions;
  }

  async populateData(): Promise<void> {
    if (this.TitleOptions.length === 0) {
      this.tabControlService.loadingTitles = true;
      await this.tabControlService.getTitlesList();
      this.tabControlService.loadingTitles = false;
    }
  }

  async submitForm(): Promise<void> {
    this.loading = true;
    this.notificationService.isLoading(true);

    try {
      if (this.estateControlService.AppointmentID === null) {
        const _data = {
          AppointmentType: '',
          Email: '',
          Date: '',
          Time: '',
          NumberOfPersons: '',
          Location: '',
          BookingID: '',
        };

        const _response = await this.apiService.postRequest(
          environment.baseUrl + Constants.bookAppointmentEstateURL, _data, false);

        if (_response.Status === 1 && _response.BookingID) {
          this.estateControlService.AppointmentID = _response.BookingID;
          
        } else {
          console.error(_response);
          this.notificationService.viewToast('error', 'Could not save your details', _response.Message);
          return;
        }
      }
      
      let data = {
        ...this.contactForm.value,
        ...{
          BookAppoitmentID: this.estateControlService.AppointmentID,
          TrustType: 'Settler'
        }
      };

      if (data.Other && data.Other != '') {
        const other = data.Other;
        data.Relationship = other;
      }
      if (data.hasOwnProperty('Other')) {
        delete data['Other'];
      }
      
      const response = await this.apiService.postRequest(
        environment.baseUrl + Constants.saveAppointmentSettlorsEstateURL, data, false);
      
      // console.log('::::: RESPONSE ::::::\n', response);
      if (response.Status === 1) {
        const contactInf = {
          ...this.contactForm.value,
          ...{
            SettlorID: response.SettlorID
          }
        };

        let temp = [];
        temp.push(contactInf);

        await this.estateControlService.updateRecord(1, temp);
        console.log('>> New Record !! \n', this.estateControlService.settlors);

        this.notificationService.viewToast('success', 'Your details have been saved');
        this.navigateFunction();

      } else {
        this.notificationService.viewToast('error', response.Message);
      }
    } catch (error) {
      console.log(':: Error !! ', error);
      this.notificationService.viewToast('error', 'An unexpected error occurred');
    }
    this.notificationService.isLoading(false);
    this.loading = false;
  }

  async navigateFunction(): Promise<void> {
    this.estateControlService.page1Done = true;
    await this.estateControlService.changeTab(2);
  }
}
