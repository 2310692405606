
<div class="flex container">
    <div class="flex one">
        <div class="flex half-1" style="position: relative;">
          <img src={{MileleLogoPath}} class="milele-logo" alt="logo" (click)="navHome()" />
            
            <div class="flex power-small"> {{ powerdByText }} </div>
        </div>
  
        <div class="flex half-2">
        </div>
    </div>
  
    <div class="flex two">
        <img src={{logoPath}} class="logo" alt="logo" />
    </div>

    <div class="content-loading" *ngIf="showContinuePopup">
        <div class="flex column resume-content">
          <div class="flex resume-row bot">
            <div class="flex warn">
              <img src={{warnPath}} class="warn-img" alt="warning" />
            </div>
            <span class="resume-title">Resume Previous Session</span>
          </div>

          <div class="flex column width-100 center">
            <div class="flex resume-row" style="padding: 5%; border: 0.1px solid lightgray; box-sizing: border-box; border-radius: 5px; width: 90%;">
              <!-- <img src={{arrowPath}} class="arrow-img" alt="arrow-right" /> -->
                
              <span style="font-weight: bolder; color: #B68400;">{{'Step '}}</span>
                <div class="resume-active">
                    {{estateDataHolder.activeTab }}
                </div>
        
                <div style="width: 1px; height: 40px; background-color: #B68400; margin-left: 15px; margin-right: 15px;"></div>
                <span class="resume-text">{{ resumeActiveTextEstate }}</span>
                </div>
        
                <div class="flex column stk-div">
                <div class="flex putton green" (click)="resumeEstate()">
                    {{ 'Continue' }}
                    
                </div>
        
                <div class="flex putton red" (click)="resumeEstate(false)">
                    {{ 'New Session' }}
                </div>
                </div>
          </div>
        </div>
    </div>

    <!-- Resume -->
    <div class="content-loading" *ngIf="showResumePopup">
      <div class="flex column resume-content">
        <div class="flex resume-row bot">
          <span class="resume-title">Enter OTP</span>
        </div>
        <div class="flex resume-row">
          <span class="resume-text">
            {{ 'Use the OTP provided in your email address (' +otpEmail +' ) to retrieve your saved progress:' }}
          </span>
        </div>

        <form [formGroup]="otpForm">
          <div style="width: 100%; display: flex; align-items: center; justify-content: center; margin-bottom: 30px; margin-top: 30px;">
            <ng-otp-input
              (onInputChange)="onOtpChange($event)"
              [config]="{length:6, allowNumbersOnly: true}"></ng-otp-input>
          </div>
        </form>

        <div class="flex resume-row buttons">
          <div class="flex putton rend" (click)="closeOTPPopup()">
            {{ 'Close' }}
          </div>
          
          <div class="flex putton ngreen" [ngClass]="{'ngreen-loading': otpLoading}" (click)="verifyOTP()" *ngIf="otpReady">
            {{ otpLoading ? 'Verifying' : 'Verify' }}
            <span class="spin-otp" *ngIf="otpLoading">
              <mat-progress-spinner
                mode="indeterminate" diameter="25" strokeWidth="4"></mat-progress-spinner>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="flex content-holder">
        <div class="flex content-wrapper">
          <div class="content" *ngIf="!showSteps">
            <div class="subcontent">
                <div class="formal">
                    <h1 class="blue" style="margin-top: 40px;">Create your Plan</h1>
                    <p class="lead local">
                        ICEA LION Trust helps you have a solid plan to secure your loved ones' future.
                         To proceed, select the plan you wish to set up.
                    </p>
                    <div class="row settlor" style="display: flex; align-items: center; justify-content: center; margin-top: 10px;">
                        <app-radios style="display: flex; width: 100%; align-items: center; justify-content: center;"
                            labelOptionA="Private Trust Creation"
                            labelOptionB="Will Creation"
                            (valueChange)="onOptionChange($event)" >
                        </app-radios>
                    </div>

                    <div style="display: flex; width: 100%; align-items: center; justify-content: center; margin-top: 30px;">
                        <div class="disclaimer">
                            {{disclaimer}}
                        </div>
                    </div>
            
                    <div class="flex wide-bar" style="align-items: center; margin-left: 2px; margin-top: 20px; font-size: medium; width: 100%;">
                        <mat-checkbox [formControl]="iSwear" >
                          <p style="font-size: larger; display: flex;">
                            <span style="color: red; margin-right: 8px;">*</span>
                            <span>
                              I have read and accepted ICEA LION's Online&nbsp;<a href="https://icealion.co.ke/icea-lion-group-privacy-policy/" target="_blank" rel="noopener" style="color: #003764;"><u>Privacy Policy</u></a>&nbsp;&nbsp;and
                               <a href="../../../../assets/brochures/Engagement Proposal.pdf" target="_blank" rel="noopener" style="color: #003764;"><u>Terms of Engagement</u></a>.
                            </span>
                            
                          </p>
                        </mat-checkbox> 
                    </div>
                </div>

                <!-- BOTTOM OF FORM -->
                <app-form-bottom style="width: 100%;"
                    [text]="'Next'"
                    [formValid]="iSwear.value === true"
                    [showContinue]="'false'"
                    [buttonClickHandler]="showEstateSteps.bind(this)">
                </app-form-bottom>
            </div>
          </div>
          <!-- TABS -->
          <div class="tabs-wrapper" *ngIf="showSteps && activeTab !== 8">
            <div class="tabs">
              <div [@slideIn]="tabsAnimationState[0]" class="tab" [class.active]="activeTab === 1" [ngClass]="{'done' : page1DoneEstate}">
                <div class="round">
                  1
                </div>
                <span style="text-wrap: nowrap; text-overflow: ellipsis; word-break: break-all; overflow: hidden;">
                    {{planType === 0 ? 'Settlor' : 'Testator'}}
                </span>
              </div>
              <div [@slideIn]="tabsAnimationState[1]" class="tab" [class.active]="activeTab === 2" [ngClass]="{'done' : page2DoneEstate}">
                <div class="round">
                  2
                </div>
                {{ 'Payment' }}
              </div>

              <div [@slideIn]="tabsAnimationState[2]" class="tab" [class.active]="activeTab === 3" [ngClass]="{'done' : page3DoneEstate}">
                <div class="round">
                  3
                </div>
                {{planType === 0 ? 'Beneficiaries' : 'Will Executor'}}
              </div>
              <div [@slideIn]="tabsAnimationState[3]" class="tab" [class.active]="activeTab === 4" [ngClass]="{'done' : page4DoneEstate}">
                <div class="round">
                  4
                </div>
                {{ 'Assets' }}
              </div>
              <div [@slideIn]="tabsAnimationState[4]" class="tab" [class.active]="activeTab === 5" [ngClass]="{'done' : page5DoneEstate}">
                <div class="round">
                  5
                </div>
                {{planType === 0 ? 'Trustees' : 'Beneficiaries'}}
              </div>
              <div [@slideIn]="tabsAnimationState[5]" class="tab" [class.active]="activeTab === 6" [ngClass]="{'done' : page6DoneEstate}">
                <div class="round">
                  6
                </div>
                {{planType === 0 ? 'Enforcer' : 'Witness'}}
              </div>
              <div [@slideIn]="tabsAnimationState[6]" class="tab" [class.active]="activeTab === 7" >
                <div class="round">
                  7
                </div>
                {{'Preview'}}
              </div>
            </div>
          </div>
          <!-- LOADING SPINNER -->
          <div class="flex center content-back" *ngIf="loadingTab">
            <div class="flex loading-spinner" style="margin-top: 15%; margin-bottom: 15%;">
              <mat-progress-spinner
                  mode="indeterminate"
                  color="warn"
                  diameter="100"
                  strokeWidth="10">
              </mat-progress-spinner>
            </div>
          </div>
          <!-- CONTENT -->
           <!-- Private Trust -->
          <div class="content" *ngIf="showSteps && !loadingTab && (planType == 0 && activeTab !== 8)">
              <div class="subcontent" [ngClass]="{'hide-me' : activeTab !== 1}" [hidden]="activeTab !== 1">
                  <app-step1-settlors class="subcontent"></app-step1-settlors>
              </div>
              <div class="subcontent" [ngClass]="{'hide-me' : activeTab !== 2}" [hidden]="activeTab !== 2" *ngIf="page1DoneEstate">
                  <app-step2-payment-estate class="subcontent" ></app-step2-payment-estate>
              </div>
              <div class="subcontent" [ngClass]="{'hide-me' : activeTab !== 3}" [hidden]="activeTab !== 3" *ngIf="page2DoneEstate">
                  <app-step3-beneficiary-estate class="subcontent"></app-step3-beneficiary-estate>
              </div>
              <div class="subcontent" [ngClass]="{'hide-me' : activeTab !== 4}" [hidden]="activeTab !== 4" *ngIf="page3DoneEstate">
                  <app-step4-assets class="subcontent"></app-step4-assets>
              </div>
              <div class="subcontent" [ngClass]="{'hide-me' : activeTab !== 5}" [hidden]="activeTab !== 5" *ngIf="page4DoneEstate">
                  <app-step5-trustee class="subcontent" ></app-step5-trustee>
              </div>
              <div class="subcontent" [ngClass]="{'hide-me' : activeTab !== 6}" [hidden]="activeTab !== 6" *ngIf="page5DoneEstate">
                  <app-step6-enforcer class="subcontent" ></app-step6-enforcer>
              </div>
              <div class="subcontent" [ngClass]="{'hide-me' : activeTab !== 7}" [hidden]="activeTab !== 7" *ngIf="page6DoneEstate">
                <app-step7-previews class="subcontent" ></app-step7-previews>
            </div>
          </div>
          <!-- Will Creation -->
          <div class="content" *ngIf="showSteps && !loadingTab && (planType == 1 && activeTab !== 8)">
            <div class="subcontent" [ngClass]="{'hide-me' : activeTab !== 1}" [hidden]="activeTab !== 1">
              <app-step1-testator class="subcontent"></app-step1-testator>
            </div>
            <div class="subcontent" [ngClass]="{'hide-me' : activeTab !== 2}" [hidden]="activeTab !== 2" *ngIf="page1DoneEstate">
              <app-step2-payment-will class="subcontent" ></app-step2-payment-will>
            </div>
            <div class="subcontent" [ngClass]="{'hide-me' : activeTab !== 3}" [hidden]="activeTab !== 3" *ngIf="page2DoneEstate">
              <app-step3-executor class="subcontent"></app-step3-executor>
            </div>
            <div class="subcontent" [ngClass]="{'hide-me' : activeTab !== 4}" [hidden]="activeTab !== 4" *ngIf="page3DoneEstate">
                <app-step4-assets class="subcontent"></app-step4-assets>
            </div>
            <div class="subcontent" [ngClass]="{'hide-me' : activeTab !== 5}" [hidden]="activeTab !== 5" *ngIf="page4DoneEstate">
              <app-step3-beneficiary-estate class="subcontent" ></app-step3-beneficiary-estate>
            </div>
            <div class="subcontent" [ngClass]="{'hide-me' : activeTab !== 6}" [hidden]="activeTab !== 6" *ngIf="page5DoneEstate">
              <app-step6-witness class="subcontent" ></app-step6-witness>
            </div>
            <div class="subcontent" [ngClass]="{'hide-me' : activeTab !== 7}" [hidden]="activeTab !== 7" *ngIf="page6DoneEstate">
              <app-step7-previews class="subcontent" ></app-step7-previews>
            </div>
          </div>
        </div>
    </div>

</div>