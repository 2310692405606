import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor() { }

  Credentials(){
    let creds = "MileleTrust:#Trusted@pas!3022%";
    var resultCreds= 'Basic ' + btoa(creds);
    return resultCreds;
  }

  postFormData(url: string, data: FormData): any {
    if (!environment.production) console.log(':: URL :: ', url);
    // console.log(':: REQ :: ', data);

    return fetch(url,
      {
        method: 'POST',
        body: data,
        cache: 'no-store',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.Credentials(),
          'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0'
        }
      })
    .then((response) => response.json());
  }

  postRequest(url: string, data: any, filterNulls = true, contentType: string ='application/json'): any {
    
    const requestBody = JSON.stringify(filterNulls ? this.filterNullValues(data) : data);
    if (!environment.production) console.log(':: URL :: ', url);
    if (!environment.production) console.log(':: REQ :: ', requestBody);

    return fetch(url,
      {
        method: 'POST',
        body: requestBody,
        cache: 'no-store',
        headers: {
          'Content-Type': contentType,
          'Authorization': this.Credentials(),
          'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0'
        }
      })
    .then((response) => response.json());
  }

  getRequest(url: string): any {
    if (!environment.production) console.log(':: URL :: ', url);

    return fetch(url,
      {
        method: 'GET',
        cache: 'no-store',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.Credentials(),
          'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0'
        }
      })
    .then((response) => response.json());
  }

  getFile(url: string): any {
    if (!environment.production) console.log(':: URL :: ', url);

    return fetch(url,
      {
        method: 'GET',
        cache: 'no-store',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.Credentials(),
          'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0'
        }
      })
    .then((response) => response.text());
  }

  filterNullValues<T extends { [key: string]: any }>(obj: T): Partial<T> {
    return Object.keys(obj)
      .filter((key: keyof T) => obj[key] !== null)
      .reduce((filteredObj: Partial<T>, key: keyof T) => {
        filteredObj[key] = obj[key];
        return filteredObj;
      }, {});
  }
}
