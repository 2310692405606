<div class="flex container">
    <div class="flex one">
        <div class="flex half-1" style="position: relative;">
          <img src={{MileleLogoPath}} class="milele-logo" alt="logo" (click)="goHome()" />
          <div class="flex power-small"> {{ powerdByText }} </div>
        </div>
  
        <div class="flex half-2">
        </div>
    </div>
  
    <div class="flex two">
        <img src={{logoPath}} class="logo" alt="logo" />
    </div>

    <div class="flex content-holder">
        <div class="flex content-wrapper">
            <div class="content" >
                <div class="tab-form" >
                    <div class="form-80" style="align-items: center; padding: 5%;">
                        <div class="flex g-round">
                            <img src={{checkerPath}} class="check-mate" alt="logo" />
                        </div>
                    </div>
                    <div class="title">
                        {{title}}
                    </div>
                
                    <div class="p">
                        {{ textH1 }}<br><strong>{{ amount }}</strong>.<br>{{ textH2 }}<strong>{{ email.toLowerCase() }}</strong>.
                    </div>
                    <div class="p">
                        {{ text2 }}
                    </div>
                
                    <div class="done"  (click)="bookAppointment()">
                        {{ buttonText }}
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
