<form [formGroup]="contactForm" (ngSubmit)="submitForm()" class="tab-form" autocomplete="off" novalidate>
    <div class="form-80">
        <h1 class="blue margin">{{ contactInfoTitle }}</h1>
        <p class="lead rocal">{{ formSubtitle }}</p>

        <div class="flex blue-box">
            <p class="blue lead" >
                {{ contactInfoTitle }}
            </p>
            <div class="line-2"></div>

            <div class="row half" [ngClass]="{'half' : !(contactForm.get('Relationship')?.value === 'Other' )} ">
                <app-input [formGroup]="contactForm" class="grid-item"
                    controlName="Relationship"
                    inputType="select"
                    [ArrayItems]="RelationOptions"
                    label={{relationLabel}} hint={{relationLabel}}
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                <app-input [formGroup]="contactForm" controlName="Other"
                    *ngIf="contactForm.get('Relationship')?.value === 'Other'"
                    label='Specify Relationship' hint='Specify your relation'
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
            </div>

            <div class="row-alt">
                <div class="row-mini">
                    <app-input [formGroup]="contactForm" controlName="Title"
                        label={{titleLabel}} hint={{titleHint}}
                        inputType="select"
                        required="false"
                        [loadingOptions]="loadingTitles"
                        [ArrayItems]="TitleOptions"
                        [validationMessages]="validationMessages" [formErrors]="formErrors">
                    </app-input>

                    <app-input [formGroup]="contactForm" controlName="FirstName"
                        label={{fNameLabel}} hint={{fNameHint}}
                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                </div>
                
                <div class="row-mini">
                    <app-input [formGroup]="contactForm" controlName="MiddleName"
                        label={{mNameLabel}} hint={{mNameHint}} required='false'
                        [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                    <app-input [formGroup]="contactForm" controlName="LastName"
                        label={{surnameLabel}} hint={{surnameHint}}
                        [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                </div>
                
            </div>

            <div class="row">
                <app-input [formGroup]="contactForm" class="grid-item"
                    controlName="Phone" label={{phoneLabel}}  inputType="phone"
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                <app-input [formGroup]="contactForm" class="grid-item"
                    controlName="Email" label={{emailLabel}} hint={{emailLabel}}
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
            </div>
        </div>
    </div>

    <!-- BOTTOM OF FORM -->
    <app-form-bottom style="width: 100%;"
        [service]="estateControlService"
        [text]="loading ? 'Saving Details' : 'Save Details'"
        [formValid]="contactForm.valid ? true : false"
        [showContinue]="'false'"
        [buttonClickHandler]="submitForm.bind(this)">
    </app-form-bottom>
</form>
