import { Component } from '@angular/core';
import { TabControlService } from './services/tab-control.service';
import { NotificationService } from './services/notification.service';
import { EstateControlService } from './services/estate-control.service';
import { NavigationEnd, Router } from '@angular/router';
import { generateWhatsappChatLinkMessage } from './util/Helper';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'milele-trust-frontend';

  mPesaPath: string = '../assets/m_pesa.png';
  retryPaymentText: string = 'Sorry, we could not verify your M-Pesa payment.';
  retryQuestionText: string = 'Did you complete it successfully?';
  service: TabControlService | EstateControlService = this.tabControlService;

  constructor(
    private tabControlService: TabControlService,
    private estateControlService: EstateControlService,
    private router: Router,
    private notificationService: NotificationService
  ) {

    }

  ngOnInit() {
    this.router.events.subscribe(res=>{
      if(res instanceof NavigationEnd){
        if (res.url.includes('estate-planning')) {
          this.service = this.estateControlService;
        } else {
          this.service = this.tabControlService;
        }
      }
    })

    // this.tabControlService.initializeSalesIQ();
  }

  get loading(): boolean {
    return this.notificationService.loading;
  }
  get stkLoading(): boolean {
    return this.service.stkLoading;
  };

  get stkTimeout(): boolean {
    return this.service.stkTimeout;
  };

  get requestingSTK(): boolean {
    return this.service.requestingSTK;
  }
  set requestingSTK(value: boolean) {
    this.service.requestingSTK = value;
  }

  get enterPinText(): string {
    return this.service.stkPinText;
  };

  get stkMessage(): string {
    return this.service.stkMessage;
  }

  get resetSTK(): ()=> void {
    return this.service.resetSTK;
  };

  mpesaPayment(mode: 0 | 1): void{
    this.service.stkMode = mode;
    this.service.mpesaPayment();
  };

  closeSTKPopup(): void {
    this.service.stkLoading = false;
    this.resetSTK();
  }
  openWhatsappChat(): void {
    let name = '';

    if (this.service == this.estateControlService) {
      if (this.service.planType == 1) {
        if (this.service.testators[0]
          && this.service.testators[0].FirstName
          && this.service.testators[0].FirstName.length > 0)
            name = this.service.testators[0].FirstName;
      } else {
        if (this.service.settlors[0]
          && this.service.settlors[0].FirstName
          && this.service.settlors[0].FirstName.length > 0)
            name = this.service.settlors[0].FirstName;
      }
    } else if (this.service == this.tabControlService) {
      if (this.service.basicData
        && this.service.basicData.FirstName
        && this.service.basicData.FirstName.length > 0)
          name = this.service.basicData.FirstName;
    }
    const URL = generateWhatsappChatLinkMessage(name);
    window.open(URL, "_blank");
  }
}
