import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormArray, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { ApiService } from 'src/app/services/api/api.service';
import { EstateControlService } from 'src/app/services/estate-control.service';
import { TabControlService } from 'src/app/services/tab-control.service';
import { NotificationService } from 'src/app/services/notification.service';
import { createIDsValidatorEstate } from 'src/app/services/validators/custom.validators';
import { ValidationService } from 'src/app/services/validators/validation.service';
import { environment } from 'src/environments/environment';
import { formatDate, getEarliestDateFor18Years } from 'src/app/util/Helper';
import * as Constants from "src/app/constants/constants";
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-step3-beneficiary-estate',
  templateUrl: './step3-beneficiary-estate.component.html',
  styleUrls: ['./step3-beneficiary-estate.component.scss'],
  animations: [
    trigger('bounceIn', [
      state('in', style({ opacity: 1, transform: 'scale(1)' })),
      transition('void => *', [
        style({ opacity: 0, transform: 'scale(0.8)' }),
        animate('0.15s ease-in-out')
      ]),
      transition('* => void', [
        animate('0.15s ease-in-out', style({ opacity: 0, transform: 'scale(0.7)' }))
      ])
    ]),
    trigger('slideInRight', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('0.15s ease-in-out', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('0.15s ease-in-out', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class Step3BeneficiaryEstateComponent {
  @Output() scrollUp = new EventEmitter<number>();
  @ViewChild('suggestBox', { static: false }) suggestBox?: ElementRef;
  
  checkerPath: string = '../../../../assets/fi_check.png';

  title: string = 'Beneficiaries';
  subtitle: string = 'Add one or more beneficiaries.';

  benTypeLabel: string = 'Select Beneficiary Type';
  benTypeHint: string = 'Beneficiary type';

  relationLabel: string = 'Relationship To Settlor';
  relationHint: string = 'Specify Relationship';

  titleLabel: string = 'Title';
  titleHint: string = 'Title';
  fNameLabel: string = 'First Name';
  fNameHint : string = 'First name';
  mNameLabel : string = 'Middle Name';
  mNameHint : string = 'Middle name';
  surnameLabel : string = 'Surname';
  surnameHint : string = 'Last name';
  genderLabel: string = 'Gender';
  genderHint: string = 'Select gender';
  phoneLabel : string = 'Primary Phone Number';
  emailLabel : string = 'Email Address';
  emailHint : string = 'Email address';
  dobLabel: string = 'Date of birth';
  dobHint: string = '01/01/1990';

  institutionNameLabel: string = 'Name of Institution';
  businessNatureLabel: string = 'Nature of Business';
  orgRegNumLabel: string = 'Organization Registration Number';
  orgAddressLabel: string = 'Organization Physical Address';

  institutionNameHint: string = 'Enter name';
  businessNatureHint: string = 'Enter nature of business';
  orgRegNumHint: string = 'Enter registration Number';
  orgAddressHint: string = 'Enter physical address';

  additionalDetailsText: string = 'Upload Documents';
  // Senior
  idLabel: string = 'ID/Passport Number';
  idHint: string = 'Enter ID or Passport Number';
  kraPinLabel: string = 'KRA PIN';
  kraHint: string = 'Enter KRA PIN';
  idFileLabel: string = 'Upload Copy of ID/Passport';
  // Minor
  birthCertLabel: string = 'Upload Birth Certificate of Child';

  regCertFileLabel: string = 'Upload Registration Certificate';
  kraFileLabel: string = 'Upload KRA PIN';

  addBenText: string = 'Add Another Beneficiary';
  removeBenText: string = 'Remove Beneficiary';

  addText: string = 'Beneficiary details saved';
  updateText: string = 'Beneficiary details updated'
  
  requiredFieldString: string = 'Required field';
  invalidDocString: string = 'Please attach a valid document';

  buttonDisabled: boolean = true;
  addDisabled: boolean = true;

  



  formErrors: { [key: string]: string } = {
    Category: '',
    Title: '',
    FirstName: '',
    MiddleName: '',
    LastName: '',
    Gender: '',
    Phone: '',
    Email: '',
    DOB: '',
    IDNo: '',
    KraPin: '',
    IDNoImage: '',
    BirthCertImage: '',
    Relationship: '',
    Other: '',

    InstitutionName: '',
    BusinessNature: '',
    OrgRegNum: '',
    OrgAddress: '',
    RegCertImage: '',
    KraPinImage: '',
  };

  validationMessages: { [key: string]: {} } = {
    Category: { required: this.requiredFieldString },
    Title: { required: this.requiredFieldString },
    FirstName: { required: this.requiredFieldString },
    MiddleName: { required: this.requiredFieldString },
    LastName: { required: this.requiredFieldString },
    Gender: { required: this.requiredFieldString },
    Phone: {
      required: this.requiredFieldString,
      pattern:'Invalid phone number',
      exist: `${
        this.beneficiaries[this.beneficiaries.length-1]
        && this.beneficiaries[this.beneficiaries.length-1].personalDone
        && this.beneficiaries[this.beneficiaries.length-1].personalDone == true ? 'This number is tied to an existing contact' : 'This number is tied to an existing beneficiary' }` },
    Email: {
      required: this.requiredFieldString,
      email: "Invalid email address",
      exist: `${
        this.beneficiaries[this.beneficiaries.length-1]
        && this.beneficiaries[this.beneficiaries.length-1].personalDone
        && this.beneficiaries[this.beneficiaries.length-1].personalDone == true ? 'This email is tied to an existing contact' : 'This email is tied to an existing beneficiary' }` },
    DOB:  { required: this.requiredFieldString },
    IDNo:  { required: this.requiredFieldString, pattern:'Invalid ID or Passport number', exist: 'This ID is tied to an existing beneficiary' },
    KraPin: { required: this.requiredFieldString, pattern:'Invalid KRA PIN', exist: 'This PIN is tied to an existing beneficiary' },
    IDNoImage: { required: this.requiredFieldString, invalid: this.invalidDocString },
    BirthCertImage: { required: this.requiredFieldString, invalid: this.invalidDocString },
    Relationship: { required: this.requiredFieldString },
    Other: {required: this.requiredFieldString},

    InstitutionName: { required: this.requiredFieldString },
    BusinessNature: { required: this.requiredFieldString },
    OrgRegNum: { required: this.requiredFieldString },
    OrgAddress: { required: this.requiredFieldString },

    RegCertImage: { required: this.requiredFieldString, invalid: this.invalidDocString },
    KraPinImage: { required: this.requiredFieldString, invalid: this.invalidDocString },
  };

  benForm = this._formBuilder.group({
    beneficiaries: this._formBuilder.array([
      this.createBensFormGroup()
    ]),
  });

  guardianForm = this._formBuilder.group({
    guardians: this._formBuilder.array([
      this.createGuardiansFormGroup()
    ]),
  });

  // guardianForm = this._formBuilder.group({
  // }) as FormGroup & { [key: string]: AbstractControl };

  over18: any = '';
  endDate: any = this.maxDate;
  loadingText: string = 'Saving Beneficiary';

  loadingRemove: boolean = false;
  removeButtonText: string = 'Remove Beneficiary';
  removeContactText: string = 'Remove Contact';
  navigationRequested: boolean = false;
  currentIndex: number = 0;
  shouldInsert: boolean = false;
  
  selfRecords: any = []; // For suggesting self
  recordUpdate: any;

  constructor(
    private _formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private tabControlService: TabControlService,
    public estateControlService: EstateControlService,
    private validationService: ValidationService,
    private apiService: ApiService,
  ) { }

  ngOnInit(): void {
    let records: any = this.settlors;
    if (this.planType == 1) records = this.testators;

    this.selfRecords.push(records[0]);
    if (records.length > 1) this.selfRecords.push(records[1]);

    if (this.beneficiaries && this.beneficiaries.length > 0) {
      if (this.beneficiaries[0].personalDone
        && this.beneficiaries[0].personalDone == true) {
        const beneficiariesArray = this.benForm.get('beneficiaries') as FormArray;

        // Clear existing beneficiaries
        while (beneficiariesArray.length !== 0) {
          beneficiariesArray.removeAt(0);
        }

        // Add beneficiaries from storage
        this.beneficiaries.forEach((beneficiary: any) => {
          beneficiariesArray.push(this.createBensFormGroup(beneficiary));
        });

        if (this.planType == 0) {
          this.estateControlService.updateStorageObject();
        } else {
          this.estateControlService.updateStorageObjectWill();
        }
      }
    }

    if (this.planType == 0) {
      if (this.estateControlService.activeTab == 3) {
        this.populateData();
      }
    } else {
      if (this.estateControlService.activeTab == 5) {
        this.populateData();
      }
    }

    this.benForm.valueChanges.subscribe(() => {
      this.logErrors();
    });
    this.guardianForm.valueChanges.subscribe(() => {
      this.logErrors(1);
    });
  }

  logErrors(switcher: 0 | 1 = 0) {
    this.formErrors = this.validationService.logValidationErrors(
      switcher == 1 ? this.guardianForm : this.benForm,
      this.formErrors,
      this.validationMessages
    );
  }
  
  getDateDifference(date: string) :boolean {
    const currentDate = new Date();
    const dobDate = new Date(date);
    const ageDifferenceInMilliseconds = currentDate.getTime() - dobDate.getTime();
    const ageDate = new Date(ageDifferenceInMilliseconds);

    // Calculate age based on the year.
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);
    if (age < 18) {
      return false;
    } else {
      return true;
    }
  }

  idObject: any = null;
  kraObject: any = null;

  birthCertObject: any = null;
  regCertObject: any = null;

  businessNatureEmpty: boolean = true;

  get loading(): boolean {
    return this.notificationService.loading;
  }

  get planType(): 0 | 1 {
    return this.estateControlService.planType;
  }
  get beneficiaries(): any {
    return this.estateControlService.beneficiaries;
  }
  get settlors(): any {
    return this.estateControlService.settlors;
  }
  get testators(): any {
    return this.estateControlService.testators;
  }

  get minDate() {
    return this.tabControlService.minDOBDate;
  }
  get maxDate() {
    return this.tabControlService.maxDOBDate;
  }

  get loadingTitles(): boolean {
    return this.tabControlService.loadingTitles;
  }
  get TitleOptions(): any {
    return this.tabControlService.TitleOptions;
  }
  get BeneficiaryOptions():any {
    return this.tabControlService.BeneficiaryOptions;
  }
  get GenderOptions():any {
    return this.tabControlService.GenderOptions;
  }
  get RelationOptions():any {
    return this.tabControlService.RelationOptions;
  }
  Relationships: any = this.RelationOptions;

  get loadingBizNature(): boolean {
    return this.tabControlService.loadingBizNature;
  }
  get BusinessNatureOptions(): any {
    return this.tabControlService.BusinessNatureOptions;
  }

  get saveProgress(): ()=> void {
    return this.estateControlService.saveProgress;
  }

  async populateData(): Promise<void> {
    if (this.TitleOptions.length === 0) {
      this.tabControlService.loadingTitles = true;
      await this.tabControlService.getTitlesList();
      this.tabControlService.loadingTitles = false;
    }
    if (this.BusinessNatureOptions.length === 0) {
      this.tabControlService.loadingBizNature = true;
      await this.tabControlService.getBusinessNatOptionsList();
      this.tabControlService.loadingBizNature = false;
    }
  }

  organizeValues(items: any): string {
    try {
      let output: string = items[0];
      for (let i=1; i<items.length; i++) {
        output = output + ', ' +items[i];
      }
      // console.log('Final output : ', output);
      return output;
    } catch (error) {
      console.log('Error : ', error);
      return '';
    }
  }

  arrayContainsValue(arr: string | null | undefined, value: string) {
    return arr && arr.includes(value);
  }

  updateIDObject(object: any): void {
    this.idObject = object;
    // console.log('ID doc updated: ', this.idObject.name);
  }
  updateKraObject(object: any): void {
    this.kraObject = object;
  }
  updateBirthCertObject(object: any): void {
    this.birthCertObject = object;
  }
  updateRegCertObject(object: any): void {
    this.regCertObject = object;
  }

  onBenTypeChange(index: number): void {
    const beneType = this.getBensFormGroup(index).get('Category');

    if (beneType) {
      if (beneType.value.toLowerCase() === 'individual') {
        this.getBensFormGroup(index).get('InstitutionName')?.clearValidators();
        this.getBensFormGroup(index).get('BusinessNature')?.clearValidators();
        this.getBensFormGroup(index).get('OrgRegNum')?.clearValidators();
        this.getBensFormGroup(index).get('KraPin')?.clearValidators();
        this.getBensFormGroup(index).get('OrgAddress')?.clearValidators();

        this.getBensFormGroup(index).get('InstitutionName')?.setValue('');
        this.getBensFormGroup(index).get('BusinessNature')?.setValue('')
        this.getBensFormGroup(index).get('OrgRegNum')?.setValue('')
        this.getBensFormGroup(index).get('KraPin')?.setValue('')
        this.getBensFormGroup(index).get('OrgAddress')?.setValue('')

        this.validationService.addOrRemoveValidator(true,
          this.getBensFormGroup(index).get('Relationship')!);
        this.validationService.addOrRemoveValidator(true,
          this.getBensFormGroup(index).get('FirstName')!);
        this.validationService.addOrRemoveValidator(true,
          this.getBensFormGroup(index).get('LastName')!);
        this.validationService.addOrRemoveValidator(true,
          this.getBensFormGroup(index).get('DOB')!);

        this.validationService.addOrRemoveValidationOnValue(
          this.getBensFormGroup(index).get("Relationship")!,
          "Other",
          this.getBensFormGroup(index),
          "Other"
        );

      } else {
        this.getBensFormGroup(index).get('FirstName')?.clearValidators();
        this.getBensFormGroup(index).get('LastName')?.clearValidators();
        this.getBensFormGroup(index).get('DOB')?.clearValidators();
        this.getBensFormGroup(index).get('Gender')?.clearValidators();

        this.getBensFormGroup(index).get('Relationship')?.clearValidators();
        this.getBensFormGroup(index).get('Other')?.clearValidators();

        this.getBensFormGroup(index).get('Relationship')?.setValue('');
        this.toggleFormControls(index, true);
        
        this.getBensFormGroup(index).get('Title')?.setValue('');
        this.getBensFormGroup(index).get('FirstName')?.setValue('');
        this.getBensFormGroup(index).get('MiddleName')?.setValue('');
        this.getBensFormGroup(index).get('LastName')?.setValue('');
        this.getBensFormGroup(index).get('Phone')?.setValue('');
        this.getBensFormGroup(index).get('Email')?.setValue('');
        this.getBensFormGroup(index).get('DOB')?.setValue('');
        this.getBensFormGroup(index).get('Gender')?.setValue('');
        
        this.getBensFormGroup(index).get('Other')?.setValue('');

        this.validationService.addOrRemoveValidator(true,
          this.getBensFormGroup(index).get('InstitutionName')!);
        this.validationService.addOrRemoveValidator(true,
          this.getBensFormGroup(index).get('BusinessNature')!);
        this.validationService.addOrRemoveValidator(true,
          this.getBensFormGroup(index).get('OrgRegNum')!);

        this.validationService.addOrRemove3Validators(
          true,
          this.getBensFormGroup(index).get('KraPin')!,
          [Validators.required,
            Validators.pattern(/^[AP]\d{9}[A-Z]$/),
            createIDsValidatorEstate('PIN', this.getBens(), this.beneficiaries)
          ]
        );

        this.validationService.addOrRemoveValidator(true,
          this.getBensFormGroup(index).get('OrgAddress')!);

      }
    }
  }

  onRelationshipChange(index: number): void {
    const value = this.getBensFormGroup(index)?.get('Relationship')?.value;
    if (value) {
      switch (value.toLowerCase()) {
        case 'self':
        case 'consultant':
        case 'financial advisor':
        case 'lawyer':
        case 'parent':
        case 'spouse':
          this.endDate = getEarliestDateFor18Years();

          if (value.toLowerCase() == 'self') {
            const fields = this.benForm.get('beneficiaries') as FormArray;
            this.recordUpdate = this.selfRecords[0];
            let counter: number = 0;
            
            const hasSelfRelationship = fields.controls.some(group => {  
              if (group.get('Relationship')?.value.toLowerCase() === 'self') {
                counter++;
              }
              return counter > 1;
            });
            // To autofill second settlor / testator
            if (hasSelfRelationship && this.selfRecords.length > 1)
              this.recordUpdate = this.selfRecords[1];
      
            fields.removeAt(index);
            fields.push(this.createBensFormGroup(this.recordUpdate, value));
      
            this.benForm.setControl('beneficiaries', fields);
            this.toggleFormControls(index, false);
          } else {
            this.toggleFormControls(index, true);
          }
          break;
        default:
          this.endDate = this.maxDate;
          this.toggleFormControls(index, true);
          break;
      }
    }
  }

  toggleFormControls(index: number, enabled: boolean): void {
    if (enabled) { // Reset form when relationship != self
      const isSelfRecord: boolean = this.selfRecords.some((record: any) => {
        return this.getBensFormGroup(index).get('IDNo')?.value == record.IDNo;
      });

      if (isSelfRecord) {
        this.getBensFormGroup(index)?.get('Title')?.enable();
        this.getBensFormGroup(index)?.get('FirstName')?.enable();
        this.getBensFormGroup(index)?.get('MiddleName')?.enable();
        this.getBensFormGroup(index)?.get('LastName')?.enable();
        this.getBensFormGroup(index)?.get('Phone')?.enable();
        this.getBensFormGroup(index)?.get('Email')?.enable();
        
        this.getBensFormGroup(index)?.get('Title')?.setValue('');
        this.getBensFormGroup(index)?.get('FirstName')?.setValue('');
        this.getBensFormGroup(index)?.get('MiddleName')?.setValue('');
        this.getBensFormGroup(index)?.get('LastName')?.setValue('');

        this.getBensFormGroup(index).get('Phone')?.setValue(' ');    
        this.getBensFormGroup(index)?.get('Email')?.setValue('');
        this.getBensFormGroup(index)?.get('Gender')?.setValue('');
        this.getBensFormGroup(index)?.get('DOB')?.setValue('');

        this.getBensFormGroup(index)?.get('KraPin')?.setValue('');
      }

    } else {
      // For 'self' option under relationship
      this.getBensFormGroup(index)?.get('Title')?.disable();
      this.getBensFormGroup(index)?.get('FirstName')?.disable();
      this.getBensFormGroup(index)?.get('MiddleName')?.disable();
      this.getBensFormGroup(index)?.get('LastName')?.disable();
      this.getBensFormGroup(index)?.get('Phone')?.disable();
      this.getBensFormGroup(index)?.get('Email')?.disable();  
    }
    this.benForm.updateValueAndValidity();
  }

  createBensFormGroup(beneficiary: any = null, value: any = null): FormGroup {
    let form: FormGroup;

    if (beneficiary) {
      form = this._formBuilder.group({
        Category: [`${value ? this.BeneficiaryOptions[0] : beneficiary.Category || this.BeneficiaryOptions[0]}`, Validators.required],
        Title: [`${beneficiary.Title || ''}`],
        FirstName: [`${beneficiary.FirstName || ''}`],
        MiddleName: [`${beneficiary.MiddleName || ''}`],
        LastName: [`${beneficiary.LastName || ''}`],
        Gender: [`${beneficiary.Gender || ''}`],
        Phone: [`${beneficiary.Phone || ''}`],
        Email: [`${beneficiary.Email || ''}`, Validators.email],
        DOB: [`${beneficiary.DOB || ''}`],
        Relationship: [`${value ? value : beneficiary.Relationship || ''}`],
        Other: [`${beneficiary.Other || ''}`],
  
        // IDNo: [''],
        KraPin: [`${value ? '' : beneficiary.KraPin || ''}`],
        IDNoImage: [''],
        BirthCertImage: [''],
  
        InstitutionName: [`${beneficiary.InstitutionName || ''}`],
        BusinessNature: [`${beneficiary.BusinessNature || ''}`],
        OrgRegNum: [`${beneficiary.OrgRegNum || ''}`],
        OrgAddress: [`${beneficiary.OrgAddress || ''}`],
  
        RegCertImage: [''],
        KraPinImage: ['']
      }) as FormGroup & { [key: string]: AbstractControl };

      if (beneficiary.ContactPersons && beneficiary.ContactPersons.length > 0) {
        this.addContactArray(form, beneficiary.ContactPersons[0]);
        const contactArray = form.get('ContactPersons') as FormArray;

        beneficiary.ContactPersons.forEach((contact: any, index: number) => {
          if (index > 0) contactArray.push(this.createContactsGroup(contact, contactArray));
        });
      }

      if (!value && beneficiary.IDNoImage) {
        if (beneficiary.IDNoImage.stringValue && beneficiary.IDNoImage.stringValue.length > 0) {
          form.get('IDNoImage')?.setValue(beneficiary.IDNoImage.stringValue);
          this.updateIDObject(beneficiary.IDNoImage);
        }
      }
      if (!value && beneficiary.KraPinImage) {
        if (beneficiary.KraPinImage.stringValue && beneficiary.KraPinImage.stringValue.length > 0) {
          form.get('KraPinImage')?.setValue(beneficiary.KraPinImage.stringValue);
          this.updateKraObject(beneficiary.KraPinImage);
        }
      }
      if (beneficiary.BirthCertImage) {
        if (beneficiary.BirthCertImage.stringValue && beneficiary.BirthCertImage.stringValue.length > 0) {
          form.get('BirthCertImage')?.setValue(beneficiary.BirthCertImage.stringValue);
          this.updateBirthCertObject(beneficiary.BirthCertImage);
        }
      }
      if (beneficiary.RegCertImage) {
        if (beneficiary.RegCertImage.stringValue && beneficiary.RegCertImage.stringValue.length > 0) {
          form.get('RegCertImage')?.setValue(beneficiary.RegCertImage.stringValue);
          this.updateRegCertObject(beneficiary.RegCertImage);
        }
      }

      if (beneficiary.Category === 'Individual' || value) {
        form.get('FirstName')?.addValidators(Validators.required);
        form.get('LastName')?.addValidators(Validators.required);
        form.get('Gender')?.addValidators(Validators.required);
        form.get('DOB')?.addValidators(Validators.required);
        form.get('Relationship')?.addValidators(Validators.required);

        this.validationService.addOrRemoveValidationOnValue(
          form.get("Relationship")!,
          "Other",
          form,
          "Other"
        );

        if (beneficiary.contactsDone) {
          if (this.getDateDifference(beneficiary.DOB)) {
            form.get('IDNoImage')?.addValidators(Validators.required);
            form.get('KraPinImage')?.addValidators(Validators.required);

            if (!beneficiary.done) {
              // Autofill for self relationship
              this.selfRecords.some((record: any) => {
                if (
                  beneficiary.Relationship == 'Self' &&
                  beneficiary.FirstName == record.FirstName &&
                  beneficiary.LastName == record.LastName &&
                  beneficiary.Phone == record.Phone &&
                  beneficiary.Email == record.Email
                ) {
                  this.recordUpdate = record;
                  if (record.IDNoImage.stringValue && record.IDNoImage.stringValue.length > 0) {
                    form.get('IDNoImage')?.setValue(record.IDNoImage.stringValue);
                    this.updateIDObject(record.IDNoImage);
                    form.get('IDNoImage')?.disable();
                  }
                  if (record.KraPinImage.stringValue && record.KraPinImage.stringValue.length > 0) {
                    form.get('KraPinImage')?.setValue(record.KraPinImage.stringValue);
                    this.updateKraObject(record.KraPinImage);
                    form.get('KraPinImage')?.disable();
                  }
                  this.notificationService.viewToast('info', 'Documents added from uploads');
                }
              })
            }
          } else {
            form.get('BirthCertImage')?.addValidators(Validators.required);
          }
        }
      } else {
        form.get('InstitutionName')?.addValidators(Validators.required);
        form.get('BusinessNature')?.addValidators(Validators.required);
        form.get('OrgRegNum')?.addValidators(Validators.required);
        form.get('OrgAddress')?.addValidators(Validators.required);
        form.get('KraPin')?.addValidators(Validators.required);

        if (beneficiary.contactsDone) {
          form.get('RegCertImage')?.addValidators(Validators.required);
          form.get('KraPinImage')?.addValidators(Validators.required);
        }
      }

    } else {

      if (
        this.beneficiaries && this.beneficiaries.length > 0 &&
        !(this.beneficiaries[0].personalDone && this.beneficiaries[0].personalDone == true)
      ) {
        if (this.planType == 0) {
          this.estateControlService.refreshRecord(3);
        } else {
          this.estateControlService.refreshRecordWill(5);
        }
      }
      
      let currentBens = this.beneficiaries;
      let currentRecord = {personalDone: false, done: false, contactsDone: false, docsDone: false};

      if (
        (this.benForm && this.getBens().length > 0) ||
        this.beneficiaries &&
        (this.beneficiaries.length > 0 &&
          !(this.beneficiaries[0].personalDone && this.beneficiaries[0].personalDone == true) ) ||
        this.beneficiaries.length == 0
      ) {
        currentBens.push(currentRecord);

        if (this.planType == 0) {
          this.estateControlService.updateRecord(3, currentBens);
        } else {
          this.estateControlService.updateRecordWill(5, currentBens);
        }
      }
      
      form = this._formBuilder.group({
          Category: [this.BeneficiaryOptions[0], Validators.required],
          Title: [''],
          FirstName: ['', Validators.required],
          MiddleName: [''],
          LastName: ['', Validators.required],
          Gender: ['', Validators.required],
          Phone: [''],
          Email: ['', Validators.email],
          DOB: ['', Validators.required],
          Relationship: ['', Validators.required],
          Other: [''],
    
          // IDNo: [''],
          KraPin: [''],
          IDNoImage: [''],
          BirthCertImage: [''],
    
          InstitutionName: [''],
          BusinessNature: [''],
          OrgRegNum: [''],
          OrgAddress: [''],
    
          RegCertImage: [''],
          KraPinImage: ['']
      }) as FormGroup & { [key: string]: AbstractControl };

      this.validationService.addOrRemoveValidationOnValue(
        form.get("Relationship")!,
        "Other",
        form,
        "Other"
      );
    }

    if (this.benForm && this.getBens().length > 0) {
      this.validationService.addOrRemoveOneValidator(
        true,
        form.get('Phone')!,
        createIDsValidatorEstate('TEL', this.getBens(), this.beneficiaries)
      );

      this.validationService.addOrRemoveSpecificValidators(
        true,
        form.get('Email')!,
        [ Validators.email,
          createIDsValidatorEstate('EMAIL', this.getBens(), this.beneficiaries)
        ]
      );

      // For 'self' option control
      const fields = this.benForm.get('beneficiaries') as FormArray;
      let counter: number = 0;
      
      const hasSelfRelationship = fields.controls.some(group => {  
        if (group.get('Relationship')?.value.toLowerCase() === 'self') {
          counter++;
        }
        return counter == this.selfRecords.length;
      });

      if (hasSelfRelationship)
        this.Relationships = this.RelationOptions.filter((relation: string) => relation !== 'Self');
    }

    return form;
  };

  createContactsGroup(contact: any = null, formArray: FormArray | null): FormGroup {
    let contactForm: FormGroup;
    if (contact) {
      contactForm = this._formBuilder.group({
        Title: [`${contact.Title || ''}`],
        FirstName: [`${contact.FirstName || ''}`],
        MiddleName: [`${contact.MiddleName || ''}`],
        LastName: [`${contact.LastName || ''}`],
        Phone: [`${contact.Phone || ''}`],
        Email: [`${contact.Email || ''}`]
      });

    } else {
      contactForm = this._formBuilder.group({
        Title: [''],
        FirstName: [''],
        MiddleName: [''],
        LastName: [''],
        Phone: [''],
        Email: ['']
      });
    }

    if (this.beneficiaries[this.beneficiaries.length-1].personalDone &&  !this.beneficiaries[this.beneficiaries.length-1].contactsDone) {
      contactForm.get('FirstName')?.addValidators(Validators.required);
      contactForm.get('LastName')?.addValidators(Validators.required);

      if (this.benForm && formArray) {
        this.validationService.addOrRemoveSpecificValidators(
          true,
          contactForm.get('Phone')!,
          [Validators.required, createIDsValidatorEstate('TEL', formArray, this.beneficiaries[this.beneficiaries.length-1].ContactPersons)]
        );
        this.validationService.addOrRemove3Validators(
          true,
          contactForm.get('Email')!,
          [Validators.required, Validators.email, createIDsValidatorEstate('EMAIL', formArray, this.beneficiaries[this.beneficiaries.length-1].ContactPersons)]
        );
      } else {
        contactForm.get('Phone')?.addValidators(Validators.required);
        contactForm.get('Email')?.addValidators([Validators.required, Validators.email]);
      }
    }
    
    return contactForm;
  };

  async addNewBen() {
    const fields = this.benForm.get('beneficiaries') as FormArray;
    fields.push(this.createBensFormGroup());
    this.benForm.setControl('beneficiaries', fields);
    this.guardians = [{done: false}];
  }
  async addNewContact(index:number) {
    const fields = this.getContacts(index);
    fields.push(this.createContactsGroup(null, this.getContacts(this.getBens().length-1)));
    this.getBensFormGroup(index).setControl('ContactPersons', fields);

    let currentBens = this.beneficiaries;
    const latestRecord = currentBens[currentBens.length-1];
    
    const record =  this.getContacts(this.getBens().length - 1);
    const updatedRecord = {...latestRecord, ...{ContactPersons: record.value}};
    Object.assign(currentBens[currentBens.length-1], updatedRecord);
    console.log(this.beneficiaries);

    if (this.planType == 0) {
      this.estateControlService.updateRecord(3, currentBens);
    } else {
      this.estateControlService.updateRecordWill(5, currentBens);
    }
  }
  
  async removeBen(i:number) {
    this.currentIndex = i;
    const fields = this.benForm.get('beneficiaries') as FormArray;
    let currentBens = this.beneficiaries;

    if (currentBens[i] && currentBens[i].BeneficiaryID) {
      this.removeButtonText = 'Removing Beneficiary';
      this.loadingRemove = true;

      try {
        if (this.estateControlService.TrustID !== null) {
          const result = await this.estateControlService.removeRecord(
            'beneficiary',
            currentBens[i].BeneficiaryID,
            parseInt(this.estateControlService.TrustID),
            environment.baseUrl + Constants.estateBeneficiaryURL
          );
  
          if (result === 1) {
            this.notificationService.viewToast('success', 'Beneficiary removed successfully');
            fields.removeAt(i);
            currentBens.splice(i, 1);

            if (this.planType == 0) {
              this.estateControlService.updateRecord(3, currentBens);
            } else {
              this.estateControlService.updateRecordWill(5, currentBens);
            }
          } else {
            this.notificationService.viewToast('error', 'Error removing record');
          }
        } else {
          console.log('Relevant IDs not found');
        }
      } catch (error) {
        console.log(':: Error !! ', error);
      }
      this.removeButtonText = this.removeBenText;
      this.loadingRemove = false;
    } else {
      if (currentBens[i]) {
        fields.removeAt(i);
        // Remove settlor at index
        currentBens.splice(i, 1);

        if (this.planType == 0) {
          this.estateControlService.updateRecord(3, currentBens);
        } else {
          this.estateControlService.updateRecordWill(5, currentBens);
        }
      } else {
        fields.removeAt(i);
      }
    }

    if (this.beneficiaries[this.beneficiaries.length-1] && this.beneficiaries[this.beneficiaries.length-1].Guardians) {
      this.guardians = this.beneficiaries[this.beneficiaries.length-1].Guardians;
    }
  }

  async removeContact(i:number, j: number) {
    const fields = this.getContacts(i);
    fields.removeAt(j);
  }

  getBens() : FormArray {  
    return this.benForm.get("beneficiaries") as FormArray;
  }
  getContacts(index: number) : FormArray {
    return this.getBens().at(index).get('ContactPersons') as FormArray;  
  }
  getBensFormGroup(index: number): FormGroup {
    return this.getBens().at(index) as FormGroup;
  }
  getContactsFormGroup(index: number, innerdex: number): FormGroup {
    return this.getContacts(index).at(innerdex) as FormGroup;
  }
  addContactArray = (form: FormGroup, contact: any = null, formArray: FormArray | null = null) => {
    if (formArray) {
      form.addControl('ContactPersons', this._formBuilder.array([
        this.createContactsGroup(contact, formArray)
      ]));
    } else {
      form.addControl('ContactPersons', this._formBuilder.array([
        this.createContactsGroup(contact, null)
      ]));
    }
  }

  // ==========   Guardian controls    =========
  get GuardianOptions() {
    return this.tabControlService.GuardianOptions;
  };
  guardianTypeLabel: string = 'Guardian Type';
  guardianTypeHint: string = 'Select guardian type';
  guardians: any = [{done: false}];
  showSuggestHint: boolean = false;
  showAssignPopup: boolean = false;

  briefTimeout: any;
  idObjectGuardian: any = null;
  kraObjectGuardian: any = null;
  suggestions: any = null;
  autofilled: boolean = false;
  
  updateGuardianIDObject(object: any): void {
    this.idObjectGuardian = object;
    // console.log('ID doc updated: ', this.idObject.name);
  }
  updateGuardianKraObject(object: any): void {
    this.kraObjectGuardian = object;
  }
  
  showSuggestBrief(): void {
    setTimeout(() => {
      this.showSuggestHint = true;
    }, 1000);

    this.briefTimeout = setTimeout(() => {
      this.showSuggestHint = false;
    }, 30000);
  }

  addGuardiansArray = (guardian: any = null) => {
    this.guardianForm.addControl('guardians', this._formBuilder.array([
      this.createGuardiansFormGroup(guardian)
    ]));
  }

  async suggestSettlors(type: 'ID' | 'NAME' , val: string): Promise<any> {
    if (this.autofilled) {
      this.resetForm();
    }
    
    this.suggestions = null;
    let validEntries: any = [];

    if (val.length > 2) {
        try {
            const fields = this.guardianForm.get('guardians') as FormArray;

            for (let i = 0; i < this.settlors.length; i++) {
                let exist = false;
                let trueStuff = this.settlors[i].FirstName.includes(val);

                if (type === 'ID') {
                  trueStuff = this.settlors[i].IDNo.includes(val);
                }

                if (trueStuff) {
                    for (let j = 0; j < fields.length - 1; j++) {
                        if (
                            fields.at(j).get('IDNo')?.value === this.settlors[i].IDNo ||
                            fields.at(j).get('Phone')?.value === this.settlors[i].Phone ||
                            fields.at(j).get('KraPin')?.value === this.settlors[i].KraPin) {
                            exist = true;
                        } else {
                          this.showSuggestHint = false;
                          clearTimeout(this.briefTimeout);
                        }
                    }

                    if (!exist && validEntries.length < 3 && !validEntries.some((entry: { IDNo: string; }) => entry.IDNo === this.settlors[i].IDNo)) {
                        validEntries.push(this.settlors[i]);
                    }
                }
            }

            if (validEntries.length > 0) {
              this.suggestions = validEntries;
            }

            if (this.suggestions !== null) {
              const childDivHeight = this.suggestBox?.nativeElement.clientHeight;
              this.scrollUp.emit(childDivHeight);
            }
        } catch (error) {
            console.log('ERR !!! ', error);
        }
    }

    return this.suggestions;
  }

  resetForm(): void {
    try {
      this.autofilled = false;

      const guradian = this.getGuardians().at(this.getGuardians().length-1) as FormGroup;
      guradian.get('Title')?.setValue('');
      guradian.get('FirstName')?.setValue('');
      guradian.get('MiddleName')?.setValue('');
      guradian.get('LastName')?.setValue('');
      guradian.get('Phone')?.setValue('7');
      guradian.get('Email')?.setValue('');
      guradian.get('IDNo')?.setValue('');
      guradian.get('KraPin')?.setValue('');
      guradian.get('IDNoImage')?.setValue(null);
      guradian.get('KraPinImage')?.setValue(null);

      this.updateGuardianIDObject(null);
      this.updateGuardianKraObject(null);

    } catch (error) {
      console.log('Error!! ', error);
    }
  }

  patchSuggestedValues(index: number, value: any): void {
    try {
      if (value) {
        const fields = this.guardianForm.get('guardians') as FormArray;
        fields.removeAt(index);
        fields.push(this.createGuardiansFormGroup(true, value));

        this.guardianForm.setControl('guardians', fields);
        
        this.suggestions = null;
        this.autofilled = true;
      }

    } catch (error) {
      console.error('ERR ??? ', error);
    }
  }

  createGuardiansFormGroup(patch: boolean=false, patchData: any=null): FormGroup {
    let form: FormGroup;
    
    let category = this.tabControlService.GuardianOptions[0];

    if (this.guardianForm && this.getGuardians().length > 0) {
      category = this.tabControlService.GuardianOptions[1];
    }

    if (patchData) {
      if (patch) {
        this.autofilled = true;

        form = this._formBuilder.group({
          Category: [category , Validators.required],
          Title: [patchData.Title || ''],
          FirstName: [patchData.FirstName , Validators.required],
          MiddleName: [patchData.MiddleName || ''],
          LastName: [patchData.LastName, Validators.required],
          Phone: [patchData.Phone, Validators.required],
          Email: [patchData.Email, [Validators.required, Validators.email]],
          IDNo: [patchData.IDNo, [Validators.required, Validators.min(4)]],
          KraPin: [patchData.KraPin, [Validators.required, Validators.pattern(/^[AP]\d{9}[A-Z]$/) ]],
          IDNoImage: ['', Validators.required],
          KraPinImage: ['', Validators.required],
        });

      } else {
        form = this._formBuilder.group({
          Category: [patchData.Category || category , Validators.required],
          Title: [patchData.Title || ''],
          FirstName: [patchData.FirstName , Validators.required],
          MiddleName: [patchData.MiddleName || ''],
          LastName: [patchData.LastName || '', Validators.required],
          Phone: [patchData.Phone || '', Validators.required],
          Email: [patchData.Email || '', [Validators.required, Validators.email]],
          IDNo: [patchData.IDNo || '', [Validators.required, Validators.min(4)]],
          KraPin: [patchData.KraPin || '', [Validators.required, Validators.pattern(/^[AP]\d{9}[A-Z]$/) ]],
          IDNoImage: ['', Validators.required],
          KraPinImage: ['', Validators.required],
        });
      }

      if (patchData.IDNoImage) {
        form.get('IDNoImage')?.setValue(patchData.IDNoImage.stringValue);
        this.updateGuardianIDObject(patchData.IDNoImage);
        // console.log('New ID :\n', this.idObject);
      }
      if (patchData.KraPinImage) {
        form.get('KraPinImage')?.setValue(patchData.KraPinImage.stringValue);
        this.updateGuardianKraObject(patchData.KraPinImage);
        // console.log('New KRA PIN :\n', this.kraObject);
      }

    } else {
      this.showSuggestBrief();

      this.autofilled = false;
      let currentRecord: any = {done: false};

      if (this.guardianForm && this.getGuardians().length > 0) {
        this.guardians.push(currentRecord);
      }
      
      form = this._formBuilder.group({
        Category: [category , Validators.required],
        Title: [''],
        FirstName: ['', Validators.required],
        MiddleName: [''],
        LastName: ['', Validators.required],
        Phone: ['', Validators.required],
        Email: ['', [Validators.required, Validators.email]],
        IDNo: ['', [Validators.required, Validators.min(4)]],
        KraPin: ['', [Validators.required, Validators.pattern(/^[AP]\d{9}[A-Z]$/) ]],
        IDNoImage: ['', Validators.required],
        KraPinImage: ['', Validators.required],
      });
    }
      
    if (this.guardianForm && this.getGuardians() && this.getGuardians().length > 0) {
      this.validationService.addOrRemove3Validators(
        true,
        form.get('Email')!,
        [Validators.required,
          Validators.email,
          createIDsValidatorEstate('EMAIL', this.getGuardians(), this.guardians)
        ]
      );
      this.validationService.addOrRemoveSpecificValidators(
        true,
        form.get('Phone')!,
        [Validators.required,
          createIDsValidatorEstate('TEL', this.getGuardians(), this.guardians)
        ]
      );
      this.validationService.addOrRemove3Validators(
        true,
        form.get('IDNo')!,
        [Validators.required,
          Validators.min(4),
          createIDsValidatorEstate('ID', this.getGuardians(), this.guardians)
        ]
      );
      this.validationService.addOrRemove3Validators(
        true,
        form.get('KraPin')!,
        [Validators.required,
          Validators.pattern(/^[AP]\d{9}[A-Z]$/),
          createIDsValidatorEstate('PIN', this.getGuardians(), this.guardians)
        ]
      );
    }

    return form;
  };

  async addNewGuardian() {
    this.updateGuardianObject();
    const fields = this.guardianForm.get('guardians') as FormArray;
    fields.push(this.createGuardiansFormGroup());
    this.guardianForm.setControl('guardians', fields); 
  }

  async removeGuardian(i:number) {
    this.currentIndex = i;
    const fields = this.guardianForm.get('guardians') as FormArray;
    fields.removeAt(i);
    // Remove settlor at index
    this.guardians.splice(i, 1);
    console.log('splice guardians : ', this.guardians);

    this.updateGuardianIDObject(this.guardians[this.guardians.length-1].IDNoImage);
    this.updateGuardianKraObject(this.guardians[this.guardians.length-1].KraPinImage);
  }
  
  getGuardians() : FormArray {  
    return this.guardianForm.get("guardians") as FormArray  
  }
  getGuardiansFormGroup(index: number): FormGroup {
    const guardians = this.guardianForm.get('guardians') as FormArray;
    return guardians.at(index) as FormGroup;
  }

  updateGuardianObject(): void {
    const guardianResult = this.guardianForm.get('guardians') as FormArray;
    const record: FormGroup = this.getGuardiansFormGroup(guardianResult.value.length - 1) as FormGroup;
    let item = record.value;
    let guard = this.guardians[this.guardians.length-1];

    item.IDNoImage = this.idObjectGuardian;
    item.KraPinImage = this.kraObjectGuardian;
    guard.done = true;

    const update = {...guard, ...item};
    Object.assign(this.guardians[this.guardians.length-1], update);

    this.updateGuardianIDObject(null);
    this.updateGuardianKraObject(null);
  }

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // ======================    SAVE FUNCTIONS    ==========================

  async saveBeneficiary(index: number, record: FormGroup): Promise<void> {
    this.notificationService.isLoading(true);
    let tsMessage = this.addText;
    
    try {
      let temp = record.getRawValue();
      let originalContacts;
      let currentBens = this.beneficiaries;

      if (record.getRawValue().Category.toLowerCase() === 'individual') {
        temp.DOB = formatDate(record.getRawValue().DOB);
        temp.ContactPersons = [];
      }

      let data = {
        ...temp,
        ...{
          TrustID: this.estateControlService.TrustID,
          AccountType: this.estateControlService.planType == 0 ? 'PrivateTrust' : 'WillCreation'
        }
      };

      if (currentBens[index].contactsDone) {
        originalContacts = data.ContactPersons;
        data.ContactPersons = [];
      }

      if (data.Other && data.Other != '') {
        const other = data.Other;
        data.Relationship = other;
      }
      if (data.hasOwnProperty('Other')) {
        delete data['Other'];
      }

      if (!(data.IDNoImage === '' || data.IDNoImage === null)) {
        data.IDNoImage = this.idObject;
      } else {
        data.IDNoImage = {name: '', stringValue: ''};
      }

      if (!(data.KraPinImage === '' || data.KraPinImage === null)) {
        data.KraPinImage = this.kraObject;
      } else {
        data.KraPinImage = {name: '', stringValue: ''};
      }

      if (!(data.RegCertImage === '' || data.RegCertImage === null)) {
        data.RegCertImage = this.regCertObject;
      } else {
        data.RegCertImage = {name: '', stringValue: ''};
      }
      if (!(data.BirthCertImage === '' || data.BirthCertImage === null)) {
        data.BirthCertImage = this.birthCertObject;
      } else {
        data.BirthCertImage = {name: '', stringValue: ''};
      }

      
      if (currentBens[index].BeneficiaryID) {
        data = {...data, ...{BeneficiaryID: currentBens[index].BeneficiaryID}};
      }

      const response = await this.apiService.postRequest(
        environment.baseUrl + Constants.estateBeneficiaryURL, data);
      // console.log('::::: RESPONSE\n', response);

      if (response.Status === 1) {
        const latestRecord = currentBens[index];
        const updatedRecord = {...latestRecord, ...record.getRawValue(), ...{BeneficiaryID: response.BeneficiaryID}};
        Object.assign(currentBens[index], updatedRecord);

        if (this.planType == 0) {
          this.estateControlService.updateRecord(3, currentBens);
        } else {
          this.estateControlService.updateRecordWill(5, currentBens);
        }

        currentBens = this.beneficiaries; // update var

        if (currentBens[index].contactsDone) {
          tsMessage = this.updateText;
          let currentRecord = currentBens[index];
          currentRecord.docsDone = true;
          
          if (record.getRawValue().Category.toLowerCase() === 'individual') {
            currentRecord.ContactPersons = originalContacts;

            if (
              this.getBensFormGroup(this.getBens().length-1).get('DOB')?.value !== ''
              && this.getDateDifference(this.getBensFormGroup(this.getBens().length-1).get('DOB')?.value) === false
            ) {
              this.notificationService.viewToast('info', 'Please fill in Guardian details', 'Guardian(s) are mandatory for minor beneficiaries');
            } else {
              currentRecord.done = true;
            }
          } else {
            currentRecord.done = true;
          }

          if (data.IDNoImage && data.IDNoImage.stringValue.length > 5 && response.IdURL) {
            currentRecord.IDNoImage = this.idObject;
            currentRecord = {...currentRecord, ...{IDURL: response.IdURL}};
            this.estateControlService.updateDocSummary(response.IdURL);
          }
          if (data.KraPinImage && data.KraPinImage.stringValue.length > 5 && response.KraURL) {
            currentRecord.KraPinImage = this.kraObject;
            currentRecord = {...currentRecord, ...{KraURL: response.KraURL}};
            this.estateControlService.updateDocSummary(response.KraURL);
          }
          if (data.BirthCertImage && data.BirthCertImage.stringValue.length > 5 && response.BirthCertURL) {
            currentRecord.BirthCertImage = this.birthCertObject;
            currentRecord = {...currentRecord, ...{BirthCertURL: response.BirthCertURL}};
            this.estateControlService.updateDocSummary(response.BirthCertURL);
          }
          if (data.RegCertImage && data.RegCertImage.stringValue.length > 5 && response.RegCertURL) {
            currentRecord.RegCertImage = this.regCertObject;
            currentRecord = {...currentRecord, ...{RegCertURL: response.RegCertURL}};
            this.estateControlService.updateDocSummary(response.RegCertURL);
          }
          
          Object.assign(currentBens[index], currentRecord);
          if (this.planType == 0) {
            this.estateControlService.updateRecord(3, currentBens);
          } else {
            this.estateControlService.updateRecordWill(5, currentBens);
          }

        } else {
          if (record.value.Category.toLowerCase() === 'individual') {
            let currentRecord = currentBens[index];
            currentRecord.personalDone = true;
            currentRecord.contactsDone = true;
            Object.assign(currentBens[index], currentRecord);

            if (this.planType == 0) {
              this.estateControlService.updateRecord(3, currentBens);
            } else {
              this.estateControlService.updateRecordWill(5, currentBens);
            }

            if (this.getDateDifference(record.value.DOB)) {
              this.validationService.addOrRemoveValidator(true,
                this.getBensFormGroup(index).get('IDNoImage')!);

              this.validationService.addOrRemoveValidator(true,
                this.getBensFormGroup(index).get('KraPinImage')!);

            } else {
              this.validationService.addOrRemoveValidator(
                true,
                this.getBensFormGroup(index).get('BirthCertImage')!);
            }

            // For updating auto-fill on 'self' relationship
            if (record.value.Relationship.toLowerCase() == 'self') {
              if (this.recordUpdate.IDNoImage) {
                if (this.recordUpdate.IDNoImage.stringValue &&
                  this.recordUpdate.IDNoImage.stringValue.length > 0) {

                  this.getBensFormGroup(index).get('IDNoImage')?.setValue(
                    this.recordUpdate.IDNoImage.stringValue);
                }
                this.updateIDObject(this.recordUpdate.IDNoImage);
                this.getBensFormGroup(index).get('IDNoImage')?.disable();
              }
              if (this.recordUpdate.KraPinImage) {
                if (this.recordUpdate.KraPinImage.stringValue &&
                  this.recordUpdate.KraPinImage.stringValue.length > 0) {

                  this.getBensFormGroup(index).get('KraPinImage')?.setValue(
                    this.recordUpdate.KraPinImage.stringValue);
                }
                this.updateKraObject(this.recordUpdate.KraPinImage);
                this.getBensFormGroup(index).get('KraPinImage')?.disable();
              }

              if (this.recordUpdate.IDNoImage || this.recordUpdate.KraPinImage) {
                this.notificationService.viewToast('info', 'Documents added from uploads');
              }
            }

          } else {
            if (currentBens[index].personalDone) {
              let currentRecord = currentBens[index];
              currentRecord.contactsDone = true;
              if (response.ContactPersons && response.ContactPersons.length > 0)
                currentRecord.ContactPersons = response.ContactPersons;
              
              Object.assign(currentBens[index], currentRecord);

              if (this.planType == 0) {
                this.estateControlService.updateRecord(3, currentBens);
              } else {
                this.estateControlService.updateRecordWill(5, currentBens);
              }

              this.validationService.addOrRemoveValidator(true,
                this.getBensFormGroup(index).get('RegCertImage')!);

              this.validationService.addOrRemoveValidator(true,
                this.getBensFormGroup(index).get('KraPinImage')!);

            } else {
              let currentRecord = currentBens[index];
              currentRecord.personalDone = true;
              Object.assign(currentBens[index], currentRecord);

              if (this.planType == 0) {
                this.estateControlService.updateRecord(3, currentBens);
              } else {
                this.estateControlService.updateRecordWill(5, currentBens);
              }

              this.addContactArray(
                this.getBensFormGroup(this.getBens().length-1),
                null,
                this.getContacts(this.getBens().length-1)

              );
            }
          }
          console.log('>> New Beneficiaries !! \n', this.beneficiaries);
        }
        this.notificationService.viewToast('success', tsMessage);

      } else {
        this.notificationService.viewToast('error', response.Message);
      }
    } catch (error) {
      console.error(':: Error !! ', error);
      this.notificationService.viewToast('error', 'An unexpected error occurred');
    }
    this.notificationService.isLoading(false);
  }
  async saveGuardians(): Promise<void> {
    this.loadingText = 'Saving Guardians';
    this.notificationService.isLoading(true);

    console.log('Guardians!!!\n', this.guardians);

    try {
      let guardians = [];
      let allDone = true;

      let currentBens = this.beneficiaries;
      let latestRecord = currentBens[currentBens.length-1];

      // Add final form group to array first:
      this.updateGuardianObject();

      for (let i=0; i<this.guardians.length; i++) {
        let data = this.guardians[i];

        data = {
          ...data,
          ...{
            BeneficiaryID: latestRecord.BeneficiaryID,
            TrustID: this.estateControlService.TrustID,
            AccountType: this.estateControlService.planType == 0 ? 'PrivateTrust' : 'WillCreation'
          }
        };

        if (data.length === 1) {
          data = {...data, ...{Category: 'Principal Guardian'}};
        }

        if (data.hasOwnProperty('done')) {
          delete data['done'];
        }

        const response = await this.apiService.postRequest(
          environment.baseUrl + Constants.estateGuardianURL, data);  
        // console.log('::::: RESPONSE ::::::\n', response);

        if (response.Status === 1) {
          data = {...data, ...{GuardianID: response.GuardianID, done: true}};

          if (response.IdURL) {
            this.estateControlService.updateDocSummary(response.IdURL);
            data = {...data, ...{IDURL: response.IdURL}};
          }
          if (response.KraURL) {
            this.estateControlService.updateDocSummary(response.KraURL);
            data = {...data, ...{KraURL: response.KraURL}};
          }

          if (data.hasOwnProperty('TrustID')) {
            delete data['TrustID'];
          }
          if (data.hasOwnProperty('AccountType')) {
            delete data['AccountType'];
          }
          if (data.hasOwnProperty('BeneficiaryID')) {
            delete data['BeneficiaryID'];
          }
          
          guardians.push(data);

        } else {
          allDone = false;
        }
      }

      if (allDone) {
        this.guardians = guardians;
        this.autofilled = false;

        latestRecord.done = true;
        const updatedRecord = {...latestRecord, ...{Guardians: this.guardians}};
        Object.assign(currentBens[currentBens.length-1], updatedRecord);

        if (this.planType == 0) {
          this.estateControlService.updateRecord(3, currentBens);
        } else {
          this.estateControlService.updateRecordWill(5, currentBens);
        }

        console.log('>> Saved Beneficiary Guardians !! \n', currentBens);
        this.notificationService.viewToast('success', 'Guardians saved');
        this.guardianForm.reset();

      } else this.notificationService.viewToast('error', 'Not all Guardians were saved', 'Please try again');

    } catch (error) {
      console.error(':: Error !! ', error);
      this.notificationService.viewToast('error', 'An unexpected error occured');
    }
    this.notificationService.isLoading(false);
    this.loadingText = 'Saving Beneficiary';
  }

  async updateProgress(): Promise<void> {
    if (!this.beneficiaries[this.beneficiaries.length-1].done && this.benForm.valid) await this.submitForm();
    this.saveProgress();
  }

  async submitForm(): Promise<void> {
    if (this.beneficiaries[this.beneficiaries.length-1].done === true) {
      this.navigateFunction();
      
    } else if (
      this.beneficiaries[this.beneficiaries.length-1].docsDone &&
      this.getBensFormGroup(this.getBens().length-1).get('DOB')?.value !== '' &&
      this.getDateDifference(this.getBensFormGroup(this.getBens().length-1).get('DOB')?.value) === false
    ){
      this.saveGuardians();
    } else {
      const bens = this.benForm.get('beneficiaries') as FormArray;
      this.saveBeneficiary(bens.length - 1, this.getBensFormGroup(bens.length - 1));
    }
  }

  async navigateFunction(): Promise<void> {
    if (this.planType == 0) {
      if (!this.estateControlService.page3Done) await this.estateControlService.updatePrivateTrustProgress('Beneficiaries');
      await this.estateControlService.changeTab(4);
      this.estateControlService.page3Done = true;
    } else {
      if (!this.estateControlService.page5Done) await this.estateControlService.updateWillProgress('Beneficiaries');
      await this.estateControlService.changeTabWill(6);
      this.estateControlService.page5Done = true;
    }
  }
}
