import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { EstateControlService } from 'src/app/services/estate-control.service';
import { TabControlService } from 'src/app/services/tab-control.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ValidationService } from 'src/app/services/validators/validation.service';
import { SessionData, decodeSessionData, getValueByIdOrDescription } from 'src/app/util/Helper';

import { environment } from 'src/environments/environment';
import * as Constants from "../../../../../constants/constants";

@Component({
  selector: 'app-step3-referee',
  templateUrl: './step3-referee.component.html',
  styleUrls: ['./step3-referee.component.scss']
})
export class Step3RefereeComponent {
  session: string = ""; // used to resume session
  sessionData: SessionData | string = "error";

  title: string = 'Referral Details.';
  subtitle: string = 'Please review the information you have entered and make any changes by clicking the edit information button';
  referreLabel: string = 'Who Introduced you to Milele Trust';
  referreeOtherHint: string = 'Please tell us more';
  referreeOtherLabel: string = 'Other source';
  agentLabel: string = 'Name of Agent';
  agentHint: string = 'Enter Name';
  selectText: string = 'Select one';

  requiredFieldString: string = 'Required field';

  refValue: string = '';


  formErrors: { [key: string]: string } = {
    referree: '',
    referreeName: '',
    other: ''
  };

  validationMessages: { [key: string]: {} } = {
    referree: { required: this.requiredFieldString },
    referreeName: { required: this.requiredFieldString },
    other: { required: this.requiredFieldString }
  };


  referreeForm = this._formBuilder.group({
    referree: [``, Validators.required],
    referreeName: [``],
    other: [``], 
  });

  constructor(
    private notificationService: NotificationService,
    private tabControlService: TabControlService,
    private _formBuilder: FormBuilder,
    public estateControlService: EstateControlService,
    private validationService: ValidationService,
    private apiService: ApiService,
    private route: ActivatedRoute
  ) {
    this.referreeForm.valueChanges.subscribe(() => {
      this.estateControlService.estateReferee.updated = false;
    });
    
    this.referreeForm.get('referree')?.valueChanges.subscribe((data) => {
      if (this.session !== "") {
        try {
          const sessionData = decodeSessionData(this.session);
  
          if (this.isSessionData(sessionData) && sessionData.Referree && sessionData.Referree.Type && sessionData.Referree.Code
              && this.referreeForm.get('referree')?.value !== this.refValue) {
  
            this.referreeForm.get('referree')?.setValue(this.refValue);
            this.notificationService.viewToast('info', 'This session contains a referral from the agent')
          }
        } catch (ex) {console.log('error ? ', ex)};
      }
      
      this.checkIntermediary();
    });

    this.validationService.addOrRemoveValidationOnValues(
      this.referreeForm.get("referree")!,
      "referreeName",
      this.referreeForm,
      ["Independent Agent", "Broker", "ICEA LION Financial Advisor", "ICEA LION Trust Consultant"]
    );
    this.validationService.addOrRemoveValidationOnValue(
      this.referreeForm.get("referree")!,
      "other",
      this.referreeForm,
      "Other"
    );
  }

  async ngOnInit(): Promise<void> {
    this.route.queryParams.subscribe((params) => {
      // console.log('Params---> ', params); // log route params

      if (params["session"] != undefined) {
        this.session = params["session"];
        const sessionData = decodeSessionData(this.session);

        if (this.isSessionData(sessionData) && sessionData.Referree) {
          this.sessionData = sessionData;
          if (sessionData.Referree.Type && sessionData.Referree.Code) {
            if (this.referreeForm) {
              if (sessionData.Referree.Type == 'agent') {
                this.refValue = this.HearAboutusData[2];

              } else if (sessionData.Referree.Type == 'fubro') {
                  this.refValue = this.HearAboutusData[3];

              } else if (sessionData.Referree.Type == 'tla') {
                this.refValue = this.HearAboutusData[1];

              } else if (sessionData.Referree.Type == 'con') {
                this.refValue = this.HearAboutusData[0];
              }

              this.referreeForm.get('referree')?.setValue(this.refValue);
              this.checkIntermediary();
            }
            this.notificationService.viewToast('info', 'Referral details updated'); 
          }
        }
      }
    });

    // resume
    if (this.estateReferee.updated) {
      this.referreeForm = this._formBuilder.group({
        referree: [`${this.estateReferee.referree || ''}`, Validators.required],
        referreeName: [`${this.estateReferee.referreeName || ''}`],
        other: [`${this.estateReferee.other || ''}`],
      });
  
      if (this.referreeForm.value.referree && this.referreeForm.value.referree !== '') {
        this.checkIntermediary();
      }
    }
  }

  get loading(): boolean {
    return this.notificationService.loading;
  }
  get estateReferee(): any {
    return this.estateControlService.estateReferee;
  }
  get HearAboutusData(): any {
    return this.tabControlService.HearAboutusData;
  }
  get loadingIntermediaries(): boolean {
    return this.tabControlService.loadingIntermediaries;
  }
  get IntermediaryOptions(): any {
    return this.tabControlService.IntermediaryOptions;
  }

  isSessionData(obj: any): obj is SessionData {
    return (
      typeof obj === 'object'
    );
  }
  async checkIntermediary(): Promise<void> {
    this.referreeForm.get('referreeName')?.setValue('');
    
    const data = this.referreeForm.get('referree')?.value;

    if (data) {
      if (data.toLowerCase() == 'icea lion trust consultant') {
        await this.tabControlService.getConsultants();

      } else {
        if (data.toLowerCase() == 'independent agent') {
          await this.tabControlService.getIntermediaries('agent');

        } else if (data.toLowerCase() == 'broker') {
          await this.tabControlService.getIntermediaries('Fubro');
          
        } else if (data.toLowerCase() == 'icea lion financial advisor') {
          await this.tabControlService.getIntermediaries('tla');
        }
      }

      if (this.isSessionData(this.sessionData)) {
  
        if (this.sessionData.Referree) {
          if (this.sessionData.Referree.Type && this.sessionData.Referree.Code) {
  
            if (this.IntermediaryOptions.length > 0) {
              // this.trustForm.get('referree')?.disable();
              this.referreeForm.get('referreeName')?.setValue(
                getValueByIdOrDescription(this.sessionData.Referree.Code, this.IntermediaryOptions, 0)
              );
              this.referreeForm.get('referreeName')?.disable();
            }
          }
        }
      }
    }
  }

  async saveReferree(): Promise<void> {
    this.notificationService.isLoading(true);

    try {
      if (this.estateControlService.AppointmentID) {
        let agentCode = '';
        let agentPhone = '';

        if (this.referreeForm.controls['referreeName'].value && this.referreeForm.controls['referreeName'].value !== '') {
          agentCode = getValueByIdOrDescription(this.referreeForm.controls['referreeName'].value, this.IntermediaryOptions, 2);
          agentPhone = getValueByIdOrDescription(this.referreeForm.controls['referreeName'].value, this.IntermediaryOptions, 1);
        }

        const _data = {
          AgentType: this.referreeForm.controls['referree'].value,
          AgentCode: agentCode,
          AgentName: this.referreeForm.controls['referreeName'].value,
          AgentPhoneNo: agentPhone,

          BookingID: this.estateControlService.AppointmentID,
        };

        const _response = await this.apiService.postRequest(
          environment.baseUrl + Constants.bookAppointmentEstateURL, _data, false);

        if (_response.Status === 1) {
          await this.estateControlService.updateRecord(3, this.referreeForm.value);
          if (!environment.production) console.log('>> Referree !! \n', this.estateControlService.estateReferee);
          this.notificationService.viewToast('success', 'Details saved successfully');
          this.navigateFunction();
        } else {
          console.error(_response);
          this.notificationService.viewToast('error', 'Could not save your details', _response.Message);
          return;
        }
      } else {
        this.notificationService.viewToast('error', 'Invalid session');
      }
      this.notificationService.isLoading(false);
    } catch (error) {
      console.log(':: Error !! ', error);
      this.notificationService.isLoading(false);
      this.notificationService.viewToast('error', 'An unexpected error occurred');
    }
  }

  async submitForm(): Promise<void> {
    if (this.estateReferee.updated && this.estateReferee.updated === true) {
      await this.navigateFunction();
    } else {
      await this.saveReferree();
    }
  }

  async navigateFunction(): Promise<void> {
    await this.estateControlService.changeTab(4);
    this.estateControlService.page3Done = true;
  }
}
