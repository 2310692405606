import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EstateControlService } from 'src/app/services/estate-control.service';

@Component({
  selector: 'app-step8-complete',
  templateUrl: './step8-complete.component.html',
  styleUrls: ['./step8-complete.component.scss']
})
export class Step8CompleteComponent {
  MileleLogoPath: string  = '../../../../assets/Milele_logo_white.png';
  logoPath: string  = '../../../../assets/logo_w.png';
  checkerPath: string = '../../../../assets/fi_check.png';

  title: string = 'Your Bank Slip was successfully received.';
  powerdByText: string = 'Powered by ICEA LION Group. All rights reserved.';

  textH1: string = 'We have received your payment confirmation slip of ';
  textH2: string = ' We have sent a copy of the payment confirmation slip to your email address, ';
  text2: string = 'We are now processing your funds, and will send a notification within 48 hours.';
  
  buttonText: string = 'Book A Legal Consultation';

  email: string = 'Test@gmail.com';
  amount: string = 'KSH 20,000T';
  
  constructor(
    private estateControlService: EstateControlService,
    private router: Router,
  ) {
  }

  async ngOnInit(): Promise<void> {
    const data  = await this.estateControlService.getData(this.estateControlService.consultationKey);
    await this.estateControlService.clearServiceRecords();
    
    if (data) {
      if (data.Persons && data.Persons[0].Email) {
        this.email = data.Persons[0].Email;
      }

      if (data.Amount) {
        if (data.PaymentMode === 'M-Pesa') {
          this.title = 'Your M-Pesa payment was successfully received.';
        }
        this.amount = `KSH ${Number(data.Amount).toLocaleString()}`;
      }
    }
    else {
      this.goHome();
    }
  }

  async bookAppointment(): Promise<void> {
    const nextPage = `estate-planning/legal-consultaion`;
    this.router.navigate([nextPage]);
  }

  async goHome(): Promise<void> {
    await this.estateControlService.clearServiceRecords();
    const nextPage = ``;
    this.router.navigate([nextPage]);

  }
}
