<form [formGroup]="benForm" (ngSubmit)="submitForm()" class="tab-form" autocomplete="off" novalidate>
    <div class="form-80">
        <h1 class="blue margin">{{title}}</h1>
        <p class="lead rocal" style="margin-bottom: 0px;">{{subtitle}}</p>

        <div formArrayName="beneficiaries">
            <div *ngFor="let ben of getBens().controls; let i=index" [formGroupName]="i" style="position: relative;">

                <div style="height: 40px;" *ngIf="beneficiaries.length > 0"></div>

                <p style="font-weight: 600; font-size: 1.3em;" class="blue margin">
                    {{'Beneficiary ' + (i+1)}}
                </p>

                <div class="flex blue-box" *ngIf="beneficiaries[i] && !beneficiaries[i].personalDone ? true : false">
                    <p class="blue lead" >
                        {{'Beneficiary Information'}}
                    </p>
                    <div class="line-2"></div>

                    <div class="row" [ngClass]="{'half': getBensFormGroup(i).get('Category')?.value.toLowerCase() === 'institution'}">
                        <app-input [formGroup]="getBensFormGroup(i)" controlName="Category"
                            inputType="select"
                            [selectFunction]="onBenTypeChange.bind(this)"
                            [selectParam]="i"
                            [ArrayItems]="BeneficiaryOptions"
                            label={{benTypeLabel}} hint={{benTypeHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        <app-input [formGroup]="getBensFormGroup(i)" class="grid-item"
                            *ngIf="getBensFormGroup(i).get('Category')?.value.toLowerCase() === 'individual'"
                            controlName="Relationship"
                            inputType="select"
                            [selectFunction]="onRelationshipChange.bind(this)"
                            [selectParam]="i"
                            [ArrayItems]="Relationships"
                            label={{relationLabel}} hint={{relationHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>

                    </div>

                    <div class="row half" *ngIf="getBensFormGroup(i).get('Relationship')?.value === 'Other'">
                        <app-input [formGroup]="getBensFormGroup(i)" controlName="Other"
                            label='Specify Relationship' hint='Specify your relation to the settlor'
                            [validationMessages]="validationMessages" [formErrors]="formErrors">
                        </app-input>
                    </div>

                    <div *ngIf="getBensFormGroup(i).get('Category')?.value.toLowerCase() === 'individual'">
                        <div class="row-alt">
                            <div class="row-mini">
                                <app-input [formGroup]="getBensFormGroup(i)" controlName="Title"
                                    label={{titleLabel}} hint={{titleHint}}
                                    inputType="select"
                                    [loadingOptions]="loadingTitles"
                                    [ArrayItems]="TitleOptions"
                                    required="false"
                                    [validationMessages]="validationMessages" [formErrors]="formErrors">
                                </app-input>
            
                                <app-input [formGroup]="getBensFormGroup(i)" controlName="FirstName"
                                    label={{fNameLabel}} hint={{fNameHint}}
                                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                
                            </div>
                            
                            <div class="row-mini">
                                <app-input [formGroup]="getBensFormGroup(i)" controlName="MiddleName"
                                label={{mNameLabel}} hint={{mNameHint}} required='false'
                                [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                            <app-input [formGroup]="getBensFormGroup(i)" controlName="LastName"
                                label={{surnameLabel}} hint={{surnameHint}}
                                [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                            </div>
                            
                        </div>
                        
                        <div class="row">
                            <app-input [formGroup]="getBensFormGroup(i)" controlName="Phone"
                                class="grid-item" label={{phoneLabel}}  inputType="phone" required="false"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            <app-input [formGroup]="getBensFormGroup(i)" controlName="Email"
                                class="grid-item" label={{emailLabel}} hint={{emailLabel}} required="false"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        </div>
        
                        <div class="row">
                            <app-input [formGroup]="getBensFormGroup(i)" controlName="Gender"
                                label={{genderLabel}} hint={{genderHint}}
                                inputType="select"
                                [ArrayItems]="GenderOptions"
                                [validationMessages]="validationMessages" [formErrors]="formErrors">
                            </app-input>
                            <app-input [formGroup]="getBensFormGroup(i)" controlName="DOB"
                                class="grid-item"
                                label={{dobLabel}}
                                hint={{dobHint}}
                                [minDate]="minDate"
                                [maxDate]="endDate"
                                inputType="date"
                                [validationMessages]="validationMessages"
                                [formErrors]="formErrors"></app-input>
                        </div>
                    </div>

                    <div *ngIf="getBensFormGroup(i).get('Category')?.value.toLowerCase() === 'institution'">
                        <div class="row">
                            <app-input [formGroup]="getBensFormGroup(i)" controlName="InstitutionName"
                                class="grid-item" label={{institutionNameLabel}} hint={{institutionNameHint}}
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            <app-input [formGroup]="getBensFormGroup(i)" controlName="BusinessNature"
                                label={{businessNatureLabel}} hint={{businessNatureHint}}
                                inputType="select"
                                [ArrayItems]="BusinessNatureOptions"
                                [loadingOptions]="loadingBizNature"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        </div>

                        <div class="row">
                            <app-input
                                [formGroup]="getBensFormGroup(i)" controlName="OrgRegNum"
                                class="grid-item" label={{orgRegNumLabel}} hint={{orgRegNumHint}}
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>

                            <app-input [formGroup]="getBensFormGroup(i)" controlName="KraPin"
                                class="grid-item"  label="Institution KRA PIN" hint={{kraHint}}
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        </div>
                    </div>

                    <div class="row" [ngClass]="{'half': (getBensFormGroup(i).get('Category')?.value.toLowerCase() === 'individual' || !arrayContainsValue(getBensFormGroup(i).get('TrustPurpose')?.value, 'Other'))}">
                        <app-input
                            *ngIf="getBensFormGroup(i).get('Category')?.value.toLowerCase() === 'institution'"
                            [formGroup]="getBensFormGroup(i)" controlName="OrgAddress"
                            class="grid-item" label={{orgAddressLabel}} hint={{orgAddressHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>
                </div>

                <div class="flex done-box" *ngIf="beneficiaries[i] && beneficiaries[i].personalDone ? true : false">
                    <p class="light-blue lead" >
                        {{'Beneficiary Information'}}
                    </p>
                    <div class="flex checker-circle">
                        <img src={{checkerPath}} class="checker" alt="logo" />
                    </div>
                </div>

                <!-- INSTITUTIONS ONLY -->
                <div class="flex blue-box"
                    *ngIf="
                        getBensFormGroup(i).get('Category')?.value.toLowerCase() === 'institution' &&
                        beneficiaries[i] &&
                        beneficiaries[i].personalDone &&
                        !beneficiaries[i].contactsDone">
                    <p class="blue" style="font-weight: 600; font-size: 1.3em;" >
                        {{ 'Contact Person Information' }}
                    </p>

                    <div formArrayName="ContactPersons">
                        <div *ngFor="let contact of getContacts(i).controls; let j=index" [formGroupName]="j" style="position: relative;">
                            <div style="height: 20px;" *ngIf="j > 1"></div>
            
                            <p class="blue lead">
                                {{'Contact Person ' + (j+1)}}
                            </p>
                            <div class="line-2"></div>
    
                            <div class="row-alt">
                                <div class="row-mini">
                                    <app-input [formGroup]="getContactsFormGroup(i, j)" controlName="Title"
                                        label={{titleLabel}} hint={{titleHint}}
                                        inputType="select"
                                        [loadingOptions]="loadingTitles"
                                        [ArrayItems]="TitleOptions"
                                        required="false"
                                        [validationMessages]="validationMessages" [formErrors]="formErrors">
                                    </app-input>
                
                                    <app-input [formGroup]="getContactsFormGroup(i, j)" controlName="FirstName"
                                        label={{fNameLabel}} hint={{fNameHint}}
                                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                    
                                </div>
                                
                                <div class="row-mini">
                                    <app-input [formGroup]="getContactsFormGroup(i, j)" controlName="MiddleName"
                                    label={{mNameLabel}} hint={{mNameHint}} required='false'
                                    [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                                <app-input [formGroup]="getContactsFormGroup(i, j)" controlName="LastName"
                                    label={{surnameLabel}} hint={{surnameHint}}
                                    [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                                </div>
                                
                            </div>
                            
                            <div class="row">
                                <app-input [formGroup]="getContactsFormGroup(i, j)" controlName="Phone"
                                    class="grid-item" label={{phoneLabel}}  inputType="phone"
                                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                <app-input [formGroup]="getContactsFormGroup(i, j)" controlName="Email"
                                    class="grid-item" label={{emailLabel}} hint={{emailLabel}}
                                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            </div>
    
                            <div class="remove-button" style="margin-top: 5px;" *ngIf="j > 0" >
                                <button mat-stroked-button type="button" (click)="removeContact(i, j)">
                                    <div style="display: flex; flex: 1; align-items: center;">
                                        <span class="button-text">
                                            <u>{{ removeContactText }}</u>
                                        </span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="line-2" style="margin-top: 40px;"></div>
                    <!-- BUTTTON Add Contact person -->
                    <div class="blueStroke row" [ngClass]="{'min-55' : getContacts(i).length > 1}">
                        <button mat-stroked-button
                            [disabled]="!benForm.valid"
                            type="button" (click)="addNewContact(i)">
                            <span class="button-text">
                                {{ '+ Add Another Contact Person' }}
                            </span>
                        </button>
                    </div>
                    
                </div>

                <div class="flex done-box"
                    *ngIf="
                        getBensFormGroup(i).get('Category')?.value.toLowerCase() === 'institution' &&
                        beneficiaries[i] &&
                        beneficiaries[i].personalDone &&
                        beneficiaries[i].contactsDone">

                    <p class="light-blue lead" >
                        {{ 'Contact Information' }}
                    </p>
                    <div class="flex checker-circle">
                        <img src={{checkerPath}} class="checker" alt="logo" />
                    </div>
                </div>
                <!-- ++++++++++++++++++++++++++++ -->

                <div class="flex blue-box" *ngIf="beneficiaries[i] && beneficiaries[i].personalDone && beneficiaries[i].contactsDone && !beneficiaries[i].docsDone">
                    <p class="blue lead" style="font-weight: 500;" >
                        {{additionalDetailsText}}
                    </p>
                    <div class="line-2"></div>

                    <div *ngIf="getBensFormGroup(i).get('Category')?.value.toLowerCase() === 'individual'">
                        <div *ngIf="getBensFormGroup(i).get('DOB')?.value !== '' && getDateDifference(getBensFormGroup(i).get('DOB')?.value) === true">
                            <div class="row">
                                <app-input [formGroup]="getBensFormGroup(i)" class="grid-item"
                                    controlName="KraPinImage" label={{kraFileLabel}} inputType="file"
                                    [fileFunction]="updateKraObject.bind(this)"
                                    [existingFileName]="getBensFormGroup(i).get('Relationship')?.value == 'Self' &&
                                        recordUpdate.KraPinImage != '' ? recordUpdate.KraPinImage.name : ''"
                                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
    
                                <app-input [formGroup]="getBensFormGroup(i)" controlName="IDNoImage"
                                    [fileFunction]="updateIDObject.bind(this)"
                                    [existingFileName]="getBensFormGroup(i).get('Relationship')?.value == 'Self' &&
                                        recordUpdate.IDNoImage != '' ? recordUpdate.IDNoImage.name : ''"
                                    class="grid-item" label={{idFileLabel}} hint={{idHint}} inputType="file"
                                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            </div>
                        </div>
        
                        <div *ngIf="getBensFormGroup(i).get('DOB')?.value !== '' && getDateDifference(getBensFormGroup(i).get('DOB')?.value) === false">
                            <div class="row half">
                                <app-input [formGroup]="getBensFormGroup(i)" controlName="BirthCertImage"
                                    [fileFunction]="updateBirthCertObject.bind(this)"
                                    class="grid-item" label={{birthCertLabel}} inputType="file"
                                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            </div>
                        </div>
                    </div>
                    
                    <div *ngIf="getBensFormGroup(i).get('Category')?.value.toLowerCase() === 'institution'">
                        <div class="row">
                            <app-input [formGroup]="getBensFormGroup(i)" controlName="RegCertImage"
                                class="grid-item" label={{regCertFileLabel}} inputType="file"
                                [fileFunction]="updateRegCertObject.bind(this)"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            <app-input [formGroup]="getBensFormGroup(i)" class="grid-item" controlName="KraPinImage"
                                [fileFunction]="updateKraObject.bind(this)"
                                label={{kraFileLabel}} inputType="file"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        </div>
                    </div>
                </div>

                <div class="flex done-box" *ngIf="beneficiaries[i] && beneficiaries[i].personalDone && beneficiaries[i].contactsDone && beneficiaries[i].docsDone">
                    <p class="light-blue lead" >
                        {{additionalDetailsText}}
                    </p>
                    <div class="flex checker-circle">
                        <img src={{checkerPath}} class="checker" alt="logo" />
                    </div>
                </div>

                <form [formGroup]="guardianForm"
                    (ngSubmit)="submitForm()"
                    autocomplete="off" novalidate
                    class="flex blue-box"

                    *ngIf="beneficiaries[i] && beneficiaries[i].docsDone &&
                    !beneficiaries[i].done &&
                    getBensFormGroup(i).get('DOB')?.value !== '' && getDateDifference(getBensFormGroup(i).get('DOB')?.value) === false">

                    <p class="blue lead" >
                        {{'Guardian Information'}}
                    </p>
                    <p style="margin-top: 5px;">
                        Any beneficiary who is a minor <strong>shall</strong> be required to have a guardian.
                    </p>
                    <div class="line-2"></div>

                    <div formArrayName="guardians">
                        <div *ngFor="let guardian of getGuardians().controls; let x=index"
                            [formGroupName]="x"
                            style="position: relative;">
            
                            <div style="height: 40px;" *ngIf="x > 1"></div>
            
                            <div class="flex" style="width: 100%; align-items: center; justify-content: center; margin-top: 40px;">
                                <div class="flex brief" *ngIf="x === getGuardians().length-1 && suggestions === null && showSuggestHint"[@bounceIn]>
                                    If the Guardian is an existing Settlor, use the ID or First Name fields to search and autofill their details
                                </div>
                            </div>
            
                            <div class="flex godolkin">
                                <p class="flex brue margin" style="font-weight: 600; font-size: 1.3em;" *ngIf="getGuardians().length > 1">
                                    {{'Guardian ' + (x+1)}}
                                </p>
            
                                <div class="flex suggestions" *ngIf="x === getGuardians().length-1 && suggestions !== null" [@bounceIn] #suggestBox>
                                    <div class="flex suggest">
                                        Autofill
                                    </div>
            
                                    <div class="flex sugg-holder">
                                        <div [@slideInRight] *ngFor="let suggestion of suggestions; let s=index"
                                            (click)="patchSuggestedValues(x, suggestion)"
                                            class="flex suggestion">
                                            {{ suggestion.FirstName +' ' +suggestion.LastName }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="x == getGuardians().length-1">
                                <div class="row half" *ngIf="x > 0">
                                    <app-input [formGroup]="getGuardiansFormGroup(x)" controlName="Category"
                                        inputType="select"
                                        [ArrayItems]="GuardianOptions"
                                        label={{guardianTypeLabel}} hint={{guardianTypeHint}}
                                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                </div>
            
                                <div class="row-alt">
                                    <div class="row-mini">
                                        <app-input [formGroup]="getGuardiansFormGroup(x)" controlName="Title"
                                            label={{titleLabel}} hint={{titleHint}}
                                            inputType="select"
                                            [loadingOptions]="loadingTitles"
                                            [ArrayItems]="TitleOptions"
                                            required="false"
                                            [validationMessages]="validationMessages" [formErrors]="formErrors">
                                        </app-input>
                    
                                        <app-input [formGroup]="getGuardiansFormGroup(x)" controlName="FirstName"
                                            label={{fNameLabel}} hint={{fNameHint}}
                                            [runSuggestions]="true"
                                            [suggestFunction]="suggestSettlors.bind(this)"
                                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                        
                                    </div>
                                    
                                    <div class="row-mini">
                                        <app-input [formGroup]="getGuardiansFormGroup(x)" controlName="MiddleName"
                                        label={{mNameLabel}} hint={{mNameHint}} required='false'
                                        [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                                    <app-input [formGroup]="getGuardiansFormGroup(x)" controlName="LastName"
                                        label={{surnameLabel}} hint={{surnameHint}}
                                        [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                                    </div>
                                    
                                </div>
                                
                                <div class="row">
                                    <app-input [formGroup]="getGuardiansFormGroup(x)" controlName="Phone"
                                        class="grid-item" label={{phoneLabel}}  inputType="phone"
                                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                    <app-input [formGroup]="getGuardiansFormGroup(x)" controlName="Email"
                                        class="grid-item" label={{emailLabel}} hint={{emailLabel}}
                                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                </div>

                                <div class="row">
                                    <app-input [formGroup]="getGuardiansFormGroup(x)" controlName="IDNo"
                                        class="grid-item" label={{idLabel}} hint={{idHint}}
                                        [runSuggestions]="true"
                                        [suggestFunction]="suggestSettlors.bind(this)"
                                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                    <app-input [formGroup]="getGuardiansFormGroup(x)" controlName="KraPin"
                                        class="grid-item" label={{kraPinLabel}} hint={{kraHint}}
                                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                </div>

                                <p class="blue lead" style="margin-top: 40px; margin-bottom: 20px; margin-left: 14px;">
                                    {{'Upload Documents'}}
                                </p>
            
                                <div class="row">
                                    <app-input [formGroup]="getGuardiansFormGroup(x)" controlName="IDNoImage"
                                        [validationID]="getGuardiansFormGroup(x).get('IDNo')?.value"
                                        [fileFunction]="updateGuardianIDObject.bind(this)"
                                        [existingFileName]="idObjectGuardian && idObjectGuardian.name ? idObjectGuardian.name : ''"
                                        class="grid-item" label={{idFileLabel}} inputType="file"
                                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                    <app-input [formGroup]="getGuardiansFormGroup(x)" controlName="KraPinImage"
                                        [validationID]="getGuardiansFormGroup(x).get('KraPin')?.value"
                                        [fileFunction]="updateGuardianKraObject.bind(this)"
                                        [existingFileName]="kraObjectGuardian && kraObjectGuardian.name ? kraObjectGuardian.name : ''"
                                        class="grid-item" label={{kraFileLabel}} inputType="file"
                                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                </div>

                                <div class="line-2" style="margin-bottom: 20px; margin-top: 40px;" *ngIf="x > 0 || guardianForm.valid"></div>
                            </div>

                            <div *ngIf="x != getGuardians().length-1" class="flex done-box">
                                <p class="light-blue lead" >
                                    {{'Guardian ' + (x+1)}}
                                </p>
                                <div class="flex checker-circle">
                                    <img src={{checkerPath}} class="checker" alt="logo" />
                                </div>
                            </div>

                            <div class="abs-view">
                                <div class="remove-button-alt" *ngIf="x > 0 || getGuardians().length > 1">
                                    <button mat-stroked-button type="button" (click)="removeGuardian(x)">
                                        <div style="display: flex; flex: 1; align-items: center;">
                                            <span class="button-text">
                                                <u>{{ 'Remove Guardian' }}</u>
                                            </span>
                                        </div>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>

                    <!-- BUTTTON Add Guardian -->
                    <div class="blueStroke row" [ngClass]="{'min-55' : getGuardians().length > 1}">
                        <button mat-stroked-button
                            *ngIf="guardianForm.valid"
                            [disabled]="loading || loadingRemove"
                            type="button" (click)="addNewGuardian()">
                            <span class="button-text">
                                {{ '+ Add Another Guardian' }}
                            </span>
                        </button>
                    </div>
                </form>

                <div class="flex done-box" *ngIf="beneficiaries[i].done &&
                    getBensFormGroup(i).get('DOB')?.value !== '' && getDateDifference(getBensFormGroup(i).get('DOB')?.value) === false">
                    <p class="light-blue lead" >
                        {{'Guardian Information'}}
                    </p>
                    <div class="flex checker-circle">
                        <img src={{checkerPath}} class="checker" alt="logo" />
                    </div>
                </div>

                <div class="abs-view">
                    <div class="remove-button-alt" *ngIf="i > 0 || getBens().length > 1">
                        <button mat-stroked-button type="button" (click)="removeBen(i)">
                            <div style="display: flex; flex: 1; align-items: center;">
                                <span class="button-text">
                                    <u>{{ getBens().controls.length-1 === i ? removeButtonText : removeBenText }}</u>
                                </span>
                                <span class="spinning-global"
                                    *ngIf="currentIndex === i && loadingRemove">
                                    <mat-progress-spinner
                                        mode="indeterminate" diameter="25" strokeWidth="4">
                                    </mat-progress-spinner>
                                </span>
                            </div>
                        </button>
                    </div>
                </div>
                
            </div>
        </div>
    
        <!-- BUTTTON Add Ben -->
        <div class="blueStroke row" [ngClass]="{'min-55' : getBens().length > 1 || beneficiaries[beneficiaries.length-1].done}">
            <button mat-stroked-button
                *ngIf="beneficiaries[beneficiaries.length-1] && beneficiaries[beneficiaries.length-1].done"
                type="button" (click)="addNewBen()">
                <span class="button-text">
                    {{ '+ Add New Beneficiary'}}
                </span>
            </button>
        </div>
    </div>

    <!-- BOTTOM OF FORM -->
    <app-form-bottom style="width: 100%;"
        [service]="estateControlService"
        [formValid]="
            beneficiaries[beneficiaries.length-1].done === true ?
            true :
            (
                beneficiaries[beneficiaries.length-1].docsDone &&
                getBensFormGroup(getBens().length-1).get('DOB')?.value !== '' &&
                getDateDifference(getBensFormGroup(getBens().length-1).get('DOB')?.value) === false
            ) ?
            guardianForm.valid : benForm.valid
        "
        [showContinue]="benForm.pristine || benForm.valid || beneficiaries[beneficiaries.length-1].done ? 'true' : 'false'"
        [saveFunction]="updateProgress.bind(this)"
        [text]="
            loading ? loadingText :
            beneficiaries[beneficiaries.length-1].done ?
            'Next' :
            (
                beneficiaries[beneficiaries.length-1].docsDone &&
                getBensFormGroup(getBens().length-1).get('DOB')?.value !== '' &&
                getDateDifference(getBensFormGroup(getBens().length-1).get('DOB')?.value) === false
            ) ?
            'Save Guardian(s)' :
            beneficiaries[beneficiaries.length-1].contactsDone ? 'Upload Documents' :
            beneficiaries[beneficiaries.length-1].personalDone ? 'Update Details' : 'Save Details' "
        [buttonClickHandler]="submitForm.bind(this)">
    </app-form-bottom>
</form>
