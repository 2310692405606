import { trigger, transition, style, animate } from '@angular/animations';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { EstateControlService } from 'src/app/services/estate-control.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss'],
  animations: [
    trigger('slideIn', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)', opacity: 0 }),
        animate('300ms', style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
    ]),
  ],
})
export class AppointmentsComponent {
  tabsAnimationState: boolean[] = [false, false, false, false];

  MileleLogoPath: string  = '../../../assets/Milele_logo_white.png';
  logoPath: string  = '../../../assets/logo_w.png';
  warnPath: string = '../../../assets/warn.png';

  disclaimer: string = 'Please note that we will be saving your information as you proceed along the steps';
  powerdByText: string = 'Powered by ICEA LION Group. All rights reserved.';

  showSteps:boolean = false;
  isRelated:boolean = false;

  iSwear = new FormControl(false);

  session: string | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public estateControlService: EstateControlService,
    private notificationService: NotificationService
  ) {
    this.estateControlService.appointmentType = 0;
  }

  estateDataHolder: any = null;
  resumeActiveTextEstate: string = '';
  showResumePopup: boolean = false;

  ngAfterViewInit() {
    // Trigger the animation for each tab after a slight delay
    this.animateTabs();
  }

  async ngOnInit(): Promise<void> {
    const data = await this.estateControlService.getData(this.estateControlService.storageKeyAppointment);
    if (data && data.timeStamp) {
      console.log(data);
      const timeDiff = new Date().getTime() - parseInt(data.timeStamp);
      this.estateDataHolder = data;

      if (data.activeTab === 1) this.resumeActiveTextEstate = 'Personal Data';
      if (data.appointmentType == 1) {
        this.resumeActiveTextEstate = 'Contact Information';
      }
      if (data.activeTab === 2) this.resumeActiveTextEstate = 'Consultation Details';
      if (data.activeTab === 3) this.resumeActiveTextEstate = 'Referral Details';
      if (data.activeTab === 4) this.resumeActiveTextEstate = 'Schedule';

      if (this.estateDataHolder.AppointmentID) {
        console.log('timestamp ', this.estateDataHolder);

        if (timeDiff < 86400000 ) {
          this.showResumePopup = true;
        }
      }
    }

    this.route.queryParams.subscribe(async (params) => {
      // console.log('Params---> ',params); // log route params
      try {
        if (params["session"] != undefined) {
          this.session = params["session"];
        }
      } catch (error) {
        console.error('Session Resume error! ', error);
      }
    });
    
  }

  animateTabs() {
    for (let i = 0; i < this.tabsAnimationState.length; i++) {
      setTimeout(() => {
        this.tabsAnimationState[i] = true;
      }, 400 * i); // Adjust the delay as needed
    }
  }

  get loading(): boolean {
    return this.notificationService.loading;
  };
  get activeTab(): number {
    return this.estateControlService.activeTab;
  }
  get appointmentType(): number {
    return this.estateControlService.appointmentType;
  }

  get page1Done(): boolean {
    return this.estateControlService.page1Done;
  }
  get page2Done(): boolean {
    return this.estateControlService.page2Done;
  }
  get page3Done(): boolean {
    return this.estateControlService.page3Done;
  }

  onOptionChange(value: boolean): void {
    this.isRelated = value;

    if (this.isRelated) {
      this.estateControlService.appointmentType = 0;
    } else {
      this.estateControlService.appointmentType = 1;
    }
  }

  navHome(): void {
    const nextPage = ``;
    if (this.session) this.router.navigate([nextPage], {queryParams: {session: this.session}});
    else this.router.navigate([nextPage]);
  }

  async resumeEstate(resume: boolean = true): Promise<void> {
    if (resume) {
      await this.estateControlService.updateDataFromStorage();
    
      if (this.estateControlService.AppointmentID) {
        this.showEstateSteps();
      }
    } else {
      this.estateControlService.resetLocalData();
    }
    this.showResumePopup = false;
  }

  showEstateSteps(): void {
    this.showSteps = true;
  }

}
