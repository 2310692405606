import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-radios',
  templateUrl: './radios.component.html',
  styleUrls: ['./radios.component.scss']
})
export class RadiosComponent implements OnInit{
  @Input() preSelect: boolean = true;

  @Input()
  labelOptionA!: string;
  @Input()
  labelOptionB!: string;
  @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  selectedA: boolean | null = true;
  constructor() { }
  
  ngOnInit(): void {
    if (!this.preSelect) {
      this.selectedA = null;
    }
  }

  onRadioClick(value: boolean): void {
    this.selectedA = value;
    this.valueChange.emit(value);
  }
}
