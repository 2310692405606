<form [formGroup]="benForm" (ngSubmit)="submitForm()" class="tab-form" autocomplete="off" novalidate>
    <div class="form-80">
        <h1 class="blue margin">{{title}}</h1>
        <p class="lead rocal" style="margin-bottom: 0px;">{{subtitle}}</p>

        <div formArrayName="beneficiaries">
            <div *ngFor="let ben of getBens().controls; let i=index" [formGroupName]="i" style="position: relative;">

                <div style="height: 40px;" *ngIf="beneficiaries.length > 0"></div>

                <p style="font-weight: 600; font-size: 1.3em;" class="blue margin">
                    {{'Beneficiary ' + (i+1)}}
                </p>

                <div class="flex blue-box" *ngIf="beneficiaries[i] && !beneficiaries[i].personalDone ? true : false">
                    <p class="blue lead" >
                        {{'Beneficiary Information'}}
                    </p>
                    <div class="line-2"></div>

                    <div class="row half">
                        <app-input [formGroup]="getBensFormGroup(i)" controlName="Category"
                            inputType="select"
                            [selectFunction]="onBenTypeChange.bind(this)"
                            [selectParam]="i"
                            [ArrayItems]="BeneficiaryOptions"
                            label={{benTypeLabel}} hint={{benTypeHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>

                    </div>

                    <div *ngIf="getBensFormGroup(i).get('Category')?.value.toLowerCase() === 'individual'">
                        <div class="row-alt">
                            <div class="row-mini">
                                <app-input [formGroup]="getBensFormGroup(i)" controlName="Title"
                                    label={{titleLabel}} hint={{titleHint}}
                                    inputType="select"
                                    [loadingOptions]="loadingTitles"
                                    [ArrayItems]="TitleOptions"
                                    required="false"
                                    [validationMessages]="validationMessages" [formErrors]="formErrors">
                                </app-input>
            
                                <app-input [formGroup]="getBensFormGroup(i)" controlName="FirstName"
                                    label={{fNameLabel}} hint={{fNameHint}}
                                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                
                            </div>
                            
                            <div class="row-mini">
                                <app-input [formGroup]="getBensFormGroup(i)" controlName="MiddleName"
                                label={{mNameLabel}} hint={{mNameHint}} required='false'
                                [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                            <app-input [formGroup]="getBensFormGroup(i)" controlName="LastName"
                                label={{surnameLabel}} hint={{surnameHint}}
                                [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                            </div>
                            
                        </div>
                        
                        <div class="row">
                            <app-input [formGroup]="getBensFormGroup(i)" controlName="Phone"
                                class="grid-item" label={{phoneLabel}}  inputType="phone" required="false"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            <app-input [formGroup]="getBensFormGroup(i)" controlName="Email"
                                class="grid-item" label={{emailLabel}} hint={{emailLabel}} required="false"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        </div>
        
                        <div class="row">
                            <app-input [formGroup]="getBensFormGroup(i)" controlName="Gender"
                                label={{genderLabel}} hint={{genderHint}}
                                inputType="select"
                                [ArrayItems]="GenderOptions"
                                [validationMessages]="validationMessages" [formErrors]="formErrors">
                            </app-input>
                            <app-input [formGroup]="getBensFormGroup(i)" controlName="DOB"
                                class="grid-item"
                                label={{dobLabel}}
                                hint={{dobHint}}
                                [minDate]="minDate"
                                [maxDate]="maxDate"
                                inputType="date"
                                [validationMessages]="validationMessages"
                                [formErrors]="formErrors"></app-input>
                        </div>
                    </div>

                    <div *ngIf="getBensFormGroup(i).get('Category')?.value.toLowerCase() === 'institution'">
                        <div class="row">
                            <app-input [formGroup]="getBensFormGroup(i)" controlName="InstitutionName"
                                class="grid-item" label={{institutionNameLabel}} hint={{institutionNameHint}}
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            <app-input [formGroup]="getBensFormGroup(i)" controlName="BusinessNature"
                                label={{businessNatureLabel}} hint={{businessNatureHint}}
                                inputType="select"
                                [ArrayItems]="BusinessNatureOptions"
                                [loadingOptions]="loadingBizNature"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        </div>

                        <div class="row">
                            <app-input
                                [formGroup]="getBensFormGroup(i)" controlName="OrgRegNum"
                                class="grid-item" label={{orgRegNumLabel}} hint={{orgRegNumHint}}
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>

                            <app-input [formGroup]="getBensFormGroup(i)" controlName="KraPin"
                                class="grid-item" label={{kraPinLabel}} hint={{kraHint}}
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        </div>
                    </div>

                    <div class="row">
                        <app-input [formGroup]="getBensFormGroup(i)" controlName="TrustPurpose"
                            label={{purposeLabel}} hint={{purposeHint}}
                            inputType="multi-select"
                            [ArrayItems]="TrustPurposeOptions"
                            [subtext]="purposeSubtext"
                            [loadingOptions]="loadingTrustPurposeOptions"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>

                        <app-input [formGroup]="getBensFormGroup(i)" controlName="OtherPurpose"
                            *ngIf="arrayContainsValue(getBensFormGroup(i).get('TrustPurpose')?.value, 'Other')"
                            label={{otherPurposeLabel}} hint={{otherPurposeHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>

                        <app-input [formGroup]="getBensFormGroup(i)" controlName="PercShare"
                            *ngIf="!arrayContainsValue(getBensFormGroup(i).get('TrustPurpose')?.value, 'Other')"
                            class="grid-item" label={{shareAllocationLabel}}
                            [hint]="finalRemainingShares === 100 ? shareAllocationHint :  finalRemainingShares.toString() +'% of shares available'"
                            [subtext]="shareAllocationSubtext"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>

                    <div class="row" [ngClass]="{'half': (getBensFormGroup(i).get('Category')?.value.toLowerCase() === 'individual' || !arrayContainsValue(getBensFormGroup(i).get('TrustPurpose')?.value, 'Other'))}">
                        <app-input
                            *ngIf="getBensFormGroup(i).get('Category')?.value.toLowerCase() === 'institution'"
                            [formGroup]="getBensFormGroup(i)" controlName="OrgAddress"
                            class="grid-item" label={{orgAddressLabel}} hint={{orgAddressHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>

                        <app-input [formGroup]="getBensFormGroup(i)" controlName="PercShare"
                            *ngIf="arrayContainsValue(getBensFormGroup(i).get('TrustPurpose')?.value, 'Other')"
                            class="grid-item" label={{shareAllocationLabel}}
                            [hint]="finalRemainingShares === 100 ? shareAllocationHint :  finalRemainingShares.toString() +'% of shares available'"
                            [subtext]="shareAllocationSubtext"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>
                </div>

                <div class="flex done-box" *ngIf="beneficiaries[i] && beneficiaries[i].personalDone ? true : false">
                    <p class="light-blue lead" >
                        {{'Beneficiary Information'}}
                    </p>
                    <div class="flex checker-circle">
                        <img src={{checkerPath}} class="checker" alt="logo" />
                    </div>
                </div>

                <!-- INSTITUTIONS ONLY -->
                <div class="flex blue-box"
                    *ngIf="
                        getBensFormGroup(i).get('Category')?.value.toLowerCase() === 'institution' &&
                        beneficiaries[i] &&
                        beneficiaries[i].personalDone &&
                        !beneficiaries[i].contactsDone">
                    <p class="blue lead" >
                        {{ 'Contact Person Information' }}
                    </p>
                    <div class="line-2"></div>

                    <div formArrayName="ContactPersons">
                        <div *ngFor="let contact of getContacts(i).controls; let j=index" [formGroupName]="j" style="position: relative;">
                            <div style="height: 20px;" *ngIf="j > 1"></div>
            
                            <p style="font-weight: 600; font-size: 1.3em;" class="blue">
                                {{'Contact Person ' + (j+1)}}
                            </p>
                            <div class="line-2"></div>
    
                            <div class="row-alt">
                                <div class="row-mini">
                                    <app-input [formGroup]="getContactsFormGroup(i, j)" controlName="Title"
                                        label={{titleLabel}} hint={{titleHint}}
                                        inputType="select"
                                        [loadingOptions]="loadingTitles"
                                        [ArrayItems]="TitleOptions"
                                        required="false"
                                        [validationMessages]="validationMessages" [formErrors]="formErrors">
                                    </app-input>
                
                                    <app-input [formGroup]="getContactsFormGroup(i, j)" controlName="FirstName"
                                        label={{fNameLabel}} hint={{fNameHint}}
                                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                    
                                </div>
                                
                                <div class="row-mini">
                                    <app-input [formGroup]="getContactsFormGroup(i, j)" controlName="MiddleName"
                                    label={{mNameLabel}} hint={{mNameHint}} required='false'
                                    [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                                <app-input [formGroup]="getContactsFormGroup(i, j)" controlName="LastName"
                                    label={{surnameLabel}} hint={{surnameHint}}
                                    [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                                </div>
                                
                            </div>
                            
                            <div class="row">
                                <app-input [formGroup]="getContactsFormGroup(i, j)" controlName="Phone"
                                    class="grid-item" label={{phoneLabel}}  inputType="phone"
                                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                <app-input [formGroup]="getContactsFormGroup(i, j)" controlName="Email"
                                    class="grid-item" label={{emailLabel}} hint={{emailLabel}}
                                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            </div>
    
                            <div class="remove-button" *ngIf="j > 0">
                                <button mat-stroked-button type="button" (click)="removeContact(i, j)">
                                    <div style="display: flex; flex: 1; align-items: center;">
                                        <span class="button-text">
                                            <u>{{ removeContactText }}</u>
                                        </span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>

                    <!-- BUTTTON Add Contact person -->
                    <div class="blueStroke row" [ngClass]="{'min-55' : getContacts(i).length > 1}">
                        <button mat-stroked-button
                            [disabled]="!benForm.valid"
                            type="button" (click)="addNewContact(i)">
                            <span class="button-text">
                                {{ '+ Add Another Contact Person' }}
                            </span>
                        </button>
                    </div>
                    
                </div>

                <div class="flex done-box"
                    *ngIf="
                        getBensFormGroup(i).get('Category')?.value.toLowerCase() === 'institution' &&
                        beneficiaries[i] &&
                        beneficiaries[i].personalDone &&
                        beneficiaries[i].contactsDone">

                    <p class="light-blue lead" >
                        {{ 'Contact Information' }}
                    </p>
                    <div class="flex checker-circle">
                        <img src={{checkerPath}} class="checker" alt="logo" />
                    </div>
                </div>
                <!-- ++++++++++++++++++++++++++++ -->

                <div class="flex blue-box" *ngIf="beneficiaries[i] && beneficiaries[i].personalDone && beneficiaries[i].contactsDone && !beneficiaries[i].done">
                    <p class="blue lead" style="font-weight: 500;" >
                        {{additionalDetailsText}}
                    </p>
                    <div class="line-2"></div>

                    <div *ngIf="getBensFormGroup(i).get('Category')?.value.toLowerCase() === 'individual'">
                        <div *ngIf="getBensFormGroup(i).get('DOB')?.value !== '' && getDateDifference(getBensFormGroup(i).get('DOB')?.value) === true">
                            <div class="row">
                                <app-input [formGroup]="getBensFormGroup(i)" controlName="IDNoImage"
                                    [fileFunction]="updateIDObject.bind(this)"
                                    class="grid-item" label={{idFileLabel}} hint={{idHint}} inputType="file"
                                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                <app-input [formGroup]="getBensFormGroup(i)" class="grid-item"
                                    controlName="KraPinImage" label={{kraFileLabel}} inputType="file"
                                    [fileFunction]="updateKraObject.bind(this)"
                                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            </div>

                            <div class="row">
                                <app-input [formGroup]="getBensFormGroup(i)" controlName="IDNo"
                                    label={{idLabel}} hint={{idHint}}
                                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                <app-input [formGroup]="getBensFormGroup(i)" controlName="KraPin"
                                    label={{kraPinLabel}} hint={{kraHint}}
                                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            </div>
                        </div>
        
                        <div *ngIf="getBensFormGroup(i).get('DOB')?.value !== '' && getDateDifference(getBensFormGroup(i).get('DOB')?.value) === false">
                            <div class="row">
                                <app-input [formGroup]="getBensFormGroup(i)" controlName="BirthCertImage"
                                    [fileFunction]="updateBirthCertObject.bind(this)"
                                    class="grid-item" label={{birthCertLabel}} inputType="file"
                                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                <app-input [formGroup]="getBensFormGroup(i)" controlName="BirthCertNo"
                                    label='Birth Certificate Number' hint="Birth certificate number"
                                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            </div>
                        </div>
                    </div>
                    
                    <div *ngIf="getBensFormGroup(i).get('Category')?.value.toLowerCase() === 'institution'">
                        <div class="row">
                            <app-input [formGroup]="getBensFormGroup(i)" controlName="RegCertImage"
                                class="grid-item" label={{regCertFileLabel}} inputType="file"
                                [fileFunction]="updateRegCertObject.bind(this)"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            <app-input [formGroup]="getBensFormGroup(i)" class="grid-item" controlName="KraPinImage"
                                [fileFunction]="updateKraObject.bind(this)"
                                label={{kraFileLabel}} inputType="file"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        </div>
                    </div>
                </div>

                <div class="flex done-box" *ngIf="beneficiaries[i] && beneficiaries[i].personalDone && beneficiaries[i].contactsDone && beneficiaries[i].done">
                    <p class="light-blue lead" >
                        {{additionalDetailsText}}
                    </p>
                    <div class="flex checker-circle">
                        <img src={{checkerPath}} class="checker" alt="logo" />
                    </div>
                </div>

                <div class="abs-view">
                    <div class="goldStroke awardButton" *ngIf="beneficiaries[beneficiaries.length-1].done && finalRemainingShares > 0">
                        <button mat-stroked-button
                            class="award"
                            type="button" (click)="awardShares(i)">
                            <span class="button-text">
                                {{ 'Award remaining shares (' +finalRemainingShares +' %)' }}
                            </span>
                        </button>
                    </div>
                    <div class="remove-button-alt" *ngIf="i > 0 || getBens().length > 1">
                        <button mat-stroked-button type="button" (click)="removeBen(i)">
                            <div style="display: flex; flex: 1; align-items: center;">
                                <span class="button-text">
                                    <u>{{ getBens().controls.length-1 === i ? removeButtonText : removeBenText }}</u>
                                </span>
                                <span class="spinning-global"
                                    *ngIf="currentIndex === i && loadingRemove">
                                    <mat-progress-spinner
                                        mode="indeterminate" diameter="25" strokeWidth="4">
                                    </mat-progress-spinner>
                                </span>
                            </div>
                        </button>
                    </div>
                </div>
                
            </div>
        </div>
    
        <!-- BUTTTON Add Ben -->
        <div class="blueStroke row" [ngClass]="{'min-55' : getBens().length > 1 || beneficiaries[beneficiaries.length-1].done && finalRemainingShares > 0}">
            <button mat-stroked-button
                *ngIf="beneficiaries[beneficiaries.length-1] && beneficiaries[beneficiaries.length-1].done"
                [disabled]="finalRemainingShares === 0"
                type="button" (click)="addNewBen()">
                <span class="button-text">
                    {{ finalRemainingShares === 0 ? 'Maximum shares allocated' : '+ Add New Beneficiary'}}
                </span>
            </button>
        </div>
    </div>

    <!-- BOTTOM OF FORM -->
    <app-form-bottom style="width: 100%;"
        [formValid]="benForm.valid || beneficiaries[beneficiaries.length-1].done === true"
        [showContinue]="benForm.valid ? 'true' : 'false'"
        [saveFunction]="updateProgress.bind(this)"
        [text]="loading ? loadingText : beneficiaries[beneficiaries.length-1].done ? 'Next' : pushDocs ? 'Upload Documents' : pushContactInfo ? 'Update Details' : 'Save Details' "
        [buttonClickHandler]="submitForm.bind(this)">
    </app-form-bottom>
</form>
