import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api/api.service';
import { environment } from 'src/environments/environment';
import * as Constants from "../../constants/constants";
import { TabControlService } from 'src/app/services/tab-control.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { EstateControlService } from 'src/app/services/estate-control.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-otp-popup',
  templateUrl: './otp-popup.component.html',
  styleUrls: ['./otp-popup.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('void', style({ opacity: 0 })),
      transition(':enter', [
        animate('1000ms ease-in-out', style({ opacity: 1 })),
      ]),
    ]),
  ]
})
export class OtpPopupComponent implements OnInit {
  @Output() otpVerified: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('ngOtpInput', { static: false}) ngOtpInput: any;


  enterOTPText: string = 'Verify Your Details';
  timerText: string = ' seconds left';
  resendOTPText: string = 'Resend One Time Password';
  resendIcon: string = "replay";
  submitText: string = 'Verify One Time Password';

  isLoading: boolean = false;
  requestingOTP : boolean = false;
  // OTP VALUE
  countdown: number = 80;
  countdownIntervalId: any;

  otpForm: FormGroup;

  public dialogContentId: string = `mat-dialog-content-${parseInt(this.dialogRef.id.replace(/\D/g, ''), 10)}`;

  otpReady: boolean = false;
  otpValue: number = 0;

  service: TabControlService | EstateControlService = this.tabControlService;

  constructor(
    private dialogRef: MatDialogRef<OtpPopupComponent>,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private notificationService: NotificationService,
    private tabControlService: TabControlService,
    private estateControlService: EstateControlService,
    private router: Router,
    
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.otpForm = this.formBuilder.group({
      otp: ['']
    });
    dialogRef._containerInstance._config.ariaDescribedBy = this.dialogContentId;
  }

  ngOnInit(): void {
    if (this.router.url.includes('estate-planning')) {
      this.service = this.estateControlService;
    }

    // Update time countdown to continue if exists
    this.countdown = 80;
    if (this.service.OTPRequestStats.time !== null) {
      let timeDiff = new Date().getTime() / 1000 - this.service.OTPRequestStats.time;
      // console.log('Time Diff ! ', timeDiff);

      if (timeDiff > 0) {
        this.countdown = parseInt((80-timeDiff).toString());
      }
    }

    this.startOrRestartCountdown();
  }

  get OTPMessage(): string {
    return this.service.OTPMessage;
  }

  startOrRestartCountdown(): void {
    // Stop the current countdown if it exists
    this.stopCountdown();
  
    // Start a new countdown
    this.countdownIntervalId = setInterval(() => {
      this.countdown--;
      if (this.countdown <= 0) {
        this.stopCountdown(); // Stop the countdown when it reaches 0 or less
        this.service.OTPValid = false;
        this.service.OTPRequestStats = this.service.DefaultOTPRequestStats;
        this.emitOTPStatusToParent(false);
        // this.dialogRef.close();
      }
    }, 1000);
  }

  stopCountdown() {
    clearInterval(this.countdownIntervalId);
  }

  onOtpChange(otp: string) {
    if (otp.length === 6) {
      this.otpReady = true;
      this.otpValue = parseInt(otp);
      // console.log('OTP READY !!!', this.otpReady);
    } else {
      this.otpReady = false;
    }
  }

  async sendOTP(): Promise<void> {
    if (this.countdown <= 10) {
      this.requestingOTP = true;
      this.ngOtpInput.setValue('');
      this.resendIcon = 'sync';
      let data;
      try {
        if (this.tabControlService.activeTab === 1) {
          data = this.tabControlService.basicData;
        } else if (this.tabControlService.activeTab === 2) {
          data = this.tabControlService.settlors[this.tabControlService.OTPIndex];

          if (this.tabControlService.settlorType == 0) {
            data =
              this.tabControlService.settlors[this.tabControlService.settlors.length-1].Authorisers[this.tabControlService.OTPIndex];
          }
        } else if (this.tabControlService.activeTab === 4) {
          data = this.tabControlService.guardians[this.tabControlService.OTPIndex];
        }

        if (this.router.url.includes('estate-planning')) {
          if (this.estateControlService.planType == 0) {
            if (this.service.activeTab === 1) {
              data = this.service.settlors[this.service.OTPIndex];
            } else if (this.service.activeTab === 4) {
              data = this.estateControlService.trustees[this.service.OTPIndex];
            } else if (this.service.activeTab === 5) {
              data = this.estateControlService.enforcer;
            }
          } else {
            if (this.service.activeTab === 1) {
              data = this.estateControlService.testators[this.service.OTPIndex];
            }
          }
        }
    
        const response = await this.apiService.postRequest(
          environment.baseUrl + Constants.otpRequestURL, data);
    
        if (response.Status === 1) {
          // update the time OTP was requested:
          const timeStats = {time: new Date().getTime() / 1000, phone: data.Phone};
          this.service.OTPRequestStats = timeStats;
          this.service.OTPValid = true;
          this.countdown = 80;

          this.startOrRestartCountdown();

          this.notificationService.viewToast(
            'success',
            `OTP sent to ${data.Phone}.`,
            'We have also sent it to the provided Email');

        } else {
          this.notificationService.viewToast('error', response.Message);
        }

      } catch (error) {
        console.log(':: Error !! ', error);
        this.notificationService.viewToast('error', 'An unexpected error occurred. Please try again');
      }
      this.requestingOTP = false;
      this.resendIcon = 'replay';
    }
    
  }
  
  async verifyOtp(): Promise<void> {
    if (this.otpForm.valid) {
      this.isLoading = true;

      try {
        let otpData = this.tabControlService.basicData;

        if (this.tabControlService.OTPRequestPage === 'Basic') {
          otpData = this.tabControlService.basicData;
        } else if (this.tabControlService.OTPRequestPage === 'Settlor') {
          otpData = this.tabControlService.settlors[this.tabControlService.OTPIndex];

          if (this.tabControlService.settlorType == 0) {
            otpData =
              this.tabControlService.settlors[this.tabControlService.settlors.length-1].Authorisers[this.tabControlService.OTPIndex];
          }
        } else if (this.tabControlService.OTPRequestPage === 'Guardian') {
          otpData = this.tabControlService.guardians[this.tabControlService.OTPIndex];

        } else if (this.tabControlService.OTPRequestPage === 'SettlorEstate') {
          otpData = this.estateControlService.settlors[this.tabControlService.OTPIndex];
        } else if (this.tabControlService.OTPRequestPage === 'Testator') {
          otpData = this.estateControlService.testators[this.tabControlService.OTPIndex];
        }

        if (this.router.url.includes('estate-planning')) {
          if (this.estateControlService.planType == 0) {
            if (this.service.activeTab === 1) {
              otpData = this.service.settlors[this.service.OTPIndex];
            } else if (this.service.activeTab === 4) {
              otpData = this.estateControlService.trustees[this.service.OTPIndex];
            } else if (this.service.activeTab === 5) {
              otpData = this.estateControlService.enforcer;
            }
          } else {
            if (this.service.activeTab === 1) {
              otpData = this.estateControlService.testators[this.service.OTPIndex];
            }
          }
        }

        const data = {
          OTP: this.otpValue,
          Phone: otpData.Phone
        };

        const response = await this.apiService.postRequest(
          environment.baseUrl + Constants.otpValidateURL, data);

        if (response.Status === 1) {
          this.notificationService.viewToast('success', response.Message);

          // Reset OTP Trackers
          this.service.OTPValid = false;
          this.service.OTPRequestStats = this.service.DefaultOTPRequestStats;

          this.emitOTPStatusToParent(true);
          // Perform the necessary logic with the submitted OTP
          this.dialogRef.close();
        } else {
          this.notificationService.viewToast('error', response.Message);
          // this.emitOTPStatusToParent(false);
        }
      } catch (error) {
        console.log(':: Error !! ', error);
        this.notificationService.viewToast('error', 'An unexpected error occurred. Please try again');
      }
      this.isLoading = false;
    }
  }

  emitOTPStatusToParent(isVerified: boolean) {
    this.otpVerified.emit(isVerified);
  }

  cancel(): void {
    this.dialogRef.close();
  }
}