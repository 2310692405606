<form [formGroup]="referreeForm" (ngSubmit)="submitForm()" class="tab-form" autocomplete="off" novalidate>
    <div class="form-80">
        <h1 class="blue margin" style="margin-bottom: 15px; margin-top: 15px;">{{ title }}</h1>

        <div class="flex blue-box">
            <div class="row"
                [ngClass]="{'width-50' : !(
                    referreeForm.get('referree')?.value === 'Other' ||
                    referreeForm.get('referree')?.value === 'Independent Agent' ||
                    referreeForm.get('referree')?.value === 'Broker' ||
                    referreeForm.get('referree')?.value === 'ICEA LION Financial Advisor' ||
                    referreeForm.get('referree')?.value === 'ICEA LION Trust Consultant'
                )}
            ">
                <app-input [formGroup]="referreeForm" controlName="referree"
                    label={{referreLabel}} hint={{selectText}}
                    inputType="select"
                    [ArrayItems]="HearAboutusData"
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
    
                <app-input [formGroup]="referreeForm" controlName="other"
                    *ngIf="referreeForm.get('referree')?.value === 'Other'"
                    label={{referreeOtherLabel}} hint={{referreeOtherHint}}
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                
                <app-input [formGroup]="referreeForm" controlName="referreeName"
                    *ngIf="referreeForm.get('referree')?.value === 'Independent Agent' ||
                        referreeForm.get('referree')?.value === 'Broker' ||
                        referreeForm.get('referree')?.value === 'ICEA LION Financial Advisor' ||
                        referreeForm.get('referree')?.value === 'ICEA LION Trust Consultant' "

                    [label]="
                        referreeForm.get('referree')?.value === 'Broker' ? 'Name of Broker' :
                        referreeForm.get('referree')?.value === 'ICEA LION Trust Consultant' ? 'Name of Consultant' :
                        referreeForm.get('referree')?.value === 'ICEA LION Financial Advisor' ? 'Name of Financial Advisor'
                        : agentLabel"
                        
                    inputType="auto-complete"
                    [ArrayItems]="IntermediaryOptions"
                    [loadingOptions]="loadingIntermediaries"
                    hint={{agentHint}}
                    [validationMessages]="validationMessages" [formErrors]="formErrors">
                </app-input>
            </div>
        </div>

        <!-- <div class="flex done-box" *ngIf="referralDone">
            <p class="light-blue lead" >
                {{ 'Referral Details' }}
            </p>
            <div class="flex checker-circle">
                <img src={{checkerPath}} class="checker" alt="logo" />
            </div>
        </div> -->
    </div>

    <!-- BOTTOM OF FORM -->
    <app-form-bottom style="width: 100%;"
        [service]="estateControlService"
        [text]="loading ? 'Saving' : 'Next'"
        [formValid]="referreeForm.valid"
        [showContinue]="'false'"
        [buttonClickHandler]="submitForm.bind(this)">
    </app-form-bottom>
</form>
