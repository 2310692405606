<form [formGroup]="trustForm" (ngSubmit)="submitForm()" class="tab-form" autocomplete="off" novalidate>
    <div class="form-80">
        <h1 class="blue margin">{{title}}</h1>
        <p class="lead rocal">{{subtitle}}</p>

        <div class="flex blue-box" *ngIf="!infoDone">
            <p class="blue lead" >
                {{'Trust Information'}}
            </p>
            <div class="line-2"></div>

            <div class="row half">
                <app-input [formGroup]="trustForm" controlName="trustName"
                    class="grid-item" label={{trustNameLabel}} hint={{trustNameHint}}
                    [subtext]="trustNameSubtext"
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
            </div>

            <div class="row">
                <app-input [formGroup]="trustForm" controlName="distributionAge"
                    class="grid-item" label={{distAgeLabel}} hint={{distAgeHint}}
                    [subtext]="distAgeSubtext"
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                <app-input [formGroup]="trustForm" controlName="terminationAge"
                    class="grid-item" label={{termAgeLabel}} hint={{termAgeHint}}
                    [subtext]="termAgeSubtext"
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
            </div>

            <div class="row" [ngClass]="{'half': !arrayContainsValue(trustForm.controls['fundSource'].value, 'Other')}">
                <app-input [formGroup]="trustForm" controlName="fundSource"
                    label={{fundSourceLabel}} hint={{fundSourceHint}}
                    inputType="multi-select"
                    [ArrayItems]="FundSourceOptions"
                    [loadingOptions]="loadingFundSourceOptions"
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    
                <app-input [formGroup]="trustForm" controlName="otherSource"
                    *ngIf="arrayContainsValue(trustForm.controls['fundSource'].value, 'Other')"
                    label={{otherSourceLabel}} hint={{otherSourceHint}}
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
            </div>
        </div>


        <div class="flex done-box" *ngIf="infoDone">
            <p class="light-blue lead" >
                {{'Trust Information'}}
            </p>
            <div class="flex checker-circle">
                <img src={{checkerPath}} class="checker" alt="logo" />
            </div>
        </div>

        <div class="flex blue-box" *ngIf="arraysContainValue(beneficiaries, 'Upkeep') && infoDone && !upkeepDone">
            <p class="blue lead" >
                {{ upkeepText }}
            </p>
            <div class="line-2"></div>

            <div class="row">
                <app-input [formGroup]="trustForm" controlName="upkeepAmount"
                    label={{upkeepAmountLabel}} hint={{upkeepAmountHint}} required="false"
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
    
                <app-input [formGroup]="trustForm" controlName="upkeepFrequency"
                    label={{frequencyUpkeepLabel}} hint={{frequencyUpkeepHint}}
                    inputType="select"
                    [ArrayItems]="FrequencyOptions"
                    [loadingOptions]="loadingFrequencyOptions"
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
            </div>
    
            <div class="row">
                <app-input [formGroup]="trustForm" controlName="bank"
                    [selectFunction]="populateBankBranchesList.bind(this)"
                    inputType="select"
                    [ArrayItems]="BanksOptions"
                    [loadingOptions]="loadingBanks"
                    label={{bankLabel}} hint={{bankHint}}
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    
                <app-input [formGroup]="trustForm" controlName="bankBranch"
                    label={{bankBranchLabel}} hint={{bankBranchHint}}
                    inputType="select"
                    [ArrayItems]="BranchPlaceHolders"
                    [loadingOptions]="loadingBankBranches"
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
            </div>
    
            <div class="row">
                <app-input [formGroup]="trustForm" controlName="accName"
                    label={{accNameLabel}} hint={{accNameHint}}
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                <app-input [formGroup]="trustForm" controlName="acc"
                    label={{accLabel}} hint={{accHint}}
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
            </div>
        </div>

        <div class="flex done-box" *ngIf="arraysContainValue(beneficiaries, 'Upkeep') && infoDone && upkeepDone">
            <p class="light-blue lead" >
                {{ upkeepText }}
            </p>
            <div class="flex checker-circle">
                <img src={{checkerPath}} class="checker" alt="logo" />
            </div>
        </div>

        <div class="flex blue-box" *ngIf="!page5Done && infoDone && (!arraysContainValue(beneficiaries, 'Upkeep') || upkeepDone)">
            <p class="blue lead" >
                {{ additionalInfoText }}
            </p>
            <div class="line-2"></div>

            <div class="row" [ngClass]="{'width-50' : trustForm.controls['instructor'].value !== 'Special signing arrangement'}">
                <app-input [formGroup]="trustForm" controlName="instructor"
                    label={{instructionsLabel}} hint={{selectText}}
                    inputType="select"
                    [ArrayItems]="InstructorOptions"
                    [subtext]="instructionsSubtext"
                    [loadingOptions]="loadingInstructorOptions"
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>

                <app-input [formGroup]="trustForm" controlName="signingArrangement"
                    *ngIf="trustForm.controls['instructor'].value === 'Special signing arrangement'"
                    label={{signingArrangementLabel}}
                    hint="Specify your signing arrangement"
                    inputType="area"
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
            </div>
    

            <div class="row"
                [ngClass]="{'width-50' :
                    !(
                        trustForm.get('referree')?.value === 'Other' ||
                        trustForm.get('referree')?.value === 'Independent Agent' ||
                        trustForm.get('referree')?.value === 'Broker' ||
                        trustForm.get('referree')?.value === 'ICEA LION Financial Advisor' ||
                        trustForm.get('referree')?.value === 'ICEA LION Trust Consultant'
                    )}">

                <app-input [formGroup]="trustForm" controlName="referree"
                    label={{referreLabel}} hint={{selectText}}
                    inputType="select"
                    [ArrayItems]="HearAboutusData"
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
    
                <app-input [formGroup]="trustForm" controlName="other" required="false"
                    *ngIf="trustForm.get('referree')?.value === 'Other'"
                    label={{referreeOtherLabel}} hint={{referreeOtherHint}}
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    
                <app-input [formGroup]="trustForm" controlName="agentName"
                    *ngIf="trustForm.get('referree')?.value === 'Independent Agent' ||
                        trustForm.get('referree')?.value === 'Broker' ||
                        trustForm.get('referree')?.value === 'ICEA LION Financial Advisor' ||
                        trustForm.get('referree')?.value === 'ICEA LION Trust Consultant' "

                    [label]="
                        trustForm.get('referree')?.value === 'Broker' ? 'Name of Broker' :
                        trustForm.get('referree')?.value === 'ICEA LION Trust Consultant' ? 'Name of Consultant' :
                        trustForm.get('referree')?.value === 'ICEA LION Financial Advisor' ? 'Name of Financial Advisor'
                        : agentLabel"
                        
                    inputType="auto-complete"
                    [ArrayItems]="IntermediaryOptions"
                    [loadingOptions]="loadingIntermediaries"
                    hint={{agentHint}}
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
            </div>
        </div>

        <div class="flex done-box" *ngIf="page5Done">
            <p class="light-blue lead" >
                {{ additionalInfoText }}
            </p>
            <div class="flex checker-circle">
                <img src={{checkerPath}} class="checker" alt="logo" />
            </div>
        </div>

    </div>

    <!-- BOTTOM OF FORM -->
    <app-form-bottom style="width: 100%;"
        [text]="loading ? loadingText : upkeepDone ? 'Next' : 'Save Details' "
        [showContinue]="trustForm.valid ? 'true' : 'false'"
        [saveFunction]="updateProgress.bind(this)"
        [formValid]="trustForm.valid"
        [buttonClickHandler]="submitForm.bind(this)"></app-form-bottom>
</form>
