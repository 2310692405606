import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-option-popup',
  templateUrl: './option-popup.component.html',
  styleUrls: ['./option-popup.component.scss']
})
export class OptionPopupComponent implements OnInit{
  @Output() close = new EventEmitter<void>();

  session: string | null = null;

  closePopup(): void {
    this.close.emit();
  }

  constructor (
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.journeyType = 0;
  }
  ngOnInit(): void {
    this.route.queryParams.subscribe(async (params) => {
      // console.log('Params---> ',params); // log route params
      try {
        if (params["session"] != undefined) {
          this.session = params["session"];
        }
      } catch (error) {
        console.error('Session Resume error! ', error);
      }
    });
  }


  journeyType: 0 | 1 = 0;
  setJourneyType(value: 0 | 1): void {
    this.journeyType = value;
  }

  submitRequest(): void {
    this.closePopup();
    
    if (this.journeyType === 1) {
      const nexPage = `/estate-planning/set-up`;
      if (this.session) this.router.navigate([nexPage], {queryParams: {session: this.session}});
      else this.router.navigate([nexPage]);
      
    } else {
      const nexPage = `/estate-planning/appointment`;
      if (this.session) this.router.navigate([nexPage], {queryParams: {session: this.session}});
      else this.router.navigate([nexPage]);
    }

    
  }
}
