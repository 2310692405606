import { Component } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api/api.service';
import { TabControlService } from 'src/app/services/tab-control.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import * as Constants from "../../../../../constants/constants";
import { getTodayDayOfWeek } from 'src/app/util/Helper';
import { EstateControlService } from 'src/app/services/estate-control.service';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-step4-schedule',
  templateUrl: './step4-schedule.component.html',
  styleUrls: ['./step4-schedule.component.scss'],
  animations: [
    trigger('bounceIn', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.5)' }),
        animate('300ms', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
    ]),
  ],
})
export class Step4ScheduleComponent {

  title: string = 'Book an Appointment';
  subtitle: string = 'To complete the process, we need to make an appointment with you. Please select the option for you to meet with one of our representatives.';

  appointmentDetails: string = 'Appointment Details';

  option1: string = 'Virtual (Online Meeting)';
  option2: string = 'Physical At Our Office In Riverside Park';
  option3: string = 'Physical At Your Location (Limited To Applicants In Nairobi Metropolis)';

  dateLabel:string = 'Date';
  dateHint:string = '01/01/1990';
  timeLabel:string = 'Time';
  timeHint:string = 'Enter Time';

  emailsLabel:string = 'Additional Meeting Participants';
  emailLabel:string = 'Additional Email Address';
  emailHint:string = 'Enter Email Address';
  personsLabel:string = 'Number of Persons Expected';
  personsHint:string = 'Enter Number';
  locationLabel:string = 'Location';
  locationHint:string = 'Your Location, i.e road, building, landmark';

  requiredFieldString: string = 'Required field';
  
  appointmentOptions: string[] = [this.option1, this.option2, this.option3];
  appointmentOption: string = '';

  virtualOfficeText: string = 'Available meeting hours are 8.00 AM - 6.00 PM EAT';
  selectedVirtualMeetinDay: string = '';


  formErrors: { [key: string]: string } = {
    Date: '',
    Time: '',
    PersonsNumber: '',
    Email: '',
    Location: '',
  };
  validationMessages: { [key: string]: {} } = {
    Date: { required: this.requiredFieldString },
    Time: { required: this.requiredFieldString },
    PersonsNumber: { required: this.requiredFieldString },
    Email: { email: 'Invalid email' },
    Location: { required: this.requiredFieldString },
  };

  // Forms
  virtualForm = this._formBuilder.group({
    Date: ['', Validators.required],
    Time: ['', Validators.required]
  }) as FormGroup & { [key: string]: AbstractControl };

  officeForm = this._formBuilder.group({
    PersonsNumber: ['', Validators.required],
    Date: ['', Validators.required],
    Time: ['', Validators.required]
  });

  locationForm = this._formBuilder.group({
    Location: ['', Validators.required],
    Date: ['', Validators.required],
    Time: ['', Validators.required]
  });
  

  maxDate: Date;
  ampm: 'AM' | 'PM' = 'AM';

  constructor(
    private notificationService: NotificationService,
    private tabControlService: TabControlService,
    public estateControlService: EstateControlService,
    private _formBuilder: FormBuilder,
    private apiService: ApiService
  ) {
    const currentDate = new Date();
    const tempDate = new Date(currentDate.getTime());
    tempDate.setFullYear(currentDate.getFullYear() + 1);

    this.maxDate = tempDate;
  }

  ngOnInit(): void {
    this.tomorrow.setDate(this.minDate.getDate() + 1);
    console.log(this.settlors);
    if (getTodayDayOfWeek() === 'Saturday') {
      this.virtualOfficeText = 'Available meeting hours are between 9.00 AM - 1.00 PM EAT';
    }

    if (this.virtualForm) {
      this.virtualForm.valueChanges.subscribe((form) => {
        if (form.Date) {
          this.selectedVirtualMeetinDay = this.getDayOfWeek(new Date(form.Date));
          
          if (this.selectedVirtualMeetinDay === 'Saturday') {
            this.virtualOfficeText = 'Available meeting hours are between 9.00 AM - 1.00 PM EAT';
            
          } else {
            this.virtualOfficeText = 'Available meeting hours are between 8.00 AM - 6.00 PM EAT';
          }
        }
      });
    }
  }

  getDayOfWeek(date: Date): string {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayOfWeek = date.getDay();
    return daysOfWeek[dayOfWeek];
  }


  get settlors(): any {
    return this.estateControlService.settlors;
  }
  get estateReferee(): any {
    return this.estateControlService.estateReferee;
  }
  get loading(): boolean {
    return this.notificationService.loading;
  }
  get minDate() {
    return this.tabControlService.maxDOBDate;
  }
  tomorrow = new Date(this.minDate);

  addToEmailArray = () => {
    if (this.virtualForm.get('Emails') ) {
      this.addEmail();
    } else {
      this.virtualForm.addControl('Emails', this._formBuilder.array([
        this.createEmailFormGroup()
      ]));
    }
    
  }
  createEmailFormGroup(): FormGroup {
    return this._formBuilder.group({
      Email: ['', [Validators.email]],
    });
  };
  async addEmail() {
    const fields = this.virtualForm.get('Emails') as FormArray;
    fields.push(this.createEmailFormGroup());
    this.virtualForm.setControl('Emails', fields);
  }
  async removeEmail(i:number) {
    const fields = this.virtualForm.get('Emails') as FormArray;
    fields.removeAt(i);
  }
  getEmails() : FormArray {  
    return this.virtualForm.get("Emails") as FormArray; 
  }
  getEmailFormGroup(index: number): FormGroup {
    const emails = this.virtualForm.get('Emails') as FormArray;
    return emails.at(index) as FormGroup;
  }

  isFormValid(): boolean {
    let result = false;

    if (this.appointmentOption === this.option1) result = this.virtualForm.valid;
    else if (this.appointmentOption === this.option2) result = this.officeForm.valid;
    else if (this.appointmentOption === this.option3) result = this.locationForm.valid;

    return result;
  }

  handleForm() {
    switch (this.appointmentOption) {
      case this.option1:
        this.virtualForm.get('Email')?.addValidators(Validators.email);
        this.virtualForm.get('Date')?.addValidators(Validators.required);
        this.virtualForm.get('Time')?.addValidators(Validators.required);

        this.officeForm.reset();
        this.locationForm.reset();

        this.officeForm.clearValidators();
        this.officeForm.updateValueAndValidity();
        this.locationForm.clearValidators();
        this.locationForm.updateValueAndValidity();
        break;
      case this.option2:
        this.officeForm.get('PersonsNumber')?.addValidators(Validators.required);
        this.officeForm.get('Date')?.addValidators(Validators.required);
        this.officeForm.get('Time')?.addValidators(Validators.required);

        this.virtualForm.reset();
        this.locationForm.reset();

        this.virtualForm.clearValidators();
        this.virtualForm.updateValueAndValidity();
        this.locationForm.clearValidators();
        this.locationForm.updateValueAndValidity();
        break;
      case this.option3:
        this.locationForm.get('Location')?.addValidators(Validators.required);
        this.locationForm.get('Date')?.addValidators(Validators.required);
        this.locationForm.get('Time')?.addValidators(Validators.required);

        this.officeForm.reset();
        this.virtualForm.reset();

        this.officeForm.clearValidators();
        this.officeForm.updateValueAndValidity();
        this.virtualForm.clearValidators();
        this.virtualForm.updateValueAndValidity();
        break;
      default:
        this.virtualForm.reset();
        this.officeForm.reset();
        this.locationForm.reset();

        this.virtualForm.clearValidators();
        this.virtualForm.updateValueAndValidity();
        this.officeForm.clearValidators();
        this.officeForm.updateValueAndValidity();
        this.locationForm.clearValidators();
        this.locationForm.updateValueAndValidity();
        break;
    }
  }

  timeFunction(value: 'AM' | 'PM'): void {
    this.ampm = value;
  }

  async submitForm(): Promise<void> {
    this.notificationService.isLoading(true);
    
    try {
      let data;
      let sortedEmails: any = null;

      switch (this.appointmentOption) {
        case this.option1:
          const addedEmails = this.virtualForm.get('Emails')?.value;
          let email = '';

          if (addedEmails && addedEmails.length > 0 && addedEmails[0].Email && addedEmails[0].Email != '') {
            email = addedEmails[0].Email;
            sortedEmails = [];
            sortedEmails.push(addedEmails[0].Email);

            if (addedEmails.length > 1) {
              for (let i=1; i<addedEmails.length; i++) {
                if (addedEmails[i].Email && addedEmails[i].Email != '') {
                  email += `, ${addedEmails[i].Email}`;
                  sortedEmails.push(addedEmails[i].Email);
                }
              }
            }
          }

          data = {
            Date: this.virtualForm.get('Date')?.value,
            Time: `${this.virtualForm.get('Time')?.value} ${this.ampm}`,
            Email: email
          };
  
          break;
        case this.option2:
          data = {
            Date: this.officeForm.get('Date')?.value,
            Time: `${this.officeForm.get('Time')?.value} ${this.ampm}`,
            NumberOfPersons: this.officeForm.get('PersonsNumber')?.value
          };

          break;
        case this.option3:
          data = {
            Date: this.locationForm.get('Date')?.value,
            Time: `${this.locationForm.get('Time')?.value} ${this.ampm}`,
            Location: this.locationForm.get('Location')?.value
          };

          break;
        default:
          data = null;
          this.notificationService.viewToast('warning', 'Please select an option and fill in the form');
          break;
      }
      
      if (data == null || !data.Date) {
        return;
      }

      const date = new Date(data.Date);
      let month = `${date.getMonth() + 1}`;
      let _date = `${date.getDate()}`;

      if (month.length === 1) {
        month = `0${date.getMonth() + 1}`;
      }
      if (_date.length === 1) {
        _date = `0${date.getDate()}`;
      }

      const formattedDate = `${date.getFullYear()}-${month}-${_date}`;
      data.Date = formattedDate;

      let emails = [];
      let names = [];
      let referee = '';
      const name = ` (${this.settlors[0].FirstName} ${this.settlors[0].LastName}) `;

      if (this.estateReferee.referree && this.estateReferee.referree !== '') {
        referee = `${this.estateReferee.referree}`;
      }
      if (this.estateReferee.referreeName && this.estateReferee.referreeName !== '') {
        referee = `${this.estateReferee.referreeName}`;
      }

      for (let i=0; i<this.settlors.length; i++) {
        emails.push(this.settlors[i].Email);
        names.push(this.settlors[i].FirstName);
      }
      
      if (sortedEmails) {
        for (let i=0; i<sortedEmails.length; i++) {
          emails.push(sortedEmails[i]);
          names.push('Sir/Madam');
        }
      }

      data = {...data, ...{
        Name: name,
        Names: names,
        EmailAddresses: emails,
        Referree: referee,
        AppointmentType: this.appointmentOption,
        BookingID: this.estateControlService.AppointmentID
      }};

      // console.log(date +'\n', formattedDate);

      const response = await this.apiService.postRequest(
        environment.baseUrl + Constants.bookAppointmentEstateURL, data, false);

      if (response.Status === 1) {
        this.notificationService.viewToast('success', 'Your Appointment has been scheduled', 'We will contact you via your email for more information');
        this.navigateFunction();
        
      } else {
        console.error(response);
        this.notificationService.viewToast('error', 'Could not save your details', response.Message);
        return;
      }

    } catch (error) {
      console.error (error);
      this.notificationService.viewToast('error', 'An error Occurred', 'Please try again later');
    }
    this.notificationService.isLoading(false);
  }

  async navigateFunction(): Promise<void> {
    this.estateControlService.resetLocalData();
    await this.estateControlService.changeTab(5);
  }
}
