<div [formGroup]="previewForm" class="tab-form" autocomplete="off" novalidate>
    <div class="form-80" #pdfContent>
        <h1 class="blue margin">{{title}}</h1>
        <p class="lead rocal">{{subtitle}}</p>

        <!-- SETTLORS -->
        <div class="box-wrap" *ngIf="planType == 0">
            <div class="box">
                <p class="blue lead section-title">
                    {{settlorsTitle}}
                </p>
                <div class="line-3"></div>

                <div style="display: flex; flex-direction: column; width: 100%;" *ngFor="let settlor of settlors; let i=index">
                    <div class="row" style="align-items: center; margin-bottom: 10px;">
                        <p class="blue lead">{{'Settlor ' + (i+1) }}</p>
                        <div class="goldStroke">
                            <button class="edit-text" (click)="editRecord('Settlor', i)" mat-stroked-button>{{editText}}</button>
                        </div>
                    </div>
                    <div class="items">  
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'First Name'.toUpperCase()}}</span>
                                <span class="value"><b>{{settlor.FirstName}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Middle Name'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{settlor.MiddleName === undefined || settlor.MiddleName === '' ? '-' : settlor.MiddleName}}
                                </b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Last Name/Surname'.toUpperCase()}}</span>
                                <span class="value"><b>{{settlor.LastName}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Primary Phone Number'.toUpperCase()}}</span>
                                <span class="value"><b>{{settlor.Phone}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Email'.toUpperCase()}}</span>
                                <span class="value"><b>{{settlor.Email}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'ID / Passport No.'.toUpperCase()}}</span>
                                <span class="value"><b>{{settlor.IDNo}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'KRA PIN'.toUpperCase()}}</span>
                                <span class="value"><b>{{settlor.KraPin}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Physical Address'.toUpperCase()}}</span>
                                <span class="value"><b>{{settlor.Address}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Postal Address'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{settlor.PostalAddress}}
                                </b></span>
                            </div>
                        </div>
                    </div>
                    <div class="line"></div>
                </div>
            </div>
        </div>

        <!-- TESTATORS -->
        <div class="box-wrap" *ngIf="planType == 1">
            <div class="box">
                <p class="blue lead section-title">
                    {{testatorsTitle}}
                </p>
                <div class="line-3"></div>

                <div style="display: flex; flex-direction: column; width: 100%;" *ngFor="let testator of testators; let i=index">
                    <div class="row" style="align-items: center; margin-bottom: 10px;">
                        <p class="blue lead">{{'Testator ' + (i+1) }}</p>
                        <div class="goldStroke">
                            <button class="edit-text" (click)="editRecord('Testator', i)" mat-stroked-button>{{editText}}</button>
                        </div>
                    </div>
                    <div class="items">
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Title'.toUpperCase()}}</span>
                                <span class="value"><b>{{testator.Title === undefined || testator.Title === '' ? '-' : testator.Title}}</b></span>
                            </div>
                        </div> 
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'First Name'.toUpperCase()}}</span>
                                <span class="value"><b>{{testator.FirstName}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Middle Name'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{testator.MiddleName === undefined || testator.MiddleName === '' ? '-' : testator.MiddleName}}
                                </b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Last Name/Surname'.toUpperCase()}}</span>
                                <span class="value"><b>{{testator.LastName}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Mobile Number'.toUpperCase()}}</span>
                                <span class="value"><b>{{testator.Phone}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Email'.toUpperCase()}}</span>
                                <span class="value"><b>{{testator.Email}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'ID / Passport No.'.toUpperCase()}}</span>
                                <span class="value"><b>{{testator.IDNo}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'KRA PIN'.toUpperCase()}}</span>
                                <span class="value"><b>{{testator.KraPin}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Postal Address'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{testator.PostalAddress}}
                                </b></span>
                            </div>
                        </div>
                        <div class="item-wrap full-col">
                            <div class="item">
                                <span class="key">{{'Physical Address'.toUpperCase()}}</span>
                                <span class="value"><b>{{testator.Address}}</b></span>
                            </div>
                        </div>
                        
                    </div>
                    <div class="line"></div>
                </div>
            </div>
        </div>
        <!-- Breaks used by jsPDF when generating PDF -->
        <div class="break"></div>

        <!-- EXECUTORS -->
        <div class="box-wrap" *ngIf="planType == 1">
            <div class="box">
                <p class="blue lead section-title">
                    {{executorsTitle}}
                </p>
                <div class="line-3"></div>

                <div style="display: flex; flex-direction: column; width: 100%;" *ngFor="let executor of executors; let i=index">
                    <div class="row" style="align-items: center; margin-bottom: 10px;">
                        <p class="blue lead">{{'Executor ' + (i+1) }}</p>
                        <div class="goldStroke">
                            <button class="edit-text" (click)="editRecord('Executor', i)" mat-stroked-button>{{editText}}</button>
                        </div>
                    </div>
                    <div class="items">
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Relationship To Settlor'.toUpperCase()}}</span>
                                <span class="value"><b>{{executor.Relationship}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Title'.toUpperCase()}}</span>
                                <span class="value"><b>{{executor.Title === undefined || executor.Title === '' ? '-' : executor.Title}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'First Name'.toUpperCase()}}</span>
                                <span class="value"><b>{{executor.FirstName}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Middle Name'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{executor.MiddleName === undefined || executor.MiddleName === '' ? '-' : executor.MiddleName}}
                                </b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Last Name/Surname'.toUpperCase()}}</span>
                                <span class="value"><b>{{executor.LastName}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Mobile Number'.toUpperCase()}}</span>
                                <span class="value"><b>{{executor.Phone}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Email'.toUpperCase()}}</span>
                                <span class="value"><b>{{executor.Email}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'ID / Passport No.'.toUpperCase()}}</span>
                                <span class="value"><b>{{executor.IDNo}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Postal Address'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{executor.PostalAddress}}
                                </b></span>
                            </div>
                        </div>
                        <div class="item-wrap full-col">
                            <div class="item">
                                <span class="key">{{'Physical Address'.toUpperCase()}}</span>
                                <span class="value"><b>{{executor.Address}}</b></span>
                            </div>
                        </div>
                        
                    </div>
                    <div class="line"></div>
                </div>
            </div>
        </div>
        <!-- Breaks used by jsPDF when generating PDF -->
        <div class="break" *ngIf="planType == 1"></div>
        
        <!-- BENEFICIARIES -->
        <div class="box-wrap">
            <div class="box">
                <p class="blue lead section-title" >
                    {{bensTitle}}
                </p>
                <div class="line-3"></div>

                <div style="display: flex; flex-direction: column; width: 100%;" *ngFor="let ben of beneficiaries; let i=index">
                    <div class="row" style="align-items: center; margin-bottom: 10px;">
                        <p class="blue lead">{{'Beneficiary ' +(i+1) }}</p>
                        <div class="goldStroke">
                            <button class="edit-text" (click)="editRecord('Beneficiary', i)" mat-stroked-button>{{editText}}</button>
                        </div>
                    </div>
                    <div class="items">
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Beneficiary Type'.toUpperCase()}}</span>
                                <span class="value"><b>{{ben.Category}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap" *ngIf="ben.Category === 'Individual' ">
                            <div class="item">
                                <span class="key">{{'Relationship To Settlor'.toUpperCase()}}</span>
                                <span class="value"><b>{{ben.Relationship}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap" *ngIf="ben.Category === 'Individual' ">
                            <div class="item">
                                <span class="key">{{'Title'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{ben.Title === undefined || ben.Title === '' ? '-' : ben.Title}}</b>
                                </span>
                            </div>
                        </div>
                        <div class="item-wrap" *ngIf="ben.Category === 'Individual' ">
                            <div class="item">
                                <span class="key">{{'First Name'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{ben.FirstName}}</b>
                                </span>
                            </div>
                        </div>
                        <div class="item-wrap" *ngIf="ben.Category === 'Individual' ">
                            <div class="item">
                                <span class="key">{{'Middle Name'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{ben.MiddleName === undefined || ben.MiddleName === '' ? '-' : ben.MiddleName}}
                                </b></span>
                            </div>
                        </div>
                        <div class="item-wrap" *ngIf="ben.Category === 'Individual' ">
                            <div class="item">
                                <span class="key">{{'Last Name/Surname'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{ben.LastName}}
                                </b></span>
                            </div>
                        </div>
                        <div class="item-wrap" *ngIf="ben.Category === 'Individual' ">
                            <div class="item">
                                <span class="key">{{'Primary Phone Number'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{!ben.Phone || ben.Phone === '' ? '-' : ben.Phone}}</b>
                                </span>
                            </div>
                        </div>
                        <div class="item-wrap" *ngIf="ben.Category === 'Individual' ">
                            <div class="item">
                                <span class="key">{{'Email'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{ben.Email === undefined || ben.Email === '' ? '-' : ben.Email}}
                                </b></span>
                            </div>
                        </div>
                        <div class="item-wrap" *ngIf="ben.Category === 'Individual' ">
                            <div class="item">
                                <span class="key">{{'Date of Birth'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{formatDate(ben.DOB) }}
                                </b></span>
                            </div>
                        </div>
                        <div class="item-wrap" *ngIf="ben.Category === 'Individual' ">
                            <div class="item">
                                <span class="key">{{'Gender'.toUpperCase()}}</span>
                                <span class="value"><b>{{ben.Gender}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap" *ngIf="!(ben.IDNo === undefined || ben.IDNo === '') ">   <!-- Currently off !  -->
                            <div class="item">
                                <span class="key">{{'ID/Passport Number'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{ben.IDNo}}
                                </b></span>
                            </div>
                        </div>

                        <div class="item-wrap" *ngIf="ben.Category === 'Institution' ">
                            <div class="item">
                                <span class="key">{{'Institution Name'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{ben.InstitutionName}}
                                </b></span>
                            </div>
                        </div>

                        <div class="item-wrap" *ngIf="ben.Category === 'Institution' ">
                            <div class="item">
                                <span class="key">{{'Nature of Business'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{ben.BusinessNature}}
                                </b></span>
                            </div>
                        </div>

                        <div class="item-wrap" *ngIf="ben.Category === 'Institution' ">
                            <div class="item">
                                <span class="key">{{'Registration Number'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{ben.OrgRegNum}}
                                </b></span>
                            </div>
                        </div>


                        <div class="item-wrap" *ngIf="!(ben.KraPin === undefined || ben.KraPin === '')">
                            <div class="item">
                                <span class="key">{{'KRA PIN'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{ben.KraPin}}
                                </b></span>
                            </div>
                        </div>

                        <div class="item-wrap" *ngIf="ben.Category === 'Institution' ">
                            <div class="item">
                                <span class="key">{{'Physical Address'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{ben.OrgAddress === undefined || ben.OrgAddress === '' ? '-' : ben.OrgAddress}}
                                </b></span>
                            </div>
                        </div>
                        
                    </div>

                    <div style="width: 100%;" *ngIf="ben.Category === 'Institution' ">
                        <div style="display: flex; flex-direction: column; width: 100%;" *ngFor="let contact of ben.ContactPersons; let j=index">
                            <p class="lead">{{'Contact Person ' +(j+1) }}</p>

                            <div class="items">
                                <div class="item-wrap">
                                    <div class="item">
                                        <span class="key">{{'First Name'.toUpperCase()}}</span>
                                        <span class="value"><b>
                                            {{contact.FirstName === undefined || contact.FirstName === '' ? '-' : contact.FirstName}}</b>
                                        </span>
                                    </div>
                                </div>
                                <div class="item-wrap">
                                    <div class="item">
                                        <span class="key">{{'Middle Name'.toUpperCase()}}</span>
                                        <span class="value"><b>
                                            {{contact.MiddleName === undefined || contact.MiddleName === '' ? '-' : contact.MiddleName}}
                                        </b></span>
                                    </div>
                                </div>
                                <div class="item-wrap">
                                    <div class="item">
                                        <span class="key">{{'Last Name/Surname'.toUpperCase()}}</span>
                                        <span class="value"><b>
                                            {{contact.LastName === undefined || contact.LastName === '' ? '-' : contact.LastName}}
                                        </b></span>
                                    </div>
                                </div>
                                <div class="item-wrap">
                                    <div class="item">
                                        <span class="key">{{'Primary Phone Number'.toUpperCase()}}</span>
                                        <span class="value"><b>
                                            {{contact.Phone === undefined || contact.Phone === '' ? '-' : contact.Phone}}</b>
                                        </span>
                                    </div>
                                </div>
                                <div class="item-wrap">
                                    <div class="item">
                                        <span class="key">{{'Email'.toUpperCase()}}</span>
                                        <span class="value"><b>
                                            {{contact.Email === undefined || contact.Email === '' ? '-' : contact.Email}}
                                        </b></span>
                                    </div>
                                </div>
                            </div>

                            <div class="line-c" *ngIf="j !== ben.ContactPersons.length-1"></div> 
                        </div>
                    </div>

                    <!-- Guardians -->
                    <div style="width: 100%;" *ngIf="ben.Category === 'Individual' && ben.Guardians && ben.Guardians.length > 0"> 
                        <div style="display: flex; flex-direction: column; width: 100%;" *ngFor="let guardian of ben.Guardians; let j=index">
                            <div class="row" style="align-items: center; margin-bottom: 10px;">
                                <p class="lead">{{'Guardian ' +(j+1) }}</p>
                                <div class="goldStroke">
                                    <button class="edit-text" (click)="editRecord('Guardian', j, i)" mat-stroked-button>{{editText}}</button>
                                </div>
                            </div>

                            <div class="items">
                                <div class="item-wrap">
                                    <div class="item">
                                        <span class="key">{{'First Name'.toUpperCase()}}</span>
                                        <span class="value"><b>
                                            {{guardian.FirstName === undefined || guardian.FirstName === '' ? '-' : guardian.FirstName}}</b>
                                        </span>
                                    </div>
                                </div>
                                <div class="item-wrap">
                                    <div class="item">
                                        <span class="key">{{'Middle Name'.toUpperCase()}}</span>
                                        <span class="value"><b>
                                            {{guardian.MiddleName === undefined || guardian.MiddleName === '' ? '-' : guardian.MiddleName}}
                                        </b></span>
                                    </div>
                                </div>
                                <div class="item-wrap">
                                    <div class="item">
                                        <span class="key">{{'Last Name/Surname'.toUpperCase()}}</span>
                                        <span class="value"><b>
                                            {{guardian.LastName === undefined || guardian.LastName === '' ? '-' : guardian.LastName}}
                                        </b></span>
                                    </div>
                                </div>
                                <div class="item-wrap">
                                    <div class="item">
                                        <span class="key">{{'Primary Phone Number'.toUpperCase()}}</span>
                                        <span class="value"><b>
                                            {{guardian.Phone === undefined || guardian.Phone === '' ? '-' : guardian.Phone}}</b>
                                        </span>
                                    </div>
                                </div>
                                <div class="item-wrap">
                                    <div class="item">
                                        <span class="key">{{'Email'.toUpperCase()}}</span>
                                        <span class="value"><b>
                                            {{guardian.Email === undefined || guardian.Email === '' ? '-' : guardian.Email}}
                                        </b></span>
                                    </div>
                                </div>
                                <div class="item-wrap">
                                    <div class="item">
                                        <span class="key">{{'ID / Passport No.'.toUpperCase()}}</span>
                                        <span class="value"><b>{{guardian.IDNo}}</b></span>
                                    </div>
                                </div>
                                <div class="item-wrap">
                                    <div class="item">
                                        <span class="key">{{'KRA PIN'.toUpperCase()}}</span>
                                        <span class="value"><b>{{guardian.KraPin}}</b></span>
                                    </div>
                                </div>
                            </div>

                            <div class="line-c" *ngIf="j !== ben.Guardians.length-1"></div> 
                        </div>
                    </div>
                    
                    <div class="line"></div>           
                </div>
            </div>
        </div>
        <div class="break"></div>

        <!-- ESTATE ASSETS -->
        <div class="box-wrap">
            <div class="box">
                <div class="row" style="align-items: center; margin-bottom: 10px;">
                    <p class="blue lead section-title">
                        {{assetsTitle}}
                    </p>
                    <div class="goldStroke">
                        <button class="edit-text" (click)="editRecord('Asset', 0)" mat-stroked-button>{{editText}}</button>
                    </div>
                </div>
                <div class="line-3"></div>

                <!-- Properties -->
                <div class="flex column" style="width: 100%;" *ngIf="assets.properties && assets.properties.length > 0">
                    <p class="blue lead">{{'Real Estate / Land' }}</p>

                    <div class="flex row items-assets">
                        <p>LOCATION</p>
                        <p>TITLE/LR NO.</p>
                        <p>MORTGAGE</p>
                        <p>EST. MARKET VALUE</p>
                    </div>
                    <div style="display: flex; flex-direction: column; width: 100%; margin-bottom: 10px;" *ngFor="let asset of assets.properties; let j=index">
                        <div class="items-assets">
                            <div class="item-wrap">
                                <div class="item">
                                    <span class="value"><b>{{asset.Location}}</b></span>
                                </div>
                            </div>
                            <div class="item-wrap">
                                <div class="item">
                                    <span class="value"><b>{{asset.TitleLRNo}}</b></span>
                                </div>
                            </div>
                            <div class="item-wrap">
                                <div class="item">
                                    <span class="value"><b>{{asset.Mortgage}}</b></span>
                                </div>
                            </div>
                            <div class="item-wrap">
                                <div class="item">
                                    <span class="value"><b>KSH<br>{{ convertNumber(asset.EstimatedMarketValue).toLocaleString('en-US')}}</b></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="line-c"></div>
                </div>

                <!-- Investments -->
                <div class="flex column" style="width: 100%;" *ngIf="assets.investments && assets.investments.length > 0">
                    <p class="blue lead">{{'Investments / Non-Retirement Accounts' }}</p>

                    <div class="flex row items-assets">
                        <p>FINANCIAL INSTITUTION</p>
                        <p>ACCOUNT TYPE</p>
                        <p>TITLE/LR NO.</p>
                        <p>EST. MARKET VALUE</p>
                    </div>
                    <div style="display: flex; flex-direction: column; width: 100%; margin-bottom: 10px;" *ngFor="let investment of assets.investments; let j=index">
                        <div class="items-assets">
                            <div class="item-wrap">
                                <div class="item">
                                    <span class="value"><b>{{investment.FinancialInstitution}}</b></span>
                                </div>
                            </div>
                            <div class="item-wrap">
                                <div class="item">
                                    <span class="value"><b>{{investment.AccountType}}</b></span>
                                </div>
                            </div>
                            <div class="item-wrap">
                                <div class="item">
                                    <span class="value"><b>{{investment.TitleLRNo}}</b></span>
                                </div>
                            </div>
                            <div class="item-wrap">
                                <div class="item">
                                    <span class="value"><b>KSH<br>{{ convertNumber(investment.EstimatedMarketValue).toLocaleString('en-US')}}</b></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="line-c"></div>
                </div>

                <!-- Policies -->
                <div class="flex column" style="width: 100%;" *ngIf="assets.policies && assets.policies.length > 0">
                    <p class="blue lead">{{'Life Insurance' }}</p>

                    <div class="flex row items-assets">
                        <p>FINANCIAL INSTITUTION</p>
                        <p>POLICY / ANNUITY</p>
                        <p>SUM ASSURED</p>
                        <p>MATURITY DATE</p>
                    </div>
                    <div style="display: flex; flex-direction: column; width: 100%; margin-bottom: 10px;" *ngFor="let policy of assets.policies; let j=index">
                        <div class="items-assets">
                            <div class="item-wrap">
                                <div class="item">
                                    <span class="value"><b>{{policy.FinancialInstitution}}</b></span>
                                </div>
                            </div>
                            <div class="item-wrap">
                                <div class="item">
                                    <span class="value"><b>{{policy.Policy}}</b></span>
                                </div>
                            </div>
                            <div class="item-wrap">
                                <div class="item">
                                    <span class="value"><b>KSH<br>{{convertNumber(policy.SumAssured).toLocaleString('en-US')}}</b></span>
                                </div>
                            </div>
                            <div class="item-wrap">
                                <div class="item">
                                    <span class="value"><b>{{ formatDate(policy.MaturityDate) }}</b></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="line-c"></div>
                </div>

                <!-- Benefits -->
                <div class="flex column" style="width: 100%;" *ngIf="assets.benefits && assets.benefits.length > 0">
                    <p class="blue lead">{{'Individual Retirement Scheme Benefits' }}</p>

                    <div class="flex row items-assets">
                        <p>FINANCIAL INSTITUTION</p>
                        <p>ACCOUNT TYPE</p>
                        <p>FUND VALUE</p>
                        <p>RETIREMENT DATE</p>
                    </div>
                    <div style="display: flex; flex-direction: column; width: 100%; margin-bottom: 10px;" *ngFor="let benefit of assets.benefits; let j=index">
                        <div class="items-assets">
                            <div class="item-wrap">
                                <div class="item">
                                    <span class="value"><b>{{benefit.FinancialInstitution}}</b></span>
                                </div>
                            </div>
                            <div class="item-wrap">
                                <div class="item">
                                    <span class="value"><b>{{benefit.AccountType}}</b></span>
                                </div>
                            </div>
                            <div class="item-wrap">
                                <div class="item">
                                    <span class="value"><b>KSH<br>{{convertNumber(benefit.FundValue).toLocaleString('en-US') }}</b></span>
                                </div>
                            </div>
                            <div class="item-wrap">
                                <div class="item">
                                    <span class="value"><b>{{ formatDate(benefit.RetirementDate) }}</b></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="line-c"></div>
                </div>

                <!-- Other Assets -->
                <div class="flex column" style="width: 100%;" *ngIf="assets.otherAssets && assets.otherAssets.length > 0">
                    <p class="blue lead">{{'Other Assets' }}</p>

                    <div class="flex row items-assets">
                        <p>OWNERSHIP</p>
                        <p>EST. MARKET VALUE</p>
                        <p class="full-col">DESCRIPTION</p>
                    </div>
                    <div style="display: flex; flex-direction: column; width: 100%; margin-bottom: 10px;" *ngFor="let asset of assets.otherAssets; let j=index">
                        <div class="items-assets">
                            <div class="item-wrap">
                                <div class="item">
                                    <span class="value"><b>{{asset.Ownership}}</b></span>
                                </div>
                            </div>
                            <div class="item-wrap">
                                <div class="item">
                                    <span class="value"><b>KSH<br> {{convertNumber(asset.EstimatedMarketValue).toLocaleString('en-US')}}</b></span>
                                </div>
                            </div>
                            
                            <div class="item-wrap full-col">
                                <div class="item">
                                    <span class="value"><b>{{asset.Description}}</b></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="line-c"></div>
                </div>

            </div>
        </div>
        <div class="break"></div>

        <!-- TRUSTEES -->
        <div class="box-wrap" *ngIf="planType == 0">
            <div class="box">
                <p class="blue lead section-title" >
                    {{trusteesTitle}}
                </p>
                <div class="line-3"></div>

                <div style="display: flex; flex-direction: column; width: 100%;" *ngFor="let trustee of trustees; let i=index">
                    <div class="row" style="align-items: center; margin-bottom: 10px;">
                        <p class="blue lead">{{'Trustee ' +(i+1) }}</p>
                        <div class="goldStroke">
                            <button class="edit-text" (click)="editRecord('Trustee', i)" mat-stroked-button>{{editText}}</button>
                        </div>
                    </div>
                    <div class="items">
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Trustee Type'.toUpperCase()}}</span>
                                <span class="value"><b>{{trustee.Category}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap" *ngIf="trustee.Category.includes('Individual') ">
                            <div class="item">
                                <span class="key">{{'Relationship To Settlor'.toUpperCase()}}</span>
                                <span class="value"><b>{{trustee.Relationship}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap" *ngIf="trustee.Category.includes('Individual') ">
                            <div class="item">
                                <span class="key">{{'Title'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{trustee.Title === undefined || trustee.Title === '' ? '-' : trustee.Title}}</b>
                                </span>
                            </div>
                        </div>
                        <div class="item-wrap" *ngIf="trustee.Category.includes('Individual') ">
                            <div class="item">
                                <span class="key">{{'First Name'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{trustee.FirstName}}</b>
                                </span>
                            </div>
                        </div>
                        <div class="item-wrap" *ngIf="trustee.Category.includes('Individual') ">
                            <div class="item">
                                <span class="key">{{'Middle Name'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{trustee.MiddleName === undefined || trustee.MiddleName === '' ? '-' : trustee.MiddleName}}
                                </b></span>
                            </div>
                        </div>
                        <div class="item-wrap" *ngIf="trustee.Category.includes('Individual') ">
                            <div class="item">
                                <span class="key">{{'Last Name/Surname'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{trustee.LastName}}
                                </b></span>
                            </div>
                        </div>
                        <div class="item-wrap" *ngIf="trustee.Category.includes('Individual') ">
                            <div class="item">
                                <span class="key">{{'Primary Phone Number'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{trustee.Phone === undefined || trustee.Phone === '' ? '-' : trustee.Phone}}</b>
                                </span>
                            </div>
                        </div>
                        <div class="item-wrap" *ngIf="trustee.Category.includes('Individual') ">
                            <div class="item">
                                <span class="key">{{'Email'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{trustee.Email === undefined || trustee.Email === '' ? '-' : trustee.Email}}
                                </b></span>
                            </div>
                        </div>

                        <div class="item-wrap" *ngIf="!(trustee.IDNo === undefined || trustee.IDNo === '') ">   <!-- Currently off !  -->
                            <div class="item">
                                <span class="key">{{'ID/Passport Number'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{trustee.IDNo}}
                                </b></span>
                            </div>
                        </div>

                        <div class="item-wrap" *ngIf="trustee.Category.includes('Corporate') ">
                            <div class="item">
                                <span class="key">{{'Institution Name'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{trustee.InstitutionName}}
                                </b></span>
                            </div>
                        </div>

                        <div class="item-wrap" *ngIf="trustee.Category.includes('Corporate') ">
                            <div class="item">
                                <span class="key">{{'Nature of Business'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{trustee.BusinessNature}}
                                </b></span>
                            </div>
                        </div>

                        <div class="item-wrap" *ngIf="trustee.Category.includes('Corporate') ">
                            <div class="item">
                                <span class="key">{{'Registration Number'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{trustee.OrgRegNum}}
                                </b></span>
                            </div>
                        </div>


                        <div class="item-wrap" *ngIf="!(trustee.KraPin === undefined || trustee.KraPin === '')">
                            <div class="item">
                                <span class="key">{{'KRA PIN'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{trustee.KraPin}}
                                </b></span>
                            </div>
                        </div>

                        <div class="item-wrap" *ngIf="!(trustee.PostalAddress === undefined || trustee.PostalAddress === '')">
                            <div class="item">
                                <span class="key">{{trustee.Category.includes('Corporate') ? 'Physical Address' : 'Postal Address'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{ trustee.PostalAddress}}
                                </b></span>
                            </div>
                        </div>
                        
                    </div>

                    <div style="width: 100%;" *ngIf="trustee.Category.includes('Corporate') ">
                        <div style="display: flex; flex-direction: column; width: 100%;" *ngFor="let contact of trustee.ContactPersons; let j=index">
                            <p class="lead">{{'Contact Person ' +(j+1) }}</p>

                            <div class="items">
                                <div class="item-wrap">
                                    <div class="item">
                                        <span class="key">{{'First Name'.toUpperCase()}}</span>
                                        <span class="value"><b>
                                            {{contact.FirstName === undefined || contact.FirstName === '' ? '-' : contact.FirstName}}</b>
                                        </span>
                                    </div>
                                </div>
                                <div class="item-wrap">
                                    <div class="item">
                                        <span class="key">{{'Middle Name'.toUpperCase()}}</span>
                                        <span class="value"><b>
                                            {{contact.MiddleName === undefined || contact.MiddleName === '' ? '-' : contact.MiddleName}}
                                        </b></span>
                                    </div>
                                </div>
                                <div class="item-wrap">
                                    <div class="item">
                                        <span class="key">{{'Last Name/Surname'.toUpperCase()}}</span>
                                        <span class="value"><b>
                                            {{contact.LastName === undefined || contact.LastName === '' ? '-' : contact.LastName}}
                                        </b></span>
                                    </div>
                                </div>
                                <div class="item-wrap">
                                    <div class="item">
                                        <span class="key">{{'Primary Phone Number'.toUpperCase()}}</span>
                                        <span class="value"><b>
                                            {{contact.Phone === undefined || contact.Phone === '' ? '-' : contact.Phone}}</b>
                                        </span>
                                    </div>
                                </div>
                                <div class="item-wrap">
                                    <div class="item">
                                        <span class="key">{{'Email'.toUpperCase()}}</span>
                                        <span class="value"><b>
                                            {{contact.Email === undefined || contact.Email === '' ? '-' : contact.Email}}
                                        </b></span>
                                    </div>
                                </div>
                            </div>

                            <div class="line-c" *ngIf="j !== trustee.ContactPersons.length-1"></div> 
                        </div>
                    </div>
                    
                    <div class="line"></div>           
                </div>
            </div>
        </div>
        <div class="break"></div>

        <!-- WITNESSES -->
        <div class="box-wrap" *ngIf="planType == 1">
            <div class="box">
                <p class="blue lead section-title">
                    {{witnessTitle}}
                </p>
                <div class="line-3"></div>

                <div style="display: flex; flex-direction: column; width: 100%;" *ngFor="let witness of witnesses; let i=index">
                    <div class="row" style="align-items: center; margin-bottom: 10px;">
                        <p class="blue lead">{{'Witness ' + (i+1) }}</p>
                        <div class="goldStroke">
                            <button class="edit-text" (click)="editRecord('Witness', i)" mat-stroked-button>{{editText}}</button>
                        </div>
                    </div>
                    <div class="items">
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Title'.toUpperCase()}}</span>
                                <span class="value"><b>{{witness.Title === undefined || witness.Title === '' ? '-' : witness.Title}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'First Name'.toUpperCase()}}</span>
                                <span class="value"><b>{{witness.FirstName}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Middle Name'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{witness.MiddleName === undefined || witness.MiddleName === '' ? '-' : witness.MiddleName}}
                                </b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Last Name/Surname'.toUpperCase()}}</span>
                                <span class="value"><b>{{witness.LastName}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Mobile Number'.toUpperCase()}}</span>
                                <span class="value"><b>{{witness.Phone}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Email'.toUpperCase()}}</span>
                                <span class="value"><b>{{witness.Email}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'ID / Passport No.'.toUpperCase()}}</span>
                                <span class="value"><b>{{witness.IDNo}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="item">
                                <span class="key">{{'Postal Address'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{witness.PostalAddress}}
                                </b></span>
                            </div>
                        </div>
                        
                    </div>
                    <div class="line"></div>
                </div>
            </div>
        </div>
        <div class="break"></div>

        <!-- ENFORCER -->
        <div class="box-wrap" *ngIf="planType == 0 && enforcer.done">
            <div class="box">
                <div class="row" style="align-items: center; margin-bottom: 10px;">
                    <p class="blue lead section-title">
                        {{enforcerTitle}}
                    </p>
                    <div class="goldStroke">
                        <button class="edit-text" (click)="editRecord('Enforcer', 0)" mat-stroked-button>{{editText}}</button>
                    </div>
                </div>
                <div class="line-3"></div>

                <div style="display: flex; flex-direction: column; width: 100%;">
                    <div class="items">  
                        <div class="item-wrap" *ngIf="enforcer.FirstName && enforcer.FirstName != '' ">
                            <div class="item">
                                <span class="key">{{'Relationship To Settlor'.toUpperCase()}}</span>
                                <span class="value"><b>{{enforcer.Relationship}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap" *ngIf="enforcer.FirstName && enforcer.FirstName != '' ">
                            <div class="item">
                                <span class="key">{{'Title'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{enforcer.Title === undefined || enforcer.Title === '' ? '-' : enforcer.Title}}</b>
                                </span>
                            </div>
                        </div>
                        <div class="item-wrap" *ngIf="enforcer.FirstName && enforcer.FirstName != '' ">
                            <div class="item">
                                <span class="key">{{'First Name'.toUpperCase()}}</span>
                                <span class="value"><b>{{enforcer.FirstName}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap" *ngIf="enforcer.FirstName && enforcer.FirstName != '' ">
                            <div class="item">
                                <span class="key">{{'Middle Name'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{enforcer.MiddleName === undefined || enforcer.MiddleName === '' ? '-' : enforcer.MiddleName}}
                                </b></span>
                            </div>
                        </div>
                        <div class="item-wrap" *ngIf="enforcer.LastName && enforcer.LastName != '' ">
                            <div class="item">
                                <span class="key">{{'Last Name/Surname'.toUpperCase()}}</span>
                                <span class="value"><b>{{enforcer.LastName}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap" *ngIf="enforcer.Phone && enforcer.Phone != '' ">
                            <div class="item">
                                <span class="key">{{'Primary Phone Number'.toUpperCase()}}</span>
                                <span class="value"><b>{{enforcer.Phone}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap" *ngIf="enforcer.Email && enforcer.Email != '' ">
                            <div class="item">
                                <span class="key">{{'Email'.toUpperCase()}}</span>
                                <span class="value"><b>{{enforcer.Email}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap" *ngIf="enforcer.IDNo && enforcer.IDNo != '' ">
                            <div class="item">
                                <span class="key">{{'ID / Passport No.'.toUpperCase()}}</span>
                                <span class="value"><b>{{enforcer.IDNo}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap" *ngIf="enforcer.KraPin && enforcer.KraPin != '' ">
                            <div class="item">
                                <span class="key">{{'KRA PIN'.toUpperCase()}}</span>
                                <span class="value"><b>{{enforcer.KraPin}}</b></span>
                            </div>
                        </div>
                        <div class="item-wrap" *ngIf="enforcer.Address && enforcer.Address != '' ">
                            <div class="item">
                                <span class="key">{{'Postal Address'.toUpperCase()}}</span>
                                <span class="value"><b>
                                    {{enforcer.Address}}
                                </b></span>
                            </div>
                        </div>
                    </div>
                    <div class="line" *ngIf="enforcer.done"></div>
                </div>
            </div>
        </div>
    </div>

    <!-- BOTTOM OF FORM -->
    <app-form-bottom style="width: 100%;"
        [service]="estateControlService"
        [formValid]="!loading"
        [showContinue]="'false'"
        [text]="loading ? (planType == 0 ? 'Saving Trust Details' : 'Saving Will Details') : 'Submit'"
        [buttonClickHandler]="submitForm.bind(this)">
    </app-form-bottom>
</div>

<app-estate-editor *ngIf="isPopupVisible" (close)="onPopupClose()"
    [record]="record"
    [recordType]="recordType"
    [recordIndex]="recordIndex">
</app-estate-editor>
