<div class="flex column width-100" style="height: fit-content; box-sizing: border-box; margin-bottom: 15px;">
    <!-- Properties table -->
    <div class="flex column table-holder">
        <p class="blue lead" style="font-size: larger;">
            {{ tableTitle }}
        </p>

        <table mat-table [dataSource]="dataSource" matSort class="table">

            <ng-container *ngFor="let column of columns" [matColumnDef]="column">
                <th mat-header-cell *matHeaderCellDef [mat-sort-header]="getColumnName(column)" class="header {{getColumnName(column)}}">
                    {{
                        column.toLocaleLowerCase() === 'editor' ? '' :
                        getColumnName(column).toLocaleLowerCase() === 'titlelrno' ? 'Title/LR No.' :
                        getColumnName(column).toLocaleLowerCase() === 'estimatedmarketvalue' ? 'Est. Market Value' :
                        column
                    }}
                </th>

                <td mat-cell *matCellDef="let element" class="cells {{getColumnName(column)}}">
                    {{
                        column.toLocaleLowerCase() === 'editor' ? '' :
                        getColumnName(column).toLocaleLowerCase().includes('date') ? formatDate(element[getColumnName(column)]) :
                        getColumnName(column).toLocaleLowerCase() === 'estimatedmarketvalue' ||
                        getColumnName(column).toLocaleLowerCase() === 'fundvalue' ||
                        getColumnName(column).toLocaleLowerCase() === 'sumassured' ? 'KSH ' +convertNumber(element[getColumnName(column)]).toLocaleString('en-US') :
                        element[getColumnName(column)]
                    }}

                    <div *ngIf="column.toLocaleLowerCase() === 'editor'" class="flex align fill" style="justify-content: space-between;">
                        <div class="flex center fill">
                            <div class="flex center circle green" (click)="editAsset(element, tableType)">
                                <mat-icon fontIcon="edit" aria-hidden="false" aria-label="edit asset"></mat-icon>
                            </div>
                        </div>
                        <div class="flex center fill">
                            <div class="flex center circle red" (click)="deleteAsset(element, tableType)">
                                <mat-icon fontIcon="delete_outline" aria-hidden="false" aria-label="delete asset"></mat-icon>
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>

            <!-- Header -->
            <tr mat-header-row *matHeaderRowDef="columns"></tr>
            <!-- Data -->
            <tr mat-row *matRowDef="let row; columns: columns;"></tr>
        </table>

    </div>
</div>
