<form [formGroup]="witnessForm" (ngSubmit)="submitForm()" class="tab-form" autocomplete="off" novalidate>
    <div class="formal" *ngIf="!witnessFormVisible">
        <h1 class="blue">{{title}}</h1>
        <p class="lead local">{{subtitle}}</p>
        <div class="row witness" style="display: flex; align-items: center; justify-content: center; margin-top: 30px; margin-bottom: 35px;">
            <app-radios style="display: flex; width: 100%; align-items: center; justify-content: center;"
                labelOptionA="Add witnesses already known to the testator"
                labelOptionB="Add witnesses after Will is prepared"
                (valueChange)="onOptionChange($event)" >
            </app-radios>
        </div>
    </div>

    <div class="form-80" *ngIf="witnessFormVisible">
        <h1 class="blue margin">{{title}}</h1>
        <p class="lead rocal">{{ witnessFormVisible ? subtitleAlt : subtitle}}</p>

        <div formArrayName="witnesses">
            <div *ngFor="let witness of getWitnesses().controls; let i=index" [formGroupName]="i" style="position: relative;">

                <div style="height: 40px;" *ngIf="i > 1"></div>
                <p style="font-weight: 600; font-size: 1.3em;" class="blue margin">{{'Witness ' + (i+1)}} </p>

                <div class="flex blue-box" *ngIf="witnesses[i] && !witnesses[i].verified">
                    <p class="blue lead" >
                        {{detailsTitle}}
                    </p>
                    <div class="line-2"></div>

                    <div class="row-alt">
                        <div class="row-mini">
                            <app-input [formGroup]="getWitnessFormGroup(i)" controlName="Title"
                                label={{titleLabel}} hint={{titleHint}}
                                inputType="select"
                                [loadingOptions]="loadingTitles"
                                [ArrayItems]="TitleOptions"
                                required="false"
                                [validationMessages]="validationMessages" [formErrors]="formErrors">
                            </app-input>
        
                            <app-input [formGroup]="getWitnessFormGroup(i)" controlName="FirstName"
                                label={{fNameLabel}} hint={{fNameHint}}
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            
                        </div>
                        
                        <div class="row-mini">
                            <app-input [formGroup]="getWitnessFormGroup(i)" controlName="MiddleName"
                            label={{mNameLabel}} hint={{mNameHint}} required='false'
                            [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                        <app-input [formGroup]="getWitnessFormGroup(i)" controlName="LastName"
                            label={{surnameLabel}} hint={{surnameHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                        </div>
                        
                    </div>
                    
                    <div class="row">
                        <app-input [formGroup]="getWitnessFormGroup(i)" class="grid-item"
                            controlName="Phone" label={{phoneLabel}}  inputType="phone"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        <app-input [formGroup]="getWitnessFormGroup(i)" class="grid-item"
                            controlName="Email" label={{emailLabel}} hint={{emailLabel}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>
    
                    <div class="row">
                        <app-input [formGroup]="getWitnessFormGroup(i)" class="grid-item"
                            controlName="IDNo" label={{idLabel}} hint={{idHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        <app-input [formGroup]="getWitnessFormGroup(i)" class="grid-item"
                            controlName="PostalAddress" label={{postalLabel}} hint={{postalHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>
                </div>

                <div class="flex done-box" *ngIf="witnesses[i] && witnesses[i].verified">
                    <p class="light-blue lead" >
                        {{detailsTitle}}
                    </p>
                    <div class="flex checker-circle">
                        <img src={{checkerPath}} class="checker" alt="logo" />
                    </div>
                </div>

                <div class="flex blue-box" *ngIf="witnesses[i] && witnesses[i].verified && !witnesses[i].done">
                    <p class="blue lead" >
                        {{'Upload Documents'}}
                    </p>
                    <div class="line-2"></div>

                    <div class="row">
                        <app-input [formGroup]="getWitnessFormGroup(i)" class="grid-item"
                            controlName="IDNoImage"
                            [validationID]="witnesses[i].IDNo"
                            label={{idFileLabel}}
                            [fileFunction]="updateIDObject.bind(this)"
                            inputType="file"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        <app-input [formGroup]="getWitnessFormGroup(i)" class="grid-item"
                            controlName="KraPinImage"
                            label={{kraFileLabel}}
                            [fileFunction]="updateKraObject.bind(this)"
                            inputType="file"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>

                    <div class="row half">
                        <app-input [formGroup]="getWitnessFormGroup(i)" class="grid-item"
                            controlName="PassportImage"
                            label={{photoLabel}}
                            [fileFunction]="updatePhotoObject.bind(this)"
                            inputType="file"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>
                </div>

                <div class="flex done-box" *ngIf="witnesses[i] && witnesses[i].verified && witnesses[i].done">
                    <p class="light-blue lead" >
                        {{'Upload Documents'}}
                    </p>
                    <div class="flex checker-circle">
                        <img src={{checkerPath}} class="checker" alt="logo" />
                    </div>
                </div>

                 <!-- <div class="line"></div> -->

                <div  class="remove-button" *ngIf="i > 0">
                    <button mat-stroked-button type="button" (click)="removeWitness(i)">
                        <div style="display: flex; flex: 1; align-items: center;">
                            <span class="button-text">
                                <u>{{ getWitnesses().controls.length-1 === i ? removeButtonText : removeWitnessText }}</u>
                            </span>
                            <span class="spinning-global"
                                *ngIf="currentIndex === i && loadingRemove">
                                <mat-progress-spinner
                                    mode="indeterminate" diameter="25" strokeWidth="4">
                                </mat-progress-spinner>
                            </span>
                        </div>
                    </button>
                </div>
            </div>

            <!-- BUTTTON Add Witness -->
            <div class="blueStroke row" [ngClass]="{'min-55' : getWitnesses().length > 1}">
                <button mat-stroked-button
                    *ngIf="witnesses[witnesses.length-1] && witnesses[witnesses.length-1].done === true"
                    [disabled]="loading || loadingRemove"
                    class="general-stroked-button" type="button" (click)="addNewWitness()">
                    <span class="button-text">
                        {{addWitnessText}}
                    </span>
                </button>
            </div>
        </div>
    </div>

    <!-- BOTTOM OF FORM -->
    <app-form-bottom style="width: 100%;"
        [service]="estateControlService"
        [text]="
            loading ? loadingText :
            !witnessFormVisible || (witnesses[witnesses.length-1] && witnesses[witnesses.length-1].done) ? 'Next' :
            (witnesses[witnesses.length-1] && witnesses[witnesses.length-1].verified) ? 'Upload documents' :
            'Save Details'"
            
        [formValid]="
            witnessForm.valid || !witnessFormVisible ? true : false
        "

        [showContinue]="witnessForm.pristine || witnessForm.valid || witnesses[witnesses.length-1].done ? 'true' : 'false'"
        [saveFunction]="updateProgress.bind(this)"

        [buttonClickHandler]="submitForm.bind(this)">
    </app-form-bottom>
</form>
