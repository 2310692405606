import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api/api.service';
import { TabControlService } from 'src/app/services/tab-control.service';
import { environment } from 'src/environments/environment';

import * as Constants from "../../constants/constants";
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-assign-guardian',
  templateUrl: './assign-guardian.component.html',
  styleUrls: ['./assign-guardian.component.scss']
})
export class AssignGuardianComponent implements OnInit {
  @Output() close = new EventEmitter<void>();
  @Output() closeAndProceed = new EventEmitter<void>();

  @Input()
  beneficiaries!: any;
  @Input()
  guardians!: any;
  @Input()
  currentLinked!: any;
  
  defaultText: string = 'Save and Continue';
  buttonText: string = this.defaultText;
  loading: boolean = false;

  constructor (
    private _formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private tabControlService: TabControlService,
    private apiService: ApiService
  ) {
    this.linkForm.valueChanges.subscribe(values => {
      // console.log('Selected beneficiaries:', values);
      // You can perform any additional actions based on the selected values here
    });
  }

  ngOnInit(): void {
    try {
      if (this.guardians && this.guardians.length > 0) {
        const guardiansArray = this.linkForm.get('Guardians') as FormArray;

        // Clear existing settlors
        while (guardiansArray.length !== 0) {
          guardiansArray.removeAt(0);
        }

        // Add beneficiaries from storage
        this.guardians.forEach(() => {
          guardiansArray.push(this.createFormGroup());
        });
      }

    } catch (error) {
      console.log('Error !! ', error);
    }
  }

  linkForm = this._formBuilder.group({
    Guardians: this._formBuilder.array([
      this.createFormGroup()
    ], Validators.compose([this.atLeastOneBeneficiaryAssignedValidator()])),
  });

  createFormGroup(): FormGroup {
    return this._formBuilder.group({
      Beneficiaries: [[]],
    });
  };

  getGuardians() : FormArray {  
    return this.linkForm.get("Guardians") as FormArray;
  }
  getGuardiansFormGroup(index: number): FormGroup {
    const guardians = this.linkForm.get('Guardians') as FormArray;
    return guardians.at(index) as FormGroup;
  }

  closePopup(): void {
    if (!this.loading) {
      this.close.emit();
    }
  }


  async submitRequest(): Promise<void> {
    this.loading = true;
    this.buttonText = 'Updating Details';

    try {
      const guardians = this.getGuardians();

      for (let i=0; i<guardians.length; i++) {
        const guardian = this.getGuardiansFormGroup(i);
        const newBeneficiaries = guardian.get('Beneficiaries')?.value;

        if (newBeneficiaries.length > 0) {
          const data = {
            BeneficiaryIDs: newBeneficiaries,
            GuardianID: this.guardians[i].ID,
            TrustID: this.tabControlService.TrustID
          };
    
          const response = await this.apiService.postRequest(
            environment.baseUrl + Constants.linkGuardianBeneficiaryURL, data);
  
          // console.log('::::: RESPONSE\n', response);
  
          if (response.Status === 1) {
  
            if (response.Links && response.Links.length > 0) {
              let currentGuardians = this.tabControlService.guardians;
  
              for (let j=0; j<currentGuardians.length; j++) {                
                if (currentGuardians[j].GuardianID === this.guardians[i].ID) {
                  let currentRecord = currentGuardians[j];
                  
                  currentRecord.GuardianBens = [...currentRecord.GuardianBens, ...response.Links];
                  Object.assign(currentGuardians[j], currentRecord);
                  await this.tabControlService.updateRecord(4, currentGuardians);
                }
              }
              if (response.Links.length === 1) {
                this.notificationService.viewToast('success', `Beneficiary assigned to ${this.guardians[i].Description}`);
              } else {
                this.notificationService.viewToast('success', `Beneficiaries assigned to ${this.guardians[i].Description}`);
              }
            } else {
              this.notificationService.viewToast('error', 'Records failed to save');
            }
            
          } else {
            this.notificationService.viewToast('error', 'Sorry, an error occurred');
          }
        }
      }

      this.loading = false;
      this.closeAndProceed.emit();

    } catch(error) {
      console.log('Error !! ', error);
      this.notificationService.viewToast('error', 'An unexpected error occurred');
      this.loading = false;
    }
    this.buttonText = this.defaultText;
  }

  atLeastOneBeneficiaryAssignedValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!(control instanceof FormArray)) {
        return null; // If the control is not a FormArray, return null
      }
  
      const formArray = control as FormArray;
      const controls = formArray.controls;
  
      // If there's only one guardian, check if all beneficiaries are assigned
      if (formArray.length === 1) {
        const beneficiaries = (controls[0] as FormGroup).get('Beneficiaries')?.value;
        return beneficiaries && this.beneficiaries && beneficiaries.length == this.beneficiaries.length ? null : { atLeastOneBeneficiaryRequired: true };
      }

      if (formArray.length > 1 && this.beneficiaries.length === 1) {
        // If there's only one beneficiary and multiple guardians, check if the beneficiary is assigned to at least one guardian
        let beneficiaryAssigned = false;
      
        for (const guardianControl of controls) {
          const beneficiaries = (guardianControl as FormGroup).get('Beneficiaries')?.value;
          
          // Check if the current guardian has the beneficiary assigned
          if (beneficiaries.includes(this.beneficiaries[0].ID)) {
            // If the beneficiary is assigned to at least one guardian, set the flag and exit the loop
            beneficiaryAssigned = true;
            break;
          }
        }
      
        // Return null if the beneficiary is assigned to at least one guardian, otherwise return the validation error
        return beneficiaryAssigned ? null : { atLeastOneBeneficiaryRequired: true };
      }
  
      // Collect all selected beneficiary IDs from each guardian
      const selectedBeneficiaryIds: Set<any> = new Set();
      controls.forEach((control) => {
        const beneficiaries = (control as FormGroup).get('Beneficiaries')?.value;
        beneficiaries.forEach((beneficiaryId: any) => {
          selectedBeneficiaryIds.add(beneficiaryId);
        });
      });
  
      // Check if each unique beneficiary ID exists at least once
      const allBeneficiariesAssigned = selectedBeneficiaryIds.size === formArray.length;
  
      return allBeneficiariesAssigned ? null : { atLeastOneBeneficiaryRequired: true };
    };
  }

}
