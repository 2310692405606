<form [formGroup]="testatorForm" (ngSubmit)="submitForm()" class="tab-form" autocomplete="off" novalidate>
    <div class="form-80">
        <h1 class="blue margin">{{ formTitle }}</h1>
        <p class="lead rocal">{{ formSubtitle }}</p>

        <div formArrayName="testators">
            <div *ngFor="let settlor of getTestators().controls; let i=index" [formGroupName]="i" style="position: relative;">

                <div style="height: 40px;" *ngIf="i > 1"></div>
                <p style="font-weight: 600; font-size: 1.3em;" class="blue margin"  *ngIf="i > 0">{{'Testator ' + (i+1)}} </p>

                <div class="flex blue-box" *ngIf="testators[i] && !testators[i].verified">
                    <p class="blue lead" >
                        {{ contactInfoTitle }}
                    </p>
                    <div class="line-2"></div>

                    <div class="row-alt">
                        <div class="row-mini">
                            <app-input [formGroup]="getTestatorFormGroup(i)" controlName="Title"
                                label={{titleLabel}} hint={{titleHint}} required="false"
                                inputType="select"
                                [loadingOptions]="loadingTitles"
                                [ArrayItems]="TitleOptions"
                                [validationMessages]="validationMessages" [formErrors]="formErrors">
                            </app-input>

                            <app-input [formGroup]="getTestatorFormGroup(i)" controlName="FirstName"
                                label={{fNameLabel}} hint={{fNameHint}}
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        </div>
                        
                        <div class="row-mini">
                            <app-input [formGroup]="getTestatorFormGroup(i)" controlName="MiddleName"
                                label={{mNameLabel}} hint={{mNameHint}} required='false'
                                [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                            <app-input [formGroup]="getTestatorFormGroup(i)" controlName="LastName"
                                label={{surnameLabel}} hint={{surnameHint}}
                                [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                        </div>
                        
                    </div>

                    <div class="row">
                        <app-input [formGroup]="getTestatorFormGroup(i)" class="grid-item"
                            controlName="Phone" label={{phoneLabel}}  inputType="phone"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        <app-input [formGroup]="getTestatorFormGroup(i)" class="grid-item"
                            controlName="Email" label={{emailLabel}} hint={{emailLabel}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>
                </div>

                <div class="flex done-box" *ngIf="testators[i] && testators[i].verified">
                    <p class="light-blue lead" >
                        {{ contactInfoTitle }}
                    </p>
                    <div class="flex checker-circle">
                        <img src={{checkerPath}} class="checker" alt="logo" />
                    </div>
                </div>

                <div class="flex blue-box" *ngIf="testators[i] && testators[i].verified && !testators[i].detailsDone">
                    <p class="blue lead" >
                        {{ detailsTitle }}
                    </p>
                    <div class="line-2"></div>
    
                    <div class="row">
                        <app-input [formGroup]="getTestatorFormGroup(i)" class="grid-item"
                            controlName="IDNo" label={{idLabel}} hint={{idHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        <app-input [formGroup]="getTestatorFormGroup(i)" class="grid-item"
                            controlName="KraPin" label={{kraPinLabel}} hint={{kraPinHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>
    
                    <div class="row">
                        <app-input [formGroup]="getTestatorFormGroup(i)" class="grid-item"
                            controlName="Address" label={{addressLabel}} hint={{addressHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        <app-input [formGroup]="getTestatorFormGroup(i)" class="grid-item"
                            controlName="PostalAddress" label={{postalLabel}} hint={{postalHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>
                </div>

                <div class="flex done-box" *ngIf="testators[i] && testators[i].verified && testators[i].detailsDone">
                    <p class="light-blue lead" >
                        {{ detailsTitle }}
                    </p>
                    <div class="flex checker-circle">
                        <img src={{checkerPath}} class="checker" alt="logo" />
                    </div>
                </div>

                <!--  Upload Documents -->
                <div class="flex blue-box" *ngIf="testators[i] && testators[i].detailsDone && !testators[i].done">
                    <p class="blue lead" >
                        {{'Upload Documents'}}
                    </p>
                    <div class="line-2"></div>

                    <div class="row">
                        <app-input [formGroup]="getTestatorFormGroup(i)" class="grid-item"
                            controlName="IDNoImage"
                            [validationID]="testators[i].IDNo"
                            label={{idFileLabel}}
                            [fileFunction]="updateIDObject.bind(this)"
                            inputType="file"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        <app-input [formGroup]="getTestatorFormGroup(i)" class="grid-item"
                            controlName="KraPinImage"
                            [validationID]="testators[i].KraPin"
                            label={{kraFileLabel}}
                            [fileFunction]="updateKraObject.bind(this)"
                            inputType="file"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>
                </div>

                <div class="flex done-box" *ngIf="testators[i] && testators[i].detailsDone && testators[i].done">
                    <p class="light-blue lead" >
                        {{'Upload Documents'}}
                    </p>
                    <div class="flex checker-circle">
                        <img src={{checkerPath}} class="checker" alt="logo" />
                    </div>
                </div>

                <div  class="remove-button" *ngIf="i > 0">
                    <button mat-stroked-button type="button" (click)="removeTestator(i)">
                        <div style="display: flex; flex: 1; align-items: center;">
                            <span class="button-text">
                                <u>{{ getTestators().controls.length-1 === i ? removeButtonText : removeTestatorText }}</u>
                            </span>
                            <span class="spinning-global"
                                *ngIf="currentIndex === i && loadingRemove">
                                <mat-progress-spinner
                                    mode="indeterminate" diameter="25" strokeWidth="4">
                                </mat-progress-spinner>
                            </span>
                        </div>
                    </button>
                </div>
            </div>

            <!-- BUTTTON Add Testator -->
            <div class="blueStroke row" [ngClass]="{'min-55' : getTestators().length > 1}">
                <button mat-stroked-button
                    *ngIf="testators[testators.length-1] && testators[testators.length-1].done === true"
                    [disabled]="loading || loadingRemove"
                    class="general-stroked-button" type="button" (click)="addNewTestator()">
                    <span class="button-text">
                        {{ addTestatorText }}
                    </span>
                </button>
            </div>
        </div>
    </div>

    <!-- BOTTOM OF FORM -->
    <app-form-bottom style="width: 100%;"
        [service]="estateControlService"
        [text]="
            loading ? loadingText :
            testators[testators.length-1] && testators[testators.length-1].done == true ? 'Next' :
            testators[testators.length-1] && testators[testators.length-1].verified == false ? 'Verify via OTP' :
            'Save Details'
        "

        [formValid]="
            (testators[testators.length-1] && testators[testators.length-1].done && testators[testators.length-1].done == true) ? true :
            testatorForm.valid ? true : false
        "

        [showContinue]="'false'"
        [buttonClickHandler]="submitForm.bind(this)">
    </app-form-bottom>
</form>

