import { Injectable } from '@angular/core';
import { ApiService } from './api/api.service';
import { environment } from 'src/environments/environment';
import * as Constants from "../constants/constants";
import { BehaviorSubject } from 'rxjs';
import { Intermediaries, TitleObject, delay, generateRandomNumber, getCurrentTime, getCurrentTimePlus24HoursInMilliseconds, truncateString } from '../util/Helper';
import { Router } from '@angular/router';
import * as localforage from 'localforage';
import { NotificationService } from './notification.service';

// declare var $zoho: any

@Injectable({
  providedIn: 'root'
})
export class TabControlService {
  activeTab: number = 1;
  TrustID: string | null = null;

  // Data Holders
  settlorType: 0 | 1 = 0;   // 0 - Institution | 1 - Individual
  
  // Trust Fund
  // step 1
  basicData: any = {};
  // step 2
  settlors: any = [];
  // step 3
  beneficiaries: any = [];
  // step 4
  guardians: any = [];  
  // step 5
  trustInfo: any = {};
  docSummary: any = [];
  summary: string = '';
  paymentData: any = false;


  // Page done markers
  page1Done: boolean = false;
  page2Done: boolean = false;
  page3Done: boolean = false;
  page4Done: boolean = false;
  page5Done: boolean = false;
  page6Done: boolean = false;

  // Beneficiary Shares control
  finalSharesRemaining: number = 100;
  sharesRemaining: number = 100;

  // OTP Management
  OTPRequestPage: 'Basic' | 'Settlor' | 'Guardian' | 'SettlorEstate' | 'Testator' = 'Basic';
  OTPIndex: number = 0;
  OTPValid: boolean = false;
  defaultOTPMessage: string = 'In order to proceed, please enter the One Time Password (OTP) sent to your email and phone';
  OTPMessage: string = this.defaultOTPMessage;
  DefaultOTPRequestStats: any = {time: null, phone: null};
  OTPRequestStats: any = this.DefaultOTPRequestStats;

  // STK Popup
  paymentID: string = '';
  stkLoading: boolean = false;
  stkTimeout: boolean = false;
  stkMessage: string = '';
  requestingSTK: boolean = false;
  stkRequestID: string = '';
  stkAmount: string = '';
  stkPhone: string = '';
  stkMode: 0 | 1 = 0;
  stkDefPinText: string = 'Please check for the M-Pesa popup on your phone and input your M-Pesa PIN to complete payment';
  stkAltPinText: string = 'Please wait as we confirm the payment status';
  stkPinText: string = this.stkDefPinText;

  showResumePopup: boolean = false;
  resumeSessionExpired: boolean = false;

  session: any = null;

  storageKey: string = 'MileleData';

  storageObject: any = {
    activeTab: this.activeTab,
    trustID: this.TrustID,
    basicData: this.basicData,
    settlorType: this.settlorType,
    settlors: this.settlors,
    beneficiaries: this.beneficiaries,
    guardians: this.guardians,
    trustInfo: this.trustInfo,
    paymentData: this.paymentData,
    docSummary: this.docSummary,
    summary: this.summary,
    page1Done: this.page1Done,
    page2Done: this.page2Done,
    page3Done: this.page3Done,
    page4Done: this.page4Done,
    page5Done: this.page5Done,
    page6Done: this.page6Done,

    finalSharesRemaining: this.finalSharesRemaining,
    sharesRemaining: this.sharesRemaining,
    OTPRequestStats: this.OTPRequestStats,
    paymentID: this.paymentID,
    stkLoading: this.stkLoading,
    stkTimeout: this.stkTimeout,
    stkRequestID: this.stkRequestID,
    stkAmount: this.stkAltPinText,
    stkPhone: this.stkPhone,
    stkMode: this.stkMode
  };

  onlineStorageObject: any = null;

  async updateDataFromStorage(onlineObject: any = null): Promise<void> {
    let data = await this.getData(this.storageKey);
    if (onlineObject) data = onlineObject;
    
    if (data) {
      if (data.activeTab) this.activeTab = data.activeTab;

      if (data.trustID) this.TrustID = data.trustID.toString();
      if (data.basicData) this.basicData = data.basicData;
      if (data.settlorType) this.settlorType = data.settlorType;
      if (data.settlors) this.settlors = data.settlors;
      if (data.beneficiaries) this.beneficiaries = data.beneficiaries;
      if (data.guardians) this.guardians = data.guardians;
      if (data.trustInfo) this.trustInfo = data.trustInfo;
      if (data.paymentData) this.paymentData = data.paymentData;
      if (data.docSummary) this.docSummary = data.docSummary;
      if (data.summary) this.summary = data.summary;

      switch (true) {
        case this.activeTab == 2:
          this.page1Done = true;
          break;
        case this.activeTab == 3:
          this.page1Done = true;
          this.page2Done = true;
          break;
        case this.activeTab == 4:
          this.page1Done = true;
          this.page2Done = true;
          this.page3Done = true;
          break;
        case this.activeTab == 5:
          this.page1Done = true;
          this.page2Done = true;
          this.page3Done = true;
          this.page4Done = true;
          break;
        case this.activeTab == 6:
          this.page1Done = true;
          this.page2Done = true;
          this.page3Done = true;
          this.page4Done = true;
          this.page5Done = true;
          break;
        case this.activeTab == 7:
          this.page1Done = true;
          this.page2Done = true;
          this.page3Done = true;
          this.page4Done = true;
          this.page5Done = true;
          this.page6Done = true;
          break;
        default:
          this.page1Done = false;
          this.page2Done = false;
          this.page3Done = false;
          this.page4Done = false;
          this.page5Done = false;
          this.page6Done = false;
          break;
      }

      if (data.finalSharesRemaining) this.finalSharesRemaining = data.finalSharesRemaining;
      if (data.sharesRemaining) {
        this.sharesRemaining = data.sharesRemaining;
      }
      if (data.OTPRequestStats) this.OTPRequestStats = data.OTPRequestStats;

      if (data.paymentID) this.paymentID = data.paymentID;
      if (data.stkLoading) this.stkLoading = data.stkLoading;
      if (data.stkTimeout) this.stkTimeout = data.stkTimeout;
      if (data.stkRequestID) this.stkRequestID = data.stkRequestID;
      if (data.stkAmount) this.stkAmount = data.stkAmount;
      if (data.stkPhone) this.stkPhone = data.stkPhone;
      if (data.stkMode) this.stkMode = data.stkMode;
      
    }

    this.updateStorageObject();
    // console.log('Data retrieved ', data);
  }

  updateStorageObject(): void {
    this.storageObject = {
      activeTab: this.activeTab,

      trustID: this.TrustID,
      basicData: this.basicData,
      settlorType: this.settlorType,
      settlors: this.settlors,
      beneficiaries: this.beneficiaries,
      guardians: this.guardians,
      trustInfo: this.trustInfo,
      paymentData: this.paymentData,
      docSummary: this.docSummary,
      summary: this.summary,
      page1Done: this.page1Done,
      page2Done: this.page2Done,
      page3Done: this.page3Done,
      page4Done: this.page4Done,
      page5Done: this.page5Done,
      page6Done: this.page6Done,

      finalSharesRemaining: this.finalSharesRemaining,
      sharesRemaining: this.sharesRemaining,
      OTPRequestStats: this.OTPRequestStats,
      paymentID: this.paymentID,
      stkLoading: this.stkLoading,
      stkTimeout: this.stkTimeout,
      stkRequestID: this.stkRequestID,
      stkAmount: this.stkAmount,
      stkPhone: this.stkPhone,
      stkMode: this.stkMode
    }
  }

  resetLocalData(): void {
    this.activeTab = 1;
    this.TrustID = null;
    this.basicData = {};
    this.settlorType = 0;
    this.settlors = [];
    this.beneficiaries = [];
    this.guardians = [];
    this.trustInfo = {};
    this.paymentData = false;
    this.docSummary = [];
    this.summary = '';
    this.page1Done = false;
    this.page2Done = false;
    this.page3Done = false;
    this.page4Done = false;
    this.page5Done = false;
    this.page6Done = false;

    this.finalSharesRemaining = 100;
    this.sharesRemaining = 100;
    this.OTPRequestStats = this.DefaultOTPRequestStats;
    this.paymentID = '';
    this.notificationService.isLoading(false);
    this.stkLoading = false;
    this.stkTimeout = false;
    this.stkRequestID = '';
    this.stkAmount = '';
    this.stkPhone = '';
    this.stkMode = 0;
    this.loadingUpdate = false;

    this.updateStorageObject();
    this.clearData(this.storageKey);
  }

  // Data functions
  navHome(): void {
    const homePage = ``;
    this.router.navigate([homePage]);
  }
  async changeTab(tabNumber: number) {
    this.activeTab = tabNumber;
    this.updateStorageObject();
    await this.saveData(this.storageKey, this.storageObject);
  }
  async clearServiceRecords(): Promise<void> {
    await this.changeTab(1);
    this.updateFinalRemainingShares(100);
    this.resetLocalData();
  }

  async updateRecord(tabNumber: number, data: any) {
    if (tabNumber === 1) {
      this.basicData = data;
    } else if (tabNumber === 2) {
      this.settlors = data;
    } else if (tabNumber === 3) {
      this.beneficiaries = data;
    } else if (tabNumber === 4) {
      this.guardians = data;
    } else if (tabNumber === 5) {
      this.trustInfo = data;
    } else if (tabNumber === 6) {
      this.summary = data;
    } else if (tabNumber === 7) {
      this.paymentData = data;
    }

    this.updateStorageObject();

    const dat = this.storageObject;
    // Save Data locally
    await this.saveData(this.storageKey, dat);
  }

  async refreshRecord(tabNumber: number) {
    if (tabNumber === 1) {
      this.basicData = {};
      console.log('Basic Data refreshed ', this.basicData);
    } else if (tabNumber === 2) {
      this.settlors = [];
      console.log('Settlors refreshed ', this.settlors);
    } else if (tabNumber === 3) {
      this.beneficiaries = [];
      console.log('Beneficiaries refreshed ', this.beneficiaries);
    } else if (tabNumber === 4) {
      this.guardians = [];
      console.log('Guardians refreshed ', this.guardians);
    } else if (tabNumber === 5) {
      this.trustInfo = {};
      console.log('Trust Info refreshed ', this.trustInfo);
    } else if (tabNumber === 6) {
      this.summary = '';
      console.log('Summary refreshed ', this.summary);
    } else if (tabNumber === 7) {
      this.paymentData = false;
      console.log('Payment Data refreshed ', this.paymentData);
    }

    this.updateStorageObject();

    const dat = this.storageObject;
    // Save Data locally
    await this.saveData(this.storageKey, dat);
  }

  async removeRecord(category: string, recordID: number, trustID: number): Promise<number> {
    this.notificationService.isLoading(true);
    try {
      const request = {
        Category: category,
        ID: recordID,
        TrustID: trustID
      };

      const response = await this.apiService.postRequest(
        environment.baseUrl + Constants.deleteRecordURL, request);
        this.notificationService.isLoading(false);
        if (response.Status === 1) {
          return 1;
        } else {
          console.log('>>> Error !!', response.Message);
          return 0;
        }
    } catch (error) {
      console.log(':: Error !! ', error);
      this.notificationService.isLoading(false);
      return 0;
    }
  }
  updateDocSummary(newUrl: string) {
    try {
      const item = {
        Url: newUrl
      };
      this.docSummary.push(item);

    } catch (error) {
      console.log('DocSummary !!! ', error);
    }
  }

  updateRemainingShares(value: number) {
    try {
      this.sharesRemaining = this.finalSharesRemaining - value;
    } catch (error) {
      // console.log('-- Remaining shares error : ', error, ' :: Input : ', value);
      this.sharesRemaining = this.finalSharesRemaining - 0;
    }
    // console.log('-- Remaining shares : ', this.sharesRemaining);
  };

  revertRemainingShares(value: number) {
    try {
      this.sharesRemaining = this.finalSharesRemaining + value;
    } catch (error) {
      // console.log('++ Remaining shares error : ', error, ' :: Input : ', value);
      this.sharesRemaining = this.finalSharesRemaining + 0;
    }
    // console.log('++ Remaining shares : ', this.sharesRemaining, ', == Removed value : ', value);
  };

  updateFinalRemainingShares(value: number) {
    this.finalSharesRemaining = value;
    // console.log('Final shares up to date : ', this.finalSharesRemaining);
  };

  // Select Dropdown data
  // API
  TrustPurposeOptions: any = [];
  FundSourceOptions: any = [];
  UpkeepFrequencyOptions: any = [];
  BanksOptions: any = [];
  BankBranchOptions: any = [];
  InstructorOptions: any = [];
  PreferredOptions: any = [];

  loadingBizNature: boolean = false;
  BusinessNatureOptions: any = [];

  loadingTitles: boolean = false;
  TitleOptions: any = [];

  loadingCountries: boolean = false;
  CountryOptions: any = [];

  loadingCounties: boolean = false;
  CountyOptions: any = [];

  loadingIntermediaries: boolean = false;
  IntermediaryOptions: Intermediaries[] = [];

  // Local
  HearAboutusData: any = [
    'ICEA LION Trust Consultant',
    'ICEA LION Financial Advisor',
    'Independent Agent',
    'Broker',
    'Billboard',
    'Family/Friend',
    'Google Search',
    'ICEA LION Group website',
    'Media advert (TV or Radio)',
    'Newspapers',
    'Social Media (X, Facebook, LinkedIn, Youtube)',
    'Other'
  ];
  BeneficiaryOptions: any = [
    'Individual',
    'Institution'
  ];
  GuardianOptions: any = [
    'Principal Guardian',
    'Successor Guardian'
  ];
  GenderOptions: any = [
    'Male',
    'Female',
    'Other'
  ];
  RelationOptions: any = [
    'Self',
    'Consultant',
    'Financial Advisor',
    'Lawyer',

    'Parent',
    'Son',
    'Daughter',
    'Sibling',
    'Spouse',
    'Aunt',
    'Cousin',
    'Nephew',
    'Niece',
    'Uncle',
    'Other'
  ];
  RelationInstitutionOptions: any = [
    'Owner',
    'Other Professional'
  ];
  
  // Max / Min DOB picker Dates
  minDOBDate: Date;
  maxDOBDate: Date;

  // STK
  updateSTK(requestID: string, amount: string, phone: string): void {
    this.stkRequestID = requestID;
    this.stkAmount = amount;
    this.stkPhone = phone;
    // console.log('Mpesa activated... ', this.stkRequestID, ', ', this.stkAmount, ', ', this.stkPhone);
  }
  resetSTK(): void {
    this.stkTimeout = false;
    this.stkMessage = '';
  }
  closeSTK(): void {
    this.stkLoading = false;
    this.resetSTK();;
  }

  // M-PESA Payment
  async mpesaPayment(): Promise<void> {
    this.requestingSTK = true;

    try {
      let paymentId = `MIL-${this.settlors[0].Phone}-${truncateString(this.trustInfo.TrustName)}-${generateRandomNumber()}`.replace('+', '');

      if (this.settlorType == 1) {
        paymentId = `MIL-${this.settlors[0].IDNo}-${truncateString(this.trustInfo.TrustName)}-${generateRandomNumber()}`;
      }

      this.paymentID = paymentId;
      const data = {
        Amount: this.stkAmount,
        Phone: this.stkPhone,
        BillRefNo: this.paymentID,
        TrustName: this.trustInfo.TrustName,
      };

      let response;

      if (this.stkMode === 1) {
        this.stkPinText = this.stkAltPinText;
        response = {
          Status: 1,
          BillRefNo: this.stkRequestID
        };
        // console.log('M-pesa existing check ...');
        

      } else {
        this.stkPinText = this.stkDefPinText;
        this.notificationService.viewToast('info', 'Requesting M-Pesa Payment');
        response = await this.apiService.postRequest(
          environment.baseUrl + Constants.mpesaPaymentURL, data);
      }

      if (response.Status === 1) {
        this.resetSTK();
        this.requestingSTK = false;

        this.stkRequestID = response.BillRefNo;

        if (this.stkMode === 0) {
          await delay(15000);
        }

        await this.retryMpesaPaymentConfirmation(this.stkRequestID)
        .then(async (result) => {
          console.log('Final Result:', result);

          if (result.Status === 1) {
            this.notificationService.viewToast('success', 'Payment received');

            this.closeSTK();
            if (this.activeTab === 7) {
              await this.changeTab(8);
            }
            
          } else if (result.Status === 2) {
            this.notificationService.viewToast('error', 'Could not confirm your payment');
            this.stkTimeout = true;
            
          } else {
            // Error occurred!!
            this.notificationService.viewToast('error', result.Message);
            this.stkMessage = 'Oops! Looks like an error occurred. Did you make the payment?';
            this.stkTimeout = true;
          }
          
        })
        .catch((error) => {
          console.error('Error:', error);
          this.notificationService.viewToast('error', 'An unexpected error occurred');
          this.stkMessage = 'Sorry, an unexpected error occurreed';
          this.stkTimeout = true;
          
        }); 
      } else {
        console.log('>>> Error !!', response.Message)
        this.stkMessage = response.Message;
        this.notificationService.viewToast('error', response.Message);
      }
    } catch (error) {
      console.log(':: Error !! ', error);
      this.notificationService.viewToast('error', 'A fatal error occurred');
    }
    this.requestingSTK = false;
  }

  async retryMpesaPaymentConfirmation(paymentID: string): Promise<any> {
    this.resetSTK();

    const maxAttempts = 5;
    let attempts = 0;

    const confirmMpesaPayment = async (): Promise<any> => {
      try {
        const data = {
          BillRefNo: paymentID,
          TrustName: this.trustInfo.TrustName,
          Name: this.basicData.FirstName,
          Email: this.basicData.Email,
          Phone: this.stkPhone,
          Summary: this.summary,
          Docs: this.docSummary,
          TrustID: this.TrustID
        };
  
        const response = await this.apiService.postRequest(
          environment.baseUrl + Constants.mpesaConfirmURL, data);
  
        // console.log(':::: RESPONSE !!!!\n', response);
  
        if (response && response.Status) {
          return response;
        } else {
          return {
            Status: 0,
            Message: 'Error contacting server'
          };
        }
        
      } catch (error) {
        let resp = {
          Status: 0,
          Message: 'An unexpected error occurred'
        }
        console.log(':: Error !! ', error);
  
        return resp;
      }
    }
  
    async function tryConfirmation() {
      attempts++;
      console.log(`Attempt ${attempts}`);
  
      const response = await confirmMpesaPayment();
  
      if (response.Status === 1) {
        console.log('Payment confirmed successfully');
        return response;
        
      } else if (response.Status === 2) {
        if (attempts < maxAttempts) {
          await delay(15000); // Wait for 12 seconds (4000 milliseconds)
          return tryConfirmation();
        } else {
          // console.log('Max retry attempts reached');
          return {
            Status: 2,
            Message: 'Max retry attempts reached'
          };
        }
        
      } else {
        // console.log('Max retry attempts reached');
        return {
          Status: 0,
          Message: response.Message
        };
      }
    }
  
    return tryConfirmation();
  }


  // Select data API functions
  async getBanksList() {
    try {
      const response = await this.apiService.getRequest(
        environment.baseUrl + Constants.banksURL
      );
  
      if (response.Status === 1) {
        const sortedData = response.Data.sort((a: any, b: any) => {
          return a.Description.localeCompare(b.Description);
        });
        this.BanksOptions = sortedData;
        return 1;
      } else {
        console.error('Banks !! ', response.Message);
        this.notificationService.viewToast('error', 'The page loaded with errors', 'Try reloading the page');
        return 0;
      }
    } catch(error) {
      console.error('Banks >> ', error);
      this.notificationService.viewToast('error', 'The page loaded with errors', 'Try reloading the page');
      return 0;
    }
  }

  async getTitlesList() {
    try {
      const response = await this.apiService.getRequest(
        environment.baseUrl + Constants.salutationsURL
      );
  
      if (response.Status === 1) {
        this.TitleOptions = this.customSortTitles(response.Data);
        return 1;
      } else {
        console.log('>>> Error !! ', response.Message);
        this.notificationService.viewToast('error', 'The page loaded with errors', 'Try reloading the page');
        return 0;
      }
    } catch(error) {
      console.log('>>> Error !! ', error);
      this.notificationService.viewToast('error', 'The page loaded with errors', 'Try reloading the page');
      return 0;
    }
  }
  customSortTitles(titles: TitleObject[]): TitleObject[] {
    const priorityOrder = ["Mr.", "Mrs.", "Ms.", "Miss", "Dr.", "Prof.", "Master"];
    
    return titles.sort((a, b) => {
      const indexA = priorityOrder.indexOf(a.Title);
      const indexB = priorityOrder.indexOf(b.Title);
      
      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      }
      if (indexA !== -1) {
        return -1;
      }
      if (indexB !== -1) {
        return 1;
      }
      return a.Title.localeCompare(b.Title);
    });
  }

  async getCountryList() {
    try {
      const response = await this.apiService.getRequest(
        environment.baseUrl + Constants.countriesURL
      );
  
      if (response.Status === 1) {
        this.CountryOptions = response.Data;
        return 1;
      } else {
        console.log('>>> Countries Error : ', response.Message);
        this.notificationService.viewToast('error', 'The page loaded with errors', 'Try reloading the page');
        return 0;
      }
    } catch(error) {
      console.log('>>> Countries Error !! ', error);
      this.notificationService.viewToast('error', 'The page loaded with errors', 'Try reloading the page');
      return 0;
    }
  }

  async getCountyList() {
    try {
      const response = await this.apiService.getRequest(
        environment.baseUrl + Constants.countiesURL
      );
  
      if (response.Status === 1) {
        this.CountyOptions = response.Data;
        return 1;
      } else {
        console.log('>>> Counties Error : ', response.Message);
        this.notificationService.viewToast('error', 'The page loaded with errors', 'Try reloading the page');
        return 0;
      }
    } catch(error) {
      console.log('>>> Counties Error !! ', error);
      this.notificationService.viewToast('error', 'The page loaded with errors', 'Try reloading the page');
      return 0;
    }
  }

  async getBusinessNatOptionsList() {
    try {
      const response = await this.apiService.getRequest(
        environment.baseUrl + Constants.businessNatureURL
      );

      if (response.Status === 1) {
        this.BusinessNatureOptions = response.Data;
        return 1;
      } else {
        console.log('>>> Biz Nature Error : ', response.Message);
        this.notificationService.viewToast('error', 'The page loaded with errors', 'Try reloading the page');
        return 0;
      }
    } catch(error) {
      console.log('>>> Biz Nature Error !! ', error);
      this.notificationService.viewToast('error', 'The page loaded with errors', 'Try reloading the page');
      return 0;
    }
  }

  async getConsultants() {
    try {
      this.loadingIntermediaries = true;

      const response = await this.apiService.getRequest(
        environment.baseUrl + Constants.consultantsURL
      );
  
      if (response.Status === 1) {
        this.IntermediaryOptions = response.Data;
        this.loadingIntermediaries = false;
        return 1;
      } else {
        console.log('>>> Consultants Error : ', response.Message);
        this.notificationService.viewToast('error', 'The page loaded with errors', 'Try reloading the page');
        this.loadingIntermediaries = false;
        return 0;
      }
    } catch(error) {
      console.log('>>> Consultants Error !! ', error);
      this.notificationService.viewToast('error', 'The page loaded with errors', 'Try reloading the page');
      this.loadingIntermediaries = false;
      return 0;
    }
    
  }

  async getIntermediaries(type: string) {
    try {
      this.loadingIntermediaries = true;

      const response = await this.apiService.getRequest(
        environment.baseUrl + Constants.intermediaryURL + `t=${type}`
      );
  
      if (response.Status === 1) {
        this.IntermediaryOptions = response.Data;
        this.loadingIntermediaries = false;
        return 1;
      } else {
        console.log('>>> Intermediaries Error : ', response.Message);
        this.notificationService.viewToast('error', 'The page loaded with errors', 'Try reloading the page');
        this.loadingIntermediaries = false;
        return 0;
      }
    } catch(error) {
      console.log('>>> Intermediaries Error !! ', error);
      this.notificationService.viewToast('error', 'The page loaded with errors', 'Try reloading the page');
      this.loadingIntermediaries = false;
      return 0;
    }
  }

  /// Storage
  async getData(storageKey: string): Promise<any> {
    const data = await localforage.getItem(storageKey);
    return data ? data : null;
  }

  async saveData(storageKey: string, data: any): Promise<void> {
    await localforage.setItem(storageKey, data);
    // console.log('saved\n', await this.getData(storageKey));
  }

  async clearData(storageKey: string): Promise<void> {
    await localforage.removeItem(storageKey);
  }

  loadingUpdate: boolean = false;
  
  saveProgress = async(): Promise<void> => {
    this.loadingUpdate = true;
    this.updateStorageObject();
    await this.saveData(this.storageKey, this.storageObject);

    try {
      let formattedData = this.stripSavedObjects(this.storageObject);
      let otp = generateRandomNumber();
      let expiry = getCurrentTimePlus24HoursInMilliseconds();
      const currentTime = getCurrentTime();
      
      if (this.onlineStorageObject) {
        this.notificationService.viewToast('info', 'Updating your progress at ' +currentTime);

        otp = this.onlineStorageObject.OTP;
        expiry = this.onlineStorageObject.Expiry;
      } else {
        this.notificationService.viewToast('info', 'Saving your progress at ' +currentTime);
      }
      
      formattedData = {...formattedData, ...{OTP: otp, Expiry: expiry}}
      if (formattedData == null) {
        return;
      }
      const csvData = this.convertObjectToCSV(formattedData);

      let ccEmails: any = [];

      if (this.settlors && this.settlors.length > 0) {
        if (this.settlorType === 0) {
          if (this.settlors.Authorisers && this.settlors.Authorisers.length > 0) {
            for (let i=0; i<this.settlors.Authorisers.length; i++) {
              const item = {
                FirstName: this.settlors.Authorisers[i].FirstName,
                Email: this.settlors.Authorisers[i].Email
              };

              ccEmails.push(item);
            }
          }
        
        } else {  // Remove this section if normal settlors should not receive this email
          for (let i=1; i<this.settlors.length; i++) {
            const item = {
              FirstName: this.settlors[i].FirstName,
              Email: this.settlors[i].Email
            };

            ccEmails.push(item);
          }
        }   
      }

      let data = {
        FirstName: this.basicData.FirstName,
        Email: this.basicData.Email,
        Phone: this.basicData.Phone,
        TrustID: this.TrustID?.toString(),
        OTP: otp,
        Expiry: expiry,
        Data: csvData,  
        CCEmails: ccEmails
      };

      if (this.session) {
        data = {...data, ...{Session: this.session}};
      }
      //console.log('dgsabcsdkj', csvData);

      const response = await this.apiService.postRequest(
        environment.baseUrl + Constants.continueLaterURL, data);
      // Update local objects
      if (response.Status === 1) {
        this.notificationService.viewToast('success', `Progress Saved at ${currentTime}`, `Check your email ${this.basicData.Email} for how to get back in`);

      } else if (response.Status === 2) {
        this.notificationService.viewToast('success', `Progress Updated at ${currentTime}`, `Check your email ${this.basicData.Email} for how to get back in`);

      } else {
        console.log('>>> Error !! ', response.Message);
        this.notificationService.viewToast('error', 'Failed to save your progress', 'Your details are is still saved in this computer')
      }

    } catch (error) {
      console.log(':: Error !! ', error);
      this.notificationService.viewToast('error', 'An error occurred', 'Your details are is still saved in this computer')
    }
    this.loadingUpdate = false;
  }

  // Post to server
  async checkProgress(session: string): Promise<void> {
    try {
      const data = {
        Session: session,
      };

      const response = await this.apiService.postRequest(
        environment.baseUrl + Constants.continueNowURL, data);

        if (response.Status === 1) {

          const result = await this.retrieveProgress(response.Message);

          if (result && result.Expiry) {
            const now = new Date().getTime();
            if (now <= result.Expiry) {
              this.onlineStorageObject = result;
              this.showResumePopup = true;
            } else {
              this.notificationService.viewToast('error', 'Session invalid or expired');
            }
          }
        } else {
          console.log('>>> Progress Error !! ', response.Message);
          this.notificationService.viewToast('error', 'Session invalid or expired');
        }
    } catch (error) {
      console.log('Check progress error : ', error);
    }
  }

  async retrieveProgress(csvURL: string): Promise<any> {
    try {
      const csvData = await this.apiService.getFile(csvURL);
      // console.log('CSV DATA: ', csvData);

      // Convert CSV data back to the object
      const object = this.convertCSVToObject(csvData);
      return object;

    } catch (error) {
      console.log('Retrieve progress error : ', error);
      return null;
    }
  }

  stripSavedObjects(originalObj: any): any {
    try {
      let obj = originalObj;
      
      if (obj && obj.settlors && obj.settlors.length > 0) {
        for (let i = 0; i < obj.settlors.length; i++) {
          obj.settlors[i] = this.stripImages(obj.settlors[i]);
        }
      }
      if (obj && obj.beneficiaries && obj.beneficiaries.length > 0) {
        for (let i = 0; i < obj.beneficiaries.length; i++) {
          obj.beneficiaries[i] = this.stripImages(obj.beneficiaries[i]);
        }
      }
      if (obj && obj.guardians && obj.guardians.length > 0) {
        for (let i = 0; i < obj.guardians.length; i++) {
          obj.guardians[i] = this.stripImages(obj.guardians[i]);
        }
      }
      if (obj && obj.trustees && obj.trustees.length > 0) {
        for (let i = 0; i < obj.trustees.length; i++) {
          obj.trustees[i] = this.stripImages(obj.trustees[i]);
        }
      }
      if (obj && obj.enforcer) {
        obj.enforcer = this.stripImages(obj.enforcer);
      }
      // console.log('Cleaned free of images:\n', obj);
      return obj;
    } catch (error) {
      console.error('ERROR Strip objects : ', error);
      return null;
    }
  }

  stripImages(obj: any): any {
    if (obj.IDURL) {
      obj.IDNoImage = "";
    }
    if (obj.KraURL) {
      obj.KraPinImage = "";
    }
    if (obj.BirthCertURL) {
      obj.BirthCertImage = "";
    }
    if (obj.RegCertURL) {
      obj.RegCertImage = "";
    }
    if (obj.PassportURL) {
      obj.PassportURL = "";
    }
    if (obj.CvURL) {
      obj.CvURL = "";
    }
    if (obj.Principal && obj.Principal.DeathCertURL) {
      obj.Principal.DeathCertImage = "";
    }

    return obj;
  } 

  // CSV Handlers
  convertObjectToCSV(obj: any): string {
    const csvArray: string[] = [];

    function flattenObject(object: any, prefix = '') {  
      for (const key in object) {  
        if (object.hasOwnProperty(key)) {  

          const prefixedKey = prefix + key;
                
          if (Array.isArray(object[key])) {        
            if (object[key].length === 0) {        
              csvArray.push(`${prefixedKey},[]`);    
            } else {          
              object[key].forEach((item: any, index: number) => {            
                flattenObject(item, `${prefixedKey}[${index}]_`);          
              });        
            }      
          } else if (typeof object[key] === 'object' && object[key] !== null) {     

            if (Object.keys(object[key]).length === 0) {
              csvArray.push(`${prefixedKey},{}`);
            } else {
              flattenObject(object[key], prefixedKey + '_');
            }
          } else {

            if (object[key] === null) {
              csvArray.push(`${prefixedKey},null`);
            } else if (object[key] === '') {
              csvArray.push(`${prefixedKey},""`);
            } else {
              csvArray.push(`${prefixedKey},${object[key]}`);
            }
          } 
        } 
      }
    }

    flattenObject(obj);

    return csvArray.join('\n');
  }

  convertCSVToObject(csv: string): any {
    const obj: any = {};

    const lines = csv.split('\n');
    lines.forEach(line => {
        const [key, value] = line.split(',');
        const keys = key.split('_');
        let currentObj = obj;

        for (let i = 0; i < keys.length; i++) {
            const k = keys[i];
            const isArrayElement = /\[\d+\]$/.test(k); // Check if the key represents an array element
            const isArray = /\[\]$/.test(k); // Check if the key represents an array

            if (isArrayElement) {
                const arrayKey = k.substring(0, k.indexOf('[')); // Extract the array key
                const index = parseInt(k.match(/\d+/)![0], 10); // Extract the array index

                if (!currentObj[arrayKey]) {
                    currentObj[arrayKey] = [];
                }

                if (i === keys.length - 1) {
                    currentObj[arrayKey][index] = this.parseValue(value);
                } else {
                    if (!currentObj[arrayKey][index]) {
                        currentObj[arrayKey][index] = {};
                    }
                }

                currentObj = currentObj[arrayKey][index];
            } else if (isArray) {
                const arrayKey = k.substring(0, k.indexOf('[')); // Extract the array key

                if (!currentObj[arrayKey]) {
                    currentObj[arrayKey] = [];
                }

                if (i === keys.length - 1) {
                    currentObj[arrayKey].push(this.parseValue(value));
                } else {
                    if (!currentObj[arrayKey][currentObj[arrayKey].length - 1]) {
                        currentObj[arrayKey].push({});
                    }
                }

                currentObj = currentObj[arrayKey][currentObj[arrayKey].length - 1];

            } else {
                if (!currentObj[k]) {
                    if (i === keys.length - 1) {
                        currentObj[k] = this.parseValue(value);
                        // Check if the key is 'Phone' and the value needs to be a string
                        if (k === 'Phone' && typeof currentObj[k] !== 'string') {
                            currentObj[k] = String(currentObj[k]);
                        }
                    } else {
                        currentObj[k] = {};
                    }
                }
                currentObj = currentObj[k];
            }
        }
    });

    // console.log('CSV Object: ', obj);

    return obj;
  }

  parseValue(value: string): any {
    switch (value) {
      case 'null':
        return null;
      case '""':
        return '';
      case '{}':
        return {};
      case '[]':
        return [];
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return isNaN(Number(value)) ? value : Number(value);
  
    }
  }

  // Zoho chat widget
  // initializeSalesIQ() {
  //   // Check if $zoho object is available
  //   if (typeof $zoho !== 'undefined' && $zoho.salesiq) {
  //     $zoho.salesiq.ready = () => {
  //       // You can perform additional initialization here
  //       // For example, setting user details or custom values
  //     };
  //   }
  // }

  constructor(private apiService: ApiService, private router: Router, private notificationService: NotificationService){
    const currentDate = new Date();
    const tempDate = new Date(currentDate.getTime());

    tempDate.setFullYear(currentDate.getFullYear() - 100);
    this.minDOBDate = tempDate;
    this.maxDOBDate = currentDate;
  }
}
