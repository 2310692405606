<form [formGroup]="assetForm" (ngSubmit)="navigateFunction()" class="tab-form" autocomplete="off" novalidate>
    <div class="form-80" [ngClass]="{'width-100 ' : !showBottomButtons}">
        <h1 class="blue margin" *ngIf="showBottomButtons">{{ formTitle }}</h1>
        <p class="lead rocal" *ngIf="showBottomButtons">
            {{formSubTitle}}
        </p>

        <div class="flex blue-box">
            <p class="blue lead" style="font-size: larger;">
                {{ 'Your Assets' }}
            </p>
            <div class="line-2"></div>

            <!-- PROPERTY -->
            <div class="flex column wide-bar" style="margin-top: 20px; font-size: medium; width: 100%;">
                <mat-checkbox formControlName="option1" (change)="toggleOption(1, $event)">
                    <p style="font-size: larger; display: flex;">
                        <strong>{{propertyTitle}}</strong>
                    </p>
                </mat-checkbox>

                <form [formGroup]="propertyForm" *ngIf="assetForm.get('option1')?.value"  (ngSubmit)="submitForm(1, propertyTitle)" autocomplete="off" novalidate>
                    <div class="disclaimer">
                        <div class="content">
                            Enter the asset details below and click save. After saving, you can add another asset by clicking the Add Another Asset button.
                        </div>
                    </div>

                    <app-asset-tables *ngIf="properties.length > 0"
                        [tableType]="1"
                        [data]="properties"
                        [tableTitle]="'Your Real Estate Assets'"

                        [editAsset]="updateAsset"
                        [deleteAsset]="deleteAsset"
                    ></app-asset-tables>

                    <div *ngIf="showPropertyForm">
                        <div class="line" style="margin-bottom: 15px; margin-top: 15px;"></div>

                        <div class="row">
                            <app-input [formGroup]="propertyForm" class="grid-item"
                                controlName="Location" label="Location"  hint="Location"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            <app-input [formGroup]="propertyForm" class="grid-item"
                                controlName="TitleNo" label="Title/LR No." hint="Enter Title/LR No."
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        </div>
    
                        <div class="row">
                            <app-input [formGroup]="propertyForm" controlName="Mortgage"
                                label="Mortgage"  hint="Select one"
                                inputType="select"
                                [ArrayItems]="ConfirmOptions"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            <app-input [formGroup]="propertyForm" class="grid-item"
                                controlName="Value" label="Estimated Market Value" hint="Enter estimated market value"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        </div>

                        <div *ngIf="propertyForm.controls['Mortgage'].value?.toLowerCase() == 'yes'" style="padding-left: 2%; padding-right: 2%;">
                            <p class="blue lead" style="font-size: larger;">
                                {{ 'Mortgaged Details' }}
                            </p>
                            <div class="line-2"></div>

                            <div class="row">
                                <app-input [formGroup]="propertyForm" class="grid-item"
                                    controlName="FinancierName" label="Financier Name" hint="Enter financier name"
                                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                <app-input [formGroup]="propertyForm" controlName="FinancierType"
                                    label="Financier Type"  hint="Select one"
                                    inputType="select"
                                    [ArrayItems]="FinancierOptions"
                                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            </div>

                            <div class="row half"
                                *ngIf="propertyForm.controls['FinancierType'].value?.toLowerCase() == 'other (specify)'">
                                <app-input [formGroup]="propertyForm" class="grid-item" controlName="Other"
                                    type="area"
                                    label="Other Financier Details"
                                    hint="Provide more details"
                                    [validationMessages]="validationMessages" [formErrors]="formErrors">
                                </app-input>
                            </div>
                        </div>

                        <div class="line" style="margin-bottom: 15px;"></div>

                        <!-- SAVE / CANCEL BUTTTON -->
                        <div class="flex wide-bar" style="margin-bottom: 25px; margin-right: 5%;">
                            <button mat-button
                                [disabled]="loading || !propertyForm.valid"
                                class="btn-primary next-button" >
                                <span class="button-text">
                                    {{ 'Save' }}
                                </span>
                            </button>

                            <button mat-button type="button" (click)="addProperty()"
                                class="btn-primary next-button red" >
                                <span class="button-text">
                                    {{ 'Cancel' }}
                                </span>
                            </button>
                        </div>
                    </div>
    
                    <div class="flex column wide-bar" style="margin-bottom: 10px;" *ngIf="!showPropertyForm">
                        <!-- BUTTTON Add Asset -->
                        <div class="blueStroke row">
                            <button mat-stroked-button
                                [disabled]="loading"
                                class="general-stroked-button" type="button" (click)="addProperty()">
                                <span class="button-text">
                                    {{ properties.length > 0 ? '+ Add Another Asset' : '+ Add Asset' }}
                                </span>
                            </button>
                        </div>
                        <div class="line" style="margin-top: 20px;"></div>
                    </div>
                </form>
            </div>

            <!-- INVESTMENT -->
            <div class="flex column wide-bar" style="margin-top: 5px; font-size: medium; width: 100%;">
                <mat-checkbox formControlName="option2" (change)="toggleOption(2, $event)">
                    <p style="font-size: larger; display: flex;">
                        <strong>{{investmentTitle}}</strong>
                    </p>
                </mat-checkbox>

                <form [formGroup]="investmentForm" *ngIf="assetForm.get('option2')?.value"  (ngSubmit)="submitForm(2, investmentTitle)" autocomplete="off" novalidate>
                    <div class="disclaimer">
                        <div class="content">
                            Enter the asset details below and click save. After saving, you can add another asset by clicking the Add Another Asset button.
                        </div>
                    </div>

                    <app-asset-tables *ngIf="investments.length > 0"
                        [tableType]="2"
                        [data]="investments"
                        [tableTitle]="'Your Investments'"

                        [editAsset]="updateAsset"
                        [deleteAsset]="deleteAsset"
                    ></app-asset-tables>

                    <div *ngIf="showInvestmentForm">
                        <div class="line" style="margin-bottom: 15px; margin-top: 15px;"></div>

                        <div class="row">
                            <app-input [formGroup]="investmentForm" class="grid-item"
                                controlName="Institution" label="Financial Institution"  hint="Enter Financial Institution"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            <app-input [formGroup]="investmentForm" class="grid-item"
                                controlName="AccountType" label="Account Type" hint="Select Account Type"
                                inputType="select"
                                [ArrayItems]="InvestmentAccountOptions"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        </div>

                        <div class="row">
                            <app-input [formGroup]="investmentForm" controlName="Title"
                                label="Description"  hint="Describe the investment"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            <app-input [formGroup]="investmentForm" class="grid-item"
                                controlName="Value" label="Market Value" hint="Enter market value"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        </div>

                        <div class="line" style="margin-bottom: 15px;"></div>

                        <!-- SAVE / CANCEL BUTTTON -->
                        <div class="flex wide-bar" style="margin-bottom: 25px; margin-right: 5%;">
                            <button mat-button
                                [disabled]="loading || !investmentForm.valid"
                                class="btn-primary next-button" >
                                <span class="button-text">
                                    {{ 'Save' }}
                                </span>
                            </button>

                            <button mat-button type="button" (click)="addInvestment()"
                                class="btn-primary next-button red" >
                                <span class="button-text">
                                    {{ 'Cancel' }}
                                </span>
                            </button>
                        </div>
                    </div>

                    <div class="flex column wide-bar" style="margin-bottom: 10px;" *ngIf="!showInvestmentForm">
                        <!-- BUTTTON Add Asset -->
                        <div class="blueStroke row">
                            <button mat-stroked-button
                                [disabled]="loading"
                                class="general-stroked-button" type="button" (click)="addInvestment()">
                                <span class="button-text">
                                    {{ investments.length > 0 ? '+ Add Another Investment' : '+ Add Investment' }}
                                </span>
                            </button>
                        </div>
                        <div class="line" style="margin-top: 20px;"></div>
                    </div>
                </form>
            </div>

            <!-- INSURANCE -->
            <div class="flex column wide-bar" style="margin-top: 5px; font-size: medium; width: 100%;">
                <mat-checkbox formControlName="option3" (change)="toggleOption(3, $event)" >
                    <p style="font-size: larger; display: flex;">
                        <strong>{{policyTitle}}</strong>
                    </p>
                </mat-checkbox>

                <form [formGroup]="insuranceForm" *ngIf="assetForm.get('option3')?.value"  (ngSubmit)="submitForm(3, policyTitle)" autocomplete="off" novalidate>
                    <div class="disclaimer">
                        <div class="content">
                            Enter the policy/annuity details below and click save. After saving, you can add another asset by clicking the Add Another Policy button.
                        </div>
                    </div>

                    <app-asset-tables *ngIf="policies.length > 0"
                        [tableType]="3"
                        [data]="policies"
                        [tableTitle]="'Your Life Policies and Annuities'"

                        [editAsset]="updateAsset"
                        [deleteAsset]="deleteAsset"
                    ></app-asset-tables>

                    <div *ngIf="showPolicyForm">
                        <div class="line" style="margin-bottom: 15px; margin-top: 15px;"></div>

                        <div class="row">
                            <app-input [formGroup]="insuranceForm" class="grid-item"
                                controlName="Institution" label="Financial Institution/Type"  hint="Enter Financial Institution/Type"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            <app-input [formGroup]="insuranceForm" class="grid-item"
                                controlName="Policy" label="Policy/Annuity" hint="Enter Policy/Annuity"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        </div>

                        <div class="row">
                            <app-input [formGroup]="insuranceForm" controlName="Beneficiary"
                                label="Beneficiary Details"  hint="Enter Beneficiary Details"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            <app-input [formGroup]="insuranceForm" class="grid-item"
                                controlName="SumAssured" label="Sum Assured" hint="Enter Sum Assured"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        </div>

                        <div class="row half">
                            <app-input [formGroup]="insuranceForm" controlName="Maturity"
                                class="grid-item"
                                label="Maturity/Expiry Date"
                                hint="Enter Maturity/Expiry Date"
                                [minDate]="minDate"
                                inputType="date"
                                [validationMessages]="validationMessages"
                                [formErrors]="formErrors"></app-input>
                        </div>
                        <div class="line" style="margin-bottom: 15px;"></div>

                        <!-- SAVE / CANCEL BUTTTON -->
                        <div class="flex wide-bar" style="margin-bottom: 25px; margin-right: 5%;">
                            <button mat-button
                                [disabled]="loading || !insuranceForm.valid"
                                class="btn-primary next-button" >
                                <span class="button-text">
                                    {{ 'Save' }}
                                </span>
                            </button>

                            <button mat-button type="button" (click)="addPolicy()"
                                class="btn-primary next-button red" >
                                <span class="button-text">
                                    {{ 'Cancel' }}
                                </span>
                            </button>
                        </div>
                    </div>

                    <div class="flex column wide-bar" style="margin-bottom: 10px;" *ngIf="!showPolicyForm">
                        <!-- BUTTTON Add Policy -->
                        <div class="blueStroke row">
                            <button mat-stroked-button
                                [disabled]="loading"
                                class="general-stroked-button" type="button" (click)="addPolicy()">
                                <span class="button-text">
                                    {{ policies.length > 0 ? '+ Add Another Policy' : '+ Add Policy' }}
                                </span>
                            </button>
                        </div>
                        <div class="line" style="margin-top: 20px;"></div>
                    </div>
                </form>
            </div>

            <!-- BENEFITS -->
            <div class="flex column wide-bar" style="margin-top: 5px; font-size: medium; width: 100%;">
                <mat-checkbox formControlName="option4" (change)="toggleOption(4, $event)" >
                    <p style="font-size: larger; display: flex;">
                        <strong>{{benefitsTitle}}</strong>
                    </p>
                </mat-checkbox>

                <form [formGroup]="benefitsForm" *ngIf="assetForm.get('option4')?.value"  (ngSubmit)="submitForm(4, benefitsTitle)" autocomplete="off" novalidate>
                    <div class="disclaimer">
                        <div class="content">
                            Enter the pension details below and click save. After saving, you can add another asset by clicking the Add Another Pension button.
                        </div>
                    </div>

                    <app-asset-tables *ngIf="benefits.length > 0"
                        [tableType]="4"
                        [data]="benefits"
                        [tableTitle]="'Your Pensions'"

                        [editAsset]="updateAsset"
                        [deleteAsset]="deleteAsset"
                    ></app-asset-tables>

                    <div *ngIf="showBenefitForm">
                        <div class="line" style="margin-bottom: 15px; margin-top: 15px;"></div>

                        <div class="row">
                            <app-input [formGroup]="benefitsForm" class="grid-item"
                                controlName="Institution" label="Financial Institution/Type"  hint="Enter Financial Institution/Type"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            <app-input [formGroup]="benefitsForm" class="grid-item"
                                controlName="AccountType" label="Account Type" hint="Select Account Type"
                                inputType="select"
                                [ArrayItems]="RetirementBenefitsOptions"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        </div>

                        <div class="row">
                            <app-input [formGroup]="benefitsForm" controlName="Value"
                                label="Fund Value"  hint="Enter Fund Value"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>

                            <app-input [formGroup]="benefitsForm" controlName="RetirementDate"
                                class="grid-item"
                                label="Date of Retirement"
                                hint="01/01/2030"
                                [minDate]="minDate"
                                inputType="date"
                                [validationMessages]="validationMessages"
                                [formErrors]="formErrors"></app-input>
                        </div>
                        
                        <div class="line" style="margin-bottom: 15px;"></div>

                        <!-- SAVE / CANCEL BUTTTON -->
                        <div class="flex wide-bar" style="margin-bottom: 25px; margin-right: 5%;">
                            <button mat-button
                                [disabled]="loading || !benefitsForm.valid"
                                class="btn-primary next-button" >
                                <span class="button-text">
                                    {{ 'Save' }}
                                </span>
                            </button>

                            <button mat-button type="button" (click)="addBenefit()"
                                class="btn-primary next-button red" >
                                <span class="button-text">
                                    {{ 'Cancel' }}
                                </span>
                            </button>
                        </div>
                    </div>

                    <div class="flex column wide-bar" style="margin-bottom: 10px;" *ngIf="!showBenefitForm">
                        <!-- BUTTTON Add Benefit -->
                        <div class="blueStroke row">
                            <button mat-stroked-button
                                [disabled]="loading"
                                class="general-stroked-button" type="button" (click)="addBenefit()">
                                <span class="button-text">
                                    {{ benefits.length > 0 ? '+ Add Another Pension' : '+ Add Pension' }}
                                </span>
                            </button>
                        </div>
                        <div class="line" style="margin-top: 20px;"></div>
                    </div>
                </form>
            </div>

            <!-- OTHER ASSETS -->
            <div class="flex column wide-bar" style="margin-top: 5px; font-size: medium; width: 100%;">
                <mat-checkbox formControlName="option5" (change)="toggleOption(5, $event)" >
                    <p style="font-size: larger; display: flex;">
                        <strong>
                            {{assetsTitle}}
                        </strong>
                    </p>
                </mat-checkbox>

                <form [formGroup]="otherAssetsForm" *ngIf="assetForm.get('option5')?.value"  (ngSubmit)="submitForm(5, assetsTitle)" autocomplete="off" novalidate>
                    <div class="disclaimer">
                        <div class="content">
                            Enter the asset details below and click save. After saving, you can add another asset by clicking the Add Another Asset button.
                        </div>
                    </div>

                    <app-asset-tables *ngIf="otherAssets.length > 0"
                        [tableType]="5"
                        [data]="otherAssets"
                        [tableTitle]="'Your Assets'"

                        [editAsset]="updateAsset"
                        [deleteAsset]="deleteAsset"
                    ></app-asset-tables>

                    <div *ngIf="showAssetForm">
                        <div class="line" style="margin-bottom: 15px; margin-top: 15px;"></div>

                        <div class="row">
                            <app-input [formGroup]="otherAssetsForm" class="grid-item"
                                controlName="Ownership" label="Ownership" hint="Select one"
                                inputType="select"
                                [ArrayItems]="OwnershipOptions"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            <app-input [formGroup]="otherAssetsForm" class="grid-item"
                                controlName="Value" label="Market Value" hint="Enter market value"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        </div>

                        <div class="row">
                            <app-input [formGroup]="otherAssetsForm" controlName="Description"
                                inputType="area"
                                label="Description"  hint="Describe the asset"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        </div>
                        
                        <div class="line" style="margin-bottom: 15px;"></div>

                        <!-- SAVE / CANCEL BUTTTON -->
                        <div class="flex wide-bar" style="margin-bottom: 25px; margin-right: 5%;">
                            <button mat-button
                                [disabled]="loading || !otherAssetsForm.valid"
                                class="btn-primary next-button" >
                                <span class="button-text">
                                    {{ 'Save' }}
                                </span>
                            </button>

                            <button mat-button type="button" (click)="addAssets()"
                                class="btn-primary next-button red" >
                                <span class="button-text">
                                    {{ 'Cancel' }}
                                </span>
                            </button>
                        </div>
                    </div>

                    <div class="flex column wide-bar" style="margin-bottom: 10px;" *ngIf="!showAssetForm">
                        <!-- BUTTTON Add Asset -->
                        <div class="blueStroke row">
                            <button mat-stroked-button
                                [disabled]="loading"
                                class="general-stroked-button" type="button" (click)="addAssets()">
                                <span class="button-text">
                                    {{ otherAssets.length > 0 ? '+ Add Another Asset' : '+ Add Asset' }}
                                </span>
                            </button>
                        </div>
                        <div class="line" style="margin-top: 20px;"></div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <!-- BOTTOM OF FORM -->
    <app-form-bottom style="width: 100%;"
        *ngIf="showBottomButtons"
        [service]="estateControlService"
        [text]="loading ? 'Saving Assets' : 'Next'"
        [formValid]="isPageReady()"
        [showContinue]="isPageReady() ? 'true' : 'false'"
        [saveFunction]="updateProgress.bind(this)"
        [buttonClickHandler]="navigateFunction.bind(this)">
    </app-form-bottom>
</form>
