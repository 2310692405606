import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api/api.service';
import { TabControlService } from 'src/app/services/tab-control.service';
import { createIDsValidatorEstate } from 'src/app/services/validators/custom.validators';
import { ValidationService } from 'src/app/services/validators/validation.service';
import { checkTime } from 'src/app/util/Helper';
import { environment } from 'src/environments/environment';
import * as Constants from "../../../../../constants/constants";
import { OtpPopupComponent } from 'src/app/components/otp-popup/otp-popup.component';
import { ActivatedRoute } from '@angular/router';
import { EstateControlService } from 'src/app/services/estate-control.service';
import { NotificationService } from 'src/app/services/notification.service';


@Component({
  selector: 'app-step1-settlor',
  templateUrl: './step1-settlor.component.html',
  styleUrls: ['./step1-settlor.component.scss']
})
export class Step1SettlorComponent implements OnInit {

  checkerPath: string = '../../../../assets/fi_check.png';

  formTitle: string = 'Appointment Contact Details';
  formSubtitle: string = 'Please enter the contact details of the person(s) attending the meeting';

  contactInfoTitle: string = 'Contact Person Information';

  phoneLabel : string = 'Primary Phone Number';
  emailLabel : string = 'Email Address';
  emailHint : string = 'Email address';

  titleLabel: string = 'Title';
  titleHint: string = 'Title';
  fNameLabel: string = 'First Name';
  fNameHint : string = 'First name';
  mNameLabel : string = 'Middle Name';
  mNameHint : string = 'Middle name';
  surnameLabel : string = 'Surname';
  surnameHint : string = 'Last name';

  addSettlorText: string = '+  Add Another Contact';
  removeSettlorText: string = 'Remove Contact';

  requiredFieldString: string = 'Required field';

  loading: boolean = false;
  loadingText: string = 'Requesting OTP';
  loadingRemove: boolean = false;
  currentIndex: number = 0;
  removeButtonText: string = 'Remove Contact';

  otpVerified: boolean = false;

  formErrors: { [key: string]: string } = {
    Phone: '',
    Email: '',
    
    Title: '',
    FirstName: '',
    MiddleName: '',
    LastName: '',
  };

  validationMessages: { [key: string]: {} } = {
    Phone: { required: this.requiredFieldString, pattern:'Invalid phone number', exist: 'This number is tied to an existing contact' },
    Email: { required: this.requiredFieldString, email: "Invalid email address", exist: 'This email is tied to an existing contact' },

    Title: { required: this.requiredFieldString },
    FirstName: { required: this.requiredFieldString },
    MiddleName: { required: this.requiredFieldString },
    LastName: { required: this.requiredFieldString },
  };

  settlorForm = this._formBuilder.group({
    // settlors: this._formBuilder.array([
    //   this.createSettlorsFormGroup()
    // ]),
  }) as FormGroup & { [key: string]: AbstractControl };

  constructor(
    private _formBuilder: FormBuilder,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private tabControlService: TabControlService,
    public estateControlService: EstateControlService,
    private validationService: ValidationService,
    private apiService: ApiService,
    private route: ActivatedRoute
  ) {
    this.populateData();
  }

  async ngOnInit(): Promise<void> {
    // resume
    // this.settlorForm = this._formBuilder.group({
    //   referree: [],
    //   referreeName: [``],
    //   other: [``],
    // }) as FormGroup & { [key: string]: AbstractControl };

    // if (this.settlorForm.value.referree && this.settlorForm.value.referree !== '') {
    //   this.checkIntermediary();
    // }

    await this.populateForm();
  }

  get settlors(): any {
    return this.estateControlService.settlors;
  }
  get loadingTitles(): boolean {
    return this.tabControlService.loadingTitles;
  }
  get TitleOptions(): any {
    return this.tabControlService.TitleOptions;
  }

  async populateData(): Promise<void> {
    if (this.TitleOptions.length === 0) {
      this.tabControlService.loadingTitles = true;
      await this.tabControlService.getTitlesList();
      this.tabControlService.loadingTitles = false;
    }
  }

  async populateForm(): Promise<void> {
    if (this.settlors && this.settlors.length > 0) {
      console.log(this.settlors);
      let settle = this.settlors;

      settle.forEach(async (settlor: any, index: number) => {
        if (index > 0 && !settlor.verified) {
          settle.splice(index, 1);
          await this.estateControlService.updateRecord(1, settle);
        }
      });

      // if (this.settlors.length > 1 && this.settlors[0] && this.settlors[0].verified == false) {
      //   const truncated = this.settlors.slice(0,1);
      //   await this.estateControlService.updateRecord(1, truncated);
      // }

      const firstObject = this.settlors[0];

      if (firstObject) {
        if (firstObject.verified == true) {
          // Add settlors from storage
          this.settlors.forEach((settlor: any) => {
            if (settlor.verified == true) this.addSettlorsArray(settlor);
          });

          // Filter out the unverified settlors and reassign the array
          const dat = this.settlors.filter((settlor: any) => settlor.verified == true);
          await this.estateControlService.updateRecord(1, dat);
        }
        
      }
    } else {
      this.addSettlorsArray();
    }
  }


  addSettlorsArray = (settlor: any = null) => {
    this.settlorForm.addControl('settlors', this._formBuilder.array([
      this.createSettlorsFormGroup(settlor)
    ]));
  }
  createSettlorsFormGroup(settlor: any = null): FormGroup {
    let form: FormGroup;

    // console.log('scajhbusdycbudsb ', settlor);

    if (settlor) {
      form = this._formBuilder.group({
        Phone: [`${settlor.Phone || ''}`, Validators.required],
        Email: [`${settlor.Email || ''}`, [Validators.required, Validators.email]],
        
        Title: [`${settlor.Title || ''}`],
        FirstName: [`${settlor.FirstName || ''}`, Validators.required],
        MiddleName: [`${settlor.MiddleName || ''}`,],
        LastName: [`${settlor.LastName || ''}`, Validators.required],
      });

      if (settlor.verified == true) {
        this.otpVerified = true;

        if (this.settlorForm && this.getSettlors() && this.getSettlors().length > 0 && settlor.done == false) {
          this.validationService.addOrRemove3Validators(
            true,
            form.get('Email')!,
            [Validators.required,
              Validators.email,
              createIDsValidatorEstate('EMAIL', this.getSettlors(), this.settlors)
            ]
          );
          this.validationService.addOrRemoveSpecificValidators(
            true,
            form.get('Phone')!,
            [Validators.required,
              createIDsValidatorEstate('TEL', this.getSettlors(), this.settlors)
            ]
          );
        }
      }

      // console.log('Current record verified: ', this.currentRecordVerified, '\n', settlor);
    } else {
      if (
        this.settlors && this.settlors.length > 0 && this.settlors[0].verified == false
      ) {
          this.estateControlService.refreshRecord(1);
      }

      this.otpVerified = false;
      let currentSettlors = this.settlors;
      let currentRecord: any = {verified: false, done: false};

      form = this._formBuilder.group({
        Phone: ['', Validators.required],
        Email: ['', [Validators.required, Validators.email]],
        
        Title: [''],
        FirstName: ['', Validators.required],
        MiddleName: [''],
        LastName: ['', Validators.required],
      });

      if (this.settlorForm && this.getSettlors() && this.getSettlors().length > 0) {
        this.validationService.addOrRemove3Validators(
          true,
          form.get('Email')!,
          [Validators.required,
            Validators.email,
            createIDsValidatorEstate('EMAIL', this.getSettlors(), this.settlors)
          ]
        );
        this.validationService.addOrRemoveSpecificValidators(
          true,
          form.get('Phone')!,
          [Validators.required,
            createIDsValidatorEstate('TEL', this.getSettlors(), this.settlors)
          ]
        );
      }

      if (
        this.settlors.length == 0 ||
        this.settlors.length > 0 && this.settlors[this.settlors.length-1].done == true
      ) {
        currentSettlors.push(currentRecord);
        this.estateControlService.updateRecord(1, currentSettlors);
      }
      
    }

    form.updateValueAndValidity();

    return form;
  };

  async addNewSettlor() {
    const fields = this.settlorForm.get('settlors') as FormArray;
    fields.push(this.createSettlorsFormGroup());
    this.settlorForm.setControl('settlors', fields);
  }
  // TODO: Update Remove function for Estate Settlors
  async removeSettlor(i:number) {
    this.currentIndex = i;
    const fields = this.settlorForm.get('settlors') as FormArray;
    let currentSettlors = this.settlors;
    
    if (currentSettlors[i] && currentSettlors[i].SettlorID) {
      this.removeButtonText = 'Removing Settlor';
      this.loadingRemove = true;
      
      try {
        if (this.estateControlService.AppointmentID !== null) {
          const result = await this.estateControlService.removeRecord(
            'settlor',
            currentSettlors[i].SettlorID,
            parseInt(this.estateControlService.AppointmentID),
            environment.baseUrl + Constants.saveAppointmentSettlorsEstateURL
          );

          if (result === 1) {
            this.notificationService.viewToast('success', 'Settlor removed');
            fields.removeAt(i);
            // Remove settlor at index
            currentSettlors.splice(i, 1);;
            await this.estateControlService.updateRecord(1, currentSettlors);
          } else {
            this.notificationService.viewToast('error', 'Error removing record');
          }
        } else {
          console.log('Relevant IDs not found');
        }
      } catch (error) {
        console.log(':: Error !! ', error);
      }
      this.removeButtonText = 'Remove Settlor';
      this.loadingRemove = false;
    } else {
      if (currentSettlors[i] && currentSettlors[i].done === false) {
        fields.removeAt(i);
        // Remove settlor at index
        currentSettlors.splice(i, 1);;
        await this.estateControlService.updateRecord(1, currentSettlors);
      } else {
        fields.removeAt(i);
      }
    }
  }
  getSettlors() : FormArray {  
    return this.settlorForm.get("settlors") as FormArray  
  }
  getSettlorFormGroup(index: number): FormGroup {
    const settlors = this.settlorForm.get('settlors') as FormArray;
    return settlors.at(index) as FormGroup;
  }

  async openOtpModal() {
    this.loadingText = 'Requesting OTP';
    this.loading = true;
    this.notificationService.isLoading(true);

    try {
      const records = this.settlorForm.controls['settlors'].value;
      let data = {
        FirstName: records[records.length-1].FirstName,
        LastName: records[records.length-1].LastName,
        Phone: records[records.length-1].Phone,
        Email: records[records.length-1].Email,
        MileleType: 'estate'
      };

      this.estateControlService.OTPMessage = 'In order to proceed, please enter the One Time Password (OTP) sent to the contact\'s email and phone';
      // console.log('Dat ', data);

      let savedTime = this.estateControlService.OTPRequestStats;
      let response = {Status: 0, Message: 'Initialized'};

      this.estateControlService.OTPValid = checkTime(savedTime.time);

      if (this.estateControlService.OTPValid && savedTime.phone === data.Phone) {
        response.Status = 1;
        response.Message = 'Current OTP is still Valid';

      } else {
        response = await this.apiService.postRequest(
          environment.baseUrl + Constants.otpRequestURL, data);
      }

      if (response.Status === 1) {
        let currentSettlors = this.settlors;
        let currentRecord = currentSettlors[currentSettlors.length-1];

        currentRecord = {...currentRecord, ...records[records.length-1]};
        
        Object.assign(currentSettlors[currentSettlors.length - 1], currentRecord);
        await this.estateControlService.updateRecord(1, currentSettlors);
        
        if (!this.estateControlService.OTPValid) {
          const timeStats = {time: new Date().getTime() / 1000, phone: data.Phone};
          this.estateControlService.OTPRequestStats = timeStats;
          this.estateControlService.OTPValid = true;

          this.notificationService.viewToast(
            'success',
            `OTP sent to ${data.Phone}.`,
            'We have also sent it to the provided Email');

        } else {
          this.notificationService.viewToast(
            'info',
            `Please enter the OTP that was sent to your Phone / Email` );
        }

        this.estateControlService.OTPRequestPage = 'SettlorEstate';
        this.estateControlService.OTPIndex = this.settlors.length-1;
        
        this.loadingText = 'Verify Details';

        const dialogRef = this.dialog.open(OtpPopupComponent, {
          disableClose: true,
        });
    
        dialogRef.componentInstance.otpVerified.subscribe((value: boolean) => {
          this.otpVerified = value;
          // console.log('OTP verification status received from the modal:', this.otpVerified);
        });
    
        dialogRef.afterClosed().subscribe(result => {
          console.group('OTP Dialog ', this.otpVerified);
          if (this.otpVerified == true) {
            let currentSettlors = this.settlors;
            let currentRecord = currentSettlors[currentSettlors.length-1];
            currentRecord.verified = true;

            Object.assign(currentSettlors[currentSettlors.length - 1], currentRecord);
            this.estateControlService.updateRecord(1, currentSettlors);

            this.saveSettlor();

          } else {
            this.notificationService.viewToast('warning', 'Please verify your details to proceed');
          }
          console.groupEnd();
        });
      } else {
        this.notificationService.viewToast('error', response.Message);
      }
    } catch (error) {
      console.log(':: Error !! ', error);
      this.notificationService.viewToast('error', 'OTP request failed');

    }
    this.notificationService.isLoading(false);
    this.loading = false;
  }
  
  async saveSettlor(): Promise<void> {
    this.loadingText = 'Saving Contact';

    this.loading = true;
    this.notificationService.isLoading(true);

    try {
      const settlors = this.settlorForm.get('settlors') as FormArray;
      const record: FormGroup = this.getSettlorFormGroup(settlors.length - 1) as FormGroup;

      if (this.estateControlService.AppointmentID === null) {
        const _data = {
          AppointmentType: '',
          Email: '',
          Date: '',
          Time: '',
          NumberOfPersons: '',
          Location: '',
          BookingID: '',
        };

        const _response = await this.apiService.postRequest(
          environment.baseUrl + Constants.bookAppointmentEstateURL, _data, false);

        if (_response.Status === 1 && _response.BookingID) {
          this.estateControlService.AppointmentID = _response.BookingID;
          
        } else {
          console.error(_response);
          this.notificationService.viewToast('error', 'Could not save your details', _response.Message);
          return;
        }
      }
      
      let data = {
        ...record.value,
        ...{
          BookAppoitmentID: this.estateControlService.AppointmentID,
          TrustType: 'Settler'
        }
      };

      let currentSettlors = this.estateControlService.settlors;

      if (currentSettlors[currentSettlors.length - 1].SettlorID) {
        data = {...data, ...{SettlorID: currentSettlors[currentSettlors.length - 1].SettlorID}};
      }
      
      const response = await this.apiService.postRequest(
        environment.baseUrl + Constants.saveAppointmentSettlorsEstateURL, data, false);
      
      // console.log('::::: RESPONSE ::::::\n', response);
      if (response.Status === 1) {
        const latestRecord = currentSettlors[currentSettlors.length - 1];
        let updatedRecord = {...latestRecord, ...record.value, ...{SettlorID: response.SettlorID}};
        updatedRecord.done = true;
        Object.assign(currentSettlors[currentSettlors.length - 1], updatedRecord);

        await this.estateControlService.updateRecord(1, currentSettlors);
        console.log('>> New Contacts !! \n', this.estateControlService.settlors);

        this.notificationService.viewToast('success', 'Settlor saved successfully');

      } else {
        this.notificationService.viewToast('error', response.Message);
      }
    } catch (error) {
      console.log(':: Error !! ', error);
      this.notificationService.viewToast('error', 'An unexpected error occurred');
    }
    this.notificationService.isLoading(false);
    this.loading = false;
  }

  async submitForm(): Promise<void> {
    if (this.settlors[this.settlors.length-1].done) {
      this.navigateFunction();
    } else {
      if (this.settlors[this.settlors.length-1].verified) {
        this.saveSettlor();
      } else {
        this.openOtpModal();
      }
    }
  }

  async navigateFunction(): Promise<void> {
    await this.estateControlService.changeTab(2);
    this.estateControlService.page1Done = true;
  }
}
