import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormArray, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { ApiService } from 'src/app/services/api/api.service';
import { EstateControlService } from 'src/app/services/estate-control.service';
import { TabControlService } from 'src/app/services/tab-control.service';
import { NotificationService } from 'src/app/services/notification.service';
import { createIDsValidatorEstate } from 'src/app/services/validators/custom.validators';
import { ValidationService } from 'src/app/services/validators/validation.service';
import { environment } from 'src/environments/environment';
import * as Constants from "src/app/constants/constants";
import { trigger, state, style, transition, animate } from '@angular/animations';
import { getEarliestDateFor18Years } from 'src/app/util/Helper';

@Component({
  selector: 'app-step5-trustee',
  templateUrl: './step5-trustee.component.html',
  styleUrls: ['./step5-trustee.component.scss'],
  animations: [
    trigger('bounceIn', [
      state('in', style({ opacity: 1, transform: 'scale(1)' })),
      transition('void => *', [
        style({ opacity: 0, transform: 'scale(0.8)' }),
        animate('0.15s ease-in-out')
      ]),
      transition('* => void', [
        animate('0.15s ease-in-out', style({ opacity: 0, transform: 'scale(0.7)' }))
      ])
    ]),
    trigger('slideInRight', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('0.15s ease-in-out', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('0.15s ease-in-out', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class Step5TrusteeComponent {
  @Output() scrollUp = new EventEmitter<number>();
  @ViewChild('suggestBox', { static: false }) suggestBox?: ElementRef;

  
  checkerPath: string = '../../../../assets/fi_check.png';

  title: string = 'Trustees';
  subtitle: string = 'Add one or more trustees.';

  trusteeTypeLabel: string = 'Type of Trustee';
  trusteeTypeHint: string = 'Type of trustee';

  relationLabel: string = 'Relationship To Settlor';
  relationHint: string = 'Specify Relationship';

  titleLabel: string = 'Title';
  titleHint: string = 'Title';
  fNameLabel: string = 'First Name';
  fNameHint : string = 'First name';
  mNameLabel : string = 'Middle Name';
  mNameHint : string = 'Middle name';
  surnameLabel : string = 'Surname';
  surnameHint : string = 'Last name';
  genderLabel: string = 'Gender';
  genderHint: string = 'Select gender';
  phoneLabel : string = 'Primary Phone Number';
  emailLabel : string = 'Email Address';
  emailHint : string = 'Email address';

  institutionNameLabel: string = 'Name of Institution';
  businessNatureLabel: string = 'Nature of Business';
  orgRegNumLabel: string = 'Organization Registration Number';
  orgAddressLabel: string = 'Postal Address';
  orgAddressHint: string = 'Enter Postal Address';
  
  institutionNameHint: string = 'Enter name';
  businessNatureHint: string = 'Enter nature of business';
  orgRegNumHint: string = 'Enter registration Number';
  

  additionalDetailsText: string = 'Upload Documents';

  idLabel: string = 'ID/Passport Number';
  idHint: string = 'Enter ID or Passport Number';
  kraPinLabel: string = 'KRA PIN';
  kraHint: string = 'Enter KRA PIN';
  idFileLabel: string = 'Upload Copy of ID/Passport';
  passportLabel: string = 'Upload Passport Photo';
  cvLabel: string = 'Curriculum Vitae';

  regCertFileLabel: string = 'Upload Registration Certificate';
  kraFileLabel: string = 'Upload KRA PIN';

  addBenText: string = 'Add Another Trustee';
  removeBenText: string = 'Remove Trustee';

  addText: string = 'Trustee details saved';
  updateText: string = 'Trustee details updated'
  
  requiredFieldString: string = 'Required field';
  invalidDocString: string = 'Please attach a valid document';

  buttonDisabled: boolean = true;
  addDisabled: boolean = true;

  loadingText: string = 'Saving Trustee';

  loadingRemove: boolean = false;
  removeButtonText: string = 'Remove Trustee';
  removeContactText: string = 'Remove Contact';
  navigationRequested: boolean = false;
  currentIndex: number = 0;
  shouldInsert: boolean = false;

  selfRecords: any = []; // For suggesting self
  recordUpdate: any;

  formErrors: { [key: string]: string } = {
    Category: '',
    Title: '',
    FirstName: '',
    MiddleName: '',
    LastName: '',
    Phone: '',
    Email: '',
    Relationship: '',
    Other: '',

    IDNo: '',
    KraPin: '',

    IDNoImage: '',
    KraPinImage: '',
    PassportImage: '',
    CvImage: '',

    InstitutionName: '',
    BusinessNature: '',
    OrgRegNum: '',
    PostalAddress: '',
    RegCertImage: '',
    
  };

  validationMessages: { [key: string]: {} } = {
    Category: { required: this.requiredFieldString },
    Title: { required: this.requiredFieldString },
    FirstName: { required: this.requiredFieldString },
    MiddleName: { required: this.requiredFieldString },
    LastName: { required: this.requiredFieldString },
    Phone: {
      required: this.requiredFieldString,
      pattern:'Invalid phone number',
      exist: `${
        this.trustees[this.trustees.length-1] &&
        this.trustees[this.trustees.length-1].personalDone &&
        this.trustees[this.trustees.length-1].personalDone == true ? 'This number is tied to an existing contact' : 'This number is tied to an existing trustee' }` },
    Email: {
      required: this.requiredFieldString,
      email: "Invalid email address",
      exist: `${
        this.trustees[this.trustees.length-1] &&
        this.trustees[this.trustees.length-1].personalDone &&
        this.trustees[this.trustees.length-1].personalDone == true ? 'This email is tied to an existing contact' : 'This email is tied to an existing trustee' }` },

    IDNo:  { required: this.requiredFieldString, pattern:'Invalid ID or Passport number', exist: 'This ID is tied to an existing trustee' },
    KraPin: { required: this.requiredFieldString, pattern:'Invalid KRA PIN', exist: 'This PIN is tied to an existing trustee' },
    Relationship: { required: this.requiredFieldString },
    Other: {required: this.requiredFieldString},

    IDNoImage: { required: this.requiredFieldString, invalid: this.invalidDocString },
    KraPinImage: { required: this.requiredFieldString, invalid: this.invalidDocString },
    PassportImage: { required: this.requiredFieldString, invalid: this.invalidDocString },
    CvImage: { required: this.requiredFieldString, invalid: this.invalidDocString },

    InstitutionName: { required: this.requiredFieldString },
    BusinessNature: { required: this.requiredFieldString },
    OrgRegNum: { required: this.requiredFieldString },
    PostalAddress: { required: this.requiredFieldString },

    RegCertImage: { required: this.requiredFieldString, invalid: this.invalidDocString },
    
  };

  trusteeForm = this._formBuilder.group({
    trustees: this._formBuilder.array([
      this.createTrusteeFormGroup()
    ]),
  });
  

  constructor(
    private _formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private tabControlService: TabControlService,
    public estateControlService: EstateControlService,
    private validationService: ValidationService,
    private apiService: ApiService,
  ) { }

  async ngOnInit(): Promise<void> {
    const records: any = this.settlors;
    this.selfRecords.push(records[0]);
    if (records.length > 1) this.selfRecords.push(records[1]);

    if (this.trustees && this.trustees.length > 0) {
      if (this.trustees[0].personalDone
        && this.trustees[0].personalDone == true) {
        const trusteesArray = this.trusteeForm.get('trustees') as FormArray;
        
        // Filter out the unverified settlors and reassign the array
        const dat = this.trustees.filter((trustee: any) => trustee.personalDone == true);
        await this.estateControlService.updateRecord(5, dat);
        
        // Clear existing trustees
        while (trusteesArray.length !== 0) {
          trusteesArray.removeAt(0);
        }

        // Add trustees from storage
        this.trustees.forEach((trustee: any) => {
          trusteesArray.push(this.createTrusteeFormGroup(trustee));
        });

        this.estateControlService.updateStorageObject();
      }
    }

    if (this.estateControlService.activeTab == 5) {
      this.populateData();
    }

    this.trusteeForm.valueChanges.subscribe(() => {
      this.logErrors();
    });
  }

  logErrors() {
    this.formErrors = this.validationService.logValidationErrors(
      this.trusteeForm,
      this.formErrors,
      this.validationMessages
    );
  }

  idObject: any = null;
  kraObject: any = null;

  passportObject: any = null;
  cvObject: any = null;
  regCertObject: any = null;

  businessNatureEmpty: boolean = true;

  // Autofill settlors
  showSuggestHint: boolean = false;
  showAssignPopup: boolean = false;
  autofilled: boolean = false;
  suggestions: any = null;
  briefTimeout: any;

  get settlors(): any {
    return this.estateControlService.settlors;
  }

  // ===============================================

  get loading(): boolean {
    return this.notificationService.loading;
  }
  get trustees(): any {
    return this.estateControlService.trustees;
  }

  get loadingTitles(): boolean {
    return this.tabControlService.loadingTitles;
  }
  get TitleOptions(): any {
    return this.tabControlService.TitleOptions;
  }
  get TrusteeOptions():any {
    return this.estateControlService.TrusteeOptions;
  }

  get loadingBizNature(): boolean {
    return this.tabControlService.loadingBizNature;
  }
  get BusinessNatureOptions(): any {
    return this.tabControlService.BusinessNatureOptions;
  }
  get RelationOptions():any {
    return this.tabControlService.RelationOptions;
  }
  Relationships: any = this.RelationOptions;

  get saveProgress(): ()=> void {
    return this.tabControlService.saveProgress;
  }

  showSuggestBrief(): void {
    setTimeout(() => {
      this.showSuggestHint = true;
    }, 1000);

    this.briefTimeout = setTimeout(() => {
      this.showSuggestHint = false;
    }, 30000);
  }
  async suggestSettlors(val: string): Promise<any> {
    if (this.autofilled) {
      this.resetForm();
    }
    
    this.suggestions = null;
    let validEntries: any = [];

    if (val.length > 2) {
      console.log('ascascsacascacas')
        try {
            const fields = this.trusteeForm.get('trustees') as FormArray;

            for (let i = 0; i < this.settlors.length; i++) {
                let exist = false;
                let trueStuff = this.settlors[i].IDNo.includes(val);

                if (trueStuff) {
                    for (let j = 0; j < fields.length - 1; j++) {
                        if (
                            fields.at(j).get('IDNo')?.value === this.settlors[i].IDNo ||
                            fields.at(j).get('Phone')?.value === this.settlors[i].Phone ||
                            fields.at(j).get('KraPin')?.value === this.settlors[i].KraPin) {
                            exist = true;
                        } else {
                          this.showSuggestHint = false;
                          clearTimeout(this.briefTimeout);
                        }
                    }

                    if (!exist && validEntries.length < 3 && !validEntries.some((entry: { IDNo: string; }) => entry.IDNo === this.settlors[i].IDNo)) {
                        validEntries.push(this.settlors[i]);
                    }
                }
            }

            if (validEntries.length > 0) {
              this.suggestions = validEntries;
            }
            if (this.suggestions !== null) {
              const childDivHeight = this.suggestBox?.nativeElement.clientHeight;
              this.scrollUp.emit(childDivHeight);
            }
        } catch (error) {
            console.error(error);
        }
    }

    return this.suggestions;
  }
  patchSuggestedValues(index: number, value: any): void {
    try {
      if (value) {
        const fields = this.trusteeForm.get('trustees') as FormArray;
        fields.removeAt(index);
        fields.push(this.createTrusteeFormGroup(value, true));

        this.trusteeForm.setControl('trustees', fields);
        
        this.suggestions = null;
        this.autofilled = true;
      }

    } catch (error) {
      console.error('ERR ??? ', error);
    }
  }

  resetForm(): void {
    try {
      this.autofilled = false;

      const trustee = this.getTrustees().at(this.getTrustees().length-1) as FormGroup;
      trustee.get('Title')?.setValue('');
      trustee.get('FirstName')?.setValue('');
      trustee.get('MiddleName')?.setValue('');
      trustee.get('LastName')?.setValue('');
      trustee.get('Phone')?.setValue('7');
      trustee.get('Email')?.setValue('');
      trustee.get('IDNo')?.setValue('');
      trustee.get('KraPin')?.setValue('');
      trustee.get('IDNoImage')?.setValue(null);
      trustee.get('KraPinImage')?.setValue(null);
      trustee.get('PassportImage')?.setValue(null);
      trustee.get('CvImage')?.setValue(null);

      this.updateIDObject(null);
      this.updateKraObject(null);
      this.updatePassportObject(null);
      this.updateCVObject(null);

    } catch (error) {
      console.error('ResetForm ', error);
    }
  }

  async populateData(): Promise<void> {
    if (this.TitleOptions.length === 0) {
      this.tabControlService.loadingTitles = true;
      await this.tabControlService.getTitlesList();
      this.tabControlService.loadingTitles = false;
    }
    if (this.BusinessNatureOptions.length === 0) {
      this.tabControlService.loadingBizNature = true;
      await this.tabControlService.getBusinessNatOptionsList();
      this.tabControlService.loadingBizNature = false;
    }
  }

  arrayContainsValue(arr: string | null | undefined, value: string) {
    return arr && arr.includes(value);
  }

  updateIDObject(object: any): void {
    this.idObject = object;
    // console.log('ID doc updated: ', this.idObject.name);
  }
  updateKraObject(object: any): void {
    this.kraObject = object;
  }
  updatePassportObject(object: any): void {
    this.passportObject = object;
  }
  updateCVObject(object: any): void {
    this.cvObject = object;
  }
  updateRegCertObject(object: any): void {
    this.regCertObject = object;
  }

  onTrusteeTypeChange(index: number): void {
    if (this.autofilled) this.resetForm();

    const trusteeType = this.getTrusteeFormGroup(index).get('Category');

    if (trusteeType) {
      if (trusteeType.value.toLowerCase() === 'individual trustee') {
        this.orgAddressHint = 'Enter Postal Address';
        this.orgAddressLabel = 'Postal Address';
        
        this.getTrusteeFormGroup(index).get('InstitutionName')?.clearValidators();
        this.getTrusteeFormGroup(index).get('BusinessNature')?.clearValidators();
        this.getTrusteeFormGroup(index).get('OrgRegNum')?.clearValidators();
        this.getTrusteeFormGroup(index).get('PostalAddress')?.clearValidators();

        this.getTrusteeFormGroup(index).get('InstitutionName')?.setValue('');
        this.getTrusteeFormGroup(index).get('BusinessNature')?.setValue('')
        this.getTrusteeFormGroup(index).get('OrgRegNum')?.setValue('')
        this.getTrusteeFormGroup(index).get('KraPin')?.setValue('')
        this.getTrusteeFormGroup(index).get('PostalAddress')?.setValue('')

        this.validationService.addOrRemoveValidator(true,
          this.getTrusteeFormGroup(index).get('Relationship')!);
        this.validationService.addOrRemoveValidator(true,
          this.getTrusteeFormGroup(index).get('FirstName')!);
        this.validationService.addOrRemoveValidator(true,
          this.getTrusteeFormGroup(index).get('LastName')!);
        this.validationService.addOrRemoveValidator(true,
          this.getTrusteeFormGroup(index).get('IDNo')!);
        this.validationService.addOrRemoveValidator(true,
          this.getTrusteeFormGroup(index).get('Title')!);

        this.validationService.addOrRemoveValidationOnValue(
          this.getTrusteeFormGroup(index).get("Relationship")!,
          "Other",
          this.getTrusteeFormGroup(index),
          "Other"
        );

      } else {
        this.orgAddressHint = 'Enter Physical Address';
        this.orgAddressLabel = 'Organisation Physical Address';

        this.getTrusteeFormGroup(index).get('FirstName')?.clearValidators();
        this.getTrusteeFormGroup(index).get('LastName')?.clearValidators();
        this.getTrusteeFormGroup(index).get('Title')?.clearValidators();
        this.getTrusteeFormGroup(index).get('IDNo')?.clearValidators();
        this.getTrusteeFormGroup(index).get('Relationship')?.clearValidators();
        this.getTrusteeFormGroup(index).get('Phone')?.clearValidators();
        this.getTrusteeFormGroup(index).get('Email')?.clearValidators();

        this.getTrusteeFormGroup(index).get('Relationship')?.setValue('');
        this.toggleFormControls(index, true);
        
        this.getTrusteeFormGroup(index).get('Title')?.setValue('');
        this.getTrusteeFormGroup(index).get('FirstName')?.setValue('');
        this.getTrusteeFormGroup(index).get('MiddleName')?.setValue('');
        this.getTrusteeFormGroup(index).get('LastName')?.setValue('');
        this.getTrusteeFormGroup(index).get('Phone')?.setValue('');
        this.getTrusteeFormGroup(index).get('Email')?.setValue('');
        this.getTrusteeFormGroup(index).get('PostalAddress')?.setValue('');

        this.getTrusteeFormGroup(index).get('IDNo')?.setValue('');
        this.getTrusteeFormGroup(index).get('KraPin')?.setValue('');
        
        this.getTrusteeFormGroup(index).get('Other')?.setValue('');

        this.validationService.addOrRemoveValidator(true,
          this.getTrusteeFormGroup(index).get('InstitutionName')!);
        this.validationService.addOrRemoveValidator(true,
          this.getTrusteeFormGroup(index).get('BusinessNature')!);
        this.validationService.addOrRemoveValidator(true,
          this.getTrusteeFormGroup(index).get('OrgRegNum')!);

        this.validationService.addOrRemove3Validators(
          true,
          this.getTrusteeFormGroup(index).get('KraPin')!,
          [Validators.required,
            Validators.pattern(/^[AP]\d{9}[A-Z]$/),
            createIDsValidatorEstate('PIN', this.getTrustees(), this.trustees)
          ]
        );

        this.validationService.addOrRemoveValidator(true,
          this.getTrusteeFormGroup(index).get('PostalAddress')!);

      }
    }
  }

  onRelationshipChange(index: number): void {
    const value = this.getTrusteeFormGroup(index)?.get('Relationship')?.value;
    if (value) {
      switch (value.toLowerCase()) {
        case 'self':
          const fields = this.trusteeForm.get('trustees') as FormArray;
          this.recordUpdate = this.selfRecords[0];
          let counter: number = 0;
          
          const hasSelfRelationship = fields.controls.some(group => {  
            if (group.get('Relationship')?.value.toLowerCase() === 'self') {
              counter++;
            }
            return counter > 1;
          });
          // To autofill second settlor / testator
          if (hasSelfRelationship && this.selfRecords.length > 1)
            this.recordUpdate = this.selfRecords[1];
    
          fields.removeAt(index);
          fields.push(this.createTrusteeFormGroup(this.recordUpdate, false, value));
    
          this.trusteeForm.setControl('trustees', fields);
          this.toggleFormControls(index, false);

          break;
        default:
          this.toggleFormControls(index, true);
          break;
      }
    }
  }

  toggleFormControls(index: number, enabled: boolean): void {
    if (enabled) { // Reset form when relationship != self
      const isSelfRecord: boolean = this.selfRecords.some((record: any) => {
        return this.getTrusteeFormGroup(index).get('IDNo')?.value == record.IDNo;
      });

      if (isSelfRecord) {
        this.getTrusteeFormGroup(index)?.get('Title')?.enable();
        this.getTrusteeFormGroup(index)?.get('FirstName')?.enable();
        this.getTrusteeFormGroup(index)?.get('MiddleName')?.enable();
        this.getTrusteeFormGroup(index)?.get('LastName')?.enable();
        this.getTrusteeFormGroup(index)?.get('Phone')?.enable();
        this.getTrusteeFormGroup(index)?.get('Email')?.enable();
        this.getTrusteeFormGroup(index).get('IDNo')?.enable();
        this.getTrusteeFormGroup(index).get('KraPin')?.enable();
        this.getTrusteeFormGroup(index).get('PostalAddress')?.enable();

        this.getTrusteeFormGroup(index)?.get('Title')?.setValue('');
        this.getTrusteeFormGroup(index)?.get('FirstName')?.setValue('');
        this.getTrusteeFormGroup(index)?.get('MiddleName')?.setValue('');
        this.getTrusteeFormGroup(index)?.get('LastName')?.setValue('');

        this.getTrusteeFormGroup(index).get('Phone')?.setValue(' ');    
        this.getTrusteeFormGroup(index)?.get('Email')?.setValue('');
        this.getTrusteeFormGroup(index).get('IDNo')?.setValue('');
        this.getTrusteeFormGroup(index)?.get('KraPin')?.setValue('');

        this.getTrusteeFormGroup(index).get('PostalAddress')?.setValue('');
      }

    } else {
      // For 'self' option under relationship
      this.getTrusteeFormGroup(index)?.get('Title')?.disable();
      this.getTrusteeFormGroup(index)?.get('FirstName')?.disable();
      this.getTrusteeFormGroup(index)?.get('MiddleName')?.disable();
      this.getTrusteeFormGroup(index)?.get('LastName')?.disable();

      this.getTrusteeFormGroup(index)?.get('Phone')?.disable();
      this.getTrusteeFormGroup(index)?.get('Email')?.disable();  
      this.getTrusteeFormGroup(index).get('IDNo')?.disable();
      this.getTrusteeFormGroup(index).get('KraPin')?.disable();
      this.getTrusteeFormGroup(index).get('PostalAddress')?.disable();
    }
    this.trusteeForm.updateValueAndValidity();
  }

  createTrusteeFormGroup(trustee: any = null, patch: boolean = false, value: any = null): FormGroup {
    let form: FormGroup;
    let category = this.TrusteeOptions[0];

    if (this.trusteeForm && this.getTrustees().length > 0) {
      category = this.TrusteeOptions[1];
    }

    if (trustee) {
      if (patch) this.autofilled = true;

      form = this._formBuilder.group({
        Category: [`${value ? this.TrusteeOptions[0] : patch ? category : trustee.Category || this.TrusteeOptions[0]}`, Validators.required],
        Relationship: [`${value ? value : trustee.Relationship || ''}`],
        Title: [`${trustee.Title || ''}`],
        FirstName: [`${trustee.FirstName || ''}`],
        MiddleName: [`${trustee.MiddleName || ''}`],
        LastName: [`${trustee.LastName || ''}`],
        Phone: [`${trustee.Phone || ''}`],
        Email: [`${trustee.Email || ''}`, Validators.email],
        Other: [`${trustee.Other || ''}`],
  
        IDNo: [`${trustee.IDNo || ''}`],
        KraPin: [`${trustee.KraPin || ''}`],

        IDNoImage: [''],
        KraPinImage: [''],
        PassportImage: [''],
        CvImage: [''],
  
        InstitutionName: [`${trustee.InstitutionName || ''}`],
        BusinessNature: [`${trustee.BusinessNature || ''}`],
        OrgRegNum: [`${trustee.OrgRegNum || ''}`],
        PostalAddress: [`${trustee.PostalAddress || ''}`, Validators.required],
  
        RegCertImage: ['']
      }) as FormGroup & { [key: string]: AbstractControl };

      if (trustee.ContactPersons && trustee.ContactPersons.length > 0) {
        this.addContactArray(form, trustee.ContactPersons[0]);

        const contactArray = form.get('ContactPersons') as FormArray;

        trustee.ContactPersons.forEach((contact: any, index: number) => {
          if (index > 0) contactArray.push(this.createContactsGroup(contact, contactArray));
        });
      }

      if (!value || !patch && trustee.IDNoImage) {
        if (trustee.IDNoImage.stringValue && trustee.IDNoImage.stringValue.length > 0) {
          form.get('IDNoImage')?.setValue(trustee.IDNoImage.stringValue);
          this.updateIDObject(trustee.IDNoImage);
        }
      }
      if (!value || !patch && trustee.KraPinImage) {
        if (trustee.KraPinImage.stringValue && trustee.KraPinImage.stringValue.length > 0) {
          form.get('KraPinImage')?.setValue(trustee.KraPinImage.stringValue);
          this.updateKraObject(trustee.KraPinImage);
        }
      }
      if (!patch && trustee.PassportImage) {
        if (trustee.PassportImage.stringValue && trustee.PassportImage.stringValue.length > 0) {
          form.get('PassportImage')?.setValue(trustee.PassportImage.stringValue);
          this.updatePassportObject(trustee.PassportImage);
        }
      }
      if (!patch && trustee.CvImage) {
        if (trustee.CvImage.stringValue && trustee.CvImage.stringValue.length > 0) {
          form.get('CvImage')?.setValue(trustee.CvImage.stringValue);
          this.updateCVObject(trustee.CvImage);
        }
      }
      if (!patch && trustee.RegCertImage) {
        if (trustee.RegCertImage.stringValue && trustee.RegCertImage.stringValue.length > 0) {
          form.get('RegCertImage')?.setValue(trustee.RegCertImage.stringValue);
          this.updateRegCertObject(trustee.RegCertImage);
        }
      }

      let cat: string = trustee.Category || '';
      if (patch) cat = category;

      if (cat?.toLowerCase() === 'individual trustee' || value) {
        form.get('FirstName')?.addValidators(Validators.required);
        form.get('LastName')?.addValidators(Validators.required);
        form.get('Phone')?.addValidators(Validators.required);
        form.get('Email')?.addValidators([Validators.required, Validators.email]);
        form.get('Relationship')?.addValidators(Validators.required);

        form.get('IDNo')?.addValidators(Validators.required);
        form.get('KraPin')?.addValidators(Validators.required);
        form.get('PostalAddress')?.addValidators(Validators.required);

        this.validationService.addOrRemoveValidationOnValue(
          form.get("Relationship")!,
          "Other",
          form,
          "Other"
        );

        if (patch || trustee.contactsDone) {
          form.get('IDNoImage')?.addValidators(Validators.required);
          form.get('KraPinImage')?.addValidators(Validators.required);
          form.get('PassportImage')?.addValidators(Validators.required);
          form.get('CvImage')?.addValidators(Validators.required);

          if (!trustee.done) {
            // Autofill for self relationship
            this.selfRecords.some((record: any) => {
              if (
                trustee.Relationship == 'Self' &&
                trustee.FirstName == record.FirstName &&
                trustee.LastName == record.LastName &&
                trustee.Phone == record.Phone &&
                trustee.Email == record.Email
              ) {
                this.recordUpdate = record;
                if (record.IDNoImage.stringValue && record.IDNoImage.stringValue.length > 0) {
                  form.get('IDNoImage')?.setValue(record.IDNoImage.stringValue);
                  this.updateIDObject(record.IDNoImage);
                  form.get('IDNoImage')?.disable();
                }
                if (record.KraPinImage.stringValue && record.KraPinImage.stringValue.length > 0) {
                  form.get('KraPinImage')?.setValue(record.KraPinImage.stringValue);
                  this.updateKraObject(record.KraPinImage);
                  form.get('KraPinImage')?.disable();
                }
                this.notificationService.viewToast('info', 'Documents added from uploads');
              }
            })
          }
        }

      } else {
        form.get('InstitutionName')?.addValidators(Validators.required);
        form.get('BusinessNature')?.addValidators(Validators.required);
        form.get('OrgRegNum')?.addValidators(Validators.required);
        form.get('PostalAddress')?.addValidators(Validators.required);
        form.get('KraPin')?.addValidators(Validators.required);

        if (trustee.contactsDone) {
          form.get('RegCertImage')?.addValidators(Validators.required);
          form.get('KraPinImage')?.addValidators(Validators.required);
        }
      }

      form.updateValueAndValidity();

    } else {
      this.showSuggestBrief();

      if (
        this.trustees && this.trustees.length > 0 &&
        !(this.trustees[0].personalDone && this.trustees[0].personalDone == true)
      ) {
          this.estateControlService.refreshRecord(5);
      }
      
      let currentTrustees = this.trustees;
      let currentRecord = {personalDone: false, done: false, contactsDone: false};

      if (
        (this.trusteeForm && this.getTrustees().length > 0) ||
        this.trustees &&
        (this.trustees.length > 0 &&
          !(this.trustees[0].personalDone && this.trustees[0].personalDone == true) ) ||
        this.trustees.length == 0
      ) {
        currentTrustees.push(currentRecord);
        this.estateControlService.updateRecord(5, currentTrustees);
      }
      
      form = this._formBuilder.group({
        Category: [this.TrusteeOptions[0], Validators.required],
        Relationship: ['', Validators.required],
        Title: [''],
        FirstName: ['', Validators.required],
        MiddleName: [''],
        LastName: ['', Validators.required],
        Phone: ['', Validators.required],
        Email: ['', [Validators.required, Validators.email]],
        Other: [''],
  
        IDNo: ['', Validators.required],
        KraPin: ['', Validators.required],

        IDNoImage: [''],
        KraPinImage: [''],
        PassportImage: [''],
        CvImage: [''],
  
        InstitutionName: [''],
        BusinessNature: [''],
        OrgRegNum: [''],
        PostalAddress: ['', Validators.required],
  
        RegCertImage: ['']
      }) as FormGroup & { [key: string]: AbstractControl };

      this.validationService.addOrRemoveValidationOnValue(
        form.get("Relationship")!,
        "Other",
        form,
        "Other"
      );
    }

    if (this.trusteeForm && this.getTrustees().length > 0) {
      this.validationService.addOrRemoveSpecificValidators(
        true,
        form.get('Phone')!,
        [Validators.required,
          createIDsValidatorEstate('TEL', this.getTrustees(), this.trustees)
        ]
      );

      this.validationService.addOrRemove3Validators(
        true,
        form.get('Email')!,
        [ Validators.email,
          Validators.required,
          createIDsValidatorEstate('EMAIL', this.getTrustees(), this.trustees)
        ]
      );
    
      // For 'self' option control
      const fields = this.trusteeForm.get('trustees') as FormArray;
      let counter: number = 0;
    
      const hasSelfRelationship = fields.controls.some(group => {  
        if (group.get('Relationship')?.value.toLowerCase() === 'self') {
          counter++;
        }
        return counter == this.selfRecords.length;
      });

      if (hasSelfRelationship)
        this.Relationships = this.RelationOptions.filter((relation: string) => relation !== 'Self');
    }

    return form;
  };

  createContactsGroup(contact: any = null, formArray: FormArray | null): FormGroup {
    let contactForm: FormGroup;
    if (contact) {
      contactForm = this._formBuilder.group({
        Title: [`${contact.Title || ''}`],
        FirstName: [`${contact.FirstName || ''}`, Validators.required],
        MiddleName: [`${contact.MiddleName || ''}`],
        LastName: [`${contact.LastName || ''}`, Validators.required],
        Phone: [`${contact.Phone || ''}`],
        Email: [`${contact.Email || ''}`, Validators.email]
      });

    } else {
      contactForm = this._formBuilder.group({
        Title: [''],
        FirstName: ['', Validators.required],
        MiddleName: [''],
        LastName: ['', Validators.required],
        Phone: [''],
        Email: ['', Validators.email]
      });
    }

    if (this.trustees[this.trustees.length-1].personalDone &&  !this.trustees[this.trustees.length-1].contactsDone) {
      if (this.trusteeForm && formArray) {
        this.validationService.addOrRemoveSpecificValidators(
          true,
          contactForm.get('Phone')!,
          [Validators.required, createIDsValidatorEstate('TEL', formArray, this.trustees[this.trustees.length-1].ContactPersons)]
        );
        this.validationService.addOrRemove3Validators(
          true,
          contactForm.get('Email')!,
          [Validators.required, Validators.email, createIDsValidatorEstate('EMAIL', formArray, this.trustees[this.trustees.length-1].ContactPersons)]
        );
      } else {
        contactForm.get('Phone')?.addValidators(Validators.required);
        contactForm.get('Email')?.addValidators([Validators.required, Validators.email]);
      }
    }
    
    return contactForm;
  };
  addContactArray = (form: FormGroup, contact: any = null, formArray: FormArray | null = null) => {
    if (formArray) {
      console.log('MONEY')
      form.addControl('ContactPersons', this._formBuilder.array([
        this.createContactsGroup(contact, formArray)
      ]));
    } else {
      form.addControl('ContactPersons', this._formBuilder.array([
        this.createContactsGroup(contact, null)
      ]));
    }
  }

  async addTrustee() {
    const fields = this.trusteeForm.get('trustees') as FormArray;
    fields.push(this.createTrusteeFormGroup());
    this.trusteeForm.setControl('trustees', fields);
  }
  async addNewContact(index:number) {
    const fields = this.getContacts(index);
    fields.push(this.createContactsGroup(null, this.getContacts(this.getTrustees().length-1)));
    this.getTrusteeFormGroup(index).setControl('ContactPersons', fields);

    let currentTrustees = this.trustees;
    const latestRecord = currentTrustees[currentTrustees.length-1];
    
    const record =  this.getContacts(this.getTrustees().length - 1);
    const updatedRecord = {...latestRecord, ...{ContactPersons: record.getRawValue()}};
    Object.assign(currentTrustees[currentTrustees.length-1], updatedRecord);

    await this.estateControlService.updateRecord(5, currentTrustees);
  }
  
  async removeTrustee(i:number) {
    this.currentIndex = i;
    const fields = this.trusteeForm.get('trustees') as FormArray;
    let currentTrustees = this.trustees;

    if (currentTrustees[i] && currentTrustees[i].TrusteeID) {
      console.log ('Trustee ID to remove : ', currentTrustees[i].TrusteeID);
      this.removeButtonText = 'Removing Trustee';
      this.loadingRemove = true;

      try {
        if (this.estateControlService.TrustID !== null) {
          const result = await this.tabControlService.removeRecord(
            'Beneficiaries',
            currentTrustees[i].TrusteeID,
            parseInt(this.estateControlService.TrustID));
  
          if (result === 1) {
            this.notificationService.viewToast('success', 'Trustee removed successfully');
            fields.removeAt(i);
            currentTrustees.splice(i, 1);;
            this.estateControlService.updateRecord(5, currentTrustees);
          } else {
            this.notificationService.viewToast('error', 'Error removing record');
          }
        } else {
          console.log('Relevant IDs not found');
        }
      } catch (error) {
        console.log(':: Error !! ', error);
      }
      this.removeButtonText = this.removeBenText;
      this.loadingRemove = false;
    } else {
      if (currentTrustees[i]) {
        fields.removeAt(i);
        // Remove settlor at index
        currentTrustees.splice(i, 1);;
        this.estateControlService.updateRecord(5, currentTrustees);
      } else {
        fields.removeAt(i);
      }
    }
  }

  async removeContact(i:number, j: number) {
    const fields = this.getContacts(i);
    fields.removeAt(j);
  }

  getTrustees() : FormArray {  
    return this.trusteeForm.get("trustees") as FormArray;
  }
  getContacts(index: number) : FormArray {
    return this.getTrustees().at(index).get('ContactPersons') as FormArray;  
  }
  getTrusteeFormGroup(index: number): FormGroup {
    return this.getTrustees().at(index) as FormGroup;
  }
  getContactsFormGroup(index: number, innerdex: number): FormGroup {
    return this.getContacts(index).at(innerdex) as FormGroup;
  }

  async saveTrustee(index: number, record: FormGroup): Promise<void> {
    this.notificationService.isLoading(true);
    let tsMessage = this.addText;
    
    try {
      let temp = record.getRawValue();
      let originalContacts;
      let currentTrustees = this.trustees;

      if (record.getRawValue().Category.toLowerCase() === 'individual trustee') {
        temp.ContactPersons = [];
      }

      let data = {...temp, ...{TrustID: this.estateControlService.TrustID}};

      if (currentTrustees[index].contactsDone) {
        originalContacts = data.ContactPersons;
        data.ContactPersons = [];
      }

      if (data.Other && data.Other != '') {
        const other = data.Other;
        data.Relationship = other;
      }
      if (data.hasOwnProperty('Other')) {
        delete data['Other'];
      }

      if (!(data.IDNoImage === '' || data.IDNoImage === null)) {
        data.IDNoImage = this.idObject;
      } else {
        data.IDNoImage = {name: '', stringValue: ''};
      }

      if (!(data.KraPinImage === '' || data.KraPinImage === null)) {
        data.KraPinImage = this.kraObject;
      } else {
        data.KraPinImage = {name: '', stringValue: ''};
      }

      if (!(data.RegCertImage === '' || data.RegCertImage === null)) {
        data.RegCertImage = this.regCertObject;
      } else {
        data.RegCertImage = {name: '', stringValue: ''};
      }
      if (!(data.PassportImage === '' || data.PassportImage === null)) {
        data.PassportImage = this.passportObject;
      } else {
        data.PassportImage = {name: '', stringValue: ''};
      }
      if (!(data.CvImage === '' || data.CvImage === null)) {
        data.CvImage = this.cvObject;
      } else {
        data.CvImage = {name: '', stringValue: ''};
      }

      
      if (currentTrustees[index].TrusteeID) {
        data = {...data, ...{TrusteeID: currentTrustees[index].TrusteeID}};
      }

      const response = await this.apiService.postRequest(
        environment.baseUrl + Constants.privateTrusteeURL, data);
      // console.log('::::: RESPONSE\n', response);

      if (response.Status === 1) {
        let latestRecord = currentTrustees[index];

        if (this.autofilled) {
          latestRecord.personalDone = true;
          latestRecord.contactsDone = true;
        }

        const updatedRecord = {...latestRecord, ...record.getRawValue(), ...{TrusteeID: response.TrusteeID}};
        Object.assign(currentTrustees[index], updatedRecord);

        this.estateControlService.updateRecord(5, currentTrustees);    
        currentTrustees = this.trustees; // update var

        if (currentTrustees[index].contactsDone) {
          tsMessage = this.updateText;
          let currentRecord = currentTrustees[index];
          
          if (record.getRawValue().Category.toLowerCase() === 'individual trustee') {
            currentRecord.ContactPersons = originalContacts;
          }

          if (data.IDNoImage && data.IDNoImage.stringValue.length > 5 && response.IdURL) {
            currentRecord.IDNoImage = this.idObject;
            currentRecord = {...currentRecord, ...{IDURL: response.IdURL}};
            this.estateControlService.updateDocSummary(response.IdURL);
          }
          if (data.KraPinImage && data.KraPinImage.stringValue.length > 5 && response.KraURL) {
            currentRecord.KraPinImage = this.kraObject;
            currentRecord = {...currentRecord, ...{KraURL: response.KraURL}};
            this.estateControlService.updateDocSummary(response.KraURL);
          }
          if (data.PassportImage && data.PassportImage.stringValue.length > 5 && response.PassportURL) {
            currentRecord.PassportImage = this.passportObject;
            currentRecord = {...currentRecord, ...{PassportURL: response.PassportURL}};
            this.estateControlService.updateDocSummary(response.PassportURL);
          }
          if (data.RegCertImage && data.RegCertImage.stringValue.length > 5 && response.RegCertURL) {
            currentRecord.RegCertImage = this.regCertObject;
            currentRecord = {...currentRecord, ...{RegCertURL: response.RegCertURL}};
            this.estateControlService.updateDocSummary(response.RegCertURL);
          }
          if (data.CvImage && data.CvImage.stringValue.length > 5 && response.CvURL) {
            currentRecord.CvImage = this.cvObject;
            currentRecord = {...currentRecord, ...{CvURL: response.CvURL}};
            this.estateControlService.updateDocSummary(response.CvURL);
          }
          currentRecord.done = true;
          Object.assign(currentTrustees[index], currentRecord);
          this.estateControlService.updateRecord(5, currentTrustees);

          if (this.autofilled) this.autofilled = false;

        } else {
          if (record.getRawValue().Category.toLowerCase() === 'individual trustee') {
            let currentRecord = currentTrustees[index];
            currentRecord.personalDone = true;
            currentRecord.contactsDone = true;
            Object.assign(currentTrustees[index], currentRecord);

            this.estateControlService.updateRecord(5, currentTrustees);

            this.validationService.addOrRemoveValidator(true,
                this.getTrusteeFormGroup(index).get('IDNoImage')!);
            this.validationService.addOrRemoveValidator(true,
              this.getTrusteeFormGroup(index).get('KraPinImage')!);
            this.validationService.addOrRemoveValidator(true,
              this.getTrusteeFormGroup(index).get('PassportImage')!);
            this.validationService.addOrRemoveValidator(true,
              this.getTrusteeFormGroup(index).get('CvImage')!);

            // For updating auto-fill on 'self' relationship
            if (record.value.Relationship.toLowerCase() == 'self') {
              if (this.recordUpdate.IDNoImage) {
                if (this.recordUpdate.IDNoImage.stringValue &&
                  this.recordUpdate.IDNoImage.stringValue.length > 0) {

                  this.getTrusteeFormGroup(index).get('IDNoImage')?.setValue(
                    this.recordUpdate.IDNoImage.stringValue);
                }
                this.updateIDObject(this.recordUpdate.IDNoImage);
                this.getTrusteeFormGroup(index).get('IDNoImage')?.disable();
              }
              if (this.recordUpdate.KraPinImage) {
                if (this.recordUpdate.KraPinImage.stringValue &&
                  this.recordUpdate.KraPinImage.stringValue.length > 0) {

                  this.getTrusteeFormGroup(index).get('KraPinImage')?.setValue(
                    this.recordUpdate.KraPinImage.stringValue);
                }
                this.updateKraObject(this.recordUpdate.KraPinImage);
                this.getTrusteeFormGroup(index).get('KraPinImage')?.disable();
              }

              if (this.recordUpdate.IDNoImage || this.recordUpdate.KraPinImage) {
                this.notificationService.viewToast('info', 'Documents added from uploads');
              }
            }

          } else {
            if (currentTrustees[index].personalDone) {
              let currentRecord = currentTrustees[index];
              currentRecord.contactsDone = true;
              if (response.ContactPersons && response.ContactPersons.length > 0)
                currentRecord.ContactPersons = response.ContactPersons;
              Object.assign(currentTrustees[index], currentRecord);

              this.estateControlService.updateRecord(5, currentTrustees);

              this.validationService.addOrRemoveValidator(true,
                this.getTrusteeFormGroup(index).get('RegCertImage')!);

              this.validationService.addOrRemoveValidator(true,
                this.getTrusteeFormGroup(index).get('KraPinImage')!);

            } else {
              let currentRecord = currentTrustees[index];
              currentRecord.personalDone = true;
              Object.assign(currentTrustees[index], currentRecord);

              this.estateControlService.updateRecord(5, currentTrustees);
              this.addContactArray(
                this.getTrusteeFormGroup(this.getTrustees().length-1),
                null,
                this.getContacts(this.getTrustees().length-1)
              );
            }
          }
          console.log('>> New Trustees !! \n', this.trustees);
        }
        this.notificationService.viewToast('success', tsMessage);

      } else {
        this.notificationService.viewToast('error', response.Message);
      }
    } catch (error) {
      console.error(':: Error !! ', error);
      this.notificationService.viewToast('error', 'An unexpected error occurred');
    }
    this.notificationService.isLoading(false);
  }

  async updateProgress(): Promise<void> {
    if (!this.trustees[this.trustees.length-1].done && this.trusteeForm.valid) {
      const tees = this.trusteeForm.get('trustees') as FormArray;
      await this.saveTrustee(tees.length - 1, this.getTrusteeFormGroup(tees.length - 1));
    }
    this.saveProgress();
  }

  submitForm(): void {
    if (this.trustees[this.trustees.length-1].done === true) {
      this.navigateFunction();
      
    } else {
      const bens = this.trusteeForm.get('trustees') as FormArray;
      this.saveTrustee(bens.length - 1, this.getTrusteeFormGroup(bens.length - 1));
    }
  }

  async navigateFunction(): Promise<void> {
    if (!this.estateControlService.page5Done) await this.estateControlService.updatePrivateTrustProgress('Trustees');
    await this.estateControlService.changeTab(6);
    this.estateControlService.page5Done = true;
  }
}
