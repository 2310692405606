import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api/api.service';
import { TabControlService } from 'src/app/services/tab-control.service';
import { ValidationService } from 'src/app/services/validators/validation.service';
import { environment } from 'src/environments/environment';
import * as Constants from "../../constants/constants";
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-book-call',
  templateUrl: './book-call.component.html',
  styleUrls: ['./book-call.component.scss']
})
export class BookCallComponent {

  @Output() close = new EventEmitter<void>();

  nameLabel : string = 'Your Name';
  nameHint : string = 'Enter your name';

  emailLabel : string = 'Your Email';
  emailHint : string = 'Enter email';
  
  phoneLabel : string = 'Phone Number';

  reasonLabel: string = 'Reason for call';
  reasonHint: string = 'Your reason';

  requiredFieldString: string = 'Required field';

  buttonText: string = 'Submit Request';
  loading: boolean = false;


  formErrors: { [key: string]: string } = {
    Name: '',
    Phone: '',
    Email: '',
    Reason: '',
  };

  validationMessages: { [key: string]: {} } = {
    Name: { required: this.requiredFieldString },
    Phone: { required: this.requiredFieldString, pattern:'Invalid phone number' },
    Email: { required: this.requiredFieldString, email: "Invalid email address" },
    Reason: { required: this.requiredFieldString },
  };

  bookForm = this._formBuilder.group({
    Name: ['', Validators.required],
    Phone: ['', Validators.required],
    Email: ['', [Validators.email, Validators.required]],
    Reason: [''],

    iSwear: [false],
  });


  constructor (
    private _formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private tabControlService: TabControlService,
    private validationService: ValidationService,
    private apiService: ApiService
  ) {}

  ngOnInit(): void {}

  logErrors() {
    this.formErrors = this.validationService.logValidationErrors(
      this.bookForm,
      this.formErrors,
      this.validationMessages
    );
  }

  closePopup(): void {
    if (!this.loading) {
      this.close.emit();
    }
  }

  async submitRequest(): Promise<void> {
    this.loading = true;

    try {
      const data = {
        Name: this.bookForm.value.Name,
        Phone: this.bookForm.value.Phone,
        Email: this.bookForm.value.Email,
        Reason: this.bookForm.value.Reason,
      };

      const response = await this.apiService.postRequest(
        environment.baseUrl + Constants.bookCallURL, data);
      // console.log('::::: RESPONSE\n', response);
      if (response.Status === 1) {
        this.notificationService.viewToast('success', 'Your request has been received.');
        this.loading = false;
        this.close.emit();
      } else {
        this.notificationService.viewToast('error', 'Sorry, could not log your request');
        this.loading = false;
      }
    } catch(error) {
      this.loading = false;
      this.notificationService.viewToast('error', 'An unexpected error occurred');
    }
  }
}
