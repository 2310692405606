<form [formGroup]="paymentForm" (ngSubmit)="submitForm()" class="tab-form" autocomplete="off" novalidate>
    <div class="form-80">
        <h1 class="blue margin">{{ title }}</h1>
        <p class="lead rocal">
            {{ subtitle }}
        </p>

        <div class="flex blue-box">
            <p class="blue lead">
                {{ paymentTitle }}
            </p>
            <div class="line-2"></div>

            <div class="flex row">
                <p class="lead rocal">Trust Creation Fee</p>
                <p><strong>KSH{{' ' +trustFee.toLocaleString('en-US')}}</strong></p>
            </div>
            <div class="line-2"></div>

            <p class="lead rocal">
                Select any additional services below (Optional)
            </p>
            <!-- CHECKBOXES -->
            <div class="flex column pay-bar grey-borders">
                <div class="flex fill grey-bottom">
                    <mat-checkbox [formControl]="plusLegal" style="width: 100%;">
                        <div class="flex row">
                            <div class="flex fill">
                                <p class="lead rocal">
                                    Legal Review and Opinion of Asset Listing
                                </p>
                            </div>
                            
                            <p><strong>KSH{{' ' +legalReviewFee.toLocaleString('en-US')}}</strong></p>
                        </div>
                    </mat-checkbox>
                </div>

                <div class="flex fill grey-bottom">
                    <mat-checkbox [formControl]="plusTax" style="width: 100%;">
                        <div class="flex row">
                            <div class="flex fill">
                                <p class="lead rocal">
                                    Tax Consultation
                                </p>
                            </div>
                            
                            <p><strong>KSH{{' ' +taxConsFee.toLocaleString('en-US')}}</strong></p>
                        </div>
                    </mat-checkbox>
                </div>

                <div class="flex fill">
                    <mat-checkbox [formControl]="plusInvestment" style="width: 100%;">
                        <div class="flex row">
                            <div class="flex fill">
                                <p class="lead rocal">
                                    Investment Consultation
                                </p>
                            </div>
                            
                            <p><strong>KSH{{' ' +investmentConsFee.toLocaleString('en-US')}}</strong></p>
                        </div>
                    </mat-checkbox>
                </div>
            </div>
            <!-- TOTAL AMOUNT TO PAY -->
            <div class="flex fill row">
                <div class="flex fill">
                    <p class="blue lead">
                        Total Amount To Pay
                    </p>
                </div>
                
                <p class="amount"><strong>KSH{{' ' +totalAmount.toLocaleString('en-US')}}</strong></p>
            </div>
            <div class="line" style="margin-top: 15px;"></div>
            <!-- MAKE PAYMENT -->
            <div *ngIf="!payment.done">
                <p class="blue lead">
                    {{ title }}
                </p>
                <p>
                    {{makePaymentOption}}
                </p>
                <!-- FULL / PARTIAL PAYMENT -->
                <div class="row" style="display: flex; align-items: center; justify-content: center; margin-top: 10px;">
                    <app-radios style="display: flex; width: 100%; align-items: center; justify-content: center;"
                        labelOptionA="Pay Full Amount"
                        labelOptionB="Pay in Installments"
                        [preSelect]="false"
                        (valueChange)="onOptionChange($event)" >
                    </app-radios>
                </div>
                <p>
                    *Receive a 10% discount for full payment
                </p>
    
                <div *ngIf="payFullAmount == false">
                    <p class="blue lead">
                        {{ instalmenPaymentsTitle }}
                    </p>
                    <p>
                        {{instalmenPaymentOption}}
                    </p>
    
                    <div class="line-2"></div>
                    <!-- INSTALLMENTS OPTION -->
                    <div class="row" style="display: flex; align-items: center; justify-content: center; margin-top: 25px;">
                        <app-radios style="display: flex; width: 100%; align-items: center; justify-content: center;"
                            labelOptionA="50% upon submitting application then 50% within four (4) months"
                            labelOptionB="30% upon submitting application then 70% within four (4) months"
                            [preSelect]="false"
                            (valueChange)="onInstalmentChange($event)" >
                        </app-radios>
                    </div>
                </div>
    
                <div *ngIf="payFullAmount == true || payFullAmount == false && pay50Instalment !== null" style="margin-top: 20px;">
                    <p class="blue lead">
                        {{ trustDetailsTitle }}
                    </p>
                    <div class="line-2"></div>
                    <div class="row half">
                        <app-input [formGroup]="paymentForm" controlName="trustName"
                            label={{trustLabel}} hint={{trustHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>
    
                    <div class="row" style="margin-top: 10px;"
                        [ngClass]="{'width-50' : !(
                            paymentForm.get('referree')?.value === 'Other' ||
                            paymentForm.get('referree')?.value === 'Independent Agent' ||
                            paymentForm.get('referree')?.value === 'Broker' ||
                            paymentForm.get('referree')?.value === 'ICEA LION Financial Advisor' ||
                            paymentForm.get('referree')?.value === 'ICEA LION Trust Consultant'
                        )}
                    ">
                        <app-input [formGroup]="paymentForm" controlName="referree"
                            label={{referreLabel}} hint={{selectText}}
                            inputType="select"
                            [ArrayItems]="HearAboutusData"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
            
                        <app-input [formGroup]="paymentForm" controlName="other"
                            *ngIf="paymentForm.get('referree')?.value === 'Other'"
                            label={{referreeOtherLabel}} hint={{referreeOtherHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        
                        <app-input [formGroup]="paymentForm" controlName="referreeName"
                            *ngIf="paymentForm.get('referree')?.value === 'Independent Agent' ||
                                paymentForm.get('referree')?.value === 'Broker' ||
                                paymentForm.get('referree')?.value === 'ICEA LION Financial Advisor' ||
                                paymentForm.get('referree')?.value === 'ICEA LION Trust Consultant' "
    
                            [label]="
                                paymentForm.get('referree')?.value === 'Broker' ? 'Name of Broker' :
                                paymentForm.get('referree')?.value === 'ICEA LION Trust Consultant' ? 'Name of Consultant' :
                                paymentForm.get('referree')?.value === 'ICEA LION Financial Advisor' ? 'Name of Financial Advisor'
                                : agentLabel"
                                
                            inputType="auto-complete"
                            [ArrayItems]="IntermediaryOptions"
                            [loadingOptions]="loadingIntermediaries"
                            hint={{agentHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors">
                        </app-input>
                    </div>
                </div>
    
                <div *ngIf="payFullAmount == true || payFullAmount == false && pay50Instalment !== null" style="margin-top: 20px;">
                    <p class="blue lead">
                        {{ paymentDetailsTitle }}
                    </p>
                    <div class="line-2"></div>
    
                    <div class="row width-50" *ngIf="payFullAmount == false && pay50Instalment !== null">
                        <app-input
                            [formGroup]="paymentForm"
                            controlName="PaymentMode"
                            label={{paymentModeLabel}}
                            inputType="select"
                            [ArrayItems]="PaymentModeOptions"
                            [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                    </div>
    
                    <!-- FULL PAYMENT / PARTIAL BANK PAYMENT-->
                    <div *ngIf="payFullAmount == true || (payFullAmount == false && pay50Instalment !== null && paymentForm.controls['PaymentMode'].value !== 'M-Pesa')">
                        <div class="row">
                            <app-input [formGroup]="paymentForm"
                                inputType="view"
                                label='Pay'
                                subtext="{{amountHint}}"
                                hint="{{ 'KSH ' +paymentAmount.toLocaleString()}}"></app-input>
                            <app-input [formGroup]="paymentForm" controlName="bank"
                                [selectFunction]="populateBankBranchesList.bind(this)"
                                inputType="select"
                                [ArrayItems]="BanksOptions"
                                [loadingOptions]="loadingBanks"
                                label={{bankLabel}} hint={{bankHint}}
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        </div>
    
                        <div class="row">
                            <app-input [formGroup]="paymentForm" controlName="bankBranch"
                                label={{bankBranchLabel}} hint={{bankBranchHint}}
                                inputType="select"
                                [ArrayItems]="BranchPlaceHolders"
                                [loadingOptions]="loadingBankBranches"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            <app-input [formGroup]="paymentForm" controlName="accName"
                                label={{accNameLabel}} hint={{accNameHint}}
                                [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                        </div>
            
                        <div class="row">
                            <app-input [formGroup]="paymentForm" controlName="acc"
                                label={{accLabel}} hint={{accHint}}
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input
                                 >
                            <app-input [formGroup]="paymentForm" controlName="slipDoc"
                                [fileFunction]="updateSlipObject.bind(this)"
                                label={{uploadSlipLabel}} inputType="file"
                                [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                        </div>
                    </div>
    
                    <!-- PARTIAL PAYMENT MPESA -->
                    <div *ngIf="payFullAmount == false && pay50Instalment !== null && paymentForm.controls['PaymentMode'].value == 'M-Pesa'">
                        <div class="row">
                            <app-input [formGroup]="paymentForm" controlName="phone"
                                label={{phoneLabel}} inputType="phone"
                                [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                            <app-input [formGroup]="paymentForm"
                                inputType="view"
                                label='Amount' hint="{{ 'KSH ' +paymentAmount.toLocaleString()}}"></app-input>
                        </div>
                    </div>
    
                    <!-- BANK DETAILS -->
                    <div *ngIf="payFullAmount == true || (payFullAmount == false && pay50Instalment !== null && paymentForm.controls['PaymentMode'].value !== 'M-Pesa')" class="bankBox-wrap">
                        <div class="bankBox">
                            <p class="lead blue" style="margin-bottom: -20px; margin-left: 0px; font-weight: 600;">
                                {{instructionsTitleBank}}
                            </p>
                            <div class="line"></div>
                            <div class="row">
                                <div>{{iceaFundLabel}}</div> <div class="right-divs">{{iceaFundNameVal}}</div>    
                            </div>
                            <div class="row">
                                <div>{{iceaAcc}}</div> <div class="right-divs">{{iceaAccountVal}}</div>
                            </div>
                            <div class="row">
                                <div>{{iceaBank}}</div> <div class="right-divs">{{iceaBankVal}}</div>
                            </div>
                            <div class="row">
                                <div>{{iceaBranch}}</div> <div class="right-divs">{{iceaBranchVal}}</div>
                            </div>
                            <div class="row">
                                <div>{{iceaBankCode}}</div> <div class="right-divs">{{iceaBankCodeVal}}</div>
                            </div>
                            <div class="row">
                                <div>{{iceaBranchCode}}</div> <div class="right-divs">{{iceaBranchCodeVal}}</div>
                            </div>
                            <div class="row">
                                <div>{{iceaClearing}}</div> <div class="right-divs">{{iceaClearingVal}}</div>
                            </div>
                        </div>
                    </div>
    
                    <!-- M-PESA DETAILS -->
                    <div *ngIf="payFullAmount == false && pay50Instalment !== null && paymentForm.controls['PaymentMode'].value == 'M-Pesa'" class="badge-wrapper">
                        <div class="badge">
                            <p class="lead blue" style="font-size: larger; margin-left: 2.8%; font-weight: 600;">
                                {{instructionsTitle}}
                            </p>
                            <ol>
                                <li>{{inst1}}</li>
                                <li>{{inst2}}</li>
                                <li>{{inst3}}</li>
                            </ol>
    
                            <p class="lead blue" style="font-size: larger; margin-left: 2.8%; font-weight: 600;">
                                {{instructionsTitle2}}
                            </p>
                            <ul>
                                <li>{{inst4}}</li>
                                <li>{{inst5}}<b>{{iceaPaybill}}</b></li>
                                <li>{{inst6}}<b>{{ paymentID }}</b></li>
                            </ul>
                            <!-- Confirm payment -->
                             <div style="height: 80px;" class="flex align">
                                <div mat-button type="button" class="btn-primary next-button confirm-payment-button" (click)="mpesaPayment(true)">
                                    {{ 'Confirm payment' }}
                                </div>
                             </div>

                            <div style="height: 20px;"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- PAYMENT SUMMARY -->
            <div *ngIf="payment.done">
                <div class="badge-wrapper" style="margin-top: 0px;">
                    <div class="badge">
                        <p class="lead blue" style="font-size: larger; margin-left: 2.8%; font-weight: 600;">
                            {{ 'Payment Summary' }}
                        </p>
                        <ul>
                            <li>{{'Payment Mode : '}}&nbsp;&nbsp;<b>{{ payment.mode || 'MPESA' }}</b></li>
                            <li *ngIf="payment.mode != 'RTGS' ">{{'Phone Number : '}}&nbsp;&nbsp;<b>{{ payment.phone || '0712333454' }}</b></li>
                            <li>Paid Amount&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;<b>KSH{{payment.Amount ? ' ' +payment.Amount.toLocaleString('en-US') : ' 10, 000 Tests' }}</b></li>
                            
                            <br *ngIf="payment.mode != 'RTGS' ">
                            <li *ngIf="payment.mode != 'RTGS' ">    
                                {{'MPESA Receipt : '}}<b style="color: #003764;">{{ stkRequestID }}</b>
                            </li>
                        </ul>
                        <div style="height: 20px;"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- BOTTOM OF FORM -->
    <app-form-bottom style="width: 100%;"
        [service]="estateControlService"
        [text]="payment.done ? (loading ? 'Saving Details': 'Next') : (loading ? 'Processing Payment': 'Submit') "
        [formValid]="payment.done || payment.uploaded ? true : paymentForm.valid ? true : false"
        [showContinue]="'false'"
        [buttonClickHandler]="submitForm.bind(this)">
    </app-form-bottom>
</form>
