<form [formGroup]="enforcerForm" (ngSubmit)="submitForm()" class="tab-form" autocomplete="off" novalidate>
    <div class="form-80">
        <h1 class="blue margin">{{title}}</h1>
        <p class="lead rocal">Add details of enforcer below or click on the&nbsp;<strong>Skip</strong>&nbsp;button below to skip.</p>

        <div class="flex" style="width: 100%; align-items: center; justify-content: center; margin-bottom: 20px;">
            <div class="flex brief" *ngIf="suggestions === null && showSuggestHint"[@bounceIn]>
                If the Enforcer is also a Settlor, use the ID field to search and autofill their details
            </div>
        </div>

        <div class="flex godolkin">

            <div class="flex suggestions" *ngIf="suggestions !== null" [@bounceIn] #suggestBox>
                <div class="flex suggest">
                    Autofill
                </div>

                <div class="flex sugg-holder">
                    <div [@slideInRight] *ngFor="let suggestion of suggestions; let s=index"
                        (click)="patchSuggestedValues(suggestion)"
                        class="flex suggestion">
                        {{ suggestion.FirstName +' ' +suggestion.LastName }}
                    </div>
                </div>
            </div>
        </div>

        <div class="flex blue-box" *ngIf="!enforcer.verified">
            <div class="flex align width-100" style="justify-content: space-between;">
                <p class="blue lead" >
                    {{subtitle}}
                </p>

                <div class="flex center reset-button" (click)="resetForm()" *ngIf="enforcerForm.touched">
                    <mat-icon style="margin-right: 10px;" fontIcon="clear_all" aria-hidden="false" aria-label="reset form"></mat-icon>
                    {{ 'Reset Form' }}
                </div>
            </div>
            
            <div class="line-2"></div>

            <div class="row-alt">
                <div class="row-mini">
                    <app-input [formGroup]="enforcerForm" controlName="Title"
                        label={{titleLabel}} hint={{titleHint}}
                        inputType="select" required='false'
                        [loadingOptions]="loadingTitles"
                        [ArrayItems]="TitleOptions"
                        [validationMessages]="validationMessages" [formErrors]="formErrors">
                    </app-input>

                    <app-input [formGroup]="enforcerForm" controlName="FirstName"
                        label={{fNameLabel}} hint={{fNameHint}}
                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                </div>
                
                <div class="row-mini">
                    <app-input [formGroup]="enforcerForm" controlName="MiddleName"
                        label={{mNameLabel}} hint={{mNameHint}} required='false'
                        [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                    <app-input [formGroup]="enforcerForm" controlName="LastName"
                        label={{surnameLabel}} hint={{surnameHint}}
                        [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                </div>
                
            </div>

            <div class="row">
                <app-input [formGroup]="enforcerForm" class="grid-item"
                    controlName="Phone" label={{phoneLabel}}  inputType="phone"
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                <app-input [formGroup]="enforcerForm" class="grid-item"
                    controlName="Email" label={{emailLabel}} hint={{emailLabel}}
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
            </div>

            <div class="row">
                <app-input [formGroup]="enforcerForm" class="grid-item"
                    controlName="IDNo" label={{idLabel}} hint={{idHint}}
                    [runSuggestions]="true"
                    [altIDSuggest]="true"
                    [suggestID]="suggestSettlors.bind(this)"
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                <app-input [formGroup]="enforcerForm" class="grid-item"
                    controlName="KraPin" label={{kraPinLabel}} hint={{kraHint}}
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
            </div>

            <div class="row">
                <app-input [formGroup]="enforcerForm" class="grid-item"
                    controlName="Address" label={{addressLabel}} hint={{addressHint}}
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                <app-input [formGroup]="enforcerForm" class="grid-item"
                    controlName="Relationship"
                    inputType="select"
                    [selectFunction]="onRelationshipChange.bind(this)"
                    [ArrayItems]="RelationOptions"
                    label={{relationLabel}} hint={{relationHint}}
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
            </div>   
            
            <div class="row half" *ngIf="enforcerForm.get('Relationship')?.value === 'Other'">
                <app-input [formGroup]="enforcerForm" controlName="Other"
                    label='Specify Relationship' hint='Specify your relation to the settlor'
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
            </div>
        </div>

        <div class="flex done-box" *ngIf="enforcer.verified">
            <p class="light-blue lead" >
                {{subtitle}}
            </p>
            <div class="flex checker-circle">
                <img src={{checkerPath}} class="checker" alt="logo" />
            </div>
        </div>

        <div class="flex blue-box" *ngIf="(enforcer.verified || autofilled) && !enforcer.done">
            <p class="blue lead" >
                {{uploadTitle}}
            </p>
            <div class="line-2"></div>

            <div class="row">
                <app-input [formGroup]="enforcerForm"
                    controlName="IDNoImage"
                    [validationID]="enforcerForm.get('IDNo')?.value"
                    [fileFunction]="updateIDObject.bind(this)"
                    [existingFileName]="enforcerForm.get('Relationship')?.value == 'Self' &&
                                        recordUpdate.IDNoImage != '' ? recordUpdate.IDNoImage.name : ''"
                    class="grid-item" label={{idFileLabel}} hint={{idHint}} inputType="file"
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                <app-input [formGroup]="enforcerForm" class="grid-item"
                    controlName="KraPinImage"
                    [validationID]="enforcerForm.get('KraPin')?.value"
                    label={{kraFileLabel}} inputType="file"
                    [fileFunction]="updateKraObject.bind(this)"
                    [existingFileName]="enforcerForm.get('Relationship')?.value == 'Self' &&
                                        recordUpdate.KraPinImage != '' ? recordUpdate.KraPinImage.name : ''"
                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
            </div>
        </div>

        <div class="flex done-box" *ngIf="enforcer.done">
            <p class="light-blue lead" >
                {{uploadTitle}}
            </p>
            <div class="flex checker-circle">
                <img src={{checkerPath}} class="checker" alt="logo" />
            </div>
        </div>
    </div>

    <!-- BOTTOM OF FORM -->
    <app-form-bottom style="width: 100%;"
        [service]="estateControlService"
        [text]="!enforcerForm.touched ? 'Skip' : loading ? loadingText : enforcer.done ? 'Next' : enforcer.verified ? 'Upload Documents' :  'Save Details' "
        [showContinue]="enforcerForm.touched ? (enforcerForm.valid || enforcer.done ? 'true' : 'false') : 'true'"
        [saveFunction]="updateProgress.bind(this)"
        [formValid]="!enforcerForm.touched ? true : enforcerForm.valid"
        [buttonClickHandler]="submitForm.bind(this)"></app-form-bottom>
</form>
