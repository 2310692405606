import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private toastr: ToastrService) { }

  // Loading control
  loading: boolean = false;
  loadingTab: boolean = false;
  validatingFile: boolean = false;

  defaultMessage: string = 'Sorry, an error occurred';
  autoDismissTimeout: any;
  public showToast: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public persistToast: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public toastMessage: BehaviorSubject<string> = new BehaviorSubject<string>(this.defaultMessage);
  public toastSubMessage: BehaviorSubject<string> = new BehaviorSubject<string>(''); 
  public toastType: BehaviorSubject<'error' | 'success' | 'info'> =
    new BehaviorSubject<'error' | 'success' | 'info'>('error');
  

  isLoading(value: boolean) {
    this.loading = value;
  }
  isLoadingTab(value: boolean) {
    this.loadingTab = value;
  }
  isValidatingFile(value: boolean) {
    this.validatingFile = value;
  }

  // Displays Toast
  viewToast(
    toastState: 'error' | 'success' | 'info' | 'warning',
    toastMsg: string,
    toastSubMsg: string = '',
    persist: boolean = false
  ): void {  
    try {
      if (toastState == 'success')
        this.showSuccess(toastMsg, toastSubMsg, persist)
      else if (toastState == 'info')
        this.showInfo(toastMsg, toastSubMsg, persist)
      else if (toastState == 'error')
        this.showError(toastMsg, toastSubMsg, persist)
      else
        this.showInfo(toastMsg, toastSubMsg, persist)
    } catch (error) {
      console.error('notify ', error);
    }
  }

  // Toastr Functions

  showSuccess(msg: string, subMsg: string, persist: boolean) {
    if (subMsg == '')
      this.toastr.success(
        msg,
        undefined,
        {
          disableTimeOut: (persist ? 'timeOut' : persist),
          closeButton: persist,
          toastClass: 'ngx-toastr toaster-success',
          titleClass: 'toast-title toaster-title',
          messageClass: 'toast-message toaster-title'
        });
    else
      this.toastr.success(
        subMsg,
        msg, 
        {
          disableTimeOut: (persist ? 'timeOut' : persist),
          closeButton: persist,
          toastClass: 'ngx-toastr toaster-success',
          titleClass: 'toast-title toaster-title',
          messageClass: 'toast-message toaster-message'
        });
  }

  showInfo(msg: string, subMsg: string, persist: boolean) {
    if (subMsg == '')
      this.toastr.info(
        msg,
        undefined,
        {
          disableTimeOut: (persist ? 'timeOut' : persist),
          closeButton: persist,
          toastClass: 'ngx-toastr toaster-info',
          titleClass: 'toast-title toaster-title',
          messageClass: 'toast-message toaster-title'
        });
    else
      this.toastr.info(
        subMsg,
        msg,
        {
          disableTimeOut: (persist ? 'timeOut' : persist),
          closeButton: persist,
          toastClass: 'ngx-toastr toaster-info',
          titleClass: 'toast-title toaster-title',
          messageClass: 'toast-message toaster-message'
        });
  }

  showWarning(msg: string, subMsg: string, persist: boolean) {
    if (subMsg == '')
      this.toastr.warning(
        msg,
        undefined,
        {
          disableTimeOut: (persist ? 'timeOut' : persist),
          closeButton: persist,
          toastClass: 'ngx-toastr toaster-warn',
          titleClass: 'toast-title toaster-title',
          messageClass: 'toast-message toaster-title'
        });
    else
      this.toastr.warning(
        subMsg,
        msg,
        {
          disableTimeOut: (persist ? 'timeOut' : persist),
          closeButton: persist,
          toastClass: 'ngx-toastr toaster-warn',
          titleClass: 'toast-title toaster-title',
          messageClass: 'toast-message toaster-message'
        });
  }

  showError(msg: string, subMsg: string, persist: boolean) {
    if (subMsg == '')
      this.toastr.error(
        msg,
        undefined,
        {
          disableTimeOut: (persist ? 'timeOut' : persist),
          closeButton: persist,
          toastClass: 'ngx-toastr toaster-error',
          titleClass: 'toast-title toaster-title',
          messageClass: 'toast-message toaster-title'
        });
    else
      this.toastr.error(
        subMsg,
        msg,
        {
          disableTimeOut: (persist ? 'timeOut' : persist),
          closeButton: persist,
          toastClass: 'ngx-toastr toaster-error',
          titleClass: 'toast-title toaster-title',
          messageClass: 'toast-message toaster-message'
        });
  }
}
