import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { TabControlService } from 'src/app/services/tab-control.service';

@Component({
  selector: 'app-private-trust',
  templateUrl: './estate-planning.component.html',
  styleUrls: ['./estate-planning.component.scss']
})
export class EstatePlanningComponent {
  MileleLogoPath: string  = '../../../assets/Milele_logo_white_alt.png';
  logoPath: string  = '../../../assets/logo_w.png';

  daBabyPath: string  = '../../../assets/dad_baby.png';
  auntPath: string  = '../../../assets/aunt.png';
  familyPath: string  = '../../../assets/fam_2.png';
  specPath: string  = '../../../assets/spec_person.png';
  dadPath: string  = '../../../assets/dad.png';

  bookCall :string = 'Book A Call';
  startHere: string = 'Start Here';
  powererdBy: string = 'Powered by ICEA LION. All rights reserved.';

  constructor(
    private router: Router,
    private location: Location,
    private tabControlService: TabControlService
  ) {

  }

  showBookCall: boolean = false;
  showEstateOptions: boolean = false;

  toggleText1: boolean = false;
  toggleText2: boolean = false;
  toggleText3: boolean = false;
  toggleText4: boolean = false;
  toggleText5: boolean = false;

  toggleDescription(value: number): void {
    if (value == 1) this.toggleText1 = !this.toggleText1;
    if (value == 2) this.toggleText2 = !this.toggleText2;
    if (value == 3) this.toggleText3 = !this.toggleText3;
    if (value == 4) this.toggleText4 = !this.toggleText4;
    if (value == 5) this.toggleText5 = !this.toggleText5;
  }

  toggleBookPopupClose(value: boolean): void {
    this.showBookCall = value;
  }

  toggleEstatePopupClose(value: boolean): void {
    this.showBookCall = false;
    this.showEstateOptions = value;
  }

  navEstatePlanning(): void {
    this.toggleEstatePopupClose(true);
  }

  goBack(): void {
    this.location.back();
  }

  get navHome(): ()=> void {
    return this.tabControlService.navHome;
  }
  
}
