<form [formGroup]="executorForm" (ngSubmit)="submitForm()" class="tab-form" autocomplete="off" novalidate>
    <div class="form-80">
        <h1 class="blue margin">{{ formTitle }}</h1>
        <p class="lead rocal">{{ formSubtitle }}</p>

        <div formArrayName="executors">
            <div *ngFor="let executor of getExecutors().controls; let i=index" [formGroupName]="i" style="position: relative;">

                <div style="height: 40px;" *ngIf="i > 1"></div>
                <p style="font-weight: 600; font-size: 1.3em;" class="blue margin"  *ngIf="getExecutors().length > 1">{{'Executor ' + (i+1)}} </p>

                <div class="flex blue-box" *ngIf="executors[i] && !executors[i].done">
                    <p class="blue lead" >
                        {{ detailsTitle }}
                    </p>
                    <div class="line-2"></div>

                    <div class="row-alt">
                        <div class="row-mini">
                            <app-input [formGroup]="getExecutorFormGroup(i)" controlName="Title"
                                label={{titleLabel}} hint={{titleHint}} required="false"
                                inputType="select"
                                [loadingOptions]="loadingTitles"
                                [ArrayItems]="TitleOptions"
                                [validationMessages]="validationMessages" [formErrors]="formErrors">
                            </app-input>

                            <app-input [formGroup]="getExecutorFormGroup(i)" controlName="FirstName"
                                label={{fNameLabel}} hint={{fNameHint}}
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        </div>
                        
                        <div class="row-mini">
                            <app-input [formGroup]="getExecutorFormGroup(i)" controlName="MiddleName"
                                label={{mNameLabel}} hint={{mNameHint}} required='false'
                                [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                            <app-input [formGroup]="getExecutorFormGroup(i)" controlName="LastName"
                                label={{surnameLabel}} hint={{surnameHint}}
                                [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                        </div>
                        
                    </div>

                    <div class="row">
                        <app-input [formGroup]="getExecutorFormGroup(i)" class="grid-item"
                            controlName="Phone" label={{phoneLabel}}  inputType="phone"
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        <app-input [formGroup]="getExecutorFormGroup(i)" class="grid-item"
                            controlName="Email" label={{emailLabel}} hint={{emailLabel}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>

                    <div class="row">
                        <app-input [formGroup]="getExecutorFormGroup(i)" class="grid-item"
                            controlName="Relationship"
                            inputType="select"
                            [selectFunction]="onRelationshipChange.bind(this)"
                            [selectParam]="i"
                            [ArrayItems]="RelationOptions"
                            label={{settlorRelationLabel}} hint={{settlorRelationHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>

                        <app-input [formGroup]="getExecutorFormGroup(i)" class="grid-item"
                            controlName="IDNo" label={{idLabel}} hint={{idHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>

                    <div class="row half" *ngIf="getExecutorFormGroup(i).get('Relationship')?.value === 'Other'">
                        <app-input [formGroup]="getExecutorFormGroup(i)" controlName="Other"
                            label='Specify Relationship' hint='Specify your relation to the testator'
                            [validationMessages]="validationMessages" [formErrors]="formErrors">
                        </app-input>
                    </div>

                    <div class="row">
                        <app-input [formGroup]="getExecutorFormGroup(i)" class="grid-item"
                            controlName="Address" label={{addressLabel}} hint={{addressHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        <app-input [formGroup]="getExecutorFormGroup(i)" class="grid-item"
                            controlName="PostalAddress" label={{postalLabel}} hint={{postalHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>
                </div>

                <div class="flex done-box" *ngIf="executors[i] && executors[i].done">
                    <p class="light-blue lead" >
                        {{detailsTitle}}
                    </p>
                    <div class="flex checker-circle">
                        <img src={{checkerPath}} class="checker" alt="logo" />
                    </div>
                </div>

                <div  class="remove-button" *ngIf="i > 0">
                    <button mat-stroked-button type="button" (click)="removeExecutor(i)">
                        <div style="display: flex; flex: 1; align-items: center;">
                            <span class="button-text">
                                <u>{{ removeButtonText }}</u>
                            </span>
                            <span class="spinning-global"
                                *ngIf="currentIndex === i && loadingRemove">
                                <mat-progress-spinner
                                    mode="indeterminate" diameter="25" strokeWidth="4">
                                </mat-progress-spinner>
                            </span>
                        </div>
                    </button>
                </div>
            </div>
        </div>

        <!-- BUTTTON Add Testator -->
        <div class="blueStroke row" [ngClass]="{'min-55' : getExecutors().length > 1}">
            <button mat-stroked-button
                *ngIf="executors[executors.length-1] && executors[executors.length-1].done === true"
                [disabled]="loading || loadingRemove"
                class="general-stroked-button" type="button" (click)="addNewExecutor()">
                <span class="button-text">
                    {{ addExecutorText }}
                </span>
            </button>
        </div>
    </div>

    <!-- BOTTOM OF FORM -->
    <app-form-bottom style="width: 100%;"
        [service]="estateControlService"
        [text]="
            loading ? 'Saving Executor' :
            executors[executors.length-1] && executors[executors.length-1].done == true ? 'Next' :
            'Save Details'
        "

        [formValid]="
            (executors[executors.length-1] && executors[executors.length-1].done && executors[executors.length-1].done == true) ? true :
            executorForm.valid ? true : false
        "

        [showContinue]="executorForm.pristine || executorForm.valid || executors[executors.length-1].done ? 'true' : 'false'"
        [saveFunction]="updateProgress.bind(this)"
        [buttonClickHandler]="submitForm.bind(this)">
    </app-form-bottom>
</form>
