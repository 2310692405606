<form [formGroup]="trusteeForm" (ngSubmit)="submitForm()" class="tab-form" autocomplete="off" novalidate>
    <div class="form-80">
        <h1 class="blue margin">{{title}}</h1>
        <p class="lead rocal" style="margin-bottom: 0px;">{{subtitle}}</p>

        <div formArrayName="trustees">
            <div *ngFor="let ben of getTrustees().controls; let i=index" [formGroupName]="i" style="position: relative;">
<!-- 
                <div style="height: 40px;" *ngIf="trustees.length > 0 && !showSuggestHint"></div> -->

                <div class="flex" style="width: 100%; align-items: center; justify-content: center; margin-bottom: 20px;">
                    <div class="flex brief" *ngIf="i === getTrustees().length-1 && suggestions === null && showSuggestHint"[@bounceIn]>
                        If the Trustee is an existing Settlor, use the ID field to search and autofill their details
                    </div>
                </div>

                <div class="flex godolkin">
                    <p style="font-weight: 600; font-size: 1.3em;" class="blue margin" *ngIf="getTrustees().length > 1">
                        {{'Trustee ' + (i+1)}}
                    </p>

                    <div class="flex suggestions" *ngIf="i === getTrustees().length-1 && suggestions !== null" [@bounceIn] #suggestBox>
                        <div class="flex suggest">
                            Autofill
                        </div>

                        <div class="flex sugg-holder">
                            <div [@slideInRight] *ngFor="let suggestion of suggestions; let s=index"
                                (click)="patchSuggestedValues(i, suggestion)"
                                class="flex suggestion">
                                {{ suggestion.FirstName +' ' +suggestion.LastName }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex blue-box" *ngIf="trustees[i] && !trustees[i].personalDone || autofilled ? true : false">
                    <p class="blue lead" >
                        {{'Trustee Information'}}
                    </p>
                    <div class="line-2"></div>

                    <div class="row" [ngClass]="{'half': getTrusteeFormGroup(i).get('Category')?.value.toLowerCase().includes('corporate')}">
                        <app-input [formGroup]="getTrusteeFormGroup(i)" controlName="Category"
                            inputType="select"
                            [selectFunction]="onTrusteeTypeChange.bind(this)"
                            [selectParam]="i"
                            [ArrayItems]="TrusteeOptions"
                            label={{trusteeTypeLabel}} hint={{trusteeTypeHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        <app-input [formGroup]="getTrusteeFormGroup(i)" class="grid-item"
                            *ngIf="getTrusteeFormGroup(i).get('Category')?.value.toLowerCase().includes('individual')"
                            controlName="Relationship"
                            inputType="select"
                            [selectFunction]="onRelationshipChange.bind(this)"
                            [selectParam]="i"
                            [ArrayItems]="Relationships"
                            label={{relationLabel}} hint={{relationHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>

                    <div class="row half" *ngIf="getTrusteeFormGroup(i).get('Relationship')?.value === 'Other'">
                        <app-input [formGroup]="getTrusteeFormGroup(i)" controlName="Other"
                            label='Specify Relationship' hint='Specify your relation to the settlor'
                            [validationMessages]="validationMessages" [formErrors]="formErrors">
                        </app-input>
                    </div>

                    <div *ngIf="getTrusteeFormGroup(i).get('Category')?.value.toLowerCase() === 'individual trustee'">
                        <div class="row-alt">
                            <div class="row-mini">
                                <app-input [formGroup]="getTrusteeFormGroup(i)" controlName="Title"
                                    label={{titleLabel}} hint={{titleHint}}
                                    inputType="select"
                                    [loadingOptions]="loadingTitles"
                                    [ArrayItems]="TitleOptions"
                                    required="false"
                                    [validationMessages]="validationMessages" [formErrors]="formErrors">
                                </app-input>
            
                                <app-input [formGroup]="getTrusteeFormGroup(i)" controlName="FirstName"
                                    label={{fNameLabel}} hint={{fNameHint}}
                                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                
                            </div>
                            
                            <div class="row-mini">
                                <app-input [formGroup]="getTrusteeFormGroup(i)" controlName="MiddleName"
                                label={{mNameLabel}} hint={{mNameHint}} required='false'
                                [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                            <app-input [formGroup]="getTrusteeFormGroup(i)" controlName="LastName"
                                label={{surnameLabel}} hint={{surnameHint}}
                                [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                            </div>
                            
                        </div>
                        
                        <div class="row">
                            <app-input [formGroup]="getTrusteeFormGroup(i)" controlName="Phone"
                                class="grid-item" label={{phoneLabel}}  inputType="phone"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            <app-input [formGroup]="getTrusteeFormGroup(i)" controlName="Email"
                                class="grid-item" label={{emailLabel}} hint={{emailLabel}}
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        </div>
        
                        <div class="row">
                            <app-input [formGroup]="getTrusteeFormGroup(i)" controlName="IDNo"
                                label={{idLabel}} hint={{idHint}}
                                [runSuggestions]="true"
                                [altIDSuggest]="true"
                                [suggestID]="suggestSettlors.bind(this)"
                                [validationMessages]="validationMessages" [formErrors]="formErrors">
                            </app-input>
                            <app-input [formGroup]="getTrusteeFormGroup(i)" controlName="KraPin"
                                label={{kraPinLabel}} hint={{kraHint}}
                                [validationMessages]="validationMessages" [formErrors]="formErrors">
                            </app-input>
                        </div>
                    </div>

                    <div *ngIf="getTrusteeFormGroup(i).get('Category')?.value.toLowerCase() === 'corporate trustee'">
                        <div class="row">
                            <app-input [formGroup]="getTrusteeFormGroup(i)" controlName="InstitutionName"
                                class="grid-item" label={{institutionNameLabel}} hint={{institutionNameHint}}
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            <app-input [formGroup]="getTrusteeFormGroup(i)" controlName="BusinessNature"
                                label={{businessNatureLabel}} hint={{businessNatureHint}}
                                inputType="select"
                                [ArrayItems]="BusinessNatureOptions"
                                [loadingOptions]="loadingBizNature"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        </div>

                        <div class="row">
                            <app-input
                                [formGroup]="getTrusteeFormGroup(i)" controlName="OrgRegNum"
                                class="grid-item" label={{orgRegNumLabel}} hint={{orgRegNumHint}}
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>

                            <app-input [formGroup]="getTrusteeFormGroup(i)" controlName="KraPin"
                                class="grid-item" label="Institution KRA PIN" hint={{kraHint}}
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        </div>
                    </div>

                    <div class="row half">
                        <app-input
                            [formGroup]="getTrusteeFormGroup(i)" controlName="PostalAddress"
                            class="grid-item" label={{orgAddressLabel}} hint={{orgAddressHint}}
                            [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                    </div>
                </div>

                <div class="flex done-box" *ngIf="trustees[i] && trustees[i].personalDone ? true : false">
                    <p class="light-blue lead" >
                        {{'Trustee Information'}}
                    </p>
                    <div class="flex checker-circle">
                        <img src={{checkerPath}} class="checker" alt="logo" />
                    </div>
                </div>

                <!-- INSTITUTIONS ONLY -->
                <div class="flex blue-box"
                    *ngIf="
                        getTrusteeFormGroup(i).get('Category')?.value.toLowerCase() === 'corporate trustee' &&
                        trustees[i] &&
                        trustees[i].personalDone &&
                        !trustees[i].contactsDone
                    "    
                >
                    <p class="blue"  style="font-weight: 600; font-size: 1.3em;">
                        {{ 'Contact Person Information' }}
                    </p>

                    <div formArrayName="ContactPersons">
                        <div *ngFor="let contact of getContacts(i).controls; let j=index" [formGroupName]="j" style="position: relative;">
                            <div style="height: 20px;" *ngIf="j > 1"></div>
            
                            <p class="blue lead">
                                {{'Contact Person ' + (j+1)}}
                            </p>
                            <div class="line-2"></div>
    
                            <div class="row-alt">
                                <div class="row-mini">
                                    <app-input [formGroup]="getContactsFormGroup(i, j)" controlName="Title"
                                        label={{titleLabel}} hint={{titleHint}}
                                        inputType="select"
                                        [loadingOptions]="loadingTitles"
                                        [ArrayItems]="TitleOptions"
                                        required="false"
                                        [validationMessages]="validationMessages" [formErrors]="formErrors">
                                    </app-input>
                
                                    <app-input [formGroup]="getContactsFormGroup(i, j)" controlName="FirstName"
                                        label={{fNameLabel}} hint={{fNameHint}}
                                        [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                    
                                </div>
                                
                                <div class="row-mini">
                                    <app-input [formGroup]="getContactsFormGroup(i, j)" controlName="MiddleName"
                                    label={{mNameLabel}} hint={{mNameHint}} required='false'
                                    [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                                <app-input [formGroup]="getContactsFormGroup(i, j)" controlName="LastName"
                                    label={{surnameLabel}} hint={{surnameHint}}
                                    [validationMessages]="validationMessages" [formErrors]="formErrors" ></app-input>
                                </div>
                                
                            </div>
                            
                            <div class="row">
                                <app-input [formGroup]="getContactsFormGroup(i, j)" controlName="Phone"
                                    class="grid-item" label={{phoneLabel}}  inputType="phone"
                                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                                <app-input [formGroup]="getContactsFormGroup(i, j)" controlName="Email"
                                    class="grid-item" label={{emailLabel}} hint={{emailLabel}}
                                    [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            </div>
    
                            <div class="remove-button" style="margin-top: 5px;" *ngIf="j > 0">
                                <button mat-stroked-button type="button" (click)="removeContact(i, j)">
                                    <div style="display: flex; flex: 1; align-items: center;">
                                        <span class="button-text">
                                            <u>{{ removeContactText }}</u>
                                        </span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="line-2" style="margin-top: 40px;"></div>
                    <!-- BUTTTON Add Contact person -->
                    <div class="blueStroke row" [ngClass]="{'min-55' : getContacts(i).length > 1}">
                        <button mat-stroked-button
                            [disabled]="!trusteeForm.valid"
                            type="button" (click)="addNewContact(i)">
                            <span class="button-text">
                                {{ '+ Add Another Contact Person' }}
                            </span>
                        </button>
                    </div>
                    
                </div>

                <div class="flex done-box"
                    *ngIf="
                        getTrusteeFormGroup(i).get('Category')?.value.toLowerCase() === 'corporate trustee' &&
                        trustees[i] &&
                        trustees[i].personalDone &&
                        trustees[i].contactsDone">

                    <p class="light-blue lead" >
                        {{ 'Contact Information' }}
                    </p>
                    <div class="flex checker-circle">
                        <img src={{checkerPath}} class="checker" alt="logo" />
                    </div>
                </div>
                <!-- ++++++++++++++++++++++++++++ -->

                <div class="flex blue-box" *ngIf="trustees[i] && ((trustees[i].personalDone && trustees[i].contactsDone) || autofilled) && !trustees[i].done">
                    <p class="blue lead" style="font-weight: 500;" >
                        {{additionalDetailsText}}
                    </p>
                    <div class="line-2"></div>

                    <div *ngIf="getTrusteeFormGroup(i).get('Category')?.value.toLowerCase() === 'individual trustee'">
                        <div class="row">
                            <app-input [formGroup]="getTrusteeFormGroup(i)"
                                controlName="IDNoImage"
                                [validationID]="trustees[i].IDNo"
                                [fileFunction]="updateIDObject.bind(this)"
                                [existingFileName]="getTrusteeFormGroup(i).get('Relationship')?.value == 'Self' &&
                                        recordUpdate.IDNoImage != '' ? recordUpdate.IDNoImage.name : ''"
                                class="grid-item" label={{idFileLabel}} hint={{idHint}} inputType="file"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            <app-input [formGroup]="getTrusteeFormGroup(i)" class="grid-item"
                                controlName="KraPinImage"
                                [validationID]="trustees[i].KraPin"
                                label={{kraFileLabel}} inputType="file"
                                [fileFunction]="updateKraObject.bind(this)"
                                [existingFileName]="getTrusteeFormGroup(i).get('Relationship')?.value == 'Self' &&
                                        recordUpdate.KraPinImage != '' ? recordUpdate.KraPinImage.name : ''"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        </div>
        
                        <div class="row">
                            <app-input [formGroup]="getTrusteeFormGroup(i)" controlName="PassportImage"
                                [fileFunction]="updatePassportObject.bind(this)"
                                class="grid-item" label={{passportLabel}} inputType="file"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            <app-input [formGroup]="getTrusteeFormGroup(i)" class="grid-item"
                                controlName="CvImage" label={{cvLabel}} inputType="file"
                                [fileFunction]="updateCVObject.bind(this)"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        </div>
                    </div>
                    
                    <div *ngIf="getTrusteeFormGroup(i).get('Category')?.value.toLowerCase() === 'corporate trustee'">
                        <div class="row">
                            <app-input [formGroup]="getTrusteeFormGroup(i)" controlName="RegCertImage"
                                class="grid-item" label={{regCertFileLabel}} inputType="file"
                                [fileFunction]="updateRegCertObject.bind(this)"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                            <app-input [formGroup]="getTrusteeFormGroup(i)" class="grid-item" controlName="KraPinImage"
                                [fileFunction]="updateKraObject.bind(this)"
                                label={{kraFileLabel}} inputType="file"
                                [validationMessages]="validationMessages" [formErrors]="formErrors"></app-input>
                        </div>
                    </div>
                </div>

                <div class="flex done-box" *ngIf="trustees[i] && trustees[i].personalDone && trustees[i].contactsDone && trustees[i].done">
                    <p class="light-blue lead" >
                        {{additionalDetailsText}}
                    </p>
                    <div class="flex checker-circle">
                        <img src={{checkerPath}} class="checker" alt="logo" />
                    </div>
                </div>
                <!-- REMOVE BUTTON -->
                <div class="abs-view">
                    <div class="remove-button-alt" *ngIf="i > 0 || getTrustees().length > 1">
                        <button mat-stroked-button type="button" (click)="removeTrustee(i)">
                            <div style="display: flex; flex: 1; align-items: center;">
                                <span class="button-text">
                                    <u>{{ getTrustees().controls.length-1 === i ? removeButtonText : removeBenText }}</u>
                                </span>
                                <span class="spinning-global"
                                    *ngIf="currentIndex === i && loadingRemove">
                                    <mat-progress-spinner
                                        mode="indeterminate" diameter="25" strokeWidth="4">
                                    </mat-progress-spinner>
                                </span>
                            </div>
                        </button>
                    </div>
                </div>
                
            </div>
        </div>
    
        <!-- BUTTTON Add Trustee -->
        <div class="blueStroke row" [ngClass]="{'min-55' : getTrustees().length > 1 || trustees[trustees.length-1].done}">
            <button mat-stroked-button
                *ngIf="trustees[trustees.length-1] && trustees[trustees.length-1].done"
                type="button" (click)="addTrustee()">
                <span class="button-text">
                    {{ '+ Add New Trustee'}}
                </span>
            </button>
        </div>
    </div>

    <!-- BOTTOM OF FORM -->
    <app-form-bottom style="width: 100%;"
        [service]="estateControlService"
        [formValid]="trusteeForm.valid || trustees[trustees.length-1].done === true"
        [showContinue]="trusteeForm.pristine || trusteeForm.valid || trustees[trustees.length-1].done ? 'true' : 'false'"
        [saveFunction]="updateProgress.bind(this)"
        [text]="
            loading ? loadingText :
            trustees[trustees.length-1].done ? 'Next' :
            trustees[trustees.length-1].contactsDone ? 'Upload Documents' :
            trustees[trustees.length-1].personalDone ? 'Update Details' : 'Save Details' "
        [buttonClickHandler]="submitForm.bind(this)">
    </app-form-bottom>
</form>

