import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { createAmountAbove5KValidator } from './custom.validators';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() { }

  logValidationErrors(
    group: FormGroup,
    formErrors: { [key: string]: string },
    validationMessages: { [key: string]: { [key: string]: string } }
  ): { [key: string]: string } {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.controls[key];
      formErrors[key] = "";
      if (
        abstractControl &&
        !abstractControl.valid &&
        this.isControlVisible(abstractControl) &&
        (abstractControl.touched || abstractControl.dirty)
      ) {
        const messages = validationMessages[key];
        for (const keyError in abstractControl.errors) {
          if (keyError) {
            if (keyError !== "matDatepickerParse")
              formErrors[key] += `${messages[keyError]} `;
            else formErrors[key] += `${messages["required"]} `;
          }
        }
      }

      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(
          abstractControl,
          formErrors,
          validationMessages
        );
      }

      if (abstractControl instanceof FormArray) {
        for (const control of abstractControl.controls) {
          if (control instanceof FormGroup) {
            this.logValidationErrors(control, formErrors, validationMessages);
          }
        }
      }
    });
    return formErrors;
  }

  isControlVisible(control: AbstractControl): boolean {
    // This log helps debug the validity of the form and its controls
    // console.log('::::::::::::::::::::::::::::: FORM + CONTROLS DEBUG\n', control.parent);
    let parent = control.parent;
    while (parent) {
      if (parent.disabled) {
        return false;
      }
      parent = parent.parent;
    }
    return true;
  }

  addOrRemoveValidator(delimitor: Boolean, control: AbstractControl) {
    if (delimitor) control?.setValidators(Validators.required);
    else {
      control?.clearValidators();
      control?.updateValueAndValidity();
    }
  }

  addOrRemoveOneValidator(delimitor: Boolean, control: AbstractControl, validator: ValidatorFn | ValidatorFn[] | null) {
    if (delimitor) {if (validator) control?.setValidators(validator);}
    else {
      control?.clearValidators();
      control?.updateValueAndValidity();
    }
  }

  addOrRemoveSpecificValidators(
    delimitor: Boolean,
    control: AbstractControl,
    validatorList: [
      (control: AbstractControl<any, any>) => ValidationErrors | null, ValidatorFn
    ]) {
    
    if (delimitor) control?.setValidators(validatorList);
    else {
      control?.clearValidators();
      control?.updateValueAndValidity();
    }
  }

  addOrRemove3Validators(
    delimitor: Boolean,
    control: AbstractControl,
    validatorList: [
      (control: AbstractControl<any, any>) => ValidationErrors | null,
      ValidatorFn, ValidatorFn
    ]) {
    
    if (delimitor) control?.setValidators(validatorList);
    else {
      control?.clearValidators();
      control?.updateValueAndValidity();
    }
  }

  addOrRemoveValidityOnFormGroup(
    delimiter: Boolean,
    group: string,
    controlGroup: FormGroup,
    formGroupErrors: { [key: string]: string },
    validationMessages: { [key: string]: { [key: string]: string } }
  ) {
    const groupControl = controlGroup.get(group)!;
    groupControl.setErrors(null);
    formGroupErrors[group] = "";
    if (!delimiter) {
      formGroupErrors[group] = validationMessages[group]["required"];
      groupControl.setErrors({ invalid: true });
    }
  }
  
  addOrRemoveValidationDependent(
    initControl: AbstractControl,
    effectControl: string,
    groupControl:FormGroup,
    formGroupErrors:{[key:string]:string},
    validationMessages:{[key:string]:{[key:string]:string}},
  ) {
    initControl?.valueChanges.subscribe((data: Boolean | string) => {
      const control = groupControl.get(effectControl)!;
      const delish: boolean = data == true || data.toString().toLowerCase() == 'yes' || data.toString().toLowerCase().includes('other');
      if (control instanceof FormGroup)
        this.addOrRemoveValidityOnFormGroup(
          delish,
          effectControl,
          groupControl,
          formGroupErrors,
          validationMessages
        );
      else this.addOrRemoveValidator(delish, control);
    });
  }

  addOrRemoveConditionalValidation(
    initControl: AbstractControl,
    effectControl: string,
    groupControl: FormGroup,
    midControl: string | null,
    condition: boolean,
  ) {
    initControl?.valueChanges.subscribe(() => {
      let control = groupControl.get(effectControl)!;
      if (midControl) {
        const arr = groupControl.get(midControl) as FormArray;
        const montrol = arr.at(0) as FormGroup;
        control = montrol.get('Principal')!.get(effectControl)!;
      }
      if (condition) {
        this.addOrRemoveValidator(true, control);
      } else {
        this.addOrRemoveValidator(false, control);
      }
        
    });
  }

  addOrRemoveValidationOnValues(
    initControl: AbstractControl,
    effectControl: string,
    groupControl:FormGroup,
    checkerStrings: string[] | boolean[] | null | undefined,
  ) {
    initControl?.valueChanges.subscribe((data: string) => {
      const control = groupControl.get(effectControl)!;
      if (checkerStrings && checkerStrings.length > 0 && checkerStrings.some((val: any) => val === data))
        this.addOrRemoveValidator(true, control);
      else
        this.addOrRemoveValidator(false, control);
    });
  }

  addOrRemoveValidationOnValue(
    initControl: AbstractControl,
    effectControl: string,
    groupControl:FormGroup,
    checkerString: string | boolean | null | undefined,
  ) {
    initControl?.valueChanges.subscribe((data: string) => {
      const control = groupControl.get(effectControl)!;
      if (data === checkerString)
        this.addOrRemoveValidator(true, control);
      else
        this.addOrRemoveValidator(false, control);
    });
  }

  addOrRemoveValidationOnValue2(
    initControl: AbstractControl,
    effectControl: string,
    groupControl:FormGroup,
    checkerString: boolean | undefined | null | '',
  ) {
    initControl?.valueChanges.subscribe((data: string) => {
      const control = groupControl.get(effectControl)!;
      if (checkerString === true)
        this.addOrRemoveValidator(true, control);
      else
        this.addOrRemoveValidator(false, control);
    });
  }

  addOrRemoveValidationOnFormArrayValue(
    initControl: AbstractControl,
    effectControl: string,
    checkerString: string | boolean,
  ) {
    initControl?.valueChanges.subscribe((data: string) => {
      const bens = initControl as FormArray;
      bens.controls.forEach(c => {
        const control = c.get(effectControl)!;
        if (data === checkerString)
          this.addOrRemoveValidator(true, control);
        else
          this.addOrRemoveValidator(false, control);
      })
    });
  }

  addOrRemoveCurrenciedValidator(delimitor: Boolean, control: AbstractControl) {
    if (delimitor) control?.setValidators([Validators.required, createAmountAbove5KValidator()]);
    else {
      control?.clearValidators();
      control?.updateValueAndValidity();
    }
  }
}
